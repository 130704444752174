.account-info-svg-wrapper {
  width: 113px;
  height: 113px;
  border-radius: 50%;
  background-color: gray;
  display: flex;
  justify-content: center;
  align-items: center;
  .account-info-svg-group-outer {
    path {
      fill: white;
    }
  }
}
