.hub-sv-cent-inf-container {
  display: flex;
  justify-content: flex-start;
  color: #fcedd6;
  height: 100%;
  align-items: center;
  text-transform: uppercase;
  font-size: 0.9em;
  font-weight: 700;
  letter-spacing: 0.5px;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media screen and (max-width: 450px) {
    max-width: 100px;
    width: fit-content;
    overflow: hidden;
    display: flex;
    span {
      position: relative;
      padding: 0px 4px;
      animation: leftright 3s infinite alternate ease-in-out;
    }
  }
}

.cent-inf-btm {
  width: fit-content;
  display: flex;
  justify-content: center;
  color: #fcedd6;
  height: 100%;
  align-items: center;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.5px;
  white-space: nowrap;
}

.cent-inf-btm-mobile {
  width: fit-content;
  display: flex;
  justify-content: center;
  color: #fcedd6;
  height: 100%;
  align-items: center;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.5px;
  white-space: nowrap;
}

@media only screen and (max-width: 340px) {
  .cent-inf-btm {
    font-size: 9px;
  }
}

@media only screen and (max-width: 500px) {
  .cent-inf-btm {
    font-size: 11px;
  }
}

.back-button {
  display: flex;
  justify-content: center;
  color: #fcedd6;
  height: 100%;
  align-items: center;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.5px;
  margin-right: 10px;
  cursor: pointer;
}

@keyframes leftright {
  0%,
  20% {
    transform: translateX(0%);
    left: 0%;
  }
  80%,
  100% {
    transform: translateX(-100%);
    left: 100%;
  }
}

.fader {
  position: absolute;
  top: 0;
  height: 100%;
  width: 0px;

  &.fader-left {
    left: 0;
  }

  &.fader-right {
    right: 0;
  }
}
