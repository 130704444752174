$options: #365899;
$title: #1d2129;
$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);

.hub-chat {
  margin-right: 14px;

  .hub-chat-icon {
    position: relative;
    height: 32px;
    width: 29px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-top: 12px;
    .hub-chat-image {
      height: 24px;
      width: 24px;
      cursor: pointer;
    }

    .hub-chat-count {
      height: 20px;
      width: 20px;
      max-width: 37px;
      min-height: 17px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 13px;
      right: -14px;
      font-size: 14px;
      border-radius: 50%;
      background-color: $var-color--dark-orange;
      font-weight: 400;
      color: white;
      cursor: pointer;
    }
  }

  .hub-chat-container {
    border: 1px solid rgba(100, 100, 100, 0.4);
    width: 340px;
    font-size: 12px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.25);
    position: absolute;
    z-index: 1;
    background-color: rgba(9, 19, 43, 0.6);
    border-radius: 1px;

    .hub-chat-header {
      display: flex;
      justify-content: space-between;
      padding: 8px;
      background-color: rgba(9, 19, 43, 0.6);

      .hub-chat-header-title {
        color: $var-color--light-orange;
        font-weight: bold;
      }

      .hub-chat-header-option {
        margin: 0 5px;
        color: $options;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }

    .hub-chat-items {
      height: 400px;
      overflow-y: auto;

      .hub-chat-loader {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .hub-chat-empty-chat {
      display: flex;
      align-items: center;
      justify-content: center;
      height: inherit;
    }

    .hub-chat-footer {
      text-align: center;
      padding: 5px;
      color: $options;
      text-transform: capitalize;
      font-weight: bold;

      .hub-chat-see-all {
        cursor: pointer;
        color: $options;
      }
    }
  }
}

.chat-alert-svg-wrapper {
  display: flex;
  justify-content: center;
  height: inherit;
  width: inherit;
  align-items: center;
  .chat-alert-svg-outer {
    width: 50px;
    height: 50px;
    fill: rgba(255, 255, 255, 0.7);
  }
}
