.dashboard-content-wrapper {
  position: relative;
  max-width: 1440px;
  width: 100%;
  height: calc(100% - 88px);
  margin-left: auto;
  margin-right: auto;
  background-size: cover;
  overflow: hidden;
  z-index: 1;
  &::-webkit-scrollbar {
    display: none;
  }
  .dashboard-contents-container {
    max-width: 1000px;
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: left;
    background-size: cover;
    flex-wrap: wrap;
    overflow-y: auto;
    z-index: 1;
  }
  .dashboard-block-wrapper {
    // height: 250px;
    font-size: 1.1em;
    font-weight: 700;
    letter-spacing: 5px;
    width: 450px;
    // height: calc(50% - 37px);
    cursor: pointer;
    padding-top: 17px;
    // margin: 0.5% 6% 0.5% !important;
    border-radius: 25px;
    overflow: hidden;
    // height: 230px

    color: $color-dashboard-item;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0);
    margin: 10px;
    text-align: center;
    svg {
      max-width: 90%;
    }

    .dashboard-block-footer {
      padding-top: 8px;
      font-size: 0.6em;
      min-height: 22px;
      letter-spacing: 2px;
    }
    .outer-svg-layer {
      padding: 0 0 10px 0;

      &--impact {
      }
    }
    .dashboard-block-svg {
      fill: $color-dashboard-item;
    }
    &:hover {
      background-color: $color-block-hover-background;
      border-width: 1px;
      border-color: #a7a7a700;
      box-shadow: 8px 8px 2px rgba(0, 0, 0, 0.4);
      color: $color-dashboard-highlight;
      .dashboard-block-svg {
        fill: $color-dashboard-highlight;
      }
    }
  }

  @media screen and (min-width: 300px) and (max-width: 480px) {
    .dashboard-contents-container {
    }
    .dashboard-block-wrapper {
      padding: 10px 80px;
      width: 100%;
      height: 80px;
    }
  }
}
.small-block-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dashboard-center-info {
  width: 100%;
  display: flex;
  align-items: center;
  img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-right: 10px;
  }
}

.dashboard-bottom-padder {
  height: 88px;
}
