//screensize bigger than normal laptops
@media screen and (min-width: 1441px) {
  #getting-started-page-content-wrapper-mobile {
  }
}
@media screen and (max-width: 1440px) {
  #getting-started-page-content-wrapper-mobile {
  }
}
// screen#1 iPad pro vertical
@media screen and (max-width: 1024px) {
  #getting-started-page-content-wrapper-mobile {
  }
}
// screen#2 ipad vertical

@media screen and (max-width: 768px) and (max-width: 860px) {
  #getting-started-page-content-wrapper-mobile {
  }
}
// screen#3  surface duo

@media screen and (min-width: 481px) and (max-width: 767px) {
  #getting-started-page-content-wrapper-mobile {
    .getting-started-sections {
      .title-wrapper {
        width: 90%;
        margin-left: 10%;
        text-align: center;
      }
      .title {
        font-size: 1em;
      }
      .getting-started-list-content-wrapper {
        padding-right: 10px;
        width: calc(100% - 30px);
      }
    }
  }
}
// screen#4 iphone 12 pro max,
@media screen and (min-width: 415px) and (max-width: 480px) {
  #getting-started-page-content-wrapper-mobile {
    .getting-started-sections {
      .title-wrapper {
        width: 90%;
        margin-left: 10%;
        text-align: center;
      }
      .title {
        font-size: 1em;
      }
      .getting-started-list-content-wrapper {
        padding-right: 10px;
        width: calc(100% - 30px);
      }
    }
  }
}
// screen#5 iphone 11 && pixel 2 && pixel2 xl iphone 6/7/8, iphone x
@media screen and (min-width: 391px) and (max-width: 414px) {
  #getting-started-page-content-wrapper-mobile {
    .getting-started-sections {
      .title-wrapper {
        width: 90%;
        margin-left: 10%;
        text-align: center;
      }
      .title {
        font-size: 1em;
      }
      .getting-started-list-content-wrapper {
        padding-right: 10px;
        width: calc(100% - 30px);
      }
    }
  }
}
// screen#6 iphone 12, iphone 12 pro, iphone 6/7/8
@media screen and (min-width: 361px) and (max-width: 390px) {
  #getting-started-page-content-wrapper-mobile {
    .getting-started-sections {
      .title-wrapper {
        width: 90%;
        margin-left: 10%;
        text-align: center;
      }
      .title {
        font-size: 1em;
      }
      .getting-started-list-content-wrapper {
        padding-right: 10px;
        width: calc(100% - 30px);
      }
    }
  }
}
// screen#7 iphone 12 mini && Moto G4 && Galaxy S9 && galaxy s5 galaxy s9
@media screen and (min-width: 321px) and (max-width: 360px) {
  #getting-started-page-content-wrapper-mobile {
    .getting-started-sections {
      .title-wrapper {
        width: 90%;
        margin-left: 10%;
        text-align: center;
      }
      .title {
        font-size: 1em;
      }
      .getting-started-list-content-wrapper {
        padding-right: 10px;
        width: calc(100% - 30px);
      }
    }
  }
}
// screen#8 iphone 5/se, galaxy fold
@media screen and (min-width: 280px) and (max-width: 320px) {
  #getting-started-page-content-wrapper-mobile {
    .getting-started-sections {
      .title-wrapper {
        width: 90%;
        margin-left: 10%;
        text-align: center;
      }
      .title {
        font-size: 1em;
      }
      .getting-started-list-content-wrapper {
        padding-right: 10px;
        width: calc(100% - 30px);
      }
    }
  }
}
