.drag-item {
  border: solid 1px black;
  padding: 30px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.hr-item {
  width: 100px;
}