.hub-community-container {
  max-width: 1440px;
  min-height: calc(100% - 88px);
  width: 100%;
  height: calc(100% - 220px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 1;
  overflow-y: auto;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(255, 255, 255, 0.5);
  &::-webkit-scrollbar {
    display: none;
  }
  .hub-community-contents {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    .hub-community-sections-wrapper {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
    }
  }
}

.hub-friends-container {
}
