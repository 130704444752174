@media screen and (min-width: 1441px){
  #mobile-adjusted-banner{
    .banner-lading-header{
      font-size: 60px;
      text-align: center;
      top: 50%;
      left: 50%;
      width: 80%;
      transform: translate(-50%, -50%)
    }
  }
  .banner-container{
    width: 100vw;
    img{
      width: 100%
    }
  }
  .banner-regular-container{
    width: 100vw;
    img{
      width: 100%;
    }
  }
}
@media screen and (min-width: 840px) and (max-width: 1080px){
 #mobile-adjusted-banner{
    .banner-lading-header{
      font-size: 36px;
      text-align: center;
       top: 37%;
    left: 9%;
    }
  }
}

@media screen and (min-width: 1081px)and (max-width: 1240px){
  #mobile-adjusted-banner{
    .banner-lading-header{
      font-size: 40px;
      text-align: center;
       top: 35%;
    left: 17%;
    }
  }
}
@media screen and (min-width: 641px) and (max-width: 840px){
 #mobile-adjusted-banner{
    .banner-lading-header{
      font-size: 30px;
      text-align: center;
      top: 50%;
      left: 50%;
      width: 80%;
      transform: translate(-50%, -50%)
    }
  }
}
@media screen  and (min-width: 481px) and (max-width: 640px){
 #mobile-adjusted-banner{
    .banner-lading-header{
      font-size: 24px;
      text-align: center;
      top: 50%;
      left: 50%;
      width: 80%;
      transform: translate(-50%, -50%);
    }
  }
}
@media screen and (max-width: 480px){
  .hub-page-container{
  .hub-page-padder{
    align-items: flex-start;
  }
}
  #mobile-adjusted-banner{
    .banner-lading-header{
      font-size: 30px;
      text-align: center;
      top: 50%;
      left: 50%;
      width: 80%;
      transform: translate(-50%, -50%)
    }
  }

  #mobile-adjusted-two-pics{
    .top-section-second{
      text-align: center;
    }
  }
  #banner-regular-mobile{
    .banner-lading-header-large{
      font-size: 40px;
      top: 3%;
    left: 4%;;
    }
  }
}






  
