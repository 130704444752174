@media screen and (max-width: 480px) {
  #mobile-top-nav {
    .top-nav-padder {
      //  height: 74%;
      .top-nav-left {
        width: 199px;
        height: 100%;
        min-width: 199px;
        .logoimagelink {
          display: flex;
          align-items: center;
          width: 63%;
          display: flex;
          align-items: center;
        }
        .logoimage {
          width: 100%;
          height: 89%;
          object-fit: contain;
          /* margin-left: 20px; */
          width: 89%;
          margin-left: 11px;
        }
      }
      .top-nav-right {
        width: 78px;
        .top-nav-login-button {
          width: 108px;
        }
      }
    }
  }
  #account-box {
    .encloser {
      width: 84%;
    }
  }
  #hub-page-wrapper-mobile {
    .hub-placeholder-wrapper {
      .hub-pl-bottom {
        justify-content: center;
        span {
          text-align: center;
          font-size: 18px;
        }
      }
    }
  }
  .logged_in_profile_container .logged_in_down_arrow {
    margin-right: 7px;
  }
  .logged_in_profile_container .logged_in_up_arrow {
    margin-right: 7px;
  }

  .logged_in_profile_container {
    justify-content: space-between;
    width: 153px;
  }
  .logged_in_profile_container .logged_in_name {
    width: calc(100% - 96px);
    display: flex;
    justify-content: center;
    color: white;
  }
  .hub-top-nav-left {
    width: 199px;
    height: 100%;
    min-width: 199px;
  }
  #top-nav-right-hub {
    width: 114px;
  }
  .logged_in_name {
    font-size: 13px;
  }
  #logoimage-hub {
    height: 89%;
    object-fit: contain;
    margin-left: 11px;
    width: 81%;
  }
  .logged_in_profile_container {
    height: 30px;
    display: flex;
    justify-content: center;
    margin-left: 22px;
  }
  #logoimagelink-hub {
    display: flex;
    align-items: center;
    width: 69%;
  }

  .hub-top-nav-padder {
    width: calc(100% - 48px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 10px 24px;
    background-color: #273a07;
    height: calc(100% - 19px);
  }

  .opened-box-hamburger-right {
    position: absolute;
    top: 71px;
    right: 30px;
    z-index: 20;
  }

  .hamburger-contents-container-right {
    width: 140px;
  }

  .opened-box-hamburger {
    position: absolute;
    top: 71px;
    left: 8px;
    z-index: 10;
  }

  .hamburger-contents-container {
    width: 196px;
  }
}
@media screen and (max-width: 768px) {
  #two-stack-blocks {
    padding: 0px 0px;
    margin-top: 0px;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .two-stack-container {
      margin: 0 auto;
      width: 300px;
      height: 200px;
      margin-top: 30px;
    }
  }

  #c-text {
  }

  #mobile-txt-footer {
    a {
      font-size: 10px;
    }
  }
}
@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 1440px) {
}
@media screen and (min-width: 1441px) {
  .banner-container {
    width: 100vw;
    img {
      width: 100%;
    }
  }
  .banner-regular-container {
    width: 100vw;
    img {
      width: 100%;
    }
  }
}

@media screen and (max-width: 320px) {
  #c-text {
    span {
      font-size: 11px;
    }
  }
}

@media screen and (max-width: 320px) {
  #mobile-top-nav {
    .top-nav-padder {
      .top-nav-right {
        width: 33px;
        .top-nav-login-button {
          height: 29px;
        }
      }
      .top-nav-left {
        .logoimagelink {
          width: 68%;
        }
        .logoimage {
          height: 93%;
          text-decoration: none;
          margin-left: 5px;
          border: 1px solid transparent;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

@media screen and (max-width: 360px) {
  #mobile-top-nav .top-nav-padder .top-nav-right {
    width: 72px;
  }
}

@media screen and (max-width: 320px) {
  #mobile-top-nav .top-nav-padder .top-nav-right {
    width: 72px;
  }
  .top-section-second {
    text-align: center;
  }
  .top-nav-left {
    margin-left: 0px;
  }
}

@media screen and (max-width: 1118px) {
  .page-content-wrapper
    form
    .signup-block-top
    .select-check
    .select-check-container {
    margin: 20px 0px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    span {
      text-align: center;
    }
  }
}

@media screen and (max-width: 320px) {
  .logged_in_profile_container {
    justify-content: space-between;
    width: 126px;
  }

  #top-nav-right-hub {
    width: 72px;
  }
}
