$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;
.horizontal-navigator-filter-wrapper {
  display: flex;
  flex-direction: column;
  width: 95vw;
  margin: 0 auto;
  margin-top: 10px;
  max-width: 1440px;
  .navigator-filter--top {
    display: flex;
    justify-content: space-between;
    .navigator-filter__scroll-prev-button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 65px;
      width: 45px;
      cursor: pointer;
      .nav-filter-svg-outer {
        width: 40px;
        height: 50px;
        fill: rgba(255, 255, 255, 0.4);
        &:hover {
          fill: #f7b844;
        }
      }
    }
    .navigator-filter__contents {
      display: flex;
      height: auto;
      width: calc(100% - 110px);
      flex-direction: column;
      .navigator-filter__contents__top {
        width: 100%;
        height: 25px;
        background-color: rgba(255, 255, 255, 0.6);
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        span {
          color: $var-color--dar-blue;
          font-size: 18px;
        }
      }
      .navigator-filter__contents__bottom {
        width: 100%;
        display: flex;
        height: 40px;
        justify-content: space-between;
        .navigator-filter__contents__bottom--seasons {
          display: flex;
          height: 100%;
          width: calc(25% - 5px);
          justify-content: center;
          align-items: center;
          // color: rgba(255, 255, 255, 0.3);
          background-color: rgba(255, 255, 255, 0.3);
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          cursor: pointer;
          &:hover {
            background-color: rgba(255, 255, 255, 0.6);
            span {
              color: $var-color--dar-blue;
            }
          }
        }

        .season-selected {
          background-color: rgba(255, 255, 255, 0.6);
          span {
            color: $var-color--dar-blue;
          }
        }
      }
    }
    .navigator-filter__scroll-next-button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 65px;
      width: 40px;
      cursor: pointer;
      .nav-filter-svg-outer {
        width: 40px;
        height: 50px;
        fill: rgba(255, 255, 255, 0.4);
        &:hover {
          fill: #f7b844;
        }
      }
    }
  }
  .navigator-filter--bottom {
    width: calc(100% - 110px);
    height: 35px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 5px;
    padding-left: 6px;
    .navigator-filter--bottom--buttons {
      width: 22%;
      height: 100%;
      display: flex;
      margin: 4px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      cursor: pointer;
      color: white;
      &:hover {
        span {
          color: $var-color--dar-blue;
        }
      }
    }
    .all {
      background-color: $var-color--button-unselected-blue;
      &:hover {
        background-color: $var-color--light-blue;
        span {
          color: $var-color--dar-blue;
        }
      }
    }
    .my-adventures {
      background-color: $var-color--button-unselected-blue;
      &:hover {
        background-color: $var-color--light-blue;
        span {
          color: $var-color--dar-blue;
        }
      }
    }
    .invited {
      background-color: $var-color--button-unselected-blue;
      &:hover {
        background-color: $var-color--light-blue;
        span {
          color: $var-color--dar-blue;
        }
      }
    }
    .shared {
      background-color: $var-color--button-unselected-blue;
      &:hover {
        background-color: $var-color--light-blue;
        span {
          color: $var-color--dar-blue;
        }
      }
    }

    .selected-type-all {
      span {
        color: $var-color--dar-blue;
      }
      background-color: $var-color--light-blue;
    }
    .selected-type-my-adventures {
      span {
        color: $var-color--dar-blue;
      }
      background-color: $var-color--light-blue;
    }
    .selected-type-invited {
      span {
        color: $var-color--dar-blue;
      }
      background-color: $var-color--light-blue;
    }
    .selected-type-shared {
      span {
        color: $var-color--dar-blue;
      }
      background-color: $var-color--light-blue;
    }
  }
}
