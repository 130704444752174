.page-container {
  min-width: 100vw;
  min-height: 100vh;
  max-width: 100vw;
  display: flex;
  // justify-content: center;
  flex-direction: column;
  align-items: center;
  // background-color: #09132B;
  .middle-span-landing {
    display: flex;
    justify-content: center;
    height: 100px;
    text-align: center;
    color: #f7b844;
    font-size: 24px;
  }
}

.tagline {
  text-align: center;
  font-size: 23px;
  color: #ffffff;
  // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.page-content-wrapper {
  max-width: 1440px;
  margin-top: 68px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: calc(100vh - 242px);
  width: 100%;
  padding-bottom: 30px;
}

.landing-two-stack-groups {
  width: 100%;
  display: flex;
  border-top: 6px solid #09132b;
  flex-direction: column;
  margin-top: 30px;
  .two-stack-headers {
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    border-top: 2px solid #f7b844;
    color: #f7b844;
    width: 98%;
    margin: 0 auto;

    span {
      // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
      font-weight: 700;
      font-size: 24px;
    }
  }

  .two-stack-blocks {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 50px;
    width: calc(100% - 100px);
    margin-top: 30px;
  }
}

#special-orange {
  color: #f7b844;
}
