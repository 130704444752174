
.two-stack-container{
  height: 169px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 22px 0px;
  width:200px;
  border:1px solid white;
  border-radius: 40px;
    background-color: #2E364B;
  &:hover{
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-name: hvr-ripple-out;
    animation-name: hvr-ripple-out;
    
    .svgJoinOuter{
      
      .svgJoin {
      fill: #F7B844;
      }
    }

    .svgBuildOuter{
      .svgBuild{
          fill: #F7B844;
        }
      }











      
      .svgTagAndSaveOuter {
          .svgTagAndSave{
            fill: #F7B844;
          }
      }
      
      .svgInviteOuter{
          .svgInvite{
            fill: #F7B844;
        }
      }
      .svgCollaborateOuter{
          .svgCollaborate{
            fill: #F7B844;
          }
      } 

      .svgLinkOuter{
        .svgLink{
          fill: #F7B844;
        }
    } 
      
      .svgShareOuter{
          .svgShare{
            fill: #F7B844;
          }
      }
      .svgStewardOuter{
          .svgSteward{
            fill: #F7B844;
          }
        }
      .svgCommunityOuter{
        .svgCommunity{
          fill: #F7B844;
        }
      }
      .svgVoiceOuter{
        .svgVoice{
      
          fill: #F7B844;
        }
      }
      .two-stack-text{
    color: #F7B844;
      }
  }    
}  





.two-stack-logo{
      width: 87px;
}

.two-stack-text{
  // font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: 500px;
  font-size: 24px;
  margin-top:30px;
  color: white;
}


@-webkit-keyframes hvr-ripple-in {
  100% {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
  }
}
@keyframes hvr-ripple-in {
  100% {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
  }
}