.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: $soleilSemibold;
  border: none;
  outline: none;
  &.bg-Burgundy {
    background-color: $burgundy;
  }

  &.bg-Warm7 {
    background-color: $warm7;
  }
  &.bg-Green1 {
    background-color: $green1;
  }

  &.bg-Cool7 {
    background-color: $cool7;
  }
  &.bg-Green4 {
    background-color: $green4;
  }
  &.txt-white {
    color: white;
  }
  &.all-caps {
    text-transform: uppercase;
  }

  &.medium {
    width: 179px;
    height: 45px;
  }
  &.smallMedium {
    width: 120px;
    height: 26px;
  }
  &.small {
    width: 89px;
    height: 26px;
  }
  &.roundedCorner {
    border-radius: 5px;
  }
}
@media screen and (max-width: 480px) {
  .buttons {
    &.bg-Burgundy {
    }

    &.bg-Warm7 {
    }
    &.bg-Green1 {
    }

    &.bg-Cool7 {
    }
    &.bg-Green4 {
    }
    &.txt-white {
    }
    &.all-caps {
    }
    &.medium {
    }
    &.smallMedium {
    }
    &.small {
    }
    &.roundedCorner {
    }
  }
}
