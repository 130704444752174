.image-box-container-border{
    left: 50%;
    top: 50%;
    border-radius: 15px;
    transform: translate(-50%, -65%);
    box-shadow: 0 0 0 400px #00000000;
    position: absolute;
    align-self: center;
    border-style:solid;
    border-width: 3px;
    border-color: rgba(255, 255, 255, 0.749);
    width: 300px;
    height: 264px;
    z-index: 20;
}

.image-box-container{
    border-radius: 15px;
    border-width: 3px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -60%);
    position:absolute;
    width: 400px;
    height: 400px;
    z-index: 1;
}
.slider{
    position:absolute;
    top:80%;
    margin: 0 100px 0 20px;
}