.howto-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.howto-modal-body {
  background-color: #fff;
  border-radius: 40px;
  width: 400px;
  height: 700px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
}

.howto-modal-container {
  padding: 24px;
  height: calc(100% - 50px);
  width: calc(100% - 50px);
}

.howto-modal-close-btn {
  float: right;
  font-family: 'Soleil';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 20px;
  /* identical to box height, or 80% */
  text-align: center;
  border: none;
  border-radius: 5px;
  color: #273a07;
  background-color: rgba(0, 0, 0, 0);
}

.howto-modal-close-btn:hover {
  color: #b34104;
}

.howto-modal-logo {
  width: fit-content;
  margin: auto;
}

.howto-modal-page-title {
  margin-top: 40px;
  width: 100%;
  font-family: 'Soleil';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 20px;
  /* identical to box height, or 80% */

  text-align: center;

  color: #273a07;
}

.howto-modal-page-content {
  width: 300px;
  max-width: 300px;
  height: calc(100% - 420px);
  overflow: auto;
  padding-right: 0px;
  margin: auto;
  margin-top: 25px;
  font-family: 'Albiona';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 20px;
  /* or 111% */

  text-align: center;

  color: #273a07;
}

.howto-modal-page-content::-webkit-scrollbar {
  display: block;
  width: 5px;
}
.howto-modal-page-content::-webkit-scrollbar-track {
  background-color: #eee;
}

.howto-modal-page-content::-webkit-scrollbar-thumb {
  background-color: dimgrey;
  border-radius: 2px;
  border-right: none;
  border-left: none;
}

.howto-modal-page-content::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 0px;
}

.howto-modal-page-content::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 0px;
}

.howto-modal-page-footer {
  width: 100%;
  text-align: center;
  margin-top: 16px;
}
