.contact-us-blocks{
   display:flex;
    flex-direction: row;
    justify-content: space-around;
    padding:0px 0px;
    width:calc(100% - 100px);
}

.two-stack-container-contact{
      height: 75px;
    text-align: center;
    display: flex;
    justify-content: flex-start;
    align-items: ceil($number: 0);
    flex-direction: row;
    padding: 0px 0px;
    width:290px;
    // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;


}

.contact-logo{
  width: 25px;
  height: 25px;
}

.contact-text-block{
  margin-left: 20px;
  // width: 
  text-align: start;
  color:white;
}

.email-reset-under{
  &:hover{
    color: #6EC6ED;
  }
}