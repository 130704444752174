.min-blk-btn-wrapper {
  width: 32px;
  height: 35px;
  background: none;
  border: none;
  padding: none;
  .min-blk-btn {
    width: 35px;
    height: 35px;
    background: none;
    border: none;
    &:focus {
      outline: none;
      border: none;
    }
    &:hover {
      cursor: grab;
    }
    .min-blk-btns-stcker-up {
      display: flex;
      flex-direction: row;
      height: 100%;
      align-items: center;
      width: 100%;
      justify-content: space-between;

      .svg-thumbs-up {
        fill: rgba(255, 255, 255, 0.35);
        margin-left: 3px;
      }
      .like-counter {
        color: rgba(255, 255, 255, 0.35);
        margin-left: 4px;
        margin-right: 4px;
      }
      .like-counter-left {
        color: rgba(255, 255, 255, 0.35);
        margin-left: -2px;
        margin-right: 4px;
      }
      &:hover {
        cursor: pointer;
        .svg-thumbs-up {
          fill: #f7b844;
        }
        /*         .like-counter {
          color: #6ec6ed;
        } */
      }
    }
    .min-blk-btns-stcker-dn {
      display: flex;
      flex-direction: row-reverse;
      height: 100%;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      .svg-thumbs-down {
        fill: rgba(255, 255, 255, 0.35);
      }
      .like-counter {
        color: rgba(255, 255, 255, 0.35);
        margin-right: 3px;
      }
      &:hover {
        cursor: pointer;
        .svg-thumbs-down {
          fill: #f7b844;
        }
        /*         .like-counter {
          color: #6ec6ed;
        } */
      }
    }

    .blk-indic-voted {
      svg,
      // div,
      path,
      span {
        fill: #f7b844 !important;
        color: #f7b844 !important;
      }
    }
    .blk-indic-vote-nmb {
      color: #f7b844 !important;
    }
    .svg-thumbs-up-outer {
      width: 15px;
      height: 15px;
    }
    .svg-thumbs-down-outer {
      width: 15px;
      height: 15px;
    }
    .min-blk-btn-img {
      width: 60%;
      height: 60%;
    }
    .like-counter {
      // margin:0px 3px;
    }
  }

  .tool-tip {
    position: relative;
    display: inline;
    .tool-tip-text {
      visibility: hidden;
      width: 120px;
      //background-color: #ffffff;
      color: #f7b844;
      text-align: center;
      font-size: 10px;
      padding: 0px 0;
      border-radius: 6px;
    }
  }
  .tool-tip:hover .tool-tip-text {
    visibility: visible;
  }
}
