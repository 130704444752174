$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;

.user-notifications-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .user-notifications-header-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 50px;
    span {
      color: $green7;
      font-weight: 700;
    }
  }
}

.notification-back-button-wrapper {
  background-color: transparent;
  border: none;
  outline: none;
  margin-left: -15px;
  cursor: pointer;
  &:hover {
    svg {
      path {
        fill: $var-color--light-orange;
      }
    }
  }
  &:focus {
    outline: none;
    border: none;
  }
}
