
.two-stack-bold-container{
      min-height: 159px;
    text-align: center;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 22px 0px;
    min-width:170px;
    max-width: 70%;
    height:auto;
    color:white;
    // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.two-stack-logo-bold{
      width: 87px;
      height:76px;
}

.two-stack-text{
  // font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: 500;
  font-size: 24px;
  margin-top:30px;
}


.two-stack-bold-header{
  margin-top: 20px;
  font-size: 24px;
  // font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: 700;
  color: #F7B844;
}

.two-stack-bold-text{
  margin-top:20px;
  font-size: 24px;
  font-weight: 300;
}

.bold-text{
  font-weight: 900;
  font-size:26px;
}