.snap-scroll-wrapper {
  overflow-y: hidden;

  .snap-scroll-container {
    overflow-y: auto;
    max-height: 100%;
    // scroll-snap-type: mandatory;
    scroll-snap-points-y: repeat(350px);
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      display: none;
    }
    .block-summary-page-sections {
      scroll-snap-align: start;
    }
  }
}
