$color-background: #3c4352;
$color-block-highlight: #515a6e;
$color-block-shadow: rgba(0, 0, 0, 0.2);
$color-highlight: #f7b844;
$color-highlight-v2: #273a07;
$var-color--dark-orange: #da4431;

.version {
  span {
    // color: rgba(255, 255, 255, 0.4);
    color: $green7;
    font-size: 12px;
  }
  margin-bottom: 20px;
}
.custom-line-element-wrapper {
  padding: 5px 0 5px 0;
  display: flex;
  justify-content: center;
  color: $green7;
  font-size: 12px;
  letter-spacing: 3px;
  .center-text {
    font-weight: 700;
    font-size: 16px;
  }
  // .custom-line-element{

  //     width: 80%;
  //     border: .2px solid #a7a7a7;
  // }
}
.action-buttons-section-wrapper,
.map-buttons-section-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 0 0 7px;
  .block-text-description {
    padding: 5px 0 0 0;
    text-align: center;
  }

  .action-button-wrapper {
    &:hover {
      color: $warm7;
      fill: $warm7;
      cursor: pointer;
      .menu-drawer-svg {
        fill: $warm7;
      }
    }
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 2px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 5px 10px 5px 0px;
    padding: 0px 5px 0 5px;
    width: 90px;
    height: 80px;
    color: $green7;
    .menu-drawer-svg {
      fill: $green7;
    }

    &:hover {
      background-color: $color-block-hover-background;
      box-shadow: 4px 4px 3px $color-block-shadow;
      fill: $color-dashboard-highlight;
    }
  }
}
.navigation-section-wrapper {
  font-size: 15px;
  letter-spacing: 3px;
  padding: 5px 0 0 50px;
  .menu-navigation-text {
    padding: 5px 0 5px 0;
    color: $green7;
    cursor: pointer;
  }
  .menu-navigation-button {
    &:hover {
      div {
        color: $warm7;
        fill: $warm7;
      }
    }
  }
}

.drawer-menu-divider-wrapper {
  display: flex;
  height: auto;
  align-items: center;
  width: 100%;
  justify-content: center;
  flex-direction: row;
  span {
    color: $green7;
    font-size: 12px;
    letter-spacing: 3px;
    margin-right: 34px;
  }
  hr {
    border: none;
    color: $green7;
    background-color: $green7;
    height: 1px;
    width: 25%;
  }
  .drawer-menu-cog-outer {
    width: 35px;
    height: 35px;
    path {
      fill: $green7;
    }
    margin-left: -1px;
  }
}
