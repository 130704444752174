.CancelSubscribePage {
  margin: auto;
  margin-top: 45px;
  width: 60%;
  background-color: #09132b;
  border-radius: 5px;
  overflow: hidden;
  .CancelSubscribePage_nav {
    width: 100%;
    background-color:#74c485;
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    padding: 6px 6px;
    color: #273a07;
    box-sizing: border-box;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .CancelSubscribePage__body1 {
    padding: 40px 15px;
    color: white;
    font-size: 30px;
    font-weight: bold;
  }
  .CancelSubscribePage__body2 {
    width: 100%;
    // border: 1px solid #09132b;
    background-color: white;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    .CancelSubscribePage__body2__line {
      width: 1px;
      height: 90%;
      background-color: rgba(0, 0, 0, 0.5);
      margin: auto;
    }
    .CancelSubscribePage__body2__box {
      width: 90%;
      margin: auto;
      margin-top: 10px;
      margin-bottom: 10px;
      // border: 1px solid rgba(0, 0, 0, 0.1);
      transition: all 0.2s;
      box-sizing: border-box;
      padding: 10px;
    }
    .CancelSubscribePage__body2__textbox {
      padding-top: 15px;
      padding-right: 15px;
      box-sizing: border-box;
      margin: auto;
      .payment-successful-text-contents-wrapper {
        width: 50%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        & > div {
          width: 20px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 5px;
          span {
            color: red;
          }
        }
        .payment-successful-text {
          font-size: 0.9em;
        }
      }
      .CancelSubscribePage__body2__textbox-text {
        font-size: 13px;
        color: rgba(0, 0, 0, 0.8);
        display: flex;
        text-align: left;
        margin-left: 30px;
        margin-top: 15px;
      }
    }
  }
  .CancelSubscribePage__header1 {
    font-size: 20px;
    text-align: left;
    font-weight: 900;
    line-height: 1.2;
    color: #74c485;
    max-width: 500px;
    margin: auto;
    text-align: center;
  }
  .CancelSubscribePage__header2 {
    font-size: 23px;
    text-align: left;
    font-weight: 900;
    line-height: 1.2;
    color: #273a07;
    max-width: 450px;
    margin: auto;
    margin-top: 20px;
    text-align: center;
  }

  .CancelSubscribePage__sadface-wrapper {
    width: fit-content;
    height: fit-content;
    margin: auto;
    margin-top: 50px;
  }
  .CancelSubscribePage__list_point {
    width: 21px;
    height: 21px;
    min-width: 21px;
    border-radius: 50%;
    background-color: #ff6600;
    position: relative;
    margin-right: 15px;
    .CancelSubscribePage__list_point-mid {
      width: 70%;
      height: 70%;
      border-radius: 50% 70%;
      background-color: rgba(255, 255, 255, 0.5);
    }
    .CancelSubscribePage__list_point-inner {
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.9);
    }
  }
  .CancelSubscribePage__btn-cont {
    display: flex;
    // margin: auto;
    margin-top: 20px;
    // border: 1px solid;
    // width: 80%;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .CancelSubscribePage__btn-1 {
    // box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.2s;
    // border: 1px solid rgba(0, 0, 0, 0);
    margin-right: 5px;
    margin-left: 5px;
    margin-top: 25px;

    width: 350px;
    max-width: 400px;
    border-radius: 5px;
    // &:hover {
    //   border: 1px solid #f7b844;
    //   box-shadow: 0 0 3px 3px rgba(253, 209, 144, 0.6);
    // }
    .CancelSubscribePage__btn-1__inner {
      text-align: center;
      background-color: #273a07;
      padding: 10px 15px;
      margin: 3px;
      color: white;
      font-weight: bold;
      border-radius: 5px;
      font-size: 20px;
      min-height: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .CancelSubscribePage__btn-2 {
    // box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.2s;
    // border: 1px solid rgba(0, 0, 0, 0);
    margin-top: 25px;
    margin-right: 5px;
    margin-left: 5px;
    width: 350px;
    max-width: 400px;
    // &:hover {
    //   border: 1px solid #da4431;
    //   box-shadow: 0 0 3px 3px rgba(255, 88, 88, 0.1);
    // }
    .CancelSubscribePage__btn-2__inner {
      text-align: center;
      background-color: #b43f00;
      border-radius: 5px;
      padding: 10px 15px;
      margin: 3px;
      color: white;
      font-weight: bold;
      font-size: 20px;
      min-height: 50px;
    }
  }
  .CancelSubscribePage__cancel {
    color: #09132b;
    margin: auto;
    margin-top: 80px;
    font-weight: bold;
    display: flex;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: rgba(47, 47, 133, 0.1);
    align-items: center;
    justify-content: center;
  }
  a {
    color: black;
    text-decoration: none;
    &:hover {
      color: black;
    }
  }

  .CancelSubscribePage__imgcont {
    width: 50px;
    height: 50px;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 15px;
  }
  .CancelSubscribePage__body3 {
    width: 100%;
    border: 2px solid #09132b;
    background-color: white;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-wrap: wrap;
    .CancelSubscribePage__body3__font1 {
      color: rgba(0, 0, 0, 0.8);
      margin-bottom: 40px;
    }
  }
  .CancelSubscribePage__header3 {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin: auto;
    margin-bottom: 30px;
    padding-bottom: 30px;
    width: 80%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}
.account-cancel-feedback{
  width: 230px;
  height: 100px;
}
.account-cancel-reasons-wrapper{
  max-width: 300px;
  margin: auto;
  margin-top: 30px;
}
.account-cancel-reasons-context-wrapper--confirm {
  margin-top: 20px;
  display: flex;
  .account-cancel-reason-button {
    width: 100px;
    height: 35px;
    border-radius: 5px;
    border: none;
    outline: none;
    &:focus {
      border: none;
      outline: none;
    }
    &.--confirm {
      background-color: #b43f00;
      margin-right: 10px;
      color: white;
    }
    &.--cancel {
      background-color: #273a07;
      color: white;
    }
  }
}