$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;

.tree-wrapper {
  min-height: 50% !important;
  margin: 0 auto;
  height: calc(100% - 39px);
  width: 100%;

  & > div.check-list-tree {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    & > div {
      width: 100% !important;
      border-bottom: 1px solid #827700;
      box-sizing: border-box;
      & > div.rst__virtualScrollOverride {
        width: 100% !important;
        height: 100% !important;
        &::-webkit-scrollbar {
          display: none;
        }
        & > div {
          & > div.rst__node,
          div.rstcustom__node {
            min-height: 0px !important;
            background-color: white;
            border-bottom: 1px solid #827700;
            .rstcustom__rowWrapper {
              padding: 0px !important;
            }
            div.rst__nodeContent {
              width: 85% !important;
            }
            & > div:nth-last-child(2).rst__lineBlock::before {
              height: 1px;
              top: 64% !important;
              right: -10px !important;
              width: 50%;
              background-color: transparent !important;
            }
            & > div:nth-last-child(2).rst__lineBlock::after {
              height: 100% !important;
              width: 1px !important;
              left: 72% !important;
              top: -21px !important;
              background-color: transparent !important;
              z-index: -1;
            }
            & > div:nth-child(1).rst__lineBlock::after {
              height: 162% !important;
              width: 1px !important;
              left: 72% !important;
              top: -35px !important;
              background-color: transparent !important;
            }
            & > div:nth-child(3).rst__nodeContent {
              width: 71% !important;
            }
            & > div:nth-child(4).rst__nodeContent {
              width: 61% !important;
            }
            & > div:nth-child(5).rst__nodeContent {
              width: 51% !important;
            }
            & > div:nth-child(6).rst__nodeContent {
              width: 41% !important;
            }
          }
          & > div:nth-child(1).rst__node {
            & > div:nth-child(1).rst__lineBlock::after {
              height: 100% !important;
              width: 1px !important;
              left: 72% !important;
              top: 35px !important;
              background-color: transparent !important;
            }
          }
        }
      }
    }
  }
}
.rstcustom__rowContents {
  background-color: transparent !important;
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.hidden-button-wrapper {
  background: transparent;
  border: none;
  outline: none;
  position: absolute;
  height: 39px;
  bottom: -12px;
  margin-bottom: 10px;
  cursor: pointer;
  &--check-box {
    margin-left: 10px;
    width: 50px;
    /* background: yellow; */
    border-right: 1px solid #827700;
    &--level1 {
      left: -55px;
    }
    &--level2 {
      left: -100px;
    }
    &--level3 {
      left: -145px;
    }
    &--level4 {
      left: -190px;
    }
  }
  &--edit {
    path {
      fill: #827700;
    }
    &:hover {
      path {
        fill: $var-color--light-orange;
      }
    }
    &--level1 {
      right: -18px;
    }
    &--level2 {
      right: 27px;
    }
    &--level3 {
      right: 72px;
    }
    &--level4 {
      right: 117px;
    }
  }
  &--delete {
    path {
      fill: #827700;
    }
    &:hover {
      path {
        fill: $var-color--light-orange;
      }
    }
    &--level1 {
      right: -46px;
    }
    &--level2 {
      right: -1px;
    }
    &--level3 {
      right: 44px;
    }
    &--level4 {
      right: 89px;
    }
  }
  &:focus {
    outline: none;
    border: none;
  }
  .tree-node-svg-outer {
    margin-top: 1px;
    width: 15px;
    height: 15px;
    fill: rgba(0, 0, 0, 0.4);
  }
}
.rstcustom__collapseButton {
  display: none !important;
}

.ReactVirtualized__Grid__innerScrollContainer {
  height: 100% !important;
  max-height: 100% !important;
}

.rstcustom__nodeContent {
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  width: calc(100% - 50px) !important;
}
.rstcustom__rowContents {
  padding: 0 5px 0 15px !important;
  width: 100%;
  min-width: 50%;
}
.rstcustom__rowLabel {
  width: 100%;
}
.rstcustom__rowTitle {
  width: 100%;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #827700;
}
