.partner-box-wrapper {
  max-width: 300px;
  min-width: 300px;
  max-height: 300px;
  min-height: 300px;
  height: 300px;
  width: 300px;
  border-radius: 10px;
  position: relative;
  // background-color: yellow;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border: 4px solid transparent;
  overflow: hidden;
  margin: 5px;
  cursor: pointer;
  @media (max-width: 420px) {
    width: 200px !important;
    max-width: 200px;
    min-width: 200px;
    max-height: none;
    height: fit-content;
  }
  &.disable-cursor-pointer {
    cursor: default;
  }
  &.selected-partner {
    border: 4px solid $var-color--light-blue;
  }
  .partner-box-top {
    z-index: 2;
    width: 100%;
    height: 25.5%;

    .partner-box-logo-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      .partner-box-logo-image {
        width: 80%;
        height: auto;
        object-fit: contain;
      }
    }
  }
  .partner-box-middle {
    z-index: 2;
    width: calc(100% - 30px);
    height: calc(59.5% - 10px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    // background-color: rgba(110, 198, 237, 0.49);
    border-radius: 5px;
    margin: 5px 15px;

    .partner-box-company-name {
      width: 100%;
      height: 16%;
      display: flex;
      justify-content: center;
      align-items: center;
      .partner-box-company-name-text {
        // color: white;
        color: $var-color--dar-blue;
        font-weight: 700;
      }
    }
    .partner-box-company-description {
      width: calc(100% - 10px);
      height: calc(54% - 10px);
      padding: 5px;
      .partner-box-company-description-text {
        // color: white;
        color: $var-color--dar-blue;
      }
    }
  }
  .partner-box-bottom {
    z-index: 2;
    width: 100%;
    height: 15%;
    // background-color: red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    // background-color: rgba(110, 198, 237, 0.49);

    .partner-box-button {
      background-color: #273a07;
      border-radius: 5px;
      width: 100px;
      height: 25px;
      margin: 0px 5px;
      border: none;
      outline: none;
      text-align: center;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background-color: #176b1e;
      }
    }
    .impact-box-recommended-flag {
      position: absolute;
      top: 0px;
      background-color: $var-color--light-orange;
      border-radius: 5px;
      padding: 10px 20px;
    }
  }
}
.partner-box-image-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  .partner-box-background-image-opacity-layer {
    width: 100%;
    height: 100%;
  }
}
