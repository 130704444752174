$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;

.subscription-checkout-plan-detail-wrapper {
  width: 95%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: rgba(255, 255, 255, 0.7);
  background-color: #273a07;

  border-radius: 5px;
  margin-top: 10px;
  .subscription-checkout-plan-detail-top {
    width: 66%;
    height: 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .subscription-checkout-plan-detail-top-image {
      width: 90%;
      height: auto;
      object-fit: contain;
    }
    .subscription-checkout-plan-detail-top-name {
      width: 90%;
      display: flex;
      justify-content: center;
      margin-top: 10px;
      text-align: center;
      & > span {
        font-size: 1.8em;
        color: white;
      }
    }
  }
}

.subscription-checkout-plan-prices-section {
  margin: 0 auto;
  margin-top: 10px;
  // background-color: rgba(255, 255, 255, 0.7);
  width: 95%;
  display: flex;
  justify-self: center;
  align-items: center;
  flex-direction: column;
  padding: 10px 0px;
  border-radius: 5px;
  padding-bottom: 0px;
  .subscription-prices-price-detail-wrapper {
    display: flex;
    width: 100%;
    height: 50px;
    // border: 1px solid black;
    margin-top: 10px;

    background-color: rgba(255, 255, 255, 0.7);

    border-radius: 5px;
    &.--promotion-description {
      min-height: 50px;
      height: auto;
      .subscription-prices-header-section {
        min-height: 50px;
      }
      .subscription-prices-prices-section {
        min-height: 50px;
        height: auto;
      }
    }
    .subscription-prices-header-section {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      & > span {
        margin-left: 10px;
      }
    }
    .subscription-prices-prices-section {
      width: calc(50% - 25px);
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 25px;
    }
  }
}

.subscription-checkout-promotion-wrapper {
  margin: 0 auto;
  margin-top: 10px;
  background-color: rgba(255, 255, 255, 0.7);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 10px 0px;
  border-radius: 5px;
  height: 50px;
  .subscription-promotion-inner-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    .subscription-checkout-promotion-text-wrapper {
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      span {
        margin-left: 10px;
      }
    }
    .input-and-button-wrapper {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      position: relative;
      & > input {
        width: 65%;
        height: 75%;
        border: none;
        border-radius: 5px;
      }
      & > button {
        width: 35%;
        height: 85%;
        border: none;
        outline: none;
        background-color: $var-color--light-orange;
        border-radius: 5px;
        margin-left: 5px;
        cursor: pointer;
        margin-right: 10px;
        &:hover {
          background-color: $var-color--light-blue;
        }
      }
      .promocode-error-container {
        position: absolute;
        color: red;
        left: -10px;
      }
      .promocode-error-message-container {
        position: absolute;
        color: red;
        font-size: 8px;
        bottom: 8px;
        left: 9px;
      }
    }
  }
}

.subscription-checkout-paypal-buttons-wrapper {
  margin: 0 auto;
  margin-top: 10px;
  background-color: rgba(255, 255, 255, 0.7);
  width: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 10px 0px;
  border-radius: 5px;
  display: flex;
  // height: 110px;
  height: auto;
  padding-top: 20px;
  margin-bottom: 20px;
  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  button.subscription-checkout-update-button {
    width: 60%;
    margin-bottom: 10px;
    background-color: $var-color--light-orange;
    color: $var-color--dar-blue;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border: none;
    border-radius: 5px;
    outline: none;
    text-align: center;
    span {
      font-size: 1em;
    }
  }
}

#account-page-onboard-responsive {
  height: calc(100vh - 168px) !important;
}
