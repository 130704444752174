@mixin unselectedButtonDarkBlue {
  width: 120px;
  height: 25px;
  background-color: $green7;
  border: none;
  outline: none;
  cursor: pointer;
  color: white;
  font-size: 0.78em;
  border: 1px solid #09132b;
  margin: 0px 4px;
  margin: 5px 0px;
  border-radius: 5px;
}
@mixin selectedButtonLightBlue {
  border: 1px solid $warm7;
  background-color: $warm7;
  // color: white;
  color: $green7;
}

.map-box-module-filters-wrapper {
  width: fit-content;
  // margin-top: 70px;
  .filter-trigger-button {
    font-weight: 600;

    @include unselectedButtonDarkBlue;
    &--selected {
      @include selectedButtonLightBlue;
    }
  }
  .filter-button {
    @include unselectedButtonDarkBlue;
    &--selected {
      @include selectedButtonLightBlue;
    }
  }
  .filters-container {
    margin-top: 1px;
    margin-left: 2px;
    position: absolute;
    width: 145px;
    height: auto;
    overflow: hidden;
    transition: transform 0.2s ease-out; // note that we're transitioning transform, not height!
    transform-origin: top; // keep the top of the element in the same place. this is optional.
    background-color: #cbcbcb;
    display: flex;
    border-radius: 10px;
    flex-direction: column;
    align-items: center;
    padding: 5px 0px;
    font-family: Lucida Sans, Helvetica, Arial, sans-serif !important;
    &--closed {
      transform: scaleY(0); // implicit, but good to specify explicitly
    }
    &--open {
      transform: scaleY(1); // implicit, but good to specify explicitly
    }
    .filters-dividers-wrapper {
      width: 100%;
      font-size: 14px;
      display: flex;
      justify-content: center;
      // font-weight: 600;
      color: #264562;
      &--map-style {
        margin-top: 4px;
      }
    }
  }
}
