$breakpoint-alpha: 480px; // adjust to your needs

.rwd-table {
  margin: 1em 0;
  min-width: 300px; // adjust to your needs
  width: 100%;
  padding: 20px;

  tr {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }

  th {
    display: none; // for accessibility, use a visually hidden method here instead! Thanks, reddit!
  }

  td {
    display: block;

    &:first-child {
      padding-top: 0.5em;
    }
    &:last-child {
      padding-bottom: 0.5em;
    }

    &:before {
      content: attr(data-th) ': '; // who knew you could do this? The internet, that's who.
      font-weight: bold;

      // optional stuff to make it look nicer
      width: 6.5em; // magic number :( adjust according to your own content
      display: inline-block;
      // end options

      @media (min-width: $breakpoint-alpha) {
        display: none;
      }
    }
  }

  th,
  td {
    text-align: left;

    @media (min-width: $breakpoint-alpha) {
      display: table-cell;
      padding: 0.25em 0.5em;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
}

// presentational styling

.rwd-table {
  background: $navBackground;
  color: #fff;
  border-radius: 0.4em;
  overflow: hidden;
  tr {
    border-color: lighten(#34495e, 10%);
  }
  th,
  td {
    margin: 0.5em 1em;
    @media (min-width: $breakpoint-alpha) {
      padding: 1em !important;
    }
  }
  td {
    position: relative;
    @media (max-width: $breakpoint-alpha) {
      //   white-space: nowrap;
      padding-left: 7.5em;
      min-height: 30px;
    }
    &:before {
      @media (max-width: $breakpoint-alpha) {
        position: absolute;
        left: 0px;
        width: 7.5em;
        vertical-align: center;
        float: left;
      }
    }
  }
  th,
  td:before {
    color: #dd5;
  }
  .normal {
    background-color: $green7;
    color: white;
  }
  .offset {
    color: $green7;
  }
}

.page-number {
  display: flex;
  justify-content: center;
  height: 50px;
  align-items: center;
  .page-numbs-buttons-container {
    display: flex;
    z-index: 100;
    .page-buttons {
      width: 50px;
      display: flex;
      justify-content: center;
      cursor: pointer;
      background-color: transparent;
      border: none;
    }
  }
  .page-numb-spacer {
    width: 35px;
    display: flex;
    justify-content: center;
  }
}
