.text-title-container{
  width: 100%;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color:white;
  // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  span{
    display:flex;
    flex-direction: column;
    text-align: center;
  }
}

.text-body-container{
  padding:20px;
  border-radius: 40px;
  color:white;
  width: 93%;
  display:flex;
  margin:0 auto;
  span{
  max-width: 100%;
  // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }

}

.bold-text-type{
  font-size:18px;
  font-weight: 700;
  // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}