.itinerary-accordion-wrapper{
  background-color: $var-color--modal-accordion-color!important;
  margin-bottom: 5px!important;
  width:100%!important;
  .itinerary-accordion-body{
    width:100%;
    display:flex;
    margin:0 auto;
    flex-direction:column;
    align-items: center;
    margin-left: -15px;

  }
  .itinerary-accordion-contents-wrapper{
    width:100%;
    height:100%;
    display:flex;
    flex-direction: column;
    overflow-y:auto;
    align-items: center;

    &::-webkit-scrollbar{
     display:none;
    }
    .itinerary-accordion-itinerary-item-button{
      background-color:$var-color--itinerary-accordion-itinerary-item-button;
      border-radius: 5px;
      width: 90%;
      border:none;
      outline:none;
      margin-bottom: 5px;
      width: 363px;
      color:white;
      &:focus{
        border: none;
        outline: none;
      }
      &.itinerary-accordion-selected{
        // border: 2px solid $var-color--dar-blue;
        background-color: $var-color--light-blue;
      }
    }
  }
}