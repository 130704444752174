$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;

.google-map-module-tools--wrapper {
  width: 93%;
  margin: 0 auto;
  display: flex;
  height: auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  // padding: 0px 20px;
  .google-map-module-tools {
    &--filter {
      width: 100%;
      height: 50%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: flex-start;
      & > div.Collapsible {
        width: 100%;
      }
      .google-map-module-button {
        margin: 0px 5px;
        width: 150px;
        height: 25px;
        background-color: $var-color--button-unselected-blue;
        cursor: pointer;
        border: none;
        outline: none;
        border-radius: 10px;
        color: white;
        text-transform: uppercase;
        font-size: 0.8em;
        &--selected {
          background-color: $var-color--light-blue;
          color: $var-color--dar-blue;
        }
        &:hover {
          color: $var-color--dar-blue;
          background-color: $var-color--light-blue;
        }
      }
      .google-map-toggle-switch {
        width: 25% !important;
        margin-left: 10px;
      }
    }
  }
}

.google-map-filter-dropdown {
  &--outer {
    position: absolute;
    z-index: 50;
    background: rgba(9, 19, 43, 0.7);
    width: calc(100% - 40px);
    // padding: 20px;
  }
  &--inner {
    padding: 20px;
  }
}
.google-map-filter-button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}
