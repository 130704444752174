.search-bar-wrapper{
  width: 100%;
  justify-content: center;
  display:flex;
  height: 40px;
  align-items: center;
  .search-bar-container{
    width: 25%;
    display:flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    .search-bar-input{
      width: 200px;
      height: 20px;
      margin:20px;
      border-radius: 40px;
    }
    .search-bar-button{
      width: 80px;
      height: 20px;
      background-color: #F7B844;
      border-radius: 20px;
      border: none
    }
  }
}