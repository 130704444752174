$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;

.chat-input-wrapper {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  height: calc(100% - 360px);
  align-items: center;
  .chat-input-send-container {
    width: 97%;
    height: 95%;
    display: flex;
    background-color: white;
    border-radius: 10px;
    .chat-input-field {
      width: 90%;
      height: 100%;

      background-color: transparent;
      border: none;
      outline: none;
    }
    .chat-send-button {
      background-color: transparent;
      border: none;
      outline: none;
      width: 10%;
      height: 100%;
      &:focus {
        border: none;
        outline: none;
      }
      .send-svg-outer {
        fill: $var-color--light-blue;
      }
    }
  }
}
