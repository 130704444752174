.banner-regular-container{
  width:100%;
  // height:400px;
  position: relative;
  text-align:left;
  border-bottom: 8px solid #09132B;
  margin-bottom:0px;
  padding-bottom:0px;
    height:500px;

  img{
    width:100%;
    height:100%;
    object-fit: cover;
  }
  .banner-text-block{
    // font-family:  'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 450;
    max-width: 70%;
    position: absolute;
    top: 45px;
    left: 36px;
    color: white;
    .banner-lading-header-large{
    font-size: 62px;

    }
    .banner-lading-header-small{
    font-size: 22px;

    }


  .trademark_banner{
    font-size: 15px;
    align-self:flex-start;
  }


  }
}