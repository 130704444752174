$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;

.mod-fl-lab-inpt-wrapper {
  padding: 15px 0;
  width: 80%;
  display: flex;
  .mod-fl-lab-inpt {
    width: 100%;
    border-radius: 10px;
  }

  div.mod-fl-lab-inpt {
    border: 1px solid #000000;
    width: 100%;
  }
  label.mod-fl-lab-inpt {
    margin-bottom: 3px;
    margin-left: 3px;
  }
}

.mod-fl-ta-wrapper {
  width: 80%;
  // height: 69px;
  // .jvctCF {
  //   height: 100%;
  .mod-fl-ta {
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    // padding: 0px !important;
    border-radius: 13px;
    padding-left: 5px;
    padding: 15px !important;
    font-size: 1em;
    resize: none;
    border: none;
    outline: none;
    textarea {
      padding-left: 5px;
    }
    // font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
    //   sans-serif;
    &::placeholder {
      padding-left: 5px;
      color: #9b9b9b;
    }
    &:focus {
      border: none;
      outline: none;
    }
  }

  div.mod-fl-ta {
    border: 1px solid #000000;
    width: 100%;
    height: 100%;
  }
  input.mod-fl-ta {
    height: 100%;
  }
  label.mod-fl-ta {
    margin-bottom: 3px;
    margin-left: 3px;
  }
  // }
}

.image-edit-body-container {
  margin-bottom: 5px;
}

.mod-dropzone-wrapper {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  border: 1px solid #273a07;
  height: 120px;
  cursor: pointer;
  &:hover {
    background-color: rgba(247, 184, 68, 0.1);
    .mod-drpzn-svg-cam-outerlayer {
      .mod-drpzn-svg-cam {
        fill: #f7b844;
      }
    }
  }
  .mod-drpzn-padder {
    width: 100%;
    height: 100%;
    &:focus {
      border: none;
      outline: none;
    }
    section,
    div {
      width: 100%;
      height: 100%;
      &:focus {
        border: none;
        outline: none;
      }
    }
    .mod-drpzn {
      &:focus {
        border: none;
        outline: none;
      }
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .mod-drpzn-inner {
        height: 75% !important;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        &:focus {
          border: none;
          outline: none;
        }
        span {
          margin-bottom: 9%;
          color: #827700;
        }
      }
      .mod-drpzn-cam-wrapper {
        height: 25%;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: ㄹ;

        .mod-drpzn-svg-cam-outerlayer {
          margin-right: 10px;
          width: 25px;
          height: 25px;
          fill: #827700;
          // &:hover{
          //   fill: #F7B844
          // }
        }
      }
    }
  }
}

.prev-image-cont {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .imgprvw {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    object-fit: cover;
  }
  .remove-btn-cont {
    // position: absolute;
    width: calc(100% - 5px);
    display: flex;
    justify-content: flex-end;
    z-index: 2;
    // margin-top: -34px;
    position: absolute;
    right: 0px;
    bottom: 2px;
    padding-right: 5px;
    .mod-dpzn-svg-del-outer {
      width: 25px;
      height: 25px;
      &:hover {
        fill: #f7b844;
      }
      .mod-drpzn-svg-del {
        fill: #827700;
        &:hover {
          fill: #f7b844;
        }
      }
    }
  }
}

.mod-save-btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  // cursor: pointer;

  &--background {
    //new-modal-design
    background-color: #364301 ;
    // background-color: $var-color--dar-blue;
    height: 42px;
    padding-top: 8px;
  }
  &--large {
    button {
      width: 180px !important;
    }
  }
  button {
    cursor: pointer;
    &:focus {
      border: none;
      outline: none;
    }
    height: 30px;
    width: 150px;
    background-color: #f7b843;
    color: #545b6b;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 10px;
    &:hover {
      background-color: $var-color--light-blue;
      color: #545b6b;
    }
    &:disabled {
      color: #545b6b;
    }
  }
  .mod-save-btn-type-1 {
  }
  .mod-save-btn-type-2 {
    background-color: #da4431;
    color: white;
  }
}

.mod-togl-wrapper {
  width: 80%;
  display: flex;
  justify-content: flex-start;
  height: 30px;
  align-items: center;

  label {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    span {
      margin-left: 0.7em;
      color: white;
    }
  }
  .react-switch-handle {
    width: 21px !important;
    height: 21px !important;
    top: 3px !important;
  }
}

.mod-dte-pkr-wrapper {
  width: 80%;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  .mod-dte-pkr-untrig {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    .mod-svg-cal-wrapper {
      width: 30px;
      height: 30px;
      path {
        fill: white;
      }
    }
  }
  .mod-dte-pkr-set {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    div {
      color: white;
      margin: 4px 0px;
    }
  }
  span {
    color: white;
  }
  &:hover {
    background-color: rgba(247, 184, 68, 0.1);
    .mod-dte-pkr-untrig {
      .mod-svg-cal-wrapper {
        fill: #f7b844;
        stroke: #f7b844;
        .mod-svg-cal {
          stroke: #f7b844;
          fill: #f7b844;
        }
      }
    }
  }
}

.floating-calendar-wrapper {
  display: flex;
  position: absolute;
}

.mod-tabs-wrapper {
  margin: 10px 0px;
  // border-bottom: 1px solid #898a90;
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
  & > div {
    margin: 0px 2px;
  }
  .type1 {
    width: 32%;
  }

  .type2 {
    width: 48%;
  }
  .mod-tab-selected {
    // background-color: $var-color--light-orange;
    background-color: #6ec6ed;

    color: #545b6b;
    height: 30px;
    // font-weight: 700;

    padding: 0px 2px;
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif !important;
    font-size: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
    // border-top-left-radius: 7px;
    // border-top-right-radius: 7px;
    border-radius: 7px;
    &:hover {
      cursor: pointer;
    }
  }
  .mod-tab-unselected {
    // background-color: rgba(255, 255, 255, 0.1);
    background-color: #264562;
    color: white;
    height: 30px;
    // font-weight: 700;
    padding: 0px 2px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif !important;
    font-size: 75%;
    display: flex;
    align-items: center;
    justify-content: center;

    // border-top-left-radius: 7px;
    // border-top-right-radius: 7px;
    border-radius: 7px;
    text-align: center;
    &:hover {
      cursor: pointer;
    }
  }
}

.mod-perm-pkr-wrapper {
  width: 80%;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  .mod-perm-pkr-untrig {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .mod-svg-perm-wrapper {
      width: 30px;
      height: 30px;
    }
  }
  .mod-perm-pkr-set {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    div {
      color: white;
      margin: 4px 0px;
    }
  }
  span {
    color: white;
  }
  &:hover {
    background-color: rgba(247, 184, 68, 0.1);
    .mod-perm-pkr-untrig {
      .mod-svg-perm-wrapper {
        fill: #f7b844;
        stroke: #f7b844;
        .mod-svg-perm {
          stroke: #f7b844;
          fill: #f7b844;
        }
      }
    }
  }
}

.mod-tag-pkr-wrapper {
  width: 80%;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;

  .mod-tag-pkr-untrig {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .mod-svg-search-tag-wrapper {
      width: 30px;
      height: 30px;
    }
  }
  .mod-tag-pkr-set {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    div {
      color: white;
      margin: 4px 0px;
    }
  }
  span {
    color: white;
  }
  &:hover {
    background-color: rgba(247, 184, 68, 0.1);
    .mod-tag-pkr-untrig {
      .mod-svg-search-tag-wrapper {
        fill: #f7b844;
        stroke: #f7b844;
        .mod-svg-search-tag {
          stroke: #f7b844;
          fill: #f7b844;
        }
      }
    }
  }
}

.mod-sqr-block-wrapper {
  width: 80px;
  // border: 1px solid black;
  border-radius: 10px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 0px;
  margin: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  &:hover {
    background-color: rgba(247, 184, 68, 0.1);
  }
  .mod-sq-blk-svg-outer {
    fill: white;
    width: 30px;
    height: 30px;
  }

  .mod-sq-blk-btm-text {
    color: white;
  }
}

.mod-long-rect-blk-wrapper {
  width: 80%;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 5px 0px;
  margin-top: 10px;
  cursor: pointer;

  &:hover {
    background-color: rgba(247, 184, 68, 0.1);
  }
  .mod-long-rect-blk-top {
    span {
      color: white;
      font-weight: 700;
    }
  }
  .mod-long-rect-blk-btm {
    width: 80%;
    display: flex;
    text-align: center;
    justify-content: center;
    span {
      color: white;
      font-size: 14px;
    }
  }
}

.mod-long-rect-selected {
  background-color: rgba(247, 184, 68, 0.1);
}

.search-bar-wrapper {
  width: 100%;
  justify-content: center;
  display: flex;
  height: 40px;
  align-items: center;
  .search-bar-container {
    width: 25%;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    .search-bar-input {
      width: 200px;
      height: 20px;
      margin: 20px;
      border-radius: 40px;
    }
    .search-bar-button {
      width: 80px;
      height: 20px;
      background-color: #f7b844;
      border-radius: 20px;
      border: none;
    }
  }
}
.member-block-wrapper {
  display: flex;
  width: 100%;
  // height: 45px;
  border-radius: 15px;
  // background-color: #3c4a59;
  // background: #d1b37c;
  align-items: center;
  margin: 5px 0px;
  flex-shrink: 0;
  .mbr-blk-prf-img {
    width: 45px;
    height: 45px;
    margin-right: -40px;
    border-radius: 35%;
    background-color: transparent;
    // position: absolute;
    // left: 25px;
  }
  .mbr-blk-mid-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    .mbr-blk-mid-lft {
      display: flex;
      flex-direction: column;
      width: 80%;
      margin-left: 2%;
      // justify-content: center;
      // align-items: center;
      // padding-left: 60px;
      height: 100%;
      color: white;

      .member-block-mid-left-full-name-wrapper {
        span {
          font-size: 14px;
        }
      }
      .member-block-mid-left-location-wrapper {
        span {
          font-size: 12px;
        }
      }
    }
    .mbr-blk-mid-rt {
      width: 20%;
      height: 100%;
      align-items: center;
      display: flex;
      justify-content: flex-end;
      .mod-inv-svg-wrapper {
        width: 35px;
        height: auto;
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        // margin-right: 8px;
        .mod-inv-svg-outer {
          width: 20px;
          height: 20px;
          margin-right: 10px;
          cursor: pointer;
        }
        &:focus {
          outline: none;
        }
        &:hover {
          .mod-inv-svg-outer {
            cursor: pointer;

            fill: $var-color--light-orange;
          }
        }
      }
      .mod-inv-del-svg-wrapper {
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        padding: 0px;
        margin-right: 8px;
        &:hover {
          .mod-invg-svg-outer {
            path {
              fill: $var-color--light-orange;
            }
          }
        }
        .mod-invg-svg-outer {
          fill: rgba(255, 255, 255, 0.4);
          width: 20px;
          height: 20px;
          &.selected {
            path {
              fill: $var-color--light-orange;
            }
          }
        }
      }
    }
  }
}

.delete-modal-message-container {
  width: 100%;
  justify-content: center;
  text-align: center;
}

.modal-vote-module-wrapper {
}

.modal-comment-module-wrapper {
  background-color: #838892;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  border-radius: 10px;
  margin: 7px 0px;
  .modal-comment {
    &--left {
      width: 90%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      height: auto;
      .modal-comment {
        &--top {
          margin-left: 7px;
          color: white;
          margin-top: 10px;
        }
        &--bottom {
          margin-left: 7px;
          color: rgba(255, 255, 255, 0.5);
          margin-bottom: 10px;
        }
      }
    }
    &--right {
      width: 10%;
      .modal-comment {
        &--button {
          width: 25px;
          height: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: transparent;
          border: none;
          outline: none;
          margin-top: 10px;
          cursor: pointer;
          .comment-delete-svg-outer {
            width: 20px;
            height: 20px;
            fill: rgba(255, 255, 255, 0.4);
          }
          &:hover {
            .comment-delete-svg-outer {
              fill: $var-color--light-orange;
            }
          }
        }
      }
    }
  }
}

.modal-vote-module-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 5px 0px;
  align-items: flex-end;
  .modal-vote-module-left {
    width: 15%;
    .itinerary-block-vote-svg,
    .itinerary-block-vote-svg-outer {
      // fill: rgba(255, 255, 255, 0.5);
      fill: rgba(255, 255, 255, 1);
      width: 20px;
      height: 20px;
    }
  }
  .modal-vote-module-right {
    width: 85%;
    display: flex;
    justify-content: flex-start;
    color: white;
  }
}

.mod-fl-ta {
  font: 400 16px Arial !important;
  &::placeholder {
    font: 400 16px Arial !important;
  }
}

.account-explorer-account-hover {
  cursor: pointer;
  &:hover {
    // background-color: #d1b37c;
  }
}

.modal-list-wrapper {
  margin: 4px;
  height: 43px;
  border-radius: 10px;
  // border: 1px solid;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .modal-list-top {
    display: flex;
    width: 100%;
    height: 70%;
    background-color: #838892;
    .modal-list-top-left {
      width: 80%;
      height: 100%;
      .modal-list-top-left-title,
      .modal-list-top-left-description {
        margin-left: 5px;
        span {
          color: white;
        }
      }
    }
    .modal-list-top-right {
      width: 20%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      .list-modal-svg-outer {
        width: 18px;
        height: 18px;
        margin: 0px 4px;
        fill: rgba(255, 255, 255, 0.4);
        &:hover {
          fill: #f7b844;
        }
      }
    }
  }
  .modal-list-bottom {
    display: flex;
    width: 100%;
    height: 30%;
    justify-content: space-between;
    background-color: #646a78;
    .modal-list-bottom-item {
      width: 25%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-size: 14px;
        color: white;
      }
      input {
        background-color: rgba(255, 255, 255, 0.3);
        border: none;
        outline: none;
        width: 15px;
        height: 15px;
      }
    }
  }
}

.MuiAccordionDetails-root {
  width: 100%;
  overflow: hidden;
}

.action-button-cluster--wrapper {
  width: 100%;
  display: flex;
  & > button {
    cursor: pointer;
    width: 38%;
  }
}
.action-button-cluster-horizontal {
  flex-direction: row;
  justify-content: center;
  button {
    // background-color: $var-color--default-buttons;
    background-color: rgba(255, 255, 255, 0.1);
    background-color: $var-color--light-orange;
    outline: none;
    // color: white;
    color: black;
    border: none;
    margin: 0px 4px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    &:focus {
      outline: none;
      border: none;
    }
  }
  .action-button__triggered {
    background-color: $var-color--light-blue;

    // background-color: $var-color--dar-blue;
    color: $var-color--dar-blue;
    // color: $var-color--light-orange;
  }
}

.group-block-wrapper {
  display: flex;
  border-radius: 5px;
  width: 80px;
  height: 80px;
  margin: 0px 5px;
  background-color: #3e4658;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &:hover {
    background-color: #cda967;
  }
  .group-block--top {
    font-size: 14px;
    text-align: center;
    color: white;
  }
  .group-block--middle {
    font-size: 10px;
    text-align: center;
    color: white;
  }
  .group-block--bottom {
    font-size: 10px;
    text-align: center;
    color: white;
  }
}

.group-block-selected {
  background-color: #cda967;
}

.list-dragging {
  background-color: rgba(255, 255, 255, 0.4);
}

.folder-dropdown {
  .react-dropdown-select-dropdown {
    max-height: 200px !important;
  }
}
