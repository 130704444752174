

.bod-block-cont{
  display:flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding:0px 70px;
  width: calc(100% - 140px);
  margin-top: 100px;

}

.bod-block-pic{
  width: 150px;
  height: 150px;
  border: 1px solid gray;
}

.bod-block-text-blocks{
  width: calc(100% - 150px);
  margin: 0px 20px;
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.bod-block-header{
  font-weight: 700;
  font-size: 20px;
}

.bod-block-text{
  margin-top: 20px;
  font-size: 14px;
}