//screensize bigger than normal laptops
@media screen and (min-width: 1441px) {
  // #hub-subnav-mobile {
  //   margin-top: 20px;
  // }
  #hub-top-navbar-mobile {
    padding: 0px;
    // padding-top: 20px;
    // .hub-top-nav-bottom-border {
    //   padding-top: 20px;
    // }
    #mobile-login-button {
      width: 114px;
    }
  }
}
@media screen and (max-width: 1440px) {
  // #hub-subnav-mobile {
  //   margin-top: 20px;
  // }
  #hub-top-navbar-mobile {
    padding: 0px;
    // padding-top: 20px;
    // .hub-top-nav-bottom-border {
    //   padding-top: 20px;
    // }
    #mobile-login-button {
      width: 114px;
    }
  }
}
// screen#1 iPad pro vertical
@media screen and (max-width: 1024px) {
  // #hub-subnav-mobile {
  //   margin-top: 20px;
  // }
  #hub-top-navbar-mobile {
    padding: 0px;
    // padding-top: 20px;
    // .hub-top-nav-bottom-border {
    //   padding-top: 20px;
    // }
    .hub-top-nav-padder {
      padding: 10px 4px;
      width: calc(100% - 8px);
      .top-nav-right {
        .opened-box-hamburger-right {
          position: absolute;
          top: 71px;
          right: 19px;
          z-index: 900;
        }
      }
      .hub-notifications-container {
        transform: translateX(-305px) !important;
      }
    }
    .hub-hamburger-placeholder {
      width: 0px !important;
    }
    #mobile-login-button {
      width: 114px;
    }
  }
}
// screen#2 ipad vertical

@media screen and (max-width: 768px) and (max-width: 860px) {
  // #hub-subnav-mobile {
  //   margin-top: 20px;
  // }
  #hub-top-navbar-mobile {
    padding: 0px;
    // padding-top: 20px;
    // .hub-top-nav-bottom-border {
    //   padding-top: 20px;
    // }
    .hub-top-nav-padder {
      padding: 10px 4px;
      width: calc(100% - 8px);
      .top-nav-right {
        .opened-box-hamburger-right {
          position: absolute;
          top: 71px;
          right: 19px;
          z-index: 900;
        }
      }
      .hub-notifications-container {
        transform: translateX(-305px) !important;
      }
    }
    .hub-hamburger-placeholder {
      width: 0px !important;
    }
    #mobile-login-button {
      width: 114px;
    }
  }
}
// screen#3  surface duo

@media screen and (min-width: 481px) and (max-width: 767px) {
  // #hub-subnav-mobile {
  //   margin-top: 20px;
  // }
  #hub-top-navbar-mobile {
    padding: 0px;
    // padding-top: 20px;
    // .hub-top-nav-bottom-border {
    //   padding-top: 20px;
    // }
    #mobile-login-button {
      width: 114px;
    }
  }
}
// screen#4 iphone 12 pro max,
@media screen and (min-width: 415px) and (max-width: 480px) {
  // #hub-subnav-mobile {
  //   margin-top: 20px;
  // }
  #hub-top-navbar-mobile {
    padding: 0px;
    // padding-top: 20px;
    // .hub-top-nav-bottom-border {
    //   padding-top: 20px;
    // }
    .hub-hamburger-placeholder {
      width: 0px !important;
    }

    #top-nav-right-hub {
      margin-left: 0px;
      display: flex;
      justify-content: flex-end;
      div.Collapsible {
        width: 34px;
      }
      .hamburger-contents-container-right {
        width: 204px;
      }
      .opened-box-hamburger-right {
        position: absolute;
        top: 71px;
        right: 14px;
        z-index: 900;
      }
    }

    #mobile-login-button {
      width: 114px;
    }
    .hub-top-nav-middle {
      // min-width: 24%;
      min-width: 23%;
    }
    .logoimage {
      height: 100%;
      width: 130% !important;
    }
  }
}
// screen#5 iphone 11 && pixel 2 && pixel2 xl iphone 6/7/8, iphone x
@media screen and (min-width: 391px) and (max-width: 414px) {
  // #hub-subnav-mobile {
  //   margin-top: 20px;
  // }
  #hub-top-navbar-mobile {
    padding: 0px;
    // padding-top: 20px;
    // .hub-top-nav-bottom-border {
    //   padding-top: 20px;
    // }
    .hub-hamburger-placeholder {
      width: 0px !important;
    }

    #top-nav-right-hub {
      margin-left: 0px;
      display: flex;
      justify-content: flex-end;
      div.Collapsible {
        width: 34px;
      }
      .hamburger-contents-container-right {
        width: 204px;
      }
      .opened-box-hamburger-right {
        position: absolute;
        top: 71px;
        right: 14px;
        z-index: 900;
      }
    }

    #mobile-login-button {
      width: 114px;
    }
    .hub-top-nav-middle {
      // min-width: 24%;
      min-width: 16%;
    }
    .logoimage {
      height: 100%;
      width: 130% !important;
    }
  }
}
// screen#6 iphone 12, iphone 12 pro, iphone 6/7/8
@media screen and (min-width: 361px) and (max-width: 390px) {
  // #hub-subnav-mobile {
  //   margin-top: 20px;
  // }
  #hub-top-navbar-mobile {
    padding: 0px;
    // padding-top: 20px;
    // .hub-top-nav-bottom-border {
    //   padding-top: 20px;
    // }
    .hub-hamburger-placeholder {
      width: 0px !important;
    }

    #top-nav-right-hub {
      margin-left: 0px;
      display: flex;
      justify-content: flex-end;
      div.Collapsible {
        width: 34px;
      }
      .hamburger-contents-container-right {
        width: 204px;
      }
      .opened-box-hamburger-right {
        position: absolute;
        top: 71px;
        right: 14px;
        z-index: 900;
      }
    }

    #mobile-login-button {
      width: 114px;
    }
    .hub-top-nav-middle {
      // min-width: 24%;
      min-width: 12%;
    }
    .logoimage {
      height: 100%;
      width: 130% !important;
    }
  }
}
// screen#7 iphone 12 mini && Moto G4 && Galaxy S9 && galaxy s5 galaxy s9
@media screen and (min-width: 321px) and (max-width: 360px) {
  // #hub-subnav-mobile {
  //   margin-top: 20px;
  // }
  #hub-top-navbar-mobile {
    padding: 0px;
    // padding-top: 20px;
    // .hub-top-nav-bottom-border {
    //   padding-top: 20px;
    // }
    .hub-hamburger-placeholder {
      width: 0px !important;
    }
    #top-nav-right-hub {
      margin-left: 0px;
      display: flex;
      justify-content: flex-end;
      div.Collapsible {
        width: 34px;
      }
      .hamburger-contents-container-right {
        width: 204px;
      }
      .opened-box-hamburger-right {
        position: absolute;
        top: 71px;
        right: 14px;
        z-index: 900;
      }
    }
    #mobile-login-button {
      width: 114px;
    }
    .hub-top-nav-middle {
      // min-width: 24%;
      min-width: 8%;
    }
    .logoimage {
      height: 100%;
      width: 120% !important;
    }
  }
}
// screen#8 iphone 5/se, galaxy fold
@media screen and (min-width: 280px) and (max-width: 320px) {
  // #hub-subnav-mobile {
  //   margin-top: 20px;
  // }
  #hub-top-navbar-mobile {
    padding: 0px;
    // padding-top: 20px;
    // .hub-top-nav-bottom-border {
    //   padding-top: 20px;
    // }
    .hub-hamburger-placeholder {
      width: 0px !important;
    }

    #mobile-login-button {
      width: 114px;
    }
    .logoimage {
      height: 100%;
      width: 130% !important;
    }
  }
}
