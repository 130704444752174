$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;

.modal--input--search--button {
  border: none;
  width: auto;
  height: 43px;
  margin-left: 4px;
  background-color: #f7b844;
  z-index: 20;
  border-radius: 5px;
  /* margin-bottom: -6px; */
  margin-top: 6px;
  cursor: pointer;
  margin-left: -51px;
  &:hover {
    background-color: $var-color--light-blue;
  }
  &:focus {
    outline: none;
  }
  .modal--input--search--svg--outer {
    fill: $var-color--light-orange;
    width: 30px;
    height: 30px;
  }
}
