$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;
@keyframes grow-left {
  0% {
    width: 120px;
  }
  100% {
    width: 260px;
  }
}
.small-download-block-wrapper {
  width: 120px;
  height: 130px;
  margin: 5px;
  border-radius: 5px;
  overflow: hidden;
  .small-donwload-block-contents {
    &--top {
      width: 100%;
      height: 74%;
      background-color: $var-color--default-buttons;
      // background-color: rgba(0, 0, 0, 0.6);
      display: flex;
      justify-content: center;
      align-items: center;
      .tool-image-logo-image {
      }
      .small-block-svg-outer {
        fill: rgba(255, 255, 255, 0.7);
        width: 70px !important;
        height: 70px !important;
        & > g {
          width: 70px !important;
          height: 70px !important;
        }
      }
      .small-download-block-top {
        &--content-wrapper {
          display: none;
        }
      }
    }
    &--bottom {
      width: 100%;
      height: 26%;
      background-color: $var-color--light-blue;
      font-size: 0.7em;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.small-download-block-wrapper--selected {
  width: 314px;
  height: 130px;
  margin: 5px;
  animation: grow-left 0.1s;
  border-radius: 5px;
  overflow: hidden;
  .small-donwload-block-contents {
    &--top {
      width: 100%;
      height: 74%;
      // background-color: $var-color--default-buttons;
      background-color: rgba(0, 0, 0, 0.6);

      display: flex;
      flex-direction: row;
      overflow: hidden;
      .small-download-block-top--svg-wrapper {
        width: 30%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .small-block-svg-outer {
          fill: rgba(255, 255, 255, 0.7);
          width: 70px !important;
          height: 70px !important;
          & > g {
            width: 70px !important;
            height: 70px !important;
          }
        }
      }
      .small-download-block-top {
        &--content-wrapper {
          display: flex;
          width: calc(70% - 10px);
          height: 100%;
          flex-direction: column;
          padding-right: 10px;
          .small-download-block-top-bottom {
            &--description {
              width: 100%;
              height: 65%;
              display: flex;
              font-size: 0.8em;
              text-align: center;
              color: white;
              align-items: center;
            }
            &--download-button-wrapper {
              width: 100%;
              height: 35%;
              display: flex;
              justify-content: center;
              align-items: center;
              .small-download-block-top-bottom--download-button {
                margin-top: 0px !important;
                width: 80%;
                height: 80%;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align-last: center;
                border-radius: 5px;
                color: black;
                font-size: 0.8em;
                background-color: $var-color--light-orange;
                cursor: pointer;
                &:hover {
                  background-color: $var-color--light-blue;
                }
              }
            }
          }
        }
      }
    }
    &--bottom {
      width: 100%;
      height: 26%;
      background-color: $var-color--light-orange;
      font-size: 0.8em;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
