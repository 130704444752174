.divider-wrapper{
  width: 95%;
  height: 30px;
  border-bottom: 1px solid #F7B844;
  display:flex;
  flex-direction: row;
  .divider-button-container{
    position:absolute
  }
  .divider-title-container{
    width: 100%;
    display:flex;
    justify-content: center;
    span{
      color: #F7B844;
      font-weight: bolder;               
    }
  }
}