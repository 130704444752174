//screensize bigger than normal laptops
@media screen and (min-width: 1441px) {
}
@media screen and (max-width: 1440px) {
}
// screen#1 iPad pro vertical
@media screen and (max-width: 1024px) {
}
// screen#2 ipad vertical

@media screen and (max-width: 768px) and (max-width: 860px) {
  #qr-code-page-responsive {
    margin-left: 0px !important;
    width: 100% !important;
    .mod-fl-lab-inpt-wrapper {
      width: 90%;
      padding: 5px 0 5px 0;
    }
    .general-button {
      font-size: 14px;
    }
    .contact-apt {
      width: 90%;
      padding: 5px 0 5px 0;
      .contact-apt-child {
        width: 100%;
        input.contact-apt-child {
          width: calc(100% - 30px);
        }
      }
    }
    .subscription-plan-detail-wrapper {
      & > h2 {
        font-size: 18px;
      }
    }

    .add-edit-account-members-section-wrapper {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      .add-edit-account-member-section {
        width: calc(100% - 40px);

        height: 356px;
      }
    }
    .contact-state-country-container {
      display: flex;
      flex-direction: column;
      width: 90%;
      .contact-state-select-parent,
      .contact-country-select-parent {
        margin: 0 !important;
        .react-dropdown-select {
          width: 100% !important;
        }
      }
      .contact-state-select-parent {
        margin-bottom: 14px !important;
      }
      .contact-coutnry-select-parent {
        margin-bottom: 8px !important;
      }
    }
  }
}
// screen#3  surface duo

@media screen and (min-width: 481px) and (max-width: 767px) {
  #qr-code-page-responsive {
    .account-profile-header-container {
      flex-direction: column;
      & > div {
        width: 100%;
      }
    }
    margin-left: 0px !important;
    width: 100% !important;
    .general-button {
      font-size: 14px;
    }

    .mod-fl-lab-inpt-wrapper {
      width: 90%;
      padding: 5px 0 5px 0;
    }
    .subscription-plan-detail-wrapper {
      & > h2 {
        font-size: 18px;
      }
    }

    .contact-apt {
      width: 90%;
      padding: 5px 0 5px 0;
      .contact-apt-child {
        width: 100%;
        input.contact-apt-child {
          width: calc(100% - 30px);
        }
      }
    }
    .add-edit-account-members-section-wrapper {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      .add-edit-account-member-section {
        width: calc(100% - 40px);

        height: 356px;
      }
    }
    .contact-state-country-container {
      display: flex;
      flex-direction: column;
      width: 90%;
      .contact-state-select-parent,
      .contact-country-select-parent {
        margin: 0 !important;
        .react-dropdown-select {
          width: 100% !important;
        }
      }
      .contact-state-select-parent {
        margin-bottom: 14px !important;
      }
      .contact-coutnry-select-parent {
        margin-bottom: 8px !important;
      }
    }
    .member-summary-wrapper {
      height: auto;
      .member-summary-contents-wrapper {
        flex-direction: column;
      }
    }
  }
}
// screen#4 iphone 12 pro max,
@media screen and (min-width: 415px) and (max-width: 480px) {
  .qr-code-page-container {
    & > h4 {
      width: 95%;
      .sharvia-qr-copy-link-text {
        cursor: pointer;
        font-size: 0.6em;
      }
    }
  }
  #qr-code-page-responsive {
    .account-profile-header-container {
      flex-direction: column;
      & > div {
        width: 100%;
      }
    }
    width: 98% !important;
    overflow-x: hidden;
    margin: 10px auto auto auto !important;
    .general-button {
      font-size: 14px;
    }

    .subscription-plan-detail-wrapper {
      & > h2 {
        font-size: 18px;
      }
    }

    .mod-fl-lab-inpt-wrapper {
      width: 90%;
      padding: 5px 0 5px 0;
    }

    .contact-apt {
      width: 90%;
      padding: 5px 0 5px 0;
      .contact-apt-child {
        width: 100%;
        input.contact-apt-child {
          width: calc(100% - 30px);
        }
      }
    }
    .add-edit-account-members-section-wrapper {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      .add-edit-account-member-section {
        width: calc(100% - 40px);
        height: 356px;
        &--add {
          height: auto;
          .add-account-member-section-contents-wrapper {
            flex-direction: column;
            .addacount-member-contents {
              width: 100%;
              &--addmember {
                min-height: 185px;
                height: 185px;
                .addaccount-member-add-button {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
    .contact-state-country-container {
      display: flex;
      flex-direction: column;
      width: 90%;
      .contact-state-select-parent,
      .contact-country-select-parent {
        margin: 0 !important;
        .react-dropdown-select {
          width: 100% !important;
        }
      }
      .contact-state-select-parent {
        margin-bottom: 14px !important;
      }
      .contact-coutnry-select-parent {
        margin-bottom: 8px !important;
      }
    }
    .member-summary-wrapper {
      height: auto;
      .member-summary-contents-wrapper {
        flex-direction: column;
        align-items: center;
        .member-summary-contents-columns--2 {
          width: 100%;
        }
      }
    }
    .account-password-change-input {
      width: calc(100% - 20px);
      margin-right: 10px;
    }
  }
}
// screen#5 iphone 11 && pixel 2 && pixel2 xl iphone 6/7/8, iphone x
@media screen and (min-width: 391px) and (max-width: 414px) {
  .qr-code-page-container {
    & > h4 {
      width: 95%;
      .sharvia-qr-copy-link-text {
        cursor: pointer;
        font-size: 0.6em;
      }
    }
  }
  #qr-code-page-responsive {
    width: 98% !important;
    overflow-x: hidden;
    margin: 10px auto auto auto !important;

    .account-profile-header-container {
      flex-direction: column;
      & > div {
        width: 100%;
      }
    }

    .subscription-plan-detail-wrapper {
      & > h2 {
        font-size: 18px;
      }
    }
    .general-button {
      font-size: 14px;
    }

    .mod-fl-lab-inpt-wrapper {
      width: 90%;
      padding: 5px 0 5px 0;
    }

    .contact-apt {
      width: 90%;
      padding: 5px 0 5px 0;
      .contact-apt-child {
        width: 100%;
        input.contact-apt-child {
          width: calc(100% - 30px);
        }
      }
    }
    .add-edit-account-members-section-wrapper {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      .add-edit-account-member-section {
        width: calc(100% - 40px);

        height: 356px;
        &--add {
          height: auto;
          .add-account-member-section-contents-wrapper {
            flex-direction: column;
            .addacount-member-contents {
              width: 100%;
              &--addmember {
                min-height: 185px;
                height: 185px;
                .addaccount-member-add-button {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
    .contact-state-country-container {
      display: flex;
      flex-direction: column;
      width: 90%;
      .contact-state-select-parent,
      .contact-country-select-parent {
        margin: 0 !important;
        .react-dropdown-select {
          width: 100% !important;
        }
      }
      .contact-state-select-parent {
        margin-bottom: 14px !important;
      }
      .contact-coutnry-select-parent {
        margin-bottom: 8px !important;
      }
    }
    .member-summary-wrapper {
      height: auto;
      .member-summary-contents-wrapper {
        flex-direction: column;
        align-items: center;
        .member-summary-contents-columns--2 {
          width: 100%;
        }
      }
    }
    .account-password-change-input {
      width: calc(100% - 20px);
      margin-right: 10px;
    }
  }
}
// screen#6 iphone 12, iphone 12 pro, iphone 6/7/8
@media screen and (min-width: 361px) and (max-width: 390px) {
  .qr-code-page-container {
    & > h4 {
      width: 95%;
      .sharvia-qr-copy-link-text {
        cursor: pointer;
        font-size: 0.6em;
      }
    }
  }
  #qr-code-page-responsive {
    .account-profile-header-container {
      flex-direction: column;
      & > div {
        width: 100%;
      }
    }
    width: 98% !important;
    overflow-x: hidden;
    margin: 10px auto auto auto !important;
    .general-button {
      font-size: 14px;
    }

    .subscription-plan-detail-wrapper {
      & > h2 {
        font-size: 18px;
      }
    }

    .mod-fl-lab-inpt-wrapper {
      width: 90%;
      padding: 5px 0 5px 0;
    }

    .contact-apt {
      width: 90%;
      padding: 5px 0 5px 0;
      .contact-apt-child {
        width: 100%;
        input.contact-apt-child {
          width: calc(100% - 30px);
        }
      }
    }
    .add-edit-account-members-section-wrapper {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      .add-edit-account-member-section {
        width: calc(100% - 40px);

        height: 356px;
        &--add {
          height: auto;
          .add-account-member-section-contents-wrapper {
            flex-direction: column;
            .addacount-member-contents {
              width: 100%;
              &--addmember {
                min-height: 185px;
                height: 185px;
                .addaccount-member-add-button {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
    .contact-state-country-container {
      display: flex;
      flex-direction: column;
      width: 90%;
      .contact-state-select-parent,
      .contact-country-select-parent {
        margin: 0 !important;
        .react-dropdown-select {
          width: 100% !important;
        }
      }
      .contact-state-select-parent {
        margin-bottom: 14px !important;
      }
      .contact-coutnry-select-parent {
        margin-bottom: 8px !important;
      }
    }
    .member-summary-wrapper {
      height: auto;
      .member-summary-contents-wrapper {
        flex-direction: column;
        align-items: center;
        .member-summary-contents-columns--2 {
          width: 100%;
        }
      }
    }
    .account-password-change-input {
      width: calc(100% - 20px);
      margin-right: 10px;
    }
  }
}
// screen#7 iphone 12 mini && Moto G4 && Galaxy S9 && galaxy s5 galaxy s9
@media screen and (min-width: 321px) and (max-width: 360px) {
  .qr-code-page-container {
    & > h4 {
      width: 95%;
      .sharvia-qr-copy-link-text {
        cursor: pointer;
        font-size: 0.6em;
      }
    }
  }
  #qr-code-page-responsive {
    .account-profile-header-container {
      flex-direction: column;
      & > div {
        width: 100%;
      }
    }
    width: 98% !important;
    overflow-x: hidden;
    margin: 10px auto auto auto !important;
    .general-button {
      font-size: 14px;
    }

    .subscription-plan-detail-wrapper {
      & > h2 {
        font-size: 18px;
      }
    }

    .mod-fl-lab-inpt-wrapper {
      width: 81%;
      padding: 5px 0 5px 0;
    }

    .contact-apt {
      width: 81%;
      padding: 5px 0 5px 0;
      .contact-apt-child {
        width: 100%;
        input.contact-apt-child {
          width: calc(100% - 30px);
        }
      }
    }
    .add-edit-account-members-section-wrapper {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      .add-edit-account-member-section {
        width: calc(100% - 40px);

        height: 356px;
        &--add {
          height: auto;
          .add-account-member-section-contents-wrapper {
            flex-direction: column;
            .addacount-member-contents {
              width: 100%;
              &--addmember {
                min-height: 185px;
                height: 185px;
                .addaccount-member-add-button {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
    .contact-state-country-container {
      display: flex;
      flex-direction: column;
      width: 81%;
      .contact-state-select-parent,
      .contact-country-select-parent {
        margin: 0 !important;
        .react-dropdown-select {
          width: 100% !important;
        }
      }
      .contact-state-select-parent {
        margin-bottom: 14px !important;
      }
      .contact-coutnry-select-parent {
        margin-bottom: 8px !important;
      }
    }
    .member-summary-wrapper {
      height: auto;
      .member-summary-contents-wrapper {
        flex-direction: column;
        align-items: center;
        .member-summary-contents-columns--2 {
          width: 100%;
        }
      }
    }
    .account-password-change-input {
      width: calc(100% - 20px);
      margin-right: 10px;
    }
  }
}
// screen#8 iphone 5/se, galaxy fold
@media screen and (min-width: 280px) and (max-width: 320px) {
  .qr-code-page-container {
    & > h4 {
      width: 95%;
      .sharvia-qr-copy-link-text {
        cursor: pointer;
        font-size: 0.6em;
      }
    }
  }
  #qr-code-page-responsive {
    .account-profile-header-container {
      flex-direction: column;
      & > div {
        width: 100%;
      }
    }
    width: 98% !important;
    overflow-x: hidden;
    margin: 10px auto auto auto !important;
    .general-button {
      font-size: 14px;
    }

    .subscription-plan-detail-wrapper {
      & > h2 {
        font-size: 18px;
      }
    }

    .mod-fl-lab-inpt-wrapper {
      width: 90%;
      padding: 5px 0 5px 0;
    }

    .contact-apt {
      width: 90%;
      padding: 5px 0 5px 0;
      .contact-apt-child {
        width: 100%;
        input.contact-apt-child {
          width: calc(100% - 30px);
        }
      }
    }
    .subscription-plan-detail-wrapper {
      & > h2 {
        font-size: 18px;
      }
    }
    .add-edit-account-members-section-wrapper {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      .add-edit-account-member-section {
        width: calc(100% - 40px);

        height: 356px;
        &--add {
          height: auto;
          .add-account-member-section-contents-wrapper {
            flex-direction: column;
            .addacount-member-contents {
              width: 100%;
              &--addmember {
                min-height: 185px;
                height: 185px;
                .addaccount-member-add-button {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
    .contact-state-country-container {
      display: flex;
      flex-direction: column;
      width: 90%;
      .contact-state-select-parent,
      .contact-country-select-parent {
        margin: 0 !important;
        .react-dropdown-select {
          width: 100% !important;
        }
      }
      .contact-state-select-parent {
        margin-bottom: 14px !important;
      }
      .contact-coutnry-select-parent {
        margin-bottom: 8px !important;
      }
    }
    .member-summary-wrapper {
      height: auto;
      .member-summary-contents-wrapper {
        flex-direction: column;
        align-items: center;
        .member-summary-contents-columns--2 {
          width: 100%;
        }
      }
    }
    .account-password-change-input {
      width: calc(100% - 20px);
      margin-right: 10px;
    }
  }
}
