.about-blocks{
  display:flex;
  flex-direction: column;
}

.text-body-container{
  line-height: 1.2;
  font-size: 20px;
  // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  .bold-text{
    font-size: 30px
  }
}



.bottom-titles-container{
  display: flex;
  .bottom-titles{
    display:flex;
    flex-direction: column;
    margin-right: 60px;
  }
}