.poi-label {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  font-weight: 300 !important;
  color: #273A07;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.poi-title {
  white-space: nowrap;
  text-align: center;
}

.poi-date {
  white-space: nowrap;
  text-align: center;
}

.poi-container {
  position: relative;
}