 .sign-ups-blocks-container{
   display:flex;
   width:100%;
   height: 50px;
   justify-content: center;
   align-items: center;
   margin-top: 20px;
  //  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
     a{
      text-decoration: none;
      div{
         width: 250px;
        border:none;
        background-color: #F7B844;
        border-radius: 10px;
        margin:0 auto;
        margin-top: 20px;
        height: 37px;
        cursor: pointer;
        display:flex;
        justify-content: center;
        align-items: center;
        span{

          color: #09132B;

          font-weight: 900;
          font-size: 18px;
        }
      &:hover{
    background-color: #6EC6ED;

      } 
      }
    }
 }

 #explores{
  a{
    div{
      width: 230px;
      font-size: 16px;}

  }
  margin-bottom: 20px;
}