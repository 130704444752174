$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;

.account-payment-succesful-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  .account-payment-successful-section {
    background-color: rgba(255, 255, 255, 0.7);
    width: 95%;
    border-radius: 5px;

    &--1 {
      background-color: $var-color--dar-blue;
      height: 20vh;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      img.account-payment-successful-banner {
        width: 85%;
        height: auto;
        object-fit: contain;
      }
    }
    &--2 {
      margin-top: 10px;
      min-height: 150px;
      height: auto;
      justify-content: flex-start;
      align-items: center;
      display: flex;
      flex-direction: column;
      h2 {
        margin-bottom: 5px;
      }
      hr {
        width: 70%;
        background-color: $var-color--dar-blue;
        margin-top: 0px;
      }
      .account-payment-successful-message-features-list {
        width: 65%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 10px;
        .payment-successful-text-contents-wrapper {
          width: 50%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          & > div {
            width: 20px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 5px;
            span {
              color: red;
            }
          }
          .payment-successful-text {
            font-size: 0.9em;
          }
        }
      }
    }
    &--3 {
      display: flex;
      justify-content: center;
    }
  }
}
