$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;

.account-change-password-container {
  height: 90% !important;
  .account-password-change-input {
    max-width: 500px;
    margin-left: 10px;
    width: calc(100% - 10px);
    margin-bottom: 10px !important;
  }
  .email-error-container {
    padding-left: 15px;
    height: 67px;
    overflow-y: hidden;
    justify-content: flex-start;
    .email-error-wrapper {
      & > span {
        color: #09132b !important;
      }
    }
    span {
      color: #09132b !important;
    }
  }
  .account-change-password-status-message-wrapper {
    display: flex;
    align-items: center;
    margin-left: 5px;
    span {
      color: $var-color--dark-orange;
    }
    &.--success {
      span {
        color: $var-color--dar-blue;
      }
    }
  }
}
