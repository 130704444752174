.c-text-hub {
  color: white;
  // font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;

  z-index: 2;
  padding-bottom: 10px;
  width: 100vw;
  display: flex;
  justify-content: center;
  background-color: #09132b;
}
