$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;
.account-profile-fourth-section-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  height: 660px;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 10px;
  margin-top: 20px;
  .account-profile-fourth-section {
    &--left {
      width: 49.5%;
      height: 100%;
      border-radius: 10px;
      .account-profile-fourth-section-left {
        &--top {
          height: 50%;
          width: 100%;
          .impact-contents-wrapper {
            display: flex;
            height: 100%;
            width: 100%;
            .impact-content-section {
              &--left {
                width: 40%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                .profile-impact-svg-outer {
                  width: 200px;
                  fill: rgba(255, 255, 255, 0.7);
                  // fill:green;
                  height: 200px;
                }
              }
              &--right {
                width: 60%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                .impact-content-details {
                  &--status {
                    width: 260px;
                    // justify-content: center;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.7);
                    margin-bottom: 10px;
                    span {
                      color: white;
                      font-size: 40px;
                    }
                  }
                  display: flex;
                  flex-direction: row;
                  span {
                    color: white;
                  }
                  .impact-wrapper {
                    width: 160px;
                  }
                }
              }
            }
          }
        }
        &--bottom {
          height: 50%;
          width: 100%;
          .vehicle-info-and-image-wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .vehicle-wrapper {
              height: 100%;
              width: auto;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              .vehicle-image-container {
                margin-left: 20px;
                width: fit-content;
                height: 70%;
                max-width: 50%;
                .vehicle-image {
                  width: auto;
                  max-height: 100%;
                  // mix-blend-mode: multiply;
                }
              }
            }
            .vehicle-edit-container {
            }
            .vehicle-information-wrapper {
              display: flex;
              flex-direction: column;
              height: 100%;
              justify-content: center;
              .vehicle-information-context {
                margin: 5px 0px;
                margin-left: 10px;
                display: flex;
                flex-direction: row;
                span {
                  color: white;
                }
                .title-wrapper {
                  width: 120px;
                  font-weight: 700;
                }
              }
            }
          }
        }
      }
      .subscription-title-wrapper {
        justify-content: space-between;
        display: flex;
        width: calc(100% - 100px);
        padding: 10px 10px;
        margin: 0px 40px;
        padding-bottom: 2px;
        border-bottom: 2px solid rgba(255, 255, 255, 0.7);
        h4 {
          // width: 100%;
          color: white;
          margin: 10px 0px;
          margin-top: 5px;
          z-index: 1;
        }
        .subscription-edit-button-wrapper {
          width: 5%;
          margin-left: -5%;
          z-index: 2;
          .subscription-edit-svg-outer {
            fill: rgba(255, 255, 255, 0.4);
            width: 15px;
            height: 15px;
            cursor: pointer;
            &:hover {
              fill: $var-color--light-orange;
            }
          }
        }
      }
    }
    &--right {
      width: 49.5%;
      height: 100%;
      border-radius: 10px;
      .subscription-title-wrapper {
        justify-content: space-between;
        display: flex;
        width: calc(100% - 100px);
        padding: 10px 10px;
        margin: 0px 40px;
        padding-bottom: 2px;
        border-bottom: 2px solid rgba(255, 255, 255, 0.7);
        h4 {
          // width: 100%;
          color: white;
          margin: 10px 0px;
          margin-top: 5px;
          z-index: 1;
        }
        .subscription-edit-button-wrapper {
          width: 5%;
          margin-left: -5%;
          z-index: 2;
          .subscription-edit-svg-outer {
            fill: rgba(255, 255, 255, 0.4);
            width: 15px;
            height: 15px;
            cursor: pointer;
            &:hover {
              fill: $var-color--light-orange;
            }
          }
        }
      }
    }
  }
}
