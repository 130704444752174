.login-page-background-image {
  height: calc(100vh - 71px);
  // width:100%;
  position: relative;
  max-width: 100vw;
  object-fit: cover;
  // min-height: calc(100vh - 70px - 143px)
}

#log-in-form {
  position: absolute;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: row;
  .log-in-block {
    width: 40%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    background-color: #dfd5c1;
    padding: 50px 0px; // button{
    //   margin: 20px 0px;
    //   width: 150px;
    //   background-color:#F7B844;;
    //   border:none;
    //   border-radius: 12px;
    //   height: 30px;
    //   span{
    //     font-weight: 700;
    //     font-size:20px;
    //   }
    //   &:hover{
    //     background-color: #6EC6ED;;
    //   }
    // }
    .forgot-username-container {
      a {
        span {
          font-size: 15px;
          color: $var-dark-green;
          &:hover {
            color: #d18000;
          }
        }
        &:hover {
          color: #d18000;
        }
      }
    }

    .link-to-signup {
      display: flex;
      margin-top: 20px;
      align-items: center;
      span {
        font-size: 15px;
        color: $var-dark-green;
      }

      div {
        margin-left: 20px;
        background-color: #f7b844;
        width: 150px;
        text-align: center;
        border-radius: 10px;
        span {
          color: black;
          font-weight: 700;
        }
        // &:hover {
        //   background-color: #6ec6ed;
        // }
      }
    }
  }
}

.login-page-login {
  width: 250px;
  border: none;
  background-color: #d18000;
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 20px;
  height: 37px;
  cursor: pointer;
  &:disabled {
    background-color: #51657a;

    span {
      color: white;
    }
    &:hover {
      // background-color: #51657a;
      border-radius: 10px;
    }
  }
  span {
    font-weight: 500;
    font-size: 18px;
    color: white;
  }
  &:hover {
    // background-color: #6ec6ed;
  }
}

.login-input-dist {
  input {
    width: 100%;
  }
}

div.floating-lode-input {
  width: 100%;
  min-width: 284px;
}

input.floating-lode-input {
  padding: 13px;
  padding-left: 16px;
  border-radius: 10px;
  vertical-align: bottom;
  color: #09132b;
  width: calc(100% - 30px);
}

.forgot-login {
  width: 250px;
  border: none;
  background-color: #f7b844;
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 20px;
  height: 37px;
  cursor: pointer;
  span {
    font-weight: 700;
    font-size: 18px;
    color: #09132b;
  }
  &:hover {
    background-color: #6ec6ed;
  }
  &:disabled {
    span {
      color: gray;
    }
    &:hover {
      background-color: #f7b844;
      border-radius: 10px;
    }
  }
}
