$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;
.google-maps-module--wrapper {
  width: calc(100% - 95px);
  height: calc(100vh - 237px);
  max-height: 987px;
  padding: 20px;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  overflow: hidden;
  padding-top: 5px;
}

.map-action-wrapper {
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.google-maps-popup-button {
  background-color: $var-color--button-unselected-blue;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
  width: 75px;
  margin-top: 5px;
  &:hover {
    background-color: $var-color--light-blue;
  }
}

.gm-style-iw-c {
  top: -27px !important;
  &::after {
    display: none !important;
  }
}
.gm-style-iw-t::after {
  display: none !important;
}

.map-block-info-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .map-block-info {
    color: $var-color--dar-blue;
    &--header {
      font-weight: 700;
    }
    &--block_name_bold {
      font-weight: 700;
    }
  }
}

.google-map-bottom-spacer {
  width: 100%;
  height: 60px;
}

.google-map-user-location-svg {
  &:before {
    content: "";
    // position: relative;
    // display: block;
    // width: 300%;
    // height: 300%;
    // box-sizing: border-box;
    // margin-left: -100%;
    // margin-top: -100%;
    // border-radius: 45px;
    // background-color: #01a4e9;
    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    transform: translateX(-50%) translateY(-50%);
  }

  &:after {
    content: "";
    // position: absolute;
    // left: 0;
    // top: 0;
    // display: block;
    // width: 100%;
    // height: 100%;
    background-color: white;
    // border-radius: 15px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
  }
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}

.stand-alone-search-box {
  box-sizing: border-box;
  border: 1px solid transparent;
  width: 400px;
  height: 32px;
  padding: 0 12px;
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  font-size: 14px;
  outline: none;
  text-overflow: ellipses;
  position: absolute;
  left: 48%;
  margin-left: -120px;
  margin-top: 20px;
  z-index: 20;
}
