$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
.check-list-modal {
  height: 500px;
  transition: height 0.5s !important;
}
.check-list-form {
  height: calc(100% - 51px);
}

.block-modal-lists {
  display: flex;
  flex-direction: column;
  width: 91%;
  height: 327px;
  justify-content: flex-start;
  margin: 0 auto;
  .block-modal-add-button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.4);
    .block-modal-lists-add-svg-outer {
      fill: rgba(255, 255, 255, 0.5);
      width: 25px;
      height: 25px;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.7);
      .block-modal-lists-add-svg-outer {
        fill: #f7b844;

        svg {
          fill: #f7b844;
        }
      }
    }
  }
  .block-modal-lists-contents {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    background-color: rgba(255, 255, 255, 0.4);
    margin: 5px 0px;
    border-radius: 10px;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;

    &::-webkit-scrollbar {
      display: none;
    }
    & > div:not(.popup-wrapper--display) {
      width: calc(100% - 8px);
      height: 100%;
      padding: 0px 4px;
      overflow-y: scroll;
      overscroll-behavior: contain;
      &::-webkit-scrollbar {
        display: none;
      }
      .modal-list-wrapper-outer {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        .modal-list-input-checkbox {
          width: auto;
        }
      }
    }
  }
  .block-modal-list-create-wrapper {
    height: 140px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
  }
}

.modal-list-input-form {
  width: 348px !important;
  height: 190px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: white;
  margin: 0 auto;
  margin-top: 10px;
  .modal-list-input--container {
    width: 100%;
    &--top {
      height: 25%;
      display: flex;
    }
    &--middle {
      height: 50%;
      display: flex;
    }
    &--bottom {
      height: 25%;
    }
  }
  div {
    .modal-list-input-form {
      &--inputs {
        border: none;
        background-color: transparent;
        width: 100%;
        &:focus {
          outline: none;
        }
        &--block_name {
        }
        &--block_description {
          height: 50px;
        }
        &--block_cost {
          width: 25%;
        }
        &--block_count {
          width: 25%;

          // border: 2px solid gray;
        }
      }
      &--buttons {
        background-color: $var-color--light-orange;
        border: none;
        border-radius: 5px;
        margin: 0px 2px;
        &--save {
          border: none;
          border-radius: 5px;
        }
        &--cancel {
          margin-left: 50px;
          border: none;
          border-radius: 5px;
        }
      }
    }
  }
}

.create-check-list-modal {
  & > div.mod-type1-title {
    height: 40px;
  }
}
.check-list-modal {
  .summary-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    scroll-behavior: smooth;
    overscroll-behavior: contain;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
    .summary-section-container {
      height: 100%;
    }
  }
}
.block-summary-page-sections {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  //justify-content: center;
  &--page1 {
    // background-color: yellow;
    justify-content: left;
    .mod-fl-lab-inpt-wrapper {
      padding: 18px 0 10px 0;
    }
    .mod-fl-ta {
      height: 156px;
    }
  }
  &--page2 {
    // background-color: green;
    justify-content: left;
  }
  &--page3 {
    // background-color: blue;
    justify-content: left;

    .mod-fl-lab-inpt-wrapper {
      padding: 10px 0;
    }
  }
  &--page4 {
    // background-color: pink;
    justify-content: left;
  }
  &--page5 {
    // background-color: red;
    justify-content: left;
  }
}

.summary-section-container {
  width: 100%;
  height: 376px;
}

@keyframes fullscreenMode {
  0% {
    height: 486px;
  }
  100% {
    height: 1000px;
    max-height: 80vh;
  }
}
.check-list-full-screen-mode {
  overflow: hidden;
  max-height: 80vh !important;
  transition: height 0.5s !important;
  // animation: fullscreenMode 0.5s !important;
  height: 1000px;

  .create-block-contents {
    max-height: calc(75vh - 35px) !important;
    height: 80vh !important;
    .summary-wrapper {
      height: 100%;
      .summary-section-container {
        height: 100%;
        .block-summary-page-sections--page1 {
        }
      }
    }
  }
}

@keyframes movedown {
  0% {
    opacity: 1;
  }
  50% {
    z-index: 0;
  }
  100% {
    // display: none;
    // visibility: hidden;
    opacity: 0;

    z-index: -1000;

    // visibility: none;
  }
}
.activity-modal-buttons-wrapper,
.modal-subtle-line {
  transition: margin 0.5s;
  // -webkit-transform: translateY(00px);
}

.modal-list-viewing-list-state {
  display: none !important;
  // transition: unset 0.5s;
  // animation: movedown 0.5s;

  // opacity: 0;
  // z-index: -1000;
  // margin-bottom: -100px;
  // margin-top: 100px;
  // z-index: -1000;
  // margin-top: -100px;
  // -webkit-transform: translateY(-10000px);
  // margin-bottom: -100px;
  // margin-top: 200px;
  &--wrapper {
    min-height: 430px;
    height: calc(100% - 10px) !important;
    max-height: 100% !important;
    div.summary-wrapper {
      height: 100%;
      section.summary-section-container {
        height: 100%;
      }
    }
  }
}

.paginator-list {
  height: 330px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0.4em;
  top: 14%;
}

.checklist-modal-next-message-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-top: 5px;
}
