.public-navbar-container {
  width: 100vw;
  height: 70px;
  position: fixed;
  z-index: 20;
  left: 0;
}
#highlighted {
  color: #f7b844;
}
.top-nav-padder {
  width: calc(100% - 48px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 10px 24px;
  background-color: #09132b;
  height: calc(100% - 19px);
}

#top-nav-left-icon {
  width: 25px;
  height: 25px;
  color: white;
  cursor: pointer;
}
.top-nav-left {
  width: 230px;
  height: 100%;
  min-width: 230px;
  display: flex;
  // justify-content: row;
  align-items: center;
  margin-left: 8px;
}

.logoimage {
  height: 85%;
  object-fit: contain;
  margin-left: 20px;
  width: 89%;
}

.logoimagelink {
  height: 93%;
  text-decoration: none;
  margin-left: 5px;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
}
.logoimagelink:hover {
  border-radius: 4px;
}

.top-nav-right {
  width: 158px;
  height: 100%;
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  justify-content: flex-end;
  align-items: center;
}

.login-tab {
  width: 100%;
  cursor: pointer;
}

.opened-box {
  position: absolute;
}

.opened-box-hamburger {
  position: absolute;
  top: 71px;
  left: 31px;
  z-index: 10;
}

.contents-container {
  background-color: #09132b;
  width: 158px;
  /* top: 20px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 70px;

  .inner-contents {
    padding: 5px 0px;
    color: white;
    height: 35px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
  }

  .inner-contents:hover {
    background-color: #12234d;
  }
}

.triggerhamburger {
  cursor: pointer;
}

.hamburger-contents-container {
  width: 269px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 5px 5px;
  // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  .sectors {
    color: white;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 90%;
    margin: 0 auto;
    div {
      cursor: pointer;
      width: 100%;
      height: 45px;
      // padding-left:2px;
      display: flex;
      align-items: center;
      .nav-section-text-with-tm {
        display: flex;
        align-items: flex-start;
      }
    }
    div:hover {
      background-color: #12234dc0;
      border-radius: 10px;
    }
  }

  .hamburger-sect1 {
    border-bottom: 1px solid white;
  }

  .hamburger-sect2 {
  }
}

.top-nav-login-button {
  width: 150px;
  background-color: transparent;
  border: 1px solid #f7b844;
  box-sizing: border-box;
  text-align: center;
  font-size: 18px;
  border-radius: 10px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #6ec6ed !important;
  }
}

.hamburger-sect3 {
  font-size: 14px;
  div {
    span {
      font-size: 12px;
    }
  }
}

.tm-text {
  font-size: 6px;
}

.nav-section-text {
  display: flex;
  align-items: flex-start;
}
