$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;

.notification_card_wrapper {
  width: 95%;
  margin: 2px auto;
  border-radius: 5px;
  height: 90px;
  overflow: hidden;
  position: relative;

  &--read {
    background-color: rgba(255, 255, 255, 0.4);
  }
  &--not-read {
    background-color: rgba(255, 255, 255, 0.8);
  }
  .notification-friend-request-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #e8d5b9;
    .notification-card-opacity-layer {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      background-color: rgba(248, 247, 213, 0.4);
      z-index: 1;
    }
    .notification-friend-request {
      &-left {
        width: 80px;
        height: 100%;
        display: flex;
        align-items: center;
        z-index: 3;

        img {
          width: 80%;
          margin-left: 5%;
          height: auto;
          border-radius: 50%;
        }
      }
      &-right {
        // width: calc(100% - 80px);
        height: 100%;
        display: flex;
        flex-direction: column;
        z-index: 3;

        .notification-friend-request-rt-context-wrapper {
          height: 90%;
          width: 100%;
          display: flex;
          align-items: center;
          font-size: 0.9em;
          padding-left: 10px;
          margin-top: 10%;
          .friend-request-name {
            text-transform: capitalize;
          }
        }
        .notification-friend-request-rt-buttons-wrapper {
          height: 0%;
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .notification-friend-request-button {
            &--decline,
            &--accept {
              border: none;
              outline: none;
              cursor: pointer;
              border-radius: 5px;
              margin-right: 4px;
              &:focus {
                outline: none;
                border: none;
              }

              &:hover {
                background-color: $var-color--light-blue;
              }
            }
            &--decline {
            }
            &--accept {
              background-color: $var-color--light-orange;
            }
          }
        }
      }
    }
  }
}

.delete-notification-button {
  position: absolute;
  background-color: transparent;
  border: none;
  outline: none;
  right: 5px;
  top: 5px;
  z-index: 4;
  &:focus {
    outline: none;
    border: none;
  }
  &:hover {
    .delete-notification-svg-outer {
      path {
        fill: $var-color--light-blue;
      }
    }
  }
  .delete-notification-svg-outer {
    width: 15px;
    height: 15px;

    path {
      fill: $var-color--light-orange;
    }
  }
}

.adventure-invite-notification-card-wrapper {
  position: relative;
  .notification-card-opacity-layer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    background-color: rgba(248, 247, 213, 0.4);
    z-index: 1;
  }
}

.accept-notification-button {
  position: absolute;
  background-color: transparent;
  border: none;
  outline: none;
  left: 10px;
  top: 7px;
  z-index: 4;
  border-radius: 5px;
  background-color: $var-color--light-orange;
  &:hover {
    background-color: $var-color--light-blue;
  }
  &:focus {
    outline: none;
    border: none;
  }
  &:hover {
    .delete-notification-svg-outer {
      path {
        fill: $var-color--light-blue;
      }
    }
  }
  .delete-notification-svg-outer {
    width: 15px;
    height: 15px;

    path {
      fill: $var-color--light-orange;
    }
  }
}
