$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;
.membership-module-wrapper {
  width: 80%;
  height: 70px;
  margin-left: 20px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  background-color: $var-color--button-unselected-blue;
  border-radius: 35px;
  .membership-image-container {
    .membership-profile-image {
      width: 70px;
      height: 70px;
      border-radius: 50%;
    }
  }
  .membership-name-container {
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-right: -30px;
    .membership-name {
      width: 60%;
      border-bottom: 1px solid white;
      display: flex;
      justify-content: center;
      span {
        color: white;
      }
    }
    .membership-role {
      span {
        color: white;
      }
    }
  }
  .membership-button-container {
    width: 10%;
    height: 100%;
    display: flex;
    justify-self: center;
    align-items: center;
    .member-button {
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;

      &:hover {
        .member-edit-svg-outer {
          fill: $var-color--light-orange;
        }
      }
      .member-edit-svg-outer {
        width: 20px;
        height: 20px;
        fill: rgba(255, 255, 255, 0.4);
      }
    }
  }
}
