.slide-button {
  display: flex;
  align-items: center;
  padding: 0;
  height: 100%;
  cursor: pointer;
  span {
    width: 15px;
    color: #273a07;
    display: block;
    margin: 0 auto;
    svg {
      fill: #273a07;
      width: 15px;
    }
  }
  &--up--up {
    &:hover {
      span {
        svg,
        path {
          fill: #f7b844;
        }
      }
    }
  }
  &--next {
    right: 0;
    border-radius: 10px;
    &:hover {
      span {
        svg,
        path {
          fill: #f7b844;
        }
      }
    }
    span {
      transform: rotateZ(90deg);
    }
  }

  &--prev {
    left: 0;
    border-radius: 10px;
    &:hover {
      span {
        svg,
        path {
          fill: #f7b844;
        }
      }
    }
    span {
      transform: rotateZ(-90deg);
    }
  }
  &--up--up {
  }
}

@media screen and (max-width: 1280px) {
  .slide-button {
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.slide-button-placeholder {
  width: 55px;
}

.slider-button-group {
  display: flex;
  flex-direction: column;
  height: 151.5px;
  width: 31.5px;
  justify-content: space-between;
}

.slider-buttons-fix-width {
  width: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .trip-board-list-collapsible-icon {
    font-size: 34px;
    color: #273a07;
  }
}

.unnest-svg-outer {
  width: 25px;
  height: 25px;
  fill: #273a07;
}
