//screensize bigger than normal laptops
@media screen and (min-width: 1441px) {
}
@media screen and (max-width: 1440px) {
}
// screen#1 iPad pro vertical
@media screen and (max-width: 1024px) {
}
// screen#2 ipad vertical

@media screen and (max-width: 768px) and (max-width: 860px) {
  .white_paper {
    // margin-top: calc(100% - 200% + 45px) !important;
  }
  .hidden-button-wrapper {
    background: transparent;
    border: none;
    outline: none;
    position: absolute;
    height: 39px;
    bottom: -12px;
    margin-bottom: 10px;
    cursor: pointer;

    // &--edit {
    //   &--level1,
    //   &--level2 {
    //     right: -55px;
    //   }
    //   &--level3 {
    //     right: -40px;
    //   }
    //   &--level4 {
    //     right: 5px;
    //   }
    // }
    // &--delete {
    //   &--level1,
    //   &--level2 {
    //     right: -80px;
    //   }
    //   &--level3 {
    //     right: -65px;
    //   }
    //   &--level4 {
    //     right: -20x;
    //   }
    // }
  }
}
// screen#3  surface duo

@media screen and (min-width: 481px) and (max-width: 767px) {
  .white_paper {
    // margin-top: calc(100% - 200% + 147px) !important;
  }
  .hidden-button-wrapper {
    background: transparent;
    border: none;
    outline: none;
    position: absolute;
    height: 39px;
    bottom: -12px;
    margin-bottom: 10px;
    cursor: pointer;

    // &--edit {
    //   &--level1,
    //   &--level2 {
    //     right: -56px !important;
    //   }
    //   &--level3 {
    //     right: -40px;
    //   }
    //   &--level4 {
    //     right: 5px;
    //   }
    // }
    // &--delete {
    //   &--level1,
    //   &--level2 {
    //     right: -80px;
    //   }
    //   &--level3 {
    //     right: -65px;
    //   }
    //   &--level4 {
    //     right: -20x;
    //   }
    // }
  }
}
// screen#4 iphone 12 pro max,
@media screen and (min-width: 415px) and (max-width: 480px) {
  .white_paper {
    // margin-top: calc(100% - 200% + 81px) !important;
  }
  .hidden-button-wrapper {
    background: transparent;
    border: none;
    outline: none;
    position: absolute;
    height: 39px;
    bottom: -12px;
    margin-bottom: 10px;
    cursor: pointer;

    // &--edit {
    //   &--level1 {
    //     right: -52px !important;
    //   }
    //   &--level2 {
    //     right: -28px !important;
    //   }
    //   &--level3 {
    //     right: 16px !important;
    //   }
    //   &--level4 {
    //     right: 61px !important;
    //   }
    // }
    // &--delete {
    //   &--level1 {
    //     right: -74px !important;
    //   }
    //   &--level2 {
    //     right: -50px !important;
    //   }
    //   &--level3 {
    //     right: -5px !important;
    //   }
    //   &--level4 {
    //     right: 40px !important;
    //   }
    // }
  }
}
// screen#5 iphone 11 && pixel 2 && pixel2 xl iphone 6/7/8, iphone x
@media screen and (min-width: 391px) and (max-width: 414px) {
  .white_paper {
  }
  .hidden-button-wrapper {
    background: transparent;
    border: none;
    outline: none;
    position: absolute;
    height: 39px;
    bottom: -12px;
    margin-bottom: 10px;
    cursor: pointer;

    // &--edit {
    //   &--level1 {
    //     right: -47px !important;
    //   }
    //   &--level2 {
    //     right: -14px !important;
    //   }
    //   &--level3 {
    //     right: 30px !important;
    //   }
    //   &--level4 {
    //     right: 75px !important;
    //   }
    // }
    // &--delete {
    //   &--level1 {
    //     right: -69px !important;
    //   }
    //   &--level2 {
    //     right: -36px !important;
    //   }
    //   &--level3 {
    //     right: 9px !important;
    //   }
    //   &--level4 {
    //     right: 54px !important;
    //   }
    // }
  }
}
// screen#6 iphone 12, iphone 12 pro, iphone 6/7/8
@media screen and (min-width: 361px) and (max-width: 390px) {
  .white_paper {
    margin-top: 0px;
    // margin-top: calc(100% - 200% + 45px) !important;
  }
  .hidden-button-wrapper {
    background: transparent;
    border: none;
    outline: none;
    position: absolute;
    height: 39px;
    bottom: -12px;
    margin-bottom: 10px;
    cursor: pointer;

    // &--edit {
    //   &--level1 {
    //     right: -40px !important;
    //   }
    //   &--level2 {
    //     right: 5px !important;
    //   }
    //   &--level3 {
    //     right: 49px !important;
    //   }
    //   &--level4 {
    //     right: 94px !important;
    //   }
    // }
    // &--delete {
    //   &--level1 {
    //     right: -62px !important;
    //   }
    //   &--level2 {
    //     right: -17px !important;
    //   }
    //   &--level3 {
    //     right: 28px !important;
    //   }
    //   &--level4 {
    //     right: 73px !important;
    //   }
    // }
  }
}
// screen#7 iphone 12 mini && Moto G4 && Galaxy S9 && galaxy s5 galaxy s9
@media screen and (min-width: 321px) and (max-width: 360px) {
  .white_paper {
    margin-top: 0px;
    // margin-top: calc(100% - 200% + 16px) !important;
  }
  .hidden-button-wrapper {
    background: transparent;
    border: none;
    outline: none;
    position: absolute;
    height: 39px;
    bottom: -12px;
    margin-bottom: 10px;
    cursor: pointer;

    // &--edit {
    //   &--level1 {
    //     right: -12px !important;
    //   }
    //   &--level2 {
    //     right: 33px !important;
    //   }
    //   &--level3 {
    //     right: 77px !important;
    //   }
    //   &--level4 {
    //     right: 122px !important;
    //   }
    // }
    // &--delete {
    //   &--level1 {
    //     right: -34px !important;
    //   }
    //   &--level2 {
    //     right: 11px !important;
    //   }
    //   &--level3 {
    //     right: 56px !important;
    //   }
    //   &--level4 {
    //     right: 101px !important;
    //   }
    // }
  }
}
// screen#8 iphone 5/se, galaxy fold
@media screen and (min-width: 280px) and (max-width: 320px) {
  .white_paper {
    margin-top: 0px;
    // margin-top: calc(100% - 200% + 45px) !important;
  }
}
