.adventure-module-wrapper {
  height: calc(150vh / 4);
  width: 300px;
  border: none;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
  cursor: pointer;
  max-height: 266px;
  // box-shadow: 0 2px 1px -10px rgba(255, 255, 255, 0.093),
  //   0 4px 2px -7px rgba(255, 255, 255, 0.133),
  //   0 6px 5px -10px rgba(255, 255, 255, 0.167),
  //   0 12px 15px -12px rgba(255, 255, 255, 0.207);

  &:hover {
    box-shadow: 8px 8px 2px rgba(0, 0, 0, 0.4);
  }
  &:hover {
    .adventure-block-title-text {
      cursor: pointer;
      // color: #f7b844;
    }
    .adventure-block-bottom-center {
      cursor: pointer;
      //color: #f7b844;
    }
    .future {
      //background-color: rgba(247, 184, 68, .6);
      background: rgba(247, 184, 68, 0.6);
    }

    .past {
      //background-color: rgba(110, 198, 237, .6);
      background: rgba(110, 198, 237, 0.6);
    }
    .now {
      //background-color: rgba(218, 68, 49, .8);
      background: rgba(218, 68, 49, 0.8);
    }
  }
}

.adventure-block-top {
  // background-color: rgba(110, 198, 237, 0.3);
  background-color: #ffff;
  width: 100%;
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(255, 255, 255);
  height: 44px;
  border-bottom: none;
}

.adventure-block-title {
  // background-color: rgba(9, 19, 43, 0.6);
  // background-color: #09132b;

  width: 100%;
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: #273a07;
  height: 65%;
  border-bottom: none;

  .adventure-block-title-text {
    text-transform: uppercase;
    color: #273a07;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
  }
}

.adventure-block-status {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 25px;

  .adventure-block-season-text {
    text-transform: uppercase;
    //color: rgba(255, 255, 255, .6);
    color: rgba(9, 19, 43, 0.6);
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 2px;
  }

  .adventure-block-status-text {
    text-transform: lowercase;
    //color: rgba(255, 255, 255, .6);
    // color: rgba(9, 19, 43, 0.6);
    color: #273a07;
    // font-size: 12px;
    // font-weight: 700;
    // letter-spacing: 1px;
    color: #273a07;
    text-align: center;
    font-size: 11px;
  }
}

.future {
  //background-color: rgba(247, 184, 68, .6);
  background: linear-gradient(
    90deg,
    rgba(9, 19, 43, 0.6),
    rgba(247, 184, 68, 0.6),
    rgba(9, 19, 43, 0.6)
  );
}

.past {
  //background-color: rgba(110, 198, 237, .6);
  background: linear-gradient(
    90deg,
    rgba(9, 19, 43, 0.6),
    rgba(110, 198, 237, 0.6),
    rgba(9, 19, 43, 0.6)
  );
}

.now {
  //background-color: rgba(218, 68, 49, .8);
  background: linear-gradient(
    90deg,
    rgba(9, 19, 43, 0.6),
    rgba(218, 68, 49, 0.8),
    rgba(9, 19, 43, 0.6)
  );
}

.adventure_block_svgs {
  color: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;

  .adv-svg-itinerary {
    width: 20px;
    fill: rgba(255, 255, 255, 0.35);
  }

  .adv-svg-itinerary-outer {
    width: 20px;
    &:hover {
      .adv-svg-itinerary {
        cursor: pointer;
        fill: #f7b844;
      }
    }
  }

  .adv-ct-itinerary {
    font-size: 11px;
    margin-left: 3px;
  }

  .adv-svg-friend {
    width: 15px;
    fill: rgba(255, 255, 255, 0.35);
  }

  .adv-svg-friend-outer {
    width: 15px;
  }

  .adv-ct-friend {
    font-size: 11px;
    margin-right: 3px;
  }
}

.svg-friend-section {
  &:hover {
    .adv-svg-friend {
      fill: rgba(247, 184, 68, 0.8);
    }
    .adv-ct-friend {
      color: rgba(247, 184, 68, 0.8);
    }
    &:hover:before {
      display: block;
    }
  }
}

.adventure-block-bottom {
  // background-color: rgba(9, 19, 43, 0.6);
  // background-color: #09132b;
  background-color: #ffff;
  width: 100%;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  color: #273a07;

  .adventure-block-bottom-left {
    display: inline-block;
    color: #273a07;
    width: 14%;
  }

  .adventure-block-bottom-center {
    display: inline-block;
    color: #273a07;
    text-align: center;
    font-size: 11px;
    width: 72%;

    .adv-blk-btm-season {
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 2px;
    }

    .adv-blk-btm-dur {
      font-weight: 700;
    }

    .adv-blk-btm-det {
      font-weight: 500;
    }
  }

  .adventure-block-bottom-right {
    width: calc(14% - 10px);
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    @media (max-width: $breakpoint-alpha) {
      & > svg {
        width: 40px;
        height: 40px;
        path {
          fill: $green7;
        }
      }
    }
  }
}
.adv-block-impact {
  width: 30px;
  height: 30px;
  path {
    fill: $var-dark-green;
  }
}
//screensize bigger than normal laptops
@media screen and (min-width: 1441px) {
}
@media screen and (max-width: 1440px) {
}
// screen#1 iPad pro vertical
@media screen and (max-width: 1024px) {
}
// screen#2 ipad vertical

@media screen and (max-width: 768px) and (max-width: 860px) {
}
// screen#3  surface duo

@media screen and (min-width: 481px) and (max-width: 767px) {
}
// screen#4 iphone 12 pro max,
@media screen and (min-width: 415px) and (max-width: 480px) {
  .adventure-module-wrapper {
    width: 95vw !important;
  }
}
// screen#5 iphone 11 && pixel 2 && pixel2 xl iphone 6/7/8, iphone x
@media screen and (min-width: 391px) and (max-width: 414px) {
  .adventure-module-wrapper {
    width: 95vw !important;
  }
}
// screen#6 iphone 12, iphone 12 pro, iphone 6/7/8
@media screen and (min-width: 361px) and (max-width: 390px) {
  .adventure-module-wrapper {
    width: 95vw !important;
  }
}
// screen#7 iphone 12 mini && Moto G4 && Galaxy S9 && galaxy s5 galaxy s9
@media screen and (min-width: 321px) and (max-width: 360px) {
  .adventure-module-wrapper {
    width: 95vw !important;
  }
}
// screen#8 iphone 5/se, galaxy fold
@media screen and (min-width: 280px) and (max-width: 320px) {
  .adventure-module-wrapper {
    width: 95vw !important;
  }
}
