$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
.group-block {

    width: 20%;
    max-height: 400px;
    height: 300px;
    display: flex;
    flex-direction: column;
    margin: 20px;
    // border: 1px solid white;
    border-radius: 10px;
    overflow: hidden;
    background: linear-gradient(-125deg, #878d98, #535a6b);

    box-shadow: 2px 2.7px 1.1px -4px rgba(255, 255, 255, 0.093),
      2px 3.3px 3px -5px rgba(255, 255, 255, 0.06),
      2px 2.9px 6.6px -4px rgba(255, 255, 255, 0.167),
      2px 18.3px 14.4px -5px rgba(255, 255, 255, 0.05),
      2px 17px 19px -10px rgba(255, 255, 255, 0.1);
    .image {
      width: 100%;
      height: 35%;
      display: flex;
      justify-content: center;
      align-items: center;
      // border-bottom: 1px solid rgba(255, 255, 255, 0.4);
      // background-color: rgba(255, 255, 255, 0.3);
      img {
        /* width: 102%; */
        height: 75%;
        object-fit: fit;
        border-radius: 50%;
      }
      .friend-block-svg-outerlayer {
        height: 75%;
        fill: rgba(255, 255, 255, 0.4);
      }
    }
    .content {
      height: 50%;
      width: 100%;
      margin-top: 0px;
      color: white;
      width: calc(100% - 20px);
      margin: 0px 10px;
      background-color: rgba(255, 255, 255, 0.3);
      padding: 0px;
      display:flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      border-radius:10px;
      .header{
        font-size:20px;
        margin-bottom:10px;
        text-align: center;
      }
    }
    .extra {
      height: 20%;
      width: calc(100% - 20px);
      margin-top: 0px;
      display: flex;
      justify-content: center;
    }
  .friends-wrapper {
    width: 95%;
    height: 150px;
    margin: 20px;
    display: flex;
    flex-direction: row;
    border-radius: 80px;
    border: 1px solid $var-color--light-orange;
    .friends-image-container {
      width: 15%;
      height: 100%;
      .friends-block-image {
        width: 100%;
        border-radius: 110px;
        height: 100%;
      }
    }
    .friends-info-container {
      margin-left: 40px;
      width: 80%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .friends-info-content-container {
        .friends-info-top {
          color: white;
          text-transform: uppercase;
          font-weight: bolder;
          font-size: 24px;
        }
        .friends-info-bottom {
          color: white;
          text-transform: uppercase;
        }
      }
    }
  }

  .group-block--top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    & > button {
      height: 30px;
      text-align: center;
      border-radius: 10px;
      color: #09132b;
      outline: none;
      border: none;
      background-color: transparent;
      margin: 2px;
      &:hover {
        .group-block-svg-outer {
          fill: $var-color--light-orange;
        }
      }
    }
    .friend-block-no-picture-placeholder {
      height: 50%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .group-block-buttons {
      margin: 0px 2px;
    }
    .group-block-svg-outer {
      width: 25px;
      height: 25px;
      fill: $var-color--default-buttons;
    }
  }
}
