.svgJoinOuter{
  &:hover{
    .svgJoin {
      fill: #DA4431;
    }
  }
}

.svgBuildOuter{
  &:hover{
    .svgBuild{
        fill: #DA4431;
      }
  } 
}

.svgTagAndSaveOuter {
  &:hover{
    .svgTagAndSave{
      fill: #DA4431;
    }
  }
}

.svgInviteOuter{
  &:hover{
    .svgInvite{
      fill: #DA4431;
    }
  }
}
.svgCollaborateOuter{
  &:hover{
    .svgCollaborate{
      fill: #DA4431;
    }
  }
} 

.svgShareOuter{
  &:hover{
    .svgShare{
      fill: #DA4431;
    }
  }
}
.svgStewardOuter{
  &:hover{
    .svgSteward{
      fill: #DA4431;
    }
  }
}
.svgCommunityOuter{
  &:hover{
  .svgCommunity{
    fill: #DA4431;
  }
  }
}
.svgVoiceOuter{
  .svgVoice{
   &:hover{
    fill: #DA4431;
  }
  }
}