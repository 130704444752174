.new-location-block-wrapper {
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  .new-location-block-pop-button {
    line-height: 11px;
    @include unselectedButtonDarkBlue;

    &--new {
    }
    &--cancel {
    }
  }
}
