$green: #7ac14259;
$white: #fff;
$curve: cubic-bezier(0.65, 0, 0.45, 1);
.adventure-modal-attendees-wrapper {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 100%;
  overflow-y: auto;
  .travel-attendee-title-wrapper {
    width: 100%;
    & > span {
      color: white;
    }
  }
  .attendees-box-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    .adventure-attendee {
      // width: 50px;
      // height: 50px;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      margin: 10px;
      background: transparent;
      border: none;
      position: relative;
      & > img {
        width: 65px;
        height: 65px;
        border-radius: 50%;
      }
      & > span {
        width: 65px;
        text-align: center;
      }
      .clicked-friend-indicator {
        display: none;
      }
      &.selected-attendee {
        .clicked-friend-indicator {
          display: block;
          position: absolute;
          top: 1px;
          left: 6px;
        }
      }
    }
    .attendees-no-friends-wrapper {
      width: 100%;
      height: 250px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .no-friends-text {
        color: white;
        font-size: 16px;
        text-align: center;
        margin-bottom: 20px;
      }
    }
  }
}

.adventure-modal-travel-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 80%;
  .travel-method-box {
    &:hover {
      color: #d57e20;
      cursor: pointer;
      span {
        color: #d57e20 !important;
      }
      path {
        fill: #d57e20 !important;
      }
      .menu-drawer-svg {
        fill: #d57e20;
      }
    }
    border: none;
    outline: none;
    background-color: transparent;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 2px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 5px 10px 5px 0px;
    padding: 0px 5px 0 5px;
    width: 100px;
    height: 80px;

    .menu-drawer-svg {
      fill: white;
    }

    &:hover {
      background-color: $color-block-hover-background;
      box-shadow: 4px 4px 3px $color-block-shadow;
      fill: #d57e20;
    }
    .travel-method-svg-outer {
      width: 65px;
      height: 65px;
      border-radius: 50%;
      overflow: hidden;
      path {
        fill: rgba(255, 255, 255, 0.7);
      }
    }
    & > span {
      color: white;
      // width: 65px;
    }
    .travel-method-clicked-indicator {
      display: none;
    }
    &.selected-travel-method {
      // .travel-method-clicked-indicator {
      //   display: block;
      //   position: absolute;
      // }
      background-color: $color-block-hover-background;
      box-shadow: 4px 4px 3px $color-block-shadow;
      fill: #d57e20;
      color: #d57e20;
      cursor: pointer;
      span {
        color: #d57e20 !important;
      }
      path {
        fill: #d57e20 !important;
      }
    }
  }
}

.checkmark {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: $white;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px $green;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: $green;
  fill: none;
  animation: stroke 0.6s $curve forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s $curve 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px $green;
  }
}
