.list-module-open-button {
  // width: 15px;
  // height: 15px;
  display: flex;
  justify-content: center;
  background-color: transparent;
  border: none;
  outline: none;
  color: #646a78;

  .list-button-svg-outer {
    width: 10px;
    height: 10px;
    path {
      // width: 15px;
      // height: 15px;
      // fill: black;
      fill: #646a78;
    }
  }
  &--placeholder {
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
  }
}

.list-module-button-wrapper {
  width: 25px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #646a78;
  fill: #646a78;
}
