.comments-comment-wrapper {
  display: flex;
  flex-direction: column;
  height: calc(248px - 115px);
  overflow-y: scroll;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}
