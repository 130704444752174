$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);

// .summary-wrapper {
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   overflow-y: hidden;
//   height: 330px;
//   scroll-behavior: smooth;
//   overscroll-behavior: contain;

//   &::-webkit-scrollbar {
//     display: none;
//   }
// }
.create-block-contents {
  height: 395px !important;
  overflow-y: hidden !important;
  max-height: 395px !important;
}
.block-summary-page-sections {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  &--page1 {
    // background-color: yellow;
  }
  &--page2 {
    // background-color: green;
  }
  &--page3 {
    // background-color: blue;
    overflow: hidden;
  }
  &--page4 {
    // background-color: pink;
  }
  &--page5 {
    // background-color: red;
  }
}
.block-adder-color-picker {
  width: 92% !important;
  height: 340px;
  & > div {
    height: 100% !important;
    width: 100% !important;
    &::-webkit-scrollbar {
      display: none !important;
    }
    & > div {
      box-shadow: none !important;
      background: none !important;
      &::-webkit-scrollbar {
        display: none !important;
      }
      & > div {
        &::-webkit-scrollbar {
          display: none !important;
        }
        & > div {
          justify-content: center;
          display: flex;
          flex-wrap: wrap;
          &::-webkit-scrollbar {
            display: none !important;
          }
          & > div {
            margin-right: 2px !important;

            & > span {
              & > div {
                height: 21px !important;
                width: 36px !important;
              }
            }
          }
        }
      }
    }
  }
}
.mod-save-btn-undefined {
  background-color: white;
}
.files-wrapper {
  width: 100%;
  // height: 235px;
  display: flex;
  overflow-y: hidden;
  justify-content: flex-start;
  margin: 0 auto;
  &::-webkit-scrollbar {
    display: none;
  }

  .mod-blk-fls-left {
    width: 12%;
    min-height: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // background-color: rgba(255, 255, 255, 0.1);
    // border-top-left-radius: 10px;
    // border-bottom-left-radius: 10px;
    box-sizing: border-box;
    color: #827700;
    height: 100%;
    padding: 5px;
    border: 1px solid #827700;
    border-radius: 4px;
    cursor: pointer;
    .add-flgs-svg-outer {
      width: 25px;
      height: 25px;
      fill: #827700;
    }
    span {
      color: #827700;
      font-size: 10px;
    }
    &:hover {
      .add-flgs-svg-outer,
      span {
        fill: #545b6b;
        color: #545b6b;
      }
    }
  }
  .mod-blk-fls-right {
    width: 88%;
    height: 100%;
    display: flex;
    gap: 4px;
    // flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-x: auto;
    padding-bottom: 5px;
    margin-left: 5px;
    &::-webkit-scrollbar {
      width: 4px !important;
      height: 4px !important;
    }
    &::-webkit-scrollbar-thumb {
      background: #827700;
    }
    &::-webkit-scrollbar-track {
      position: relative;
      border-right: 1px solid #273A07;
    }
    &::-webkit-scrollbar-track::after {
      position: absolute;
      left: 2px;
      width: 1px;
      height: 100%;
      background-color:#273A07;
    }
    & > div {
      width: 98%;
      flex-shrink: 0;
      color: #827700;
      border: 1px solid #273a09;
    }
  }
}

.blk-file-wrapper {
  display: flex;
  width: 19%;
  height: 32%;
  // border: 1px solid black;
  margin: 2px 5px;
  flex-direction: column;
  background-color: #e0e2e4;
  border-radius: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
  .blk-file-top {
    width: 90%;
    height: 80%;
    .blk-file-name {
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      span {
        font-size: 12px;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: #09132b;
        font-weight: 700;
      }
    }
    .blk-file-notes {
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      span {
        font-size: 12px;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
  .blk-file-bottom {
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: row;
    .blk-file-btm-left {
      width: 25%;
      .blk-file-copy-outer {
        fill: white;
        width: 100%;
        height: 100%;
        .blk-file-copy {
          fill: white;
          width: 100%;
          height: 100%;
        }
      }
    }
    .blk-file-btm-mid {
      width: 50%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      span {
        font-size: 12px;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    .blk-file-btm-right {
      width: 25%;
      cursor: pointer;
      &:hover {
        .blk-file-delete-outerLayer,
        .blk-file-delete {
          fill: #f7b844;
        }
      }
      .blk-file-delete-outerLayer {
        fill: white;
        width: 100%;
        height: 100%;
        .blk-file-delete {
          fill: white;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.comments-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 67%;
  .comment-view-wrapper {
    width: 90%;
    // overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.votes-wrapper {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 300px;
  overflow-y: scroll;
  min-height: 300px;
}

.votes-wrapper::-webkit-scrollbar {
  display: none;
}

.color-separator-bar {
  height: 30px;
  color: white;
  border-bottom: 2px solid #6ec6ed;
  display: flex;
  align-items: flex-end;
  margin-bottom: 5px;
  padding: 0px 10px;
}

.comment-thumbs-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  .comment-thumbs-content {
    height: 45px;
    width: 45px;
    display: flex;
    flex-direction: column;
    border: 3px solid rgba(255, 255, 255, 0.4);
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 10px;
    & > svg {
      width: 25px;
      height: 25px;
      fill: rgba(255, 255, 255, 0.4);
    }
    & > span {
      color: rgba(255, 255, 255, 0.4);
    }
  }
}
.add-to-adventure-button-container {
  display: flex;
  width: 80%;
  justify-content: flex-start;
  color: #827700;
  align-items: center;
  margin-bottom: 2px;
  .svg-container {
    height: auto;
    width: auto;
    // border: 2px solid $var-color--light-orange;
    border-radius: 10px;
    display: flex;
    align-items: center;

    .added-to-adventure-svg-outer {
      width: 20px;
      height: 20px;
      margin-right: 5px;
      path {
        fill: #827700;
      }
    }
  }

  .add-to-adventure-button {
    background-color: $var-color--light-orange;
    border: none;
    outline: none;
    color: #545b6b;
    // width: 55px;
    height: 25px;
    // border:1px solid $var-color--dar-blue;
    border-radius: 5px;
    cursor: pointer;
    margin-right: -2px;
    &:hover {
      background-color: $var-color--light-blue;
      // color: $var-color--light-orange;
    }
    &:focus {
      outline: none;
    }
  }
  span {
    // margin-left: 16px;
  }
}

.create_edit_block_modal_drop_down_select_parent {
  margin: 0 auto;
  width: 100%;
  & > div {
    .create_edit_block_modal_drop_down_select {
      border-radius: 4px;
      width: 100%;
      background-color: white;
      &.--activity-categories {
        .react-dropdown-select-dropdown {
          max-height: 271px !important;
        }
      }
      .react-dropdown-select-content {
        display: flex;
        // justify-content: center;
        align-items: center;
        span {
          color: #827700;
        }
      }

      .react-dropdown-select-dropdown {
        width: 100%;
        top: 38px;
      }
    }
  }
  &input,
  input::placeholder {
    color: black;
  }
}

.red-placeholder-dropdown {
  // width: calc(100% - 26px) !important;
}

.search-location-input-wrapper {
  padding: 10px 0 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  .search-location-input {
    width: 79%;
    border-radius: 12px;
    border: none;
    outline: none;
    &:focus {
      outline: none;
      border: none;
    }
  }
}

.paginator {
  /* height: 330px; */
  justify-content: center;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 1em;
  /* top: 55px; */
  top: 0px;
  height: 100%;
}

.pagination-bullet {
  margin-bottom: 5px;
  background-color: rgb(199, 199, 199);
  border: none;
  color: white;
  padding: 5px 5px;
  border-radius: 100px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}

.pagination-bullet-active {
  margin-bottom: 5px;
  background-color: $var-color--light-orange;
  border: none;
  color: white;
  padding: 5px 5px;
  border-radius: 100px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.comments-page-toggle-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  .summary-comments-toggle {
    width: 48%;
    margin: 0px 2px;
  }
}
@keyframes growleft {
  0% {
    width: 50%;
  }
  100% {
    width: 100%;
  }
}
@keyframes shrinkleft {
  0% {
    width: 100%;
  }
  100% {
    width: 50%;
    // .activity-modal-comment-submit-button {
    //   display: none;
    // }
  }
}
.activity-modal-buttons-wrapper {
  width: 100%;
  height: 37px;
  .activity-modal-comment-submit-button {
    display: none;
  }
  .create-activity-button-box {
    display: flex;
    flex-direction: row;
  }
  &--comments-activated {
    display: flex;
    overflow: hiden;
    justify-content: flex-start;
    margin: 0 auto;
    animation: growleft 0.5s;
    & > div {
      width: 50%;
      height: auto;
      display: flex;
      align-items: center;
    }
    .activity-modal-comment-submit-button {
      display: flex;
    }
  }
  &--comments-deactivate {
    display: flex;
    overflow: hiden;
    justify-content: center;
    margin: 0 auto;
    animation: shrinkleft 0.5s;
    & > div {
      // width: 100%;
      height: auto;
      display: flex;
      align-items: center;
    }
    .activity-modal-comment-submit-button {
      display: none;
    }
  }
}

.modal-subtle-line {
  width: calc(100% - 40px);
  margin: 0px 20px;
  border-bottom: 0.5px solid #828da7;
  &--top {
  }
  &--bottom {
  }
}

.map-container {
  width: 100%;
  //border-radius: 10px;
  border: 1px solid #827700;
  overflow: hidden;
  overscroll-behavior: contain;
  height: 224px;
  .mapboxgl-canvas-container {
    overscroll-behavior: contain;

    canvas.mapboxgl-canvas {
      width: 100% !important;
      overscroll-behavior: contain;

      aspect-ratio: auto 384/100 !important;
    }
  }
}

.pagination-bullet {
  &:hover::before {
    display: inline-block;
  }
  &::before {
    content: attr(data-text);
    position: absolute;
    margin-bottom: -32px;
    font-size: 11px;
    padding: 1px 5px;
    display: none;
    color: #09132b;
    background: #f7b844;
    border-radius: 4px;
    transition: opacity 0.1s ease-out;
    z-index: 99;
    text-align: left;
    margin-left: -81px;
    margin-top: -7px;
    width: 64px;
    text-align: center;
  }
}

.new-trip-activity-modal {
  height: 530px !important;
  max-height: 530px !important;
}

.next-message-wrapper {
  margin-top: 5px;
  color: white;
}

.get-my-location-button-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin: 0 auto;

  .get-my-location-button {
    width: 90px;
    height: 30px;
    background: #a7a7a7;
    border-radius: 10px;
    outline: none;
    border: none;
    text-transform: uppercase;
    cursor: pointer;

    font-size: 0.7em;
    display: flex;
    justify-content: center;
    align-items: center;
    &:focus {
      outline: none;
    }
    &:hover {
      background: $var-color--light-blue;
    }
    .current-location-svg {
      height: 25px;
      width: 25px;
      path {
        fill: rgba(255, 255, 255, 0.7);
      }
    }
  }
  .open-waze-button,
  .open-google-maps-button {
    width: 90px;
    height: 30px;
    background: #a7a7a7;
    border-radius: 10px;
    outline: none;
    border: none;
    text-transform: uppercase;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
    &:focus {
      outline: none;
    }
    &:hover {
      background: $var-color--light-blue;
    }
    .waze-svg-outer,
    .google-map-svg-outer {
      height: 25px;
      width: 25px;
    }
  }
}
.activity-modal {
  height: 510px !important;
}
.location-section-input-top {
  padding-bottom: 3px !important;
}

.block-modal-section-wrapper {
  height: 395px !important;
}

.modal-location-long-lat-container {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0 auto;
  justify-content: space-between;
  // & > div {
  //   width: 49%;
  //   padding-bottom: 5px !important;
  //   div.floating-lode-input {
  //     width: 100%;
  //     min-width: 0px !important;
  //   }
  // }
}

.modal-location-geocoder {
  width: 100%;
  & > div {
    width: 100% !important;
    max-width: 100% !important;
    margin-bottom: 5px;
  }
}
