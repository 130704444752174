//screensize bigger than normal laptops
@media screen and (min-width: 1441px) {
}
@media screen and (max-width: 1440px) {
}
// screen#1 iPad pro vertical
@media screen and (max-width: 1024px) {
}
// screen#2 ipad vertical

@media screen and (max-width: 768px) and (max-width: 860px) {
  #friend-block-list-mobile {
    .friend-block-list-right-content-wrapper {
    }
  }
}
// screen#3  surface duo

@media screen and (min-width: 481px) and (max-width: 767px) {
  #friend-block-list-mobile {
    .friend-block-list-right-content-wrapper {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .friend-block-list-contact-wrapper,
      .friend-block-list-buttons-wrapper {
        width: 100%;
        height: 50%;
      }
      .message-block-list-contact-wrapper,
      .message-block-list-buttons-wrapper {
        width: unset;
        height: unset;
        padding-left: 0px;
      }
      .friend-block-list-contact-wrapper {
      }
      .friend-block-list-buttons-wrapper {
      }
    }
  }
}
// screen#4 iphone 12 pro max,
@media screen and (min-width: 415px) and (max-width: 480px) {
  #friend-block-list-mobile {
    .friend-block-list-right-content-wrapper {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .friend-block-list-contact-wrapper,
      .friend-block-list-buttons-wrapper {
        width: 100%;
        height: 50%;
      }
      .message-block-list-contact-wrapper,
      .message-block-list-buttons-wrapper {
        width: unset;
        height: unset;
        padding-left: 0px;
      }
      .friend-block-list-contact-wrapper {
      }
      .friend-block-list-buttons-wrapper {
      }
    }
  }
}
// screen#5 iphone 11 && pixel 2 && pixel2 xl iphone 6/7/8, iphone x
@media screen and (min-width: 391px) and (max-width: 414px) {
  #friend-block-list-mobile {
    .friend-block-list-right-content-wrapper {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .friend-block-list-contact-wrapper,
      .friend-block-list-buttons-wrapper {
        width: 100%;
        height: 50%;
      }
      .message-block-list-contact-wrapper,
      .message-block-list-buttons-wrapper {
        width: unset;
        height: unset;
        padding-left: 0px;
      }
      .friend-block-list-contact-wrapper {
      }
      .friend-block-list-buttons-wrapper {
      }
    }
  }
}
// screen#6 iphone 12, iphone 12 pro, iphone 6/7/8
@media screen and (min-width: 361px) and (max-width: 390px) {
  #friend-block-list-mobile {
    .friend-block-list-right-content-wrapper {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .friend-block-list-contact-wrapper,
      .friend-block-list-buttons-wrapper {
        width: 100%;
        height: 50%;
      }
      .message-block-list-contact-wrapper,
      .message-block-list-buttons-wrapper {
        width: 100%;
        height: 100%;
        padding-left: 0px;
      }
      .friend-block-list-contact-wrapper {
      }
      .friend-block-list-buttons-wrapper {
      }
    }
  }
}
// screen#7 iphone 12 mini && Moto G4 && Galaxy S9 && galaxy s5 galaxy s9
@media screen and (min-width: 321px) and (max-width: 360px) {
  #friend-block-list-mobile {
    .friend-block-list-right-content-wrapper {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .friend-block-list-contact-wrapper,
      .friend-block-list-buttons-wrapper {
        width: 100%;
        height: 50%;
      }
      .message-block-list-contact-wrapper,
      .message-block-list-buttons-wrapper {
        width: unset;
        height: unset;
        padding-left: 0px;
      }
      .friend-block-list-contact-wrapper {
      }
      .friend-block-list-buttons-wrapper {
      }
    }
  }
}
// screen#8 iphone 5/se, galaxy fold
@media screen and (min-width: 280px) and (max-width: 320px) {
  #friend-block-list-mobile {
    .friend-block-list-right-content-wrapper {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .friend-block-list-contact-wrapper,
      .friend-block-list-buttons-wrapper {
        width: 100%;
        height: 50%;
      }
      .message-block-list-contact-wrapper,
      .message-block-list-buttons-wrapper {
        width: unset;
        height: unset;
        padding-left: 0px;
      }
      .friend-block-list-contact-wrapper {
      }
      .friend-block-list-buttons-wrapper {
      }
    }
  }
}
