.pdf-impact-report {
  width: 1056px;
  height: max-content;
  //   position: fixed;
  box-sizing: border-box;
  padding-top: 100px;
  padding-bottom: 100px;
  position: fixed;
  z-index: -1000;
  left: 500vw;
  * {
    box-sizing: border-box;
  }
  .report-header {
    display: flex;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 40px;
  }
  .report-section {
    width: 100%;
    height: max-content;
    display: block;
    box-sizing: border-box;
    &.brief-summary {
      .brief-summary-header {
        padding: 40px;
        box-sizing: border-box;
        width: 100%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        .date-text {
          margin-top: 20px;
        }
      }
      .brief-summary-container {
        box-sizing: border-box;

        display: block;
        width: 100%;
        padding: 40px;
        padding-top: 0px;
        position: relative;
        .report-summary-container {
          display: flex;
          box-sizing: border-box;
          vertical-align: middle;
          z-index: 2;
          align-items: center;
        }

        .report-logo-container {
          width: 70%;
          margin: auto 0;
          position: absolute;
          top: 200px;
          right: -20%;
          opacity: 0.1;
          z-index: 1;
          .report-log {
            margin: auto;
            width: 100%;
            height: auto;
            object-fit: contain;
          }
        }
        .report-summary-container {
          width: 100%;
          .summary-col {
            display: block;
            &.svgs {
              width: 100%;
              display: flex;
              justify-content: center;
              .summary-card {
                width: 45%;
                height: max-content;
                display: flex;
                align-items: center;
                .card-left,
                .card-right {
                  width: 30%;
                  display: inline-block;
                  vertical-align: center;
                }
                .card-left {
                  margin-right: 30px;
                  border: 10px solid $warm1;
                  padding: 30px;
                  border-radius: 50%;
                  width: 150px;
                  height: 150px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
                .card-right {
                  text-align: center;
                }
                .report-svg-outer {
                  width: 100%;
                  height: auto;
                  path {
                    fill: $green7;
                  }
                }
              }
            }
            &.dollars {
              width: 58%;
              padding-left: 40px;
              .dollar-cards {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .dollar-card-spacer {
                  width: 50px;
                }
              }
              .dollars-line {
                background-color: $green7;
              }
            }
          }
        }
      }
    }
    &.donation-logs {
      $breakpoint-alpha: 480px; // adjust to your needs

      .rwd-table {
        margin: 1em 0;
        min-width: 300px; // adjust to your needs
        width: 100%;

        tr {
          border-top: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
        }

        th {
          display: none; // for accessibility, use a visually hidden method here instead! Thanks, reddit!
        }

        td {
          display: block;

          &:first-child {
            padding-top: 0.5em;
          }
          &:last-child {
            padding-bottom: 0.5em;
          }

          &:before {
            content: attr(data-th) ': '; // who knew you could do this? The internet, that's who.
            font-weight: bold;

            // optional stuff to make it look nicer
            width: 6.5em; // magic number :( adjust according to your own content
            display: inline-block;
            // end options

            @media (min-width: $breakpoint-alpha) {
              display: none;
            }
          }
        }

        th,
        td {
          text-align: left;

          @media (min-width: $breakpoint-alpha) {
            display: table-cell;
            padding: 0.25em 0.5em;

            &:first-child {
              padding-left: 0;
            }

            &:last-child {
              padding-right: 0;
            }
          }
        }
      }

      // presentational styling

      .rwd-table {
        background: $navBackground;
        color: #fff;
        border-radius: 0.4em;
        overflow: hidden;
        tr {
          border-color: lighten(#34495e, 10%);
        }
        th,
        td {
          margin: 0.5em 1em;
          @media (min-width: $breakpoint-alpha) {
            padding: 1em !important;
          }
        }
        th,
        td:before {
          color: #dd5;
        }
        .normal {
          background-color: $green7;
          color: white;
        }
        .offset {
          color: $green7;
        }
      }

      .page-number {
        display: flex;
        justify-content: center;
        height: 50px;
        align-items: center;
        .page-numbs-buttons-container {
          display: flex;
          .page-buttons {
            width: 50px;
            display: flex;
            justify-content: center;
            cursor: pointer;
            background-color: transparent;
            border: none;
          }
        }
        .page-numb-spacer {
          width: 35px;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
