$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);

.list-form-priority-text {
  color: black;
}

.modal-list-priority-button {
  // border: 1px solid black;
  border-radius: 50%;
  color: black !important;
  // width: 95px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid transparent;
  height: fit-content !important;
  &:hover {
    border: 3px solid rgba(0, 0, 0, 0.3);
  }

  & > div {
    // margin-left: 2px;
    margin: 0px !important;
  }
  &--selected {
    // background-color: black;
    border: 3px solid rgba(0, 0, 0, 0.4);
    color: white !important;
  }
}
section.list-form-content-sections {
  width: 95%;
  margin: 0 auto;
}
.modal-list-input-form--wrapper {
  width: 364px !important;
  // height: 374px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: #f9fbff;
  margin: 0 auto;
  margin-bottom: 87px;
  padding: 10px 0px;
  & > section.modal-list-input-section-wrapper {
    width: 100%;
  }
  .block-modal-list-input-form-scheduling {
    display: flex;
    color: white;
    width: fit-content;
    justify-content: center;
    margin-top: 5px;
    align-items: center;
    height: fit-content;
    width: 100%;
    .modal-list-input-section-wrapper {
      height: 100%;
    }
    .block-modal-list {
      &--left {
        width: 45%;
        span {
          font-size: 13px;
          margin-left: 2px;
          // color: rgba(0, 0, 0, 0.6);
          color: rgba(0, 0, 0, 0.6);
        }
      }
      &--right {
        width: 55%;
        display: flex;
        justify-content: flex-start;
        border-radius: 5px;
        padding: 5px 0px;
        padding-left: 2px;

        margin-left: 5px;
        margin-top: 4px;
        margin-bottom: 5px;
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.16);
        border: 0.2px solid #70707023;
        background-color: white;
        .list-form-priority-text {
          font-size: 13px;
          color: rgba(0, 0, 0, 0.4);
        }
        .block-modal-list-scheduling-container {
          display: flex;
          justify-content: flex-start;
          height: 20px;
          align-items: center;
          margin-right: 10px;
          .block-list {
            width: 50px;
            margin-right: 10px;

            &--high {
              width: 10px;
              height: 10px;
              border-radius: 10px;
              background-color: red;
              margin-left: 10px;
            }
            &--medium {
              width: 10px;
              height: 10px;
              border-radius: 10px;
              background-color: orange;
            }
            &--low {
              width: 10px;
              height: 10px;
              border-radius: 10px;
              background-color: green;
            }
          }
        }
      }
    }
    .modal-list-input-form-top-buttons-container {
      display: flex;
      align-items: center;
      margin-right: 5px;
      .modal-list-input-form {
        &--buttons {
          background-color: $var-color--light-orange;
          border: none;
          border-radius: 10px;
          margin: 0px 2px;
          cursor: pointer;
          &--save {
            border: none;
            border-radius: 5px;
            width: 150px;
            height: 30px;
            color: $var-color--dar-blue;
          }
          &--cancel {
            margin-left: 50px;
            border: none;
            border-radius: 5px;
          }
        }
      }
    }
  }
  .modal-list-input--container {
    width: 97%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    background-color: white;

    &--top {
      height: 20%;
      display: flex;
      border-radius: 5px;
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.16);
      border: 0.2px solid #70707023;
    }
    &--due-date {
      flex-direction: row;
      border-radius: 5px;
      height: 15%;
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.16);
      border: 0.2px solid #70707023;
      margin-top: 5px;
      align-items: center;
    }
    &--assignment {
      flex-direction: row;
      border-radius: 5px;
      height: 15%;
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.16);
      border: 0.2px solid #70707023;
      margin-top: 5px;
      align-items: center;
    }
    &--middle {
      height: 29%;
      display: flex;
      border-radius: 5px;
      margin-top: 10px;
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.16);
      border: 0.2px solid #70707023;
    }
    &--bottom {
      height: 67px;
      display: flex;
      border-radius: 5px;
      flex-direction: row;
      margin-top: 10px;
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.16);
      border: 0.2px solid #70707023;
      align-items: center;
      span {
        font-size: 1em;
        &:nth-child(2) {
          margin-left: 5px;
        }
      }
    }
  }
  div {
    .modal-list-input-form {
      &--indicators {
        margin-top: 5px;
        z-index: 5;
        font-size: 13px;
        margin-left: 6px;
        color: rgba(0, 0, 0, 0.4);
        &--cost,
        &--count,
        &--total {
          margin-top: 0px;
        }
        &--total {
          margin-left: 16px;
        }
        &--total-calculated {
          color: black;
          margin-top: 0px;
        }
      }
      &--inputs {
        border: none;
        background-color: white;
        width: 97%;
        // box-shadow: 9px 10px 13px -13px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        // margin: 0 auto;
        margin-top: -17px;
        // border: 0.1px solid rgba(216, 213, 213, 1);

        &:focus {
          outline: none;
        }
        &--block_name {
          // margin-top: 20px;
          padding-top: 15px;
        }
        &--block_description {
          height: 40px;
          resize: none;
          margin-top: 0px;
        }
        &--block_count {
          input {
            padding: 0;
            margin: 0;
            border: none;
            outline: none;
            font-size: 1em;
            margin-left: 10px;
            &::placeholder {
              color: #9b9b9b;
              transition: opacity 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
            }
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
        }
        &--block_cost {
          // &::before {
          //   content: "$";
          //   margin-top: 7px;
          //   margin-right: -6px;
          //   margin-left: 4px;
          //   z-index: 5;
          // }
          input {
            padding: 0;
            margin: 0;
            border: none;
            outline: none;
            font-size: 1em;
            margin-left: 10px;
            &::placeholder {
              color: #9b9b9b;
              transition: opacity 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
            }
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
        }
        // &--block_cost {
        //   height: 70%;
        //   width: 15%;
        //   background-color: rgba(0, 0, 0, 0.2);
        //   margin-top: 0px;
        //   margin-left: 5px;
        // }
        // &--block_count {
        //   width: 15%;
        //   height: 70%;
        //   background-color: rgba(0, 0, 0, 0.2);
        //   margin-top: 0px;
        //   margin-left: 5px;

        //   // border: 2px solid gray;
        // }
      }
      &--buttons {
        background-color: $var-color--light-orange;
        border: none;
        border-radius: 5px;
        margin: 0px 2px;
        cursor: pointer;

        &--save {
          border: none;
          border-radius: 5px;
          &:hover {
            background-color: $var-color--light-blue !important;
          }
        }
        &--cancel {
          margin-left: 50px;
          border: none;
          border-radius: 5px;
        }
      }
    }
  }
}

.list-form-top-section {
  width: 100%;
  position: relative;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  .list-form-close-button {
    width: auto;
    height: auto;
    position: absolute;
    right: 10px;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    .list-form-close-svg-outer {
      width: 15px;
      path {
        fill: rgba(0, 0, 0, 0.4);
      }
    }
    &:hover {
      .list-form-close-svg-outer {
        path {
          fill: $var-color--light-orange;
        }
      }
    }
    &:focus {
      outline: none;
    }
  }
}

.list-form-divider {
  margin: 0 auto;
  width: 95%;
  border-top: 1px solid black;
  height: 10px;
  border: none;
  color: #a7a7a7;
  background-color: #a7a7a7;
  height: 1px;
  margin-bottom: 5px;
}
