.slider {
  display: flex;
  position: relative;
  width: 100%;
  &__container {
    display: flex;
    // padding: 0 20px;
    transition: transform 300ms ease 100ms;
    z-index: 3;
    // width: calc(100% - 40px);
    width: 100%;
    // padding: 0px 20px;
  }

  &:not(&--open) .item:hover .show-details-button {
    opacity: 1;
  }

  &:not(&--open) .item:hover {
    // transform: scale(1.1) !important;
    // // div,
    // button,
    // svg,
    // path {
    //   transform: scale(1.1);
    // }
  }

  &:not(&--open):hover .item {
    // transform: translateX(-25%);
  }

  &:not(&--open) .item:hover ~ .item {
    // transform: translateX(25%);
  }
}
.slider-button-placeholder {
  // width:55px;
  width:31.5px;
  margin: 0px 5px;
}
