.invite-new-modal-wrapper {
  .invite-new-modal-padder {
    .invite-new-modal-content {
      height: calc(100% - 30px) !important;
      .invite-new-modal-content-form {
        height: 100%;
      }
    }
  }
  .email-error-container {
    height: 24px;
    margin-top: -10px;
    overflow: hidden;
    margin-bottom: 5px;
  }
}
