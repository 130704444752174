.bod-blocks-wrapper{ 
  width:100%;
  display:flex;
  border-top: 6px solid #09132B;
  flex-direction: column;
  margin-top:30px;
  .two-stack-headers{
    margin-top:10px;
    display:flex;
    justify-content: flex-start;
    span{
      // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
      font-weight: 700;
      font-size: 24px;
    }
  }

  .two-stack-blocks{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    padding:0px 50px;
    width:calc(100% - 100px);
    
  }

}


.bod-section-header{
  height:100px;
  display:flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}


