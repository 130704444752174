.slide-button {
  height: 100%;
  cursor: pointer;
  span {
    width: 15px;
    color: #273a07;
    display: block;
    margin: 0 auto;
    svg {
      fill: #273a07;
      width: 15px;
    }
  }
  &--up--up {
    height: 103px;
    &:hover {
      span {
        svg,
        path {
          fill: #f7b844;
        }
      }
    }
  }
  &--next {
    right: 0;
    border-radius: 10px;
    &:hover {
      span {
        svg,
        path {
          fill: #f7b844;
        }
      }
    }
    span {
      transform: rotateZ(90deg);
    }
  }

  &--prev {
    left: 0;
    border-radius: 10px;
    &:hover {
      span {
        svg,
        path {
          fill: #f7b844;
        }
      }
    }
    span {
      transform: rotateZ(-90deg);
    }
  }
  &--up--up {
  }
}

.slide-button--prev {
  // border-top-left-radius: 10px;
  // border-bottom-left-radius: 10px;
}

.slide-button--next {
  // border-top-left-radius: 10px;
  // border-bottom-left-radius: 10px;
}
.slide-button-placeholder {
  width: 55px;
}

.slider-button-group {
  display: flex;
  flex-direction: column;
  height: 151.5px;
  width: 31.5px;
  justify-content: space-between;
  .slide-button {
    height: 48%;
    background: rgba(255, 255, 255, 0.25);
    width: 100%;
    // border-radius: none !important;
    &--next {
      // border-bottom-right-radius: 5px;
    }
    &--next--up {
      // border-top-right-radius: 5px;
      border-radius: 10px;
    }
    &--prev {
      // border-bottom-left-radius: 5px;
    }
    &--prev--up {
      border-radius: 10px;

      // border-top-left-radius: 5px;
    }
  }
}

.unnest-svg-outer {
  width: 25px;
  height: 25px;
  fill: #273a07;
  path {
    fill: #273a07;
  }
}
