.members-page-header-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  .header-wrapper{
    border-bottom: 1px solid black;
    width: fit-content;

    padding: 0px 10px;
  }
  header{
    display:flex;
    justify-content: center;
    padding: 0px 10px;
    font-size: 30px;
    font-weight: bold;
    padding-top:5px;
  
  }
  hr {
  text-align: center;

    color: black;
    height:1px;
    outline: black;;
  }
}
