$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;

.itinerary-item-list-item-wrapper {
  height: 60px;
  padding: 10px 0px;
  border-radius: 5px;
  margin: 4px 0px;
  // background-color: $var-color--button-unselected-blue;
  background-color: #E0D5C3;
  display: flex;
  flex-direction: row;
  min-height: 60px;

  .itinerary-item-checkbox {
    background-color: #273806;
  }

  .itinerary-item-list-section {
    &--left {
      width: 45px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &--right {
      width: calc(100% - 45px);
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .itinerary-item-list-right-section {
        display: flex;
        flex-direction: row;

        &--top {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
        }

        &--bottom {
          width: 100%;
          height: 100%;

          span {
            color: #273806;
            text-transform: uppercase;
            font-size: 0.8em;
          }
        }
      }
    }
  }

  .itinerary-item-list-item {
    &--left {
      width: 20px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &--middle {
      width: 25px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &--right {
      width: calc(100% - 45px);
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: #273806;
    }
  }
}