$color-svg-fill: #a7a7a7;
$color-svg-fill-v2: #afb499;
$color-text: #a7a7a7;
$color-background: #ffffff;
$color-block-highlight: #515a6e;
$color-block-shadow: rgba(0, 0, 0, 0.2);
$color-highlight: #f7b844;
$color-highlight-v2: #273a07;
$var-color--dark-orange: #da4431;
.bottom-nav {
  overflow: overlay;
  background-color: $color-background;
  bottom: 0;
  position: fixed;
  border-top: solid;
  border-color: #273a07;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  height: 85px;
  font-weight: bolder;
  font-stretch: expanded;
  z-index: 101;
  .bottom-nav-button {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    bottom: 1px;
    .item-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: auto;

      .bottom-nav-svg-outerlayer--custom-color {
        fill: $color-svg-fill;
      }
      &:hover {
        color: $color-highlight-v2;
        .bottom-nav-svg {
          fill: $color-highlight-v2;
        }
        .bottom-nav-svg-outerlayer--svgMyHub {
          .bottom-nav-svg-outerlayer--custom-color {
            fill: $color-highlight-v2;
          }
          .bottom-nav-svg {
            fill: $color-background;
          }
        }
      }
    }
    .bottom-nav-item {
      border-top: solid;
      border-color: $color-background;
      width: 100%;
      height: 100%;
      color: $color-text;
      font-size: 1em;
      letter-spacing: 2px;
      border-width: 3px;

      &--active {
        .bottom-nav-menu {
          color: $color-highlight-v2;
        }
        fill: $color-highlight-v2;
        border-color: $color-highlight-v2;
        color: $color-highlight-v2;

        .item-container {
          .bottom-nav-svg {
            fill: $color-highlight-v2;
          }
          .bottom-nav-svg-outerlayer--svgMyHub {
            .bottom-nav-svg-outerlayer--custom-color {
              fill: $color-highlight-v2;
            }
            .bottom-nav-svg {
              fill: $color-background;
            }
          }
        }
      }
      &--notActive {
        fill: $color-svg-fill-v2;
        border-color: $color-background;
        color: $color-text;

        .item-container {
          .bottom-nav-svg {
            fill: $color-svg-fill-v2;
          }
          .bottom-nav-svg-outerlayer--svgMyHub {
            .bottom-nav-svg-outerlayer--custom-color {
              fill: $color-svg-fill-v2;
            }
            .bottom-nav-svg {
              fill: $color-svg-fill-v2;
            }
          }
        }
        &:hover {
          color: $color-highlight-v2;

          .bottom-nav-svg {
            fill: $color-highlight-v2;
          }
          .bottom-nav-svg-outerlayer--svgMyHub {
            .bottom-nav-svg-outerlayer--custom-color {
              fill: $color-highlight-v2;
            }
            .bottom-nav-svg {
              fill: $color-highlight-v2;
            }
          }
        }
      }
    }
    .bottom-nav-svg-outerlayer {
      &--svgMenu {
        width: 30px;
        height: 30px;
      }

      &--svgTrip {
        // width: 50px;
        // height: 30px;
        path {
          fill: #a7a7a7;
        }
      }
      &--svgFriend {
        width: 50px;
        height: 40px;
      }
      &--svgLibrary {
        width: 40px;
        height: 40px;
      }
      &--svgImpact {
        width: 40px;
        height: 40px;
      }
      &--svgRefresh {
        width: 30px;
        height: 30px;
      }

      padding-bottom: 3px;

      .bottom-nav-svg {
        fill: $color-svg-fill;
      }
      &--svgMyHub {
        .bottom-nav-svg {
          width: 30px;
          height: 30px;
          fill: $color-background;
        }
      }
    }
    .bottom-nav-refresh-button {
      color: $color-text;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      flex-direction: column;
      &:hover {
        color: $color-highlight-v2;
        .bottom-nav-svg {
          fill: $color-highlight-v2;
        }
      }
    }
    .bottom-nav-menu {
      color: $color-text;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      flex-direction: column;
      &:hover {
        cursor: pointer;
        color: $color-highlight-v2;
        .bottom-nav-svg {
          fill: $color-highlight-v2;
        }
      }
    }
  }
}

.dashboard-cover {
  position: fixed;
  top: 0;
  z-index: 31;
  min-width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  min-height: calc(100vh - 88px);
  touch-action: none;
  overscroll-behavior: none;
  div,
  a,
  span,
  section,
  p {
    overscroll-behavior: none;
  }
}
.bottom-menu-notification-count-wrapper {
  background-color: red;
  width: 15px;
  height: 15px;
  border-radius: 5px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 26px;
  margin-bottom: -15px;
  z-index: 10;
}

.menu-drawer-open {
  transform: translateX(0%);
}

.menu-drawer-closed {
  transform: translateX(-101%);
}
