$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;

.friend-block-list-wrapper {
  width: 90%;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.6);
  border: 3px solid transparent;
  border-radius: 10px;
  min-height: 90px;
  margin-top: 20px;
  display: flex;
  overflow: hidden;
  .friend-block-list-container {
    width: 100%;
    min-height: 90px;
    height: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    & > div {
      height: 100%;
    }
    .friend-block-list-contents-wrapper {
      display: flex;
      justify-content: flex-start;
      width: 100px;
      .friend-block-list-image-wrapper {
        height: 100%;
        width: 100px;
        display: flex;
        align-items: center;
        padding-left: 10px;
        .friend-block-list-content-profile-pic {
          width: auto;
          height: 81%;
          max-height: 72px;
          border-radius: 50%;
        }
      }
    }
    .friend-block-list-right-content-wrapper {
      width: calc(100% - 100px);
      height: fit-content;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .friend-block-list-contact-wrapper {
        display: flex;
        width: 50%;
        height: 100%;
        justify-content: center;
        padding-left: 15px;
        flex-direction: column;
        .friend-block-listcontact {
          &--top {
            height: 35px;
            display: flex;
            align-items: center;
            text-transform: capitalize;
            span {
              font-weight: 600;
              // color: $var-color--dar-blue;
              color: $var-dark-green;
            }
          }
          &--bottom {
            height: fit-content;
            display: flex;
            align-items: center;
            text-transform: capitalize;
            width: auto;
          }
        }
      }
      .friend-block-list-buttons-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        & > button {
          // width: 100px;
          // height: 100%;
          // background-color: $var-color--light-orange;
          // border-radius: 5px;
          // border: none;
          outline: none;
          // cursor: pointer;
          // margin-left: 5px;
          &:focus {
            outline: none;
          }
          // &:hover {
          //   background-color: $var-color--light-blue;
          // }
          font-size: 14px !important;
          font-family: Soleil !important;
          font-style: normal;
          font-weight: 700;
          @media screen and (max-width: 720px) {
            font-size: 12px !important;
          }
          margin: 0px 4px;
          // margin-right: 20px;
          // width: 100px;
          // height: 35px;
          // border: none;
          // border-radius: 5px;
          // outline: none;
          // cursor: pointer;
          // color: $var-color--dar-blue;
          // &:focus {
          //   outline: none;
          // }
          // &:hover {
          //   background: $var-color--light-blue;
          // }
        }
        .friend-block-list {
          &-view-profile {
            background-color: lightgray;
          }
          &-add-button {
            background-color: $var-color--light-orange;
          }
          &-unfriend-button {
            background-color: lightgray;
          }
        }
      }
    }
  }
}

.friend-block-list-skeleton {
  overflow: hidden;
  .friend-block-list-image-wrapper {
    .friend-block-list-content-profile-pic {
      background-color: #a0a0a0;
      width: 81% !important;
      border: none;
      outline: none;
    }
  }
  .friend-block-listcontact {
    &--top,
    &--bottom {
      width: 210px !important;
      height: 30px !important;
      background-color: #a0a0a0;
      margin: 2px 0px;
    }
  }
  .skeleton-button {
    width: 100px !important;
    height: 30px !important;
    background-color: #a0a0a0;
    margin: 2px 0px;
    border-radius: 5px;
  }
}
.friend-block-list-skeleton::after {
  display: block;
  content: '';
  position: absolute;
  width: 100%;
  height: 90px;
  transform: translateX(-100%);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(transparent),
    color-stop(rgba(255, 255, 255, 0.6)),
    to(transparent)
  );

  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.6),
    transparent
  );
  // background: -webkit-gradient(
  //   linear,
  //   left top,
  //   right top,
  //   from(rgba(255, 255, 255, 0.6)),
  //   color-stop(transparent),
  //   to(transparent)
  // );

  // background: linear-gradient(
  //   90deg,
  //   rgba(255, 255, 255, 0.6),
  //   transparent,
  //   transparent
  // );

  /* Adding animation */
  animation: loading 1s infinite;
}
@keyframes loading {
  100% {
    transform: translateX(150%);
  }
}
