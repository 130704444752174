$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: $var-dark-green;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;
@keyframes grow {
  0% {
    height: 100px;
  }

  98% {
    height: 450px;
  }
}

@keyframes growItinerary {
  0% {
    height: 0px;
  }

  98% {
    height: 230px;
  }
}

@keyframes shrink {
  0% {
    height: 450px;
  }

  98% {
    height: 150px;
  }
}

@keyframes shrinktitle {
  0% {
    height: 150px;
  }
  100% {
    height: 50px;
  }
}

@keyframes growbottom {
  0% {
    height: 0px;
  }
  100% {
    height: 370px;
  }
}

@keyframes growbottomItinerary {
  0% {
    height: 0px;
  }
  100% {
    height: 230px;
  }
}

@keyframes growbottomcrisp {
  0% {
    height: 0px;
  }
  100% {
    height: 160px;
  }
}
.hub-advSel-hero {
  display: none;
  height: 0;
  background-position: center;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  margin-top: 60px;
  .hub-advSel-hero-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    // object-position: 10px -322px;
  }
  .banner-float-container {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: rgba(9, 19, 43, 0.8);
    height: 40px;
    font-size: 24px;
    color: white;
    text-align: center;
    text-transform: uppercase;
    align-items: flex-end;
    font-weight: 700;
  }
  .banner-float-container-under {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: rgba(9, 19, 43, 0);
    height: 17%;
    font-size: 14px;
    color: #ffffff;
    font-weight: 700;
    text-align: center;
    align-items: center;
    flex-direction: column;
    .banner-float-text-small {
      font-size: 12px;
      color: #ffffff;
      font-weight: 500;
    }
  }
}
.hub-page-adventure-selected::-webkit-scrollbar {
  display: none;
}
.hub-page-adventure-selected {
  padding-top: 20px;
  margin: 0 auto;
  // max-width: 1440px;
  // display:flex;
  // width: 75%;
  width: 100%;
  display: flex;
  flex-direction: column;
  // background-color: #09132b;
  padding-bottom: 20px;
  margin: 0 auto;

  .custom-btn-svg {
    fill: $var-dark-green;
  }

  .hub-advSel-blocks-unselected {
    cursor: pointer;
    &:hover {
      .hub-advSel-blocks-titles {
        // color: #f7b844;
        color: rgb(82, 34, 34);
      }
    }
    &:hover {
      // box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.35);
      background-color: #6d7381;
      .hub-advSel-blocks-titles {
        color: #f7b844;
      }
    }
    width: 95%;
    height: 50px;
    // border: 2px solid #f7b844;
    box-shadow: 0 2px 1px -10px rgba(255, 255, 255, 0.093),
      0 4px 2px -7px rgba(255, 255, 255, 0.133),
      0 6px 5px -10px rgba(255, 255, 255, 0.167),
      0 12px 15px -12px rgba(255, 255, 255, 0.207);
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: $var-dark-green;
    //   animation-name: shrink;
    // animation-duration: 0.5s;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .hub-advSel-itineries-unselected {
    //   animation-name: shrink;
    // animation-duration: 0.5s;
  }

  .hub-advSel-files-unselected {
    cursor: pointer;
    &:hover {
      .hub-advSel-blocks-titles {
        color: #f7b844;
      }
    }
    &:hover {
      // box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.35);
      background-color: #6d7381;
      .hub-advSel-blocks-titles {
        color: #f7b844;
      }
    }
    width: 95%;
    height: 50px;
    // border: 2px solid #f7b844;
    box-shadow: 0 2px 1px -10px rgba(255, 255, 255, 0.093),
      0 4px 2px -7px rgba(255, 255, 255, 0.133),
      0 6px 5px -10px rgba(255, 255, 255, 0.167),
      0 12px 15px -12px rgba(255, 255, 255, 0.207);
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: $var-dark-green;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;

    //     animation-name: shrink;
    // animation-duration: 0.5s;
  }
  .hub-advSel-blocks-titles {
    font-size: 18px;
    color: $var-dark-green;
    text-transform: uppercase;
    font-weight: 700;
    &:hover {
      color: #f7b844;
    }
  }

  .hub-advSel-block-titles {
    font-size: 18px;
    color: white;
    text-transform: uppercase;
    font-weight: 700;
  }
  //// seelectoed from herer
  // .hub-advSel-itineries-selected {
  //   animation-name: grow;
  //   animation-duration: 0.5s;
  // }

  .subs-explorer-insp-sel {
  }
  .hub-advSel-blocks-selected {
    width: 95%;
    // min-height: 450px;
    // height: auto;
    // border: 2px solid #f7b844;
    border-radius: 15px;

    height: 293px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // background-color: #3A4256;
    // .adv-blks-selt-dsp {
    //   border: 1px solid #f7b844;
    //   background-color: #f7b844;
    //   color: #09132b;
    //   border-bottom: 1px solid #f7b844;
    // }
    // .adv-blks-uselt-dsp {
    //   border: 1px solid #f7b844;
    //   background-color: #09132b;
    //   color: #f7b844;
    //   border-bottom: 1px solid #f7b844;
    // }
    // .blk-selt {
    //   width: 200px;
    //   height: 33px;
    //   display: flex;
    //   justify-content: center;
    //   margin: 0px 2px;
    //   text-transform: uppercase;
    //   align-items: center;
    //   border-top-left-radius: 5px;
    //   border-top-right-radius: 5px;
    //   cursor: pointer;
    //   border-bottom: 1px solid #f7b844;
    // }

    .adv-blk-addr-btn-container {
      // width: 100%;
      justify-content: flex-start;
      display: flex;
    }
    .adv-blk-tab-container {
      width: 100%;
      justify-content: center;
      display: flex;
      // position: absolute;
    }
  }

  .hub-advSel-itineries {
    transition: transform 0.6s ease-out; // note that we're transitioning transform, not height!
    transform-origin: top;
    &--selected {
      width: 95%;
      border-radius: 15px;
      margin: 0 auto;
      margin-top: -34px;
      margin-bottom: 20px;
      height: auto;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      justify-content: flex-start;
      align-items: center;
      transition: transform 0.6s ease-out; // note that we're transitioning transform, not height!
      transform: scaleY(1); // implicit, but good to specify explicitly
      transform-origin: top;
    }
    &--unselected {
      transform: scaleY(1);
      cursor: pointer;
      &:hover {
        .hub-advSel-blocks-titles {
          color: white;
        }
      }
      &:hover {
        // box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.35);
        background-color: #eae094;
        .hub-advSel-blocks-titles {
          color: $var-dark-green;
        }
      }
      width: 95%;
      height: 50px;
      // border: 2px solid #f7b844;
      box-shadow: 0 2px 1px -10px rgba(255, 255, 255, 0.093),
        0 4px 2px -7px rgba(255, 255, 255, 0.133),
        0 6px 5px -10px rgba(255, 255, 255, 0.167),
        0 12px 15px -12px rgba(255, 255, 255, 0.207);
      border-radius: 15px;
      justify-content: center;
      align-items: center;
      display: flex;
      background-color: $var-tab-background;
      margin: 0 auto;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .hub-advSel-files-selected {
    width: 95%;
    // min-height: 450px;
    // height: auto;
    height: 450px;
    // border: 2px solid #f7b844;
    border-radius: 15px;

    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // background-color: #3A4256;
  }
  .hub-advSel-block-titles-sel {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    color: #f7b844;
    font-size: 20px;
    height: 30px;
    border-bottom: 2px solid #f7b844;
    align-items: center;
    // animation-name: shrinktitle;
    // animation-duration: 0.5s;
    // border-top-right-radius: inherit;
    // border-top-left-radius: inherit;
    // border-bottom: 1px solid #f7b844;

    .hub-advSel-block-adder-button {
      width: 10%;
      border-top-left-radius: inherit;
      padding-left: 40px;
      font-size: 30px;
    }
    .hub-advSel-block-closer {
      width: 10%;
      border-top-left-radius: inherit;
      padding-right: 40px;
      display: flex;
      justify-content: flex-end;
    }
  }
}
.test-one {
  background: cyan;
  height: 60px;
  width: 500px;
}

.hub-advSel-blocks-bottom {
  display: flex;
  flex-direction: row;
  height: calc(60% - 30px);
  width: 100%;
  max-width: 100%;
  // background-color: #09132b;
  // border: 2px solid #f7b844;
  align-items: center;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  justify-content: center;
  align-items: center;
  overscroll-behavior: contain;
  // margin-top:35px;
  // overflow-x: scroll;
  // justify-content: center;
  // overflow-x: auto;
  .adventure-selected-itineraries-board {
    height: 100%;
    display: flex;
    overflow: visible;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: flex-start;
    .ghost-create-adventure-button {
      height: 200px;
      margin: 10px 20px;
    }
  }
  &::-webkit-scollbar- {
    display: none;
  }
  &::-webkit-scrollbar {
    display: none;
  }
}

.adventure-whole {
  display: flex;
  /* flex-wrap: wrap; */
  width: 100%;
  flex-direction: row;
  height: 100%;
  // overflow-x: scroll;
  // justify-content: center;
  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scollbar- {
    display: none;
  }
  .block-mover-button-left {
  }
  .block-mover-button-right {
  }
}

.block-adder-seperator {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  .block-adder-left {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .block-adder-right {
    width: 45%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow-y: scroll;
    justify-content: center;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.adv-blks-selectors {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  .adv-blks-selt-dsp {
    border: 1px solid $var-dark-green;
    background-color: $var-dark-green;
    // background-color: #f7b844;
    // color: $var-dark-green;
    color: #ffffff;
  }
  .adv-blks-uselt-dsp {
    border: 1px solid $var-dark-green;

    border-bottom: 0px solid $var-dark-green;
  }
  .blk-selt {
    width: 200px;
    height: 40px;
    display: flex;
    justify-content: center;
    margin: 0px 2px;
    text-transform: uppercase;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }

  .adv-blk-addr-btn-container {
    width: 7%;
    justify-content: flex-start;
    display: flex;
    margin-right: -4%;
    margin-left: -3%;
    z-index: 5;
    // margin-left: 10px;
    // border-bottom: 1px solid $var-dark-green;
  }
  .adv-blk-tab-container {
    width: 97%;
    justify-content: center;
    display: flex;
    //position: absolute;
    font-size: 18px;
    font-weight: 700;
    border-bottom: 1px solid $var-dark-green;
    & > div {
      display: flex;
    }
  }

  .adv-blk-title-container {
    width: 100%;
    justify-content: center;
    display: flex;
    border-bottom: 1px solid $var-dark-green;
    height: 35px;
    & > div {
      display: flex;
      height: auto;
      align-items: center;
      & > span {
        color: $var-dark-green;
        font-size: 18px;
        font-weight: 700;
      }
    }
  }
}

.adv-blk-close-btn-container {
  &:hover {
    fill: #f7b844;
    path {
      fill: #f7b844;

      cursor: pointer;
    }
    svg,
    path {
      fill: #f7b844 !important;

      cursor: pointer;
      &:hover {
        fill: #f7b844;
      }
    }
  }
}

.block-slider-container {
  display: flex;
  padding: 0 55px;
  /* flex-wrap: wrap; */
  width: 100%;
  flex-direction: row;
  height: 100%;
  // overflow-x: scroll;
  // justify-content: center;
  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scollbar- {
    display: none;
  }
  .block-slider-container:hover .mini-block-container {
    transform: translateX(-25%);
  }
  .mini-block-container:hover ~ .mini-block-container {
    transform: translateX(25%);
  }
  .mini-block-container:hover {
    // transform: scale(1.5) !important;
  }
}
@keyframes growbottomcrispforsummary {
  0% {
    height: 0px;
  }
  100% {
    height: fit-content;
  }
}
.selected-adventure-summary-block {
  height: fit-content !important;
  transition: height 500ms ease-out;
}
.adventure-selected-no-content-svg-outer {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;

  &:hover {
    path {
      fill: $var-color--light-blue;
    }
  }
  path {
    fill: $var-color--dar-blue;
  }
}
.adventure-selected--summary--triggered--container {
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 40px;
  height: auto !important;
  transition: height 500ms ease-out;
  color: $var-color--dar-blue;
  display: flex;
  font-weight: bold;
  flex-direction: column;
  .no-summary-text-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: fit-content;

    margin-top: 10px;
    &:hover {
      cursor: pointer;
    }
    .no-summary-text {
      cursor: pointer !important;

      color: $var-color--dar-blue;
      font-size: 18px;
      margin-top: 0px;

      &:hover {
        color: $var-color--light-blue;
        // cursor: pointer;
      }
    }
    &:hover {
      .no-summary-text {
        color: $var-color--light-blue;
      }
      path {
        fill: $var-color--light-blue;
      }
    }
  }
  .banner-float-text-small {
    padding: 0 0 5px 0;
  }
  span {
    font-size: 14px;
    cursor: text;
    // margin-left: 43px;
    color: $var-color--dar-blue;
    text-align: center;
  }
}
.adventure-selecte-margin-top-remover {
  margin-top: 0px !important;
  // .adv-blk-title-container {
  //   height: 35px;
  // }
}
.trip-selected-groups--wrapper {
  height: 349px !important;
  .no-friends-groups-text-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    margin-top: 10px;

    &:hover {
      cursor: pointer;
    }
    .no-friends-groups-text {
      cursor: pointer !important;

      color: $var-color--dar-blue;
      font-size: 18px;

      &:hover {
        color: $var-color--light-blue;
        // cursor: pointer;
      }
    }
    &:hover {
      .no-friends-groups-text {
        color: $var-color--light-blue;
      }
      path {
        fill: $var-color--light-blue;
      }
    }
  }
}
.no-friends-text-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  margin-top: 10px;
  height: 200px;
  &.--adventure-selected {
    cursor: pointer;
  }
  .no-friends-text {
    cursor: pointer !important;

    color: $var-color--dar-blue;
    font-size: 18px;

    &:hover {
      color: $var-color--light-blue;
      // cursor: pointer;
    }
  }
  // &:hover {
  //   .no-friends-text {
  //     color: $var-color--light-blue;
  //   }
  //   path {
  //     fill: $var-color--light-blue;
  //   }
  // }
}

.trip-selected-itineraries--wrapper {
  // height: 230px;
}
#itinerary-section.hub-advSel-itineries-selected {
  height: 278px !important;
}

.no-itineraries-text-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  margin-top: 10px;

  &:hover {
    cursor: pointer;
  }
  .no-itineraries-text {
    cursor: pointer !important;

    color: $var-color--dar-blue;
    font-size: 18px;

    &:hover {
      color: $var-color--light-blue;
      // cursor: pointer;
    }
  }
  &:hover {
    .no-itineraries-text {
      color: $var-color--light-blue;
    }
    path {
      fill: $var-color--light-blue;
    }
  }
}

.adv-blks-selectors {
  flex-direction: row-reverse;
  & > div.adv-blk-close-btn-container {
    width: 7%;
    margin-right: -4%;
    margin-left: -3%;
    z-index: 10;
    border-bottom: none;
    button.hub-custom-btn {
      margin-top: 8px;
      path {
        fill: $var-dark-green;
      }
    }
  }
}
// .adv-blk-title-container--no-bottom-border {
//   button.hub-custom-btn {
//     margin-top: 13px;
//   }
// }

.adventure-selected-friend-groups-title {
  border-bottom: 1px solid $var-dark-green;
  padding-bottom: 5px;
  justify-content: flex-start;
  flex-direction: row-reverse;
  width: 100% !important;
  margin: 0 auto;
  border-bottom: none !important;
  & > div.title-container {
    width: 100% !important;
    border-bottom: 1px solid $var-dark-green;
  }
}

.adventure-selected-tooltip-container {
  &:hover {
    path,
    svg {
      fill: $var-color--light-orange;
    }
  }
  &::before {
    margin-top: 8px !important;
  }
}

.adventure-selected-friends-board {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .ghost-create-adventure-button {
    margin: 20px 10px 0px 10px;
    width: 90%;
    height: 90%;
    background-color: rgba(255, 255, 255, 0.6);
  }
}
.adventure-selected-friends-text {
  color: $var-dark-green;
}
