.impact-checkout-title-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  // background-color: rgba(255, 255, 255, 0.7);
  border-radius: 5px;
  padding: 10px 0px;
  & > h2 {
    color: #273a07;
  }
  .impact-checkout-big-text {
    font-size: 1.5em;
    letter-spacing: 2px;
    color: #273a07;
    text-transform: uppercase;
    font-weight: 700;
    // border-bottom: 2px solid white;
  }
  & > hr {
    width: 40%;
  }
  .impact-checkout-small-text {
    font-weight: 700;
    font-size: 1.5em;
    margin-top: 5px;
    text-transform: uppercase;
  }
}
.impact-checkout-description-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  & > span {
    font-size: 1.2em;
    text-transform: uppercase;
  }
  & > hr {
    width: 80%;
  }
}
