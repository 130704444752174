@font-face {
  font-family: Albiona ExtraLight;
  src: url('./fonts/Albiona_ExtraLight.otf');
}

@font-face {
  font-family: Albiona Light;
  src: url('./fonts/Albiona\ Light.otf');
}

@font-face {
  font-family: Soleil Bold;
  src: url('./fonts/Soleil\ Bold.otf');
}

@font-face {
  font-family: Soleil Semibold;
  src: url('./fonts/Soleil\ Semibold.otf');
}
