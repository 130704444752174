$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;

.edit-account-explorer-modal-wrapper {
  overflow: hidden !important;
  border-radius: 5px;
  .edit-account-explorer-modal-padder {
    height: 638px !important;
    max-height: 638px !important;

    .mod-cnt-def-edit-account-explorer {
      height: 605px;
      max-height: 605px;
      width: 100%;
      & > form {
        width: 100%;
        height: 100%;
      }
      .edit-account-explorer-modal-top-content {
        width: 100%;
        display: flex;
        justify-content: center;
        height: 150px;
        .edit-account-explorer-modal-top-profile-pic-wrapper {
          width: 150px;
          height: 100%;
          .edit-account-explorer-modal-top-profile-pic {
            width: 125px;
            height: 125px;
            border-radius: 50%;
          }
        }
        .edit-account-explorer-modal-top-user-info {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          .edit-account-explorer-info {
            &--username {
              margin-top: 15px;
              height: 20px;
            }
            &--acountrolename {
              height: 20px;
              margin-top: 5px;
            }
            &--name {
              height: 20px;
              margin-top: 5px;
            }
            &--aboutme {
              margin-top: 5px;
            }
            span {
              color: white;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

.account-modal-membership-wrapper {
  margin: 10px 0px;
  width: 80%;
  // height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #8f939d;
  border-radius: 8px;
  justify-content: space-evenly;
}

.account-modal-explorers-wrapper {
  margin: 10px 0px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0px;
  height: calc(100% - 122px);
  max-height: calc(100% - 151px);

  .account-explorers-container {
    width: 100%;
  }
  .explorer-menu-wrapper {
    margin: 10px 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.2);
    margin-top: 0px;
    height: 100%;
    border-radius: 10px;
    .explorer-menu-squares {
      width: 80%;
      display: flex;
      flex-wrap: wrap;
      display: flex;
      flex-wrap: wrap;
      height: 78%;
      justify-content: center;
      align-items: center;
      .menu-squares {
        width: 100px;
        height: 100px;
        font-size: 12px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px;
        border-radius: 10px;
        background-color: #4b5263;
        cursor: pointer;
        &:hover {
          background-color: #d1b37c;
        }
      }
    }
  }
}
.account-modal-memebership-top {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.smaller-logo {
  width: 60%;
  margin: auto;
}
.color-white {
  color: white;
}
.font-14 {
  font-size: 14px;
}

.account-modal-membership-subtext {
  font-size: 12px;
}
.account-modal-membership-dividers {
  width: 60%;
  // border-bottom: solid 0.25px #09132b;
  border-bottom: solid 0.25px rgba(255, 255, 255, 0.7);
}
.account-modal-memebership-middle {
  justify-content: space-around;
  display: flex;
  flex-direction: column;
  height: 40%;
  text-align: center;
}

.explorer-menu-title-tab,
.explorer-title-tab {
  margin-top: 10px;
  color: white;
  display: flex;
  width: 100%;
  align-items: center;
  & > div {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .edit-account-explorer-back-button {
    width: 25px;
    height: 25px;
    background-color: transparent;
    border: none;
    outline: none;
    margin-right: -25px;
    cursor: pointer;
    z-index: 5;
    &:focus {
      outline: none;
      border: none;
    }
    &:hover {
      .backbutton-svg-outer {
        path {
          fill: $var-color--light-orange;
        }
      }
    }
    .backbutton-svg-outer {
      width: 100%;
      height: 100%;
      path {
        fill: white;
      }
    }
  }
}

.explorer-profile-wrapper,
.explorer-address-wrapper,
.explorer-image-wrapper,
.explorer-password-wrapper {
  margin: 10px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.2);
  margin-top: 0px;

  height: 100%;
  border-radius: 10px;
}
.explorer-password-wrapper {
  overflow: hidden;
  .explorer-input-content-container {
    height: 35%;
  }
  .email-error-container {
    max-height: 51%;
    overflow: hidden;
    justify-content: flex-start;
    height: 51%;
    max-width: 353px !important;
    margin: 0 auto;
  }
}
.account-modal-memebership-bottom {
  padding-top: 20px;
}

.explorer-address-wrapper {
  .explorer-input-content-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 13px;
    & > div.mod-fl-lab-inpt-wrapper {
      width: 90%;
      padding: 0px 0px;
      margin-bottom: 12px !important;
    }
    .half-inputs {
      width: calc(45% - 5px) !important;
      &--left {
        margin-right: 5px;
      }
      &--right {
        margin-left: 5px;
      }
      div.floating-lode-input {
        min-width: 0px !important;
      }
    }
    .edit-account-explorer-dropdown-parent {
      // padding: 10px 0;
      padding: 0px !important;
      width: 90% !important;
      div.react-dropdown-select-dropdown {
        // width: 190.59375px !important;
        max-height: 192px !important;
      }
      &.--top {
        margin-top: -5px;
      }
      &.--bottom {
        margin-top: 5px;
        div.react-dropdown-select-dropdown {
          // width: 190.59375px !important;
          max-height: 125px !important;
        }
      }
      &--left {
        margin-right: 5px;
      }
      &--right {
        margin-left: 5px;
      }
    }
  }
}

.explorer-profile-wrapper {
  .explorer-input-content-container {
    margin-top: 13px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;

    & > div.mod-fl-lab-inpt-wrapper {
      width: 90%;
      padding: 0px 0px;
      margin-bottom: 14px !important;
    }
    & > div.modal-account-profile-no-bottom-spacing {
      margin-bottom: 0px !important;
    }
    .edit-account-explorer-dropdown-parent {
      /* padding: 10px 0; */
      width: 90% !important;
      /* margin: 0 auto; */
      margin-top: -15px !important;
    }
  }
  .email-error-container {
    height: 26px;
    margin-top: 3px !important;
    justify-content: flex-start;
    & > div {
      margin-top: 0px;
    }
  }
}
.explorer-image-content-container {
  width: 100%;
  height: calc(100% - 25px);
  .image-edit-body-container {
    width: 100%;
    height: 100%;
  }
}
