.tx-text-box{
  display:flex;
  font-size: 8px;
  align-items: flex-start;
}

.tm-encloser{
  display:flex;
  align-items:flex-start;
  // height:50px;
}

#tm-encloer-custom-sup{
  display:block 
}


.trademark_banner-text{
  font-size: 28px;
  display: relative;
  top:14px;
}

.tagline{
  text-align: center;
  font-size: 23px;
  color: #FFFFFF;
  // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}