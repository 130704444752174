.page-loading-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0e9d5;
  // position: absolute;
  // top: 0px;
  // left: 0px;
  z-index: 9999 !important;
  .animated-ring {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75px;
    height: 75px;
    background: transparent;
    border: 3px solid #3c3c3c;
    border-radius: 50%;
    text-align: center;
    line-height: 150px;
    font-family: sans-serif;
    font-size: 20px;
    color: $warm2;
    letter-spacing: 4px;
    text-shadow: 0 0 10px $warm2;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    &:before {
      content: '';
      position: absolute;
      top: -3px;
      left: -3px;
      width: 100%;
      height: 100%;
      border: 3px solid transparent;
      border-top: 3px solid $warm2;
      border-right: 3px solid $warm2;
      border-radius: 50%;
      animation: animateC 2s linear infinite;
    }
    .ring-light {
      display: block;
      position: absolute;
      top: calc(50% - 2px);
      left: 50%;
      width: 50%;
      height: 4px;
      background: transparent;
      transform-origin: left;
      animation: animate 2s linear infinite;
    }
    .ring-light:before {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: $warm2;
      top: -6px;
      right: -8px;
      box-shadow: 0 0 20px $warm2;
    }
    // .component-loading-image-container {
    //   width: 80%;
    //   height: 80%;
    //   position: relative;
    //   animation: rotation 2s infinite;

    //   animation-timing-function: cubic-bezier(0.415, -0.61, 0.355, 1);
    //   //   animation-timing-function: cubic-bezier(0.1, -0.28, 0.735, 0.045);

    // }
    .loading-image-container {
      object-fit: contain;
      width: 100%;
      height: 100;
      animation: rotation 2s infinite;
      position: relative;
      animation-timing-function: cubic-bezier(0.415, -0.61, 0.355, 1);
    }
  }
  .component-loading-message-container {
    position: absolute;
    top: 70%;
    font-family: sans-serif;
    font-size: 20px;
    // color: $warm9;
    color: $green4;
    letter-spacing: 4px;
    text-shadow: 0 0 10px $warm1;
    & > span {
      display: inline-block;
      //   margin: 0 -0.05em;
    }
  }
}

.modal-loading-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  // position: absolute;
  // top: 0px;
  // left: 0px;
  z-index: 9999 !important;
  .animated-ring {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75px;
    height: 75px;
    background: transparent;
    border: 3px solid #3c3c3c;
    border-radius: 50%;
    text-align: center;
    line-height: 150px;
    font-family: sans-serif;
    font-size: 20px;
    color: $warm2;
    letter-spacing: 4px;
    text-shadow: 0 0 10px $warm2;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    &:before {
      content: '';
      position: absolute;
      top: -3px;
      left: -3px;
      width: 100%;
      height: 100%;
      border: 3px solid transparent;
      border-top: 3px solid $warm2;
      border-right: 3px solid $warm2;
      border-radius: 50%;
      animation: animateC 2s linear infinite;
    }
    .ring-light {
      display: block;
      position: absolute;
      top: calc(50% - 2px);
      left: 50%;
      width: 50%;
      height: 4px;
      background: transparent;
      transform-origin: left;
      animation: animate 2s linear infinite;
    }
    .ring-light:before {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: $warm2;
      top: -6px;
      right: -8px;
      box-shadow: 0 0 20px $warm2;
    }
    // .component-loading-image-container {
    //   width: 80%;
    //   height: 80%;
    //   position: relative;
    //   animation: rotation 2s infinite;

    //   animation-timing-function: cubic-bezier(0.415, -0.61, 0.355, 1);
    //   //   animation-timing-function: cubic-bezier(0.1, -0.28, 0.735, 0.045);

    // }
    .loading-image-container {
      object-fit: contain;
      width: 100%;
      height: 100;
      animation: rotation 2s infinite;
      position: relative;

      animation-timing-function: cubic-bezier(0.415, -0.61, 0.355, 1);
    }
  }
  .component-loading-message-container {
    position: absolute;
    top: 70%;
    font-family: sans-serif;
    font-size: 20px;
    // color: $warm9;
    color: $green4;
    letter-spacing: 4px;
    text-shadow: 0 0 10px $warm1;
    & > span {
      display: inline-block;
      //   margin: 0 -0.05em;
    }
  }
}
