$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;

.map-box-location-search-wrapper {
  width: 80%;
  margin: 0 auto;
  .map-box-location-search-input {
    padding: 13px;
    padding-left: 16px;
    border-radius: 10px;
    vertical-align: bottom;
    color: #09132b;
    width: calc(100% - 30px);
    border: none;
    outline: none;
    height: 18px !important;
    &:focus {
      border: none;
      outline: none;
    }
  }
  .AutocompletePlace-results {
    position: absolute;
    background-color: white;
    top: 105px;
    z-index: 10;
    width: 383px;
    height: auto;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    .AutocompletePlace-items {
      width: calc(100% - 5px);
      height: 30px;
      display: flex;
      align-items: center;
      padding-left: 5px;
      cursor: pointer;
      span {
        font-size: 0.8em;
      }
      &:hover {
        background-color: $var-color--light-orange;
      }
    }
  }
}
