.adventure_modal {
  right: -15px;
  padding: 0px 15px 0px 0px;
  width: calc(100% + 15px);
  display: flex;
  flex-direction: column;
  align-items: center;
  overscroll-behavior: contain;
}
.create-adventure-wrapper {
  width: 400px;
  max-width: calc(100vw - 80px);
  max-height: calc(var(--vh, 1vh) * 100 - 220px);
  padding-top: 5px;
  padding-left: 20px;
  padding-right: 20px;
  overflow-y: auto;
  box-sizing: border-box;
}

.text-field {
  color: #827700;
  border-color: #273A07;
  &.required .MuiInputLabel-root {
    color: #da4431;
  }
}

@media screen and (max-width: 425px) {
  .create-adventure-wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.create-adventure-wrapper::-webkit-scrollbar {
  width: 4px !important;
}
.create-adventure-wrapper::-webkit-scrollbar-thumb {
  background: #827700;
}
.create-adventure-wrapper::-webkit-scrollbar-track {
  position: relative;
  border-right: 1px solid #273A07;
}
.create-adventure-wrapper::-webkit-scrollbar-track::after {
  position: absolute;
  left: 2px;
  width: 1px;
  height: 100%;
  background-color:#273A07;
}

.mod-cnt-def-create-adventure {
  width: 100%;
  // background-color: #757a86;
  background-color: #364301 ;

  max-height: 384px;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
  // & > div {
  //   overflow: display;
  //   height: 540px;
  // }
}

.mod-cnt-def-create-adventure-edit {
  width: 100%;
  background-color: #364301 ;
  // background-color: #757a86;
  overflow-y: scroll;
  max-height: 384px;
  &::-webkit-scrollbar {
    display: none;
  }
  & > div {
    overflow: display;
    height: 380px;
  }
}

.create-adventure-modal {
  & > div.mod-type1-title {
    height: 45px !important;
  }
}
.adventure-page-sections {
  width: 100%;
  // display: flex;
  // align-items: center;
  // flex-direction: column;
  // height: 100%;
  // overflow: hidden;
  // scroll-snap-align: start;
}

.adventure-section-container {
  width: 100%;
  height: 344px;
}

.create-adventure-description-wrapper {
  .create-adventure-description {
    height: 124px;
    &--edits {
      height: 182px;
    }
  }
}

.dropzone-placeholder {
  margin-top: 5px;
  margin-bottom: 20px;
  width: 80%;
  border-radius: 10px;
  height: 120px;
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: default;

  & > span {
    color: white;
  }
  .dropzone-placeholder-svg-outer {
    width: 30px;
    height: 30px;
    path {
      fill: white;
    }
  }
}

.brow-image-online-button {
  margin-top: 5px;
  margin-bottom: 20px;
  width: 80%;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  height: 120px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: white;
  }
}

.create-adventure-next-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 5px;

  span {
    color: white;
  }
}

.create-adventure-next-button {
  cursor: pointer;
  height: 30px;
  width: 150px;
  background-color: #f7b843;
  color: #545b6b;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 10px;
  color: #545b6b;
}
body > div:nth-child(8) .create-adventure-date-picker-dropdown {
  width: 550px !important;
  width: 97vw !important;
  max-width: 480px !important;
}

.adventure-modal-bottom-wrapper {
  width: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  height: calc(70px - 5px);
  padding-bottom: 5px;
  .mod-save-btn-wrapper--background {
    height: 31px;
  }
}
