.account-modal-membership-wrapper {
  margin: 10px 0px;
  width: 80%;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #8f939d;
  border-radius: 8px;
  justify-content: space-evenly;
}
