.notification-badges-wrapper {
  position: fixed;
  right: 0;
  bottom: 88px;
  width: 200px;
  // min-height: 50px;
  height: auto;
  padding-right: 20px;
  z-index: 20;
}
