.banner-container{
  width:100%;
  // height:400px;
  position: relative;
  text-align:left;
  border-bottom: 8px solid #09132B;
  margin-bottom:0px;
  padding-bottom:0px;
    height:500px;

  img{
    width:100%;
    height:100%;
    object-fit: cover;
  }
  div{
      // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 700;
    max-width: 100%;
    font-size: 52px;
    position: absolute;
    top: 39%;
    left: 15%;
    color: white;
  }
}

