.coming_soon_components_text_content--wrapper {
  width: calc(100% - 20px);
  display: flex;
  padding: 0 10px;
  justify-content: center;
  .coming_soon_components_text_content--padder {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    .coming_soon_components_text_content {
      display: flex;
      flex-direction: column;
      margin: 10px 0px;
      line-height: 1.6;
      letter-spacing: 1.5px;
      font-size: 19px;
    }
  }
}
