$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;

.general-section-container {
  width: 100%;
  height: 100%;
  .general-header {
    margin: 10px 20px 10px 20px;
   font-size: 23px;

    font-weight: 700;
    color: $var-color--dar-blue;
  }
  .general-section-body {
    margin: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-gap: 10px;
  }

  .mod-fl-lab-inpt-wrapper {
    .sc-hKFxyN {
      box-shadow: 4px 4px 3px rgba(0, 0, 0, 0.2);
    }
    width: 100%;
    padding: 5px 0 5px 0;
  }

  .general-section-footer-container {
    margin: 10px 20px 10px 20px;
    .general-section-footer {
      display: flex;
      flex-direction: row;
      .general-not-saved {
        text-align: center;
        margin: auto 0 auto 10px;
        font-style: italic;
        color: red;
      }
      .general-button {
        width: 150px;
        text-align: center;
        font-size: 18px;
        border-radius: 10px;
        border: none;
        height: 37px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $var-color--dar-blue;

        &--cancel {
          background-color: gainsboro;
        }
        &--save {
          background-color: $var-color--light-orange;
          margin: 0 0 0 10px;
        }
        &:hover {
          background-color: $var-color--light-blue;
        }
      }
    }
  }
}

.validated-input-wrappers {
  width: 100%;
  display: flex;
  flex-direction: column;
  .email-error-container {
    margin: 0px;
    padding-left: 5px;
    height: 28px;
    overflow-y: hidden;
    .email-error-wrapper {
      & > span {
        color: #09132b !important;
      }
    }
  }
}
