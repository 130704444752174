.itinerary-day {
  .header {
    display: flex;
    align-items: center;
    padding: 0px 5px;
    .info {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      align-items: center;
      gap: 4px;
      padding-right: 25px;
      .hr-bar {
        height: 1px;
        background-color: #273a07b0;
        flex-grow: 1;
      }
      .day-title {
        display: flex;
        align-items: center;
        gap: 6px;
        .text {
          font-weight: 700;
          line-height: 14px;
          font-size: 14px;
          text-transform: uppercase;
          color: #273a07;
        }
      }
      .itinerary-info-section-level-1-edits {
        margin: 0px 4px;
        width: 15px;
        height: 15px;
        .itinerary-create-svg-outer {
          display: none;
        }
      }
      .date-time {
        text-transform: uppercase;
        font-size: 14px;
        color: $var-dark-green;
        font-weight: 700;
        padding-right: 5px;
      }
    }
  }
}
.itinerary-info-section-left-headers {
  position: absolute;
  height: calc(100% - 22.5px);
  margin-bottom: 10.5px;
  display: flex;
  align-items: center;
  bottom: 0px;
  right: 0px;
  width: 37px;
  justify-content: center;
  .itinerary-header-text-wrapper {
    height: auto;
  }
  span {
    font-weight: 700;
    text-transform: uppercase;
    // transform-origin: 0 0;
    transform: rotate(-90deg);
    display: block;
    white-space: nowrap;
    color: $var-dark-green;
  }
  &.--dilute {
    color: #264562;
    font-weight: 500;
  }
}

.empty-block-texxt-high-lighter {
  color: $var-dark-green;
}
