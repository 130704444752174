.resend-header-block{
  display:flex;
  justify-content: center;
  width: 100%;
  text-align: center;
  // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  h2{
    color:white;
  }
}

.resend-form{
  margin-top:40px;
}

.resend-button{
width: 250px;
    border: none;
    background-color: #F7B844;
    border-radius: 10px;
    margin: 0 auto;
    margin-top: 10px;
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    font-weight: normal;
    font-size: 18px;
    &:hover{
      background-color: #6EC6ED;
    cursor: pointer;

    }
    &:disabled{
      span{color: gray;}
      &:hover{
        background-color: #F7B844;
        border-radius: 10px;
      }
    }
   
  // &:disabled{
  //   color: gray;
  // }
}

