.footer-container{
  background-color:#09132B;
  width: 100%;
  display:flex;
  flex-direction: column;
  align-items: center;
      height: 143px;
      // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
      
  a{  
  text-decoration: none;
  color:white;
  font-size: 14px;
  }
  .footer-border-top{
    width:98%; 

    border:1px solid #F7B844;
    
  }

  
  .footer-contents{
    display:flex;
  flex-direction: column;
  width: 100%;
  height: 138px;
  justify-content: center;
  align-items: flex-start;


    .footer-top{
      padding-top: 10px;
      width: 96%;
    margin: 0 auto;
      height: 70%;
      display:flex;
      justify-content: flex-start;
      .footer-left{
        display:flex;
        // width: calc(100% - 40px);
        // padding-left:40px;
        flex-direction: column;
        height:100%;
            width: 23%;
        justify-content: flex-start;;
        .footer-link-blocks{
          height: 100%;
          display:flex;
          flex-direction: column;
          justify-content: flex-start;
          width: 100%;
        }

      }

      .footer-middle{
        display:flex;
        width: 100%;
        flex-direction: column;
        height:100%;
        justify-content: flex-start;
          align-items: center;
        .mission-text{
          text-align: center;
          color: white;
          font-size: 14px;
          max-width: 81%;
          margin:0 auto;
        } 
        .company-stickers-container{
          width: 100%;
          justify-content: center;
          display:flex;
          .b-corp-sticker{
            width: 50px;
            height: 50px;
          }
        }
      }

      .footer-right{
        display:flex;
        width: 23%;
        flex-direction: column;
        height:100%;
        align-items: flex-end;
        justify-content: center;
        .footer-logo-block{
          display:flex;
          justify-content: flex-start;
            img{
              margin-right:10px;
              width: 180px;
              height: 90px;
            }
          }
      }
    }

    .footer-bottom{
      display:flex;
      flex-direction: row;
      align-items: flex-start;
      width: 100%;
      height: 10%;
      margin-bottom: 0px;
          width: 96%;
    margin: 0 auto;
      .soc-media-block{
      width: 40%;
      display:flex;
      justify-content: flex-start;
        img{
          margin-right:10px;
          width: 25px;
          height: 25px;
        }
      }
     
    }
     .c-text{
          align-items: flex-end;
          color: white;
          width:100%;
          bottom:5px;
          display:flex;
          justify-content: center;
          margin-bottom: 5px;
        span{
          font-size: 11px;
        }
      }
  }
  

  
}

a{
  text-decoration: none;
  color:white;
}
.hub-footer-top-border{
  justify-content:center;
display:flex;
width: 100%;
  div{
  border-bottom: 3px solid #F7B844;
  width: 95%;
  }
}

.footer-icons-wrapper{
    display: flex;
    width: auto;
    justify-content: center;
    margin-right: 26px;
    margin-top: -23px;
}
.footer-sticker-png{
  width: 50px;
  height:50px;
}
.footer-sticker-svg-outer{
  width: 50px;
  height:50px;
}

.footer-sticker-svg{
  width: 50px;
  height:50px;
}