$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);

.mod-share-inv-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
  :nth-child(1) {
    margin: 5px 0px;
  }
}
.invite-friends-to-adventure-modal {
  height: 100% !important;
  max-height: 100% !important;
}
.mod-inv-friends-contents--wrapper {
  width: 95%;
  height: 405px;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 10px;
  .mod-inv-friends-lodestar-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    .mod-inv-frnds-rslts-wrapper {
      width: 98%;
    }
  }
  .invite-from-account--wrapper {
    width: 100%;
    height: 100%;
    .invite-from-account-tabs--wrapper {
      width: 100%;
      height: 15%;
      display: flex;
      justify-content: center;
      align-items: center;
      .invite-from-account-tab {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40%;
        background-color: rgba(255, 255, 255, 0.4);
        height: 30px;
        margin: 0px 4px;
        border-radius: 5px;
        cursor: pointer;
        &:hover {
          background-color: $var-color--light-orange;
          span {
            color: $var-color--dar-blue;
          }
        }
      }
      .invite-from-account-tab-selected {
        background-color: $var-color--light-orange;
        span {
          color: $var-color--dar-blue;
        }
      }
    }
    .invite-from-account-search--wrapper {
      width: 100%;
      height: 10%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .invite-from-account-contents--wrapper {
      width: calc(100% - 42px);
      height: 100%;
      padding: 0px 17px;
      padding-left: 25px;
      display: flex;
      overflow-y: scroll;
      flex-direction: column;
      justify-content: flex-start;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

.invite-friends-to-trips-bottom {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
