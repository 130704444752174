$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;
$var-color--button-highlight: rgba(255, 255, 255, 0.29);
$var-color--button-highlight-transparent: rgba(255, 255, 255, 0);
$var-time-transition-fade: 0.5s;
$var-pixels-button-border-radius: 10px;
$var-pixels-button-margin: 2px 7px 0 7px;
.activities-svg-outer {
  width: 20px;
  height: 20px;
}

.activity-list-wrapper {
  display: flex;
  justify-content: space-between;
}

.activities-svg {
  fill: #827700;
  path, circle, rect {
    fill: #827700;
  }
}

.bold-item-category {
  font-weight: 900;
}

.dropdown-display-tooltip {
  &:hover::before {
    display: inline-block;
  }
  &::before {
    content: attr(data-text);
    position: absolute;
    font-size: 11px;
    padding: 1px 5px;
    display: none;
    color: #09132b;
    background: #f7b844;
    border-radius: 4px;
    transition: opacity 0.1s ease-out;
    z-index: 99;
    text-align: left;
    margin-left: 0%;
    margin-top: -20px;
  }
}
.dropdown-adventure-item {
  &--date {
    width: 56px;
    display: inline-block;
  }
  &--adventure-name {
  }
  &--status {
  }
}
