$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;
.copy-adventure-modal-information--wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #827700;
  // color: $var-color--dar-blue;
  text-align: center;
}
.copy-modal {
  & > div.mod-type1-title {
    height: 45px !important;
  }
}
