.no-button-slider-wrapper {
  display: flex;
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;

  .no-button-slider-blocks-wrapper {
    width: calc(100% - 150px);
    height: 100%;
    display: flex;
    flex-direction: row;
    z-index: 26;
  }
}
.no-button-slider-blocks-wrapper {
  width: 100%;
  height: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  box-sizing: border-box;
  gap: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  z-index: 26;
  overflow-x: auto;
  overscroll-behavior: contain;
  margin-top: 11px;
  &::-webkit-scrollbar, &::-moz-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-thumb, &::-moz-scrollbar-thumb {
    background: #8C867C55;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-track, &::-moz-scrollbar-track {
    background: transparent;
  }
}
