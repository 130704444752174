// @media screen and (max-height: 860px) {
//   #hub-page-wrapper {
//     .dashboard-block-wrapper {
//       font-size: 20px;
//       // margin: 0 4%;
//       svg {
//         max-height: 100px;
//       }
//     }
//   }
// }
// @media screen and (max-height: 750px) {
//   #hub-page-wrapper {
//     .dashboard-block-wrapper {
//       // font-size: 15px;
//       // margin: 0 4%;
//     }
//   }
// }
// @media screen and (max-height: 660px) {
//   #hub-page-wrapper {
//     .dashboard-block-wrapper {
//       font-size: 12px;
//       svg {
//         max-height: 75px;
//       }
//     }
//   }
// }
// @media screen and (max-height: 535px) {
//   #hub-page-wrapper {
//     .dashboard-block-wrapper {
//       font-size: 10px;
//     }
//   }
// }

// //screensize bigger than normal laptops
// @media screen and (min-width: 1441px) {
// }
// @media screen and (max-width: 1440px) {
//   #hub-page-wrapper {
//     .dashboard-block-wrapper {
//       // width: 250px;
//       // height: 250px;
//       // margin: 3% 3% 3% !important;
//       max-width: 25%;
//       max-height: 33%;
//       // padding-top: 17px;
//     }
//   }
// }
// // screen#1 iPad pro vertical
// @media screen and (max-width: 1024px) {
//   #hub-page-wrapper {
//     .dashboard-block-wrapper {
//       // width: 250px;
//       // height: 200px;
//       max-width: 25%;
//       max-height: 33%;
//       font-size: 15px;
//       letter-spacing: 2px;
//       // margin: 3% 3% 3% !important;
//     }
//   }
// }
// // screen#2 ipad vertical

// @media screen and (max-width: 768px) and (max-width: 860px) {
//   #hub-page-wrapper {
//     .dashboard-block-wrapper {
//       // width: 170px;
//       // height: 140px;
//       font-size: 15px;
//       letter-spacing: 2px;
//       // margin: 3% 3% 3% !important;
//       // padding-top: 17px;
//     }
//   }
// }
// // screen#3  surface duo

// @media screen and (min-width: 481px) and (max-width: 767px) {
//   #hub-page-wrapper {
//     padding: 0 0 73px 0;
//     .dashboard-block-wrapper {
//       // width: 130px;
//       // height: 110px;
//       font-size: 14px;
//       letter-spacing: 2px;
//       // margin: 3% 3% 3% !important;
//       // padding-top: 17px;
//       .dashboard-block-footer {
//         font-size: 10px;
//       }
//     }
//     .dashboard-bottom-padder {
//       height: 20px;
//     }
//   }
// }
// // screen#4 iphone 12 pro max,
// @media screen and (min-width: 415px) and (max-width: 480px) {
//   #hub-page-wrapper {
//     padding: 0 0 73px 0;
//     .dashboard-block-wrapper {
//       width: 130px;
//       height: 110px;
//       font-size: 12px;
//       letter-spacing: 2px;
//       margin: 0% 3% 3% !important;
//     }
//   }
// }
// // screen#5 iphone 11 && pixel 2 && pixel2 xl iphone 6/7/8, iphone x
// @media screen and (min-width: 391px) and (max-width: 414px) {
//   #hub-page-wrapper {
//     .dashboard-block-wrapper {
//       padding-top: 17px;
//       width: 140px;
//       height: 110px;
//       font-size: 12px;
//       letter-spacing: 2px;
//       margin: -1% 6% 2% !important;

//       .outer-svg-layer {
//         // width: calc(width/2);
//       }
//       .dashboard-block-footer {
//         font-size: 10px;
//       }
//     }
//   }
// }
// // screen#6 iphone 12, iphone 12 pro, iphone 6/7/8
// @media screen and (min-width: 361px) and (max-width: 390px) {
//   #hub-page-wrapper {
//     padding: 0 0 73px 0;
//     .dashboard-block-wrapper {
//       width: 130px;
//       height: 90px;
//       font-size: 12px;
//       letter-spacing: 2px;
//       margin: 2% 6% 2% !important;
//       padding-top: 17px;

//       .dashboard-block-footer {
//         font-size: 10px;
//       }
//     }
//     .dashboard-bottom-padder {
//       height: 20px;
//     }
//   }
// }
// // screen#7 iphone 12 mini && Moto G4 && Galaxy S9 && galaxy s5 galaxy s9
// @media screen and (min-width: 321px) and (max-width: 360px) {
//   #hub-page-wrapper {
//     .dashboard-block-wrapper {
//       width: 130px;
//       height: 84px;
//       font-size: 12px;
//       letter-spacing: 2px;
//       margin: -1% 6% 2% !important;
//       padding-top: 17px;

//       .dashboard-block-footer {
//         font-size: 10px;
//       }
//     }
//   }
// }
// // screen#8 iphone 5/se, galaxy fold
// @media screen and (min-width: 280px) and (max-width: 320px) {
//   #hub-page-wrapper {
//     .dashboard-block-wrapper {
//       width: 130px;
//       height: 100px;
//       font-size: 12px;
//       letter-spacing: 2px;
//       margin: 3% 3% 3% !important;
//       padding-top: 17px;
//       .dashboard-block-footer {
//         font-size: 10px;
//       }
//     }
//   }
// }

//screensize bigger than normal laptops
@media screen and (min-width: 1441px) {
}
@media screen and (max-width: 1440px) {
}
// screen#1 iPad pro vertical
@media screen and (max-width: 1024px) {
}
// screen#2 ipad vertical

@media screen and (max-width: 768px) and (max-width: 860px) {
}
// screen#3  surface duo

@media screen and (min-width: 481px) and (max-width: 767px) {
  .dashboard-content-wrapper {
    .dashboard-block-wrapper > svg {
      max-width: 70%;
      max-height: 40%;
    }
  }
  #dashboard-block-mobile {
    // width: calc(50% - 22px);
    // max-width: calc(50% - 22px);
    // height: calc(20% - 37px);
    // font-size: 0.8em;
    letter-spacing: 1px;
    // font-size: 0.9em;
    .dashboard-block-footer {
      padding-top: 1px;
      // font-size: 0.7em;
    }
  }
}
// screen#4 iphone 12 pro max,
@media screen and (min-width: 415px) and (max-width: 480px) {
  .dashboard-content-wrapper {
    .dashboard-block-wrapper > svg {
      max-width: 70%;
      max-height: 40%;
    }
  }
  #dashboard-block-mobile {
    // width: calc(50% - 22px);
    // max-width: calc(50% - 22px);
    // height: calc(20% - 37px);
    font-size: 0.8em;
    letter-spacing: 1px;
  }
}
// screen#5 iphone 11 && pixel 2 && pixel2 xl iphone 6/7/8, iphone x
@media screen and (min-width: 391px) and (max-width: 414px) {
  .dashboard-content-wrapper {
    .dashboard-block-wrapper > svg {
      // max-width: 60%;
      // max-height: 30%;
    }
  }
  #dashboard-block-mobile {
    // width: calc(50% - 22px);
    // max-width: calc(50% - 22px);
    // height: calc(20% - 37px);
    font-size: 0.7em;
    letter-spacing: 0.8px;
    // font-size: 0.9em;
    .dashboard-block-footer {
      padding-top: 1px;
      font-size: 0.7em;
    }
  }
}
// screen#6 iphone 12, iphone 12 pro, iphone 6/7/8
@media screen and (min-width: 361px) and (max-width: 390px) {
  .dashboard-content-wrapper {
    .dashboard-block-wrapper > svg {
      max-width: 60%;
      max-height: 60%;
    }
  }
  #dashboard-block-mobile {
    // width: calc(50% - 22px);
    // max-width: calc(50% - 22px);
    // height: calc(20% - 37px);
    font-size: 0.6em;
    letter-spacing: 0.5px;
    // font-size: 0.9em;
    .dashboard-block-footer {
      padding-top: 1px;
      font-size: 0.6em;
    }
  }
}
// screen#7 iphone 12 mini && Moto G4 && Galaxy S9 && galaxy s5 galaxy s9
@media screen and (min-width: 321px) and (max-width: 360px) {
  .dashboard-content-wrapper {
    .dashboard-block-wrapper > svg {
      max-width: 60%;
      max-height: 60%;
    }
  }
  #dashboard-block-mobile {
    // width: calc(50% - 22px);
    // max-width: calc(50% - 22px);
    // height: calc(20% - 37px);
    font-size: 0.7em;
    letter-spacing: 0.7px;
    // font-size: 0.9em;
    .dashboard-block-footer {
      padding-top: 1px;
      font-size: 0.7em;
    }
  }
}
// screen#8 iphone 5/se, galaxy fold
@media screen and (min-width: 280px) and (max-width: 320px) {
  .dashboard-content-wrapper {
    .dashboard-block-wrapper > svg {
      max-width: 60%;
      max-height: 60%;
    }
  }
  #dashboard-block-mobile {
    // width: calc(50% - 22px);
    // max-width: calc(50% - 22px);
    // height: calc(20% - 37px);
    font-size: 0.7em;
    letter-spacing: 0.7px;
    // font-size: 0.9em;
    .dashboard-block-footer {
      padding-top: 1px;
      font-size: 0.7em;
    }
  }
}
