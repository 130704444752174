$var-color--light-orange: #f7b844 !global;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;

.itinerary_adventure_block_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  .scroll-indicator-wrapper {
    width: 97%;
    display: flex;
    justify-content: flex-end;
    margin: 0 auto;
    z-index: 0;
    height: 30px;
    align-items: flex-end;
    &--library-v2 {
      width: 93% !important;
      & > div {
        width: 100% !important;

        & > div {
          width: 100% !important;
        }
      }
    }
    &--library-v2-wrapper {
      border-bottom: 2px solid #264562;
      margin-top: -18px;
      width: 93% !important;
    }
    &--itinerary-trip-board,
    &--itinerary-trip-board-placeholder {
      margin-top: -27px !important;
    }
    .scroll-indicator-parent {
      width: 150px;
      &--itinerary-trip-board {
        width: 100% !important;
        & > div {
          width: 100% !important;
        }
      }
    }
  }
  z-index: 2;
  .main-title {
    color: #273a07;
    font-size: 1em;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.itinerary-page-contents--wrapper {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  .custom-btn-svg {
    fill: rgba(110, 199, 237, 0.288);
  }
}

.itinerary-library-top {
  display: flex;
  width: 155.69px;
  justify-content: center;
  border-bottom: none;
  height: 29.5px;
  // background-color: #474e60;
  background-color: rgba(255, 255, 255, 0.25);
  // border-bottom-left-radius: 10px;
  // border-bottom-right-radius: 10px;
  border-radius: 10px;
  align-items: center;
  margin: 0 auto;
  margin-top: 5px;
  margin-bottom: 0px;
  cursor: pointer;

  &:hover {
    background-color: #60677a;
    .itinerary-svg-up-outer {
      fill: #f7b844;
      cursor: pointer;
    }
    .itinerary-info-section-left {
      background-color: rgba(255, 255, 255, 0.3) !important;
    }
    .itinerary-info-section-left-level2-version {
      background-color: rgba(255, 255, 255, 0.3) !important;
    }
  }
  .itinerary-svg-up-outer {
    width: 15px;
    height: 15px;
    fill: white;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }
}

.itinerary_adventure_block_bottom {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  text-align: start;
  align-items: center;

  &::-webkit-scrollbar {
    display: none;
  }
}
.itineraryBottomDropdown {
  display: flex;
  flex-direction: row;
  padding: 5px;
  .itinerary_adventure_block_bottom_icon_dropdown {
    // width: 30px;
    position: relative;
  }
  .itinerary-section-rt-bottom {
    span {
      color: #827700;
    }
  }
}
.itinerary_adventure_block_bottom_icon_dropdown {
  // margin: 10px;
  // padding: 10px;
  border-radius: 10px;
  position: relative;
  align-items: center;
  cursor: pointer;
  // &:hover {
  //   box-shadow: inset 100px 100px 100px 100px rgba(110, 199, 237, 0.29);

  //   .trip-board-list-collapsible-icon {
  //     color: #d18000;
  //   }
  //   .trip-board-list-collapsible-icon-plus-container > svg > g > path {
  //     // border: 6px solid #d18000;
  //     fill: #d18000 !important;
  //     // & > img {
  //     //   width: 25px;
  //     // }
  //     // & > svg {
  //     //   fill: #d18000 !important;
  //     //   color: #d18000 !important;
  //     //   & > path {
  //     //     fill: #d18000 !important;
  //     //     color: #d18000 !important;
  //     //   }
  //     // }
  //   }
  // }
  .action-svg-wrapper {
    display: flex;
    flex-direction: row;
    // flex-wrap: wrap;
    justify-content: center;
    &--board {
      flex-direction: column;
    }
    .sub-menu {
      .title {
        text-align: center;
        color: #273a07;
        font-size: 0.8em;
        font-weight: 700;
      }
      .items {
        display: flex;
        justify-content: center;
      }
    }
    & > div {
      display: flex;
      flex-direction: column;
      width: auto;
      .items > div:hover {
        a,
        span,
        path {
          color: #d18000;
          fill: #d18000;
        }
      }
      & > span {
        // color: rgba(255, 255, 255, 0.3);
        color: $var-dark-green;
        font-size: 0.8em !important;
        font-weight: 700;
        width: auto;
        cursor: pointer;
        &:hover {
          color: #6ec6ed;
        }
        a {
          // color: rgba(255, 255, 255, 0.3);
          color: #09132b;
          &:hover {
            color: #6ec6ed;
          }
        }
        span {
          cursor: pointer;
          &:hover {
            color: #6ec6ed;
          }
        }
      }
      &:nth-child(1) {
        // margin-right: 50px;
      }
      &:nth-child(3) {
        // margin-left: 60px;
      }
    }
  }
  div.create-modal-svg-wrapper {
    cursor: pointer;
    width: 80px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    &:hover {
      svg,
      path {
        fill: #d18000;
      }
    }
  }
  svg,
  path {
    // fill: rgba(255, 255, 255, 0.3);
    fill: $var-dark-green;
  }
  svg {
    width: 23px;
    height: 23px;
    // &:hover {
    //   fill: #6ec6ed;
    // }
  }
}
.block-bottom-fading {
  height: 15px;
  width: 100%;
  margin-top: -14px;
  z-index: 15;
  // background: linear-gradient(rgba(9, 20, 43, 0.015), rgba(9, 20, 43, 0.9));
}

.block-top-fading {
  height: 5px;
  width: 100%;
  margin-top: 14px;
  z-index: 15;
  background: linear-gradient(rgba(9, 20, 43, 0.9), rgba(9, 20, 43, 0.015));
}

.itinerary-adventure-block-children {
  z-index: 20;
  &:focus {
    border: none;
    outline: none;
  }
}

//------tests
// .itinerary-adventure-block-children {

//   margin-right: 40px;
//   position:relative;

// }

// .itinerary-block-spectators-container{
// position: absolute;
// display: flex;
// flex-direction: column;
// width: 30px;
// top: 0;
// right: -33px;
//   .itineraries-adventure-friend-profile-image{
//     width: 30px;
//     height: 30px;
//     border-radius: 50%;
//     border: 2px solid transparent;
//     &.no_image{
//       border: 2px solid $var-color--light-orange;
//     }
//     &:not(:first-child){
//       margin-top: -28px;
//       -webkit-mask:radial-gradient(circle at top 50%,transparent 99%,#fff 100%);
//               mask:radial-gradient(circle at top 50%,transparent 99%,#fff 100%);
//               z-index:-2;
//     }
//   }
//   .itinerary-block-spectators-count-wrapper{
//     width:100%;
//     font-size: 10px;
//     display:flex;
//     justify-content: center;
//   }
// }
.itinerary-sections {
  //border: 1px solid #f7b844;
  width: 100%;
  height: 300px;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
}
.selected-it-wrapper {
  box-shadow: inset 100px 100px 100px 100px rgba(110, 199, 237, 0.288);
  // box-shadow: inset 100px 100px 100px 100px rgba(110, 199, 237, 1);
  border-radius: 10px;
  .itinerary-section-info-wrapper {
    // border-right: 1px solid $var-color--dar-blue !important;
    border-right: none !important;
    position: relative;
  }
  .itinerary-info-section-right {
    position: relative;

    border-right: 2px solid $var-dark-green !important;
  }

  .itinerary-info-section-left {
    // background-color: rgba(255, 255, 255, 0.3) !important;
    .itinerary-info-section-left-top {
      //background-color: rgba(255, 255, 255, 0.3) !important;
    }
    .itinerary-info-section-left-bottom {
      //background-color: rgba(255, 255, 255, 0.3) !important;
    }
  }
  .itinerary-info-section-left-level2-version {
    // background-color: rgba(255, 255, 255, 0.3) !important;
    .itinerary-info-section-left-centered {
      // background-color: rgba(255, 255, 255, 0.3) !important;
    }
  }
  .itinerary-section-right-wrapper {
    border-bottom: none !important;
  }
  .itinerary-create-svg-outer {
    cursor: pointer;
    path {
      fill: $var-dark-green;
    }
    &:hover {
      fill: #f7b844;
      .itinerary-create-svg {
        fill: #f7b844;
      }
    }
    display: flex !important;
  }
}

.it-sect-wrapper-dragging {
  box-shadow: inset 100px 100px 100px 100px rgba(110, 199, 237, 0.288);
  border-radius: 10px;
  .itinerary-info-section-left {
    // background-color: rgba(255, 255, 255, 0.3) !important;
    .itinerary-info-section-left-top {
      background-color: rgba(255, 255, 255, 0.3) !important;
    }
    .itinerary-info-section-left-bottom {
      background-color: rgba(255, 255, 255, 0.3) !important;
    }
  }
  .itinerary-section-right-wrapper {
    border-bottom: none !important;
  }
  .itinerary-create-svg-outer {
    cursor: pointer;
    path {
      fill: $var-dark-green;
    }
    &:hover {
      fill: #f7b844;
      .itinerary-create-svg {
        fill: #f7b844;
      }
    }
    display: flex !important;
  }
}

.up-button {
  width: 25px;
  flex-shrink: 0;
}

.itinerary-day {
  width: 100%;
  height: fit-content;
  overflow-y: hidden;
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: inset 100px 100px 100px 100px rgba(223, 213, 193, 1);
    border-radius: 10px;
    .itinerary-section-info-wrapper {
      border-right: none;
    }
    .itinerary-section-right-wrapper {
      border-bottom: none;
    }
    .itinerary-info-section-right {
      position: relative;

      border-right: 2px solid $var-dark-green;
    }
    .itinerary-create-svg-outer {
      cursor: pointer;
      path {
        fill: $var-dark-green;
      }
      &:hover {
        // fill: #f7b844;
        .itinerary-create-svg {
          // fill: #f7b844;
        }
      }
      display: flex !important;
    }
  }
  .blocks {
    display: flex;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
  }

  .itinerary-item-sections {
    flex-grow: 1;
    width: 100%;
    height: 140px;
    box-sizing: border-box;
  }

  .itinerary-section-info-wrapper {
    width: 7%;
    min-height: 147px;
    height: 100%;
    // height: 147px;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    position: relative;

    .itinerary-info-section-left {
      width: calc(10% + 15px);
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      min-width: 15px;
      background-color: transparent;
      border-top-left-radius: 10px;

      border-bottom-left-radius: 10px;
      & > div {
        height: 28%;
        width: 100%;
      }
      .itinerary-info-section-left-top::before {
        content: attr(data-text);
        position: absolute;
        margin-bottom: -37px;
        font-size: 11px;
        padding: 1px 5px;
        display: none;
        color: $var-color--dar-blue;
        background: $var-color--light-orange;
        border-radius: 4px;
        transition: opacity 0.1s ease-out;
        z-index: 99;
        text-align: left;
      }
      .itinerary-info-section-left-top {
        border-top-left-radius: inherit;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
        margin-left: 3px;
        margin-top: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        &::before {
          content: attr(data-text);
          position: absolute;
          margin-bottom: -37px;
          font-size: 11px;
          padding: 1px 5px;
          display: none;
          color: $var-color--dar-blue;
          background: $var-color--light-orange;
          border-radius: 4px;
          transition: opacity 0.1s ease-out;
          z-index: 99;
          text-align: left;
        }
        &:hover {
          background-color: rgba(255, 255, 255, 0.3) !important;
          cursor: pointer;
          .itinerary-create-svg {
            fill: #f7b844;
          }
          &::before {
            display: inline-block;
          }
        }
        &:hover::before {
          display: inline-block;
        }
      }
      .itinerary-info-section-left-bottom {
        border-bottom-left-radius: inherit;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 3px;
        margin-bottom: 2px;
        &:hover {
          background-color: rgba(255, 255, 255, 0.3) !important;
          cursor: pointer;
          .itinerary-create-svg {
            fill: #f7b844;
          }
        }
      }
      .itinerary-create-svg-outer {
        cursor: pointer;
        &:hover {
          fill: #f7b844;
          .itinerary-create-svg {
            fill: #f7b844;
          }
        }
        width: 15px;
        height: 25px;
        fill: #8c97a4;
        display: none;
      }
    }
    .itinerary-info-section-left-centered {
      cursor: pointer;
      &:hover {
        background-color: rgba(255, 255, 255, 0.3) !important;
        .itinerary-create-svg {
          fill: #f7b844;
        }
      }
    }
    .itinerary-info-section-right {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      width: 90%;
      height: 100%;
      padding-right: 5px;
      border-right: 2px solid $var-dark-green;
      flex-direction: column;
      position: relative;

      .itinerary-info-section-right-top {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .it-sect-info-container {
          margin-right: -137px;
          width: 173%;
          height: 43%;
          .it-sect-info-title {
            color: $var-color--dar-blue;
            font-size: 0.7em;
            text-align: center;
            font-weight: 700;
            text-transform: uppercase;
            width: 163%;
            text-transform: uppercase;
            text-align: center;
            margin-left: -35%;
          }
          .it-sect-info-date {
            color: $var-color--dar-blue;
            font-size: 0.6em;
            text-align: center;
            font-weight: 600;
            text-transform: uppercase;
          }
        }
        .it-sect-info-container-level-2 {
          margin-right: -48px !important;
        }
      }
      .itinerary-info-section-right-bottom {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        z-index: 999;
        justify-content: center;
        & > div {
          margin: 0px 4px;
          &:hover {
            background-color: rgba(255, 255, 255, 0.4);
            .itinerary-create-svg-outer {
              fill: $var-color--light-orange;
            }
          }
          .itinerary-create-svg-outer {
            width: 15px;
            height: 15px;
            fill: rgba(255, 255, 255, 0.4);
            display: none;
          }
        }
      }
      .it-sect-info-container {
        transform: rotate(-90deg);

        /* Legacy vendor prefixes that you probably don't need... */

        /* Safari */
        -webkit-transform: rotate(-90deg);

        /* Firefox */
        -moz-transform: rotate(-90deg);

        /* IE */
        -ms-transform: rotate(-90deg);

        /* Opera */
        -o-transform: rotate(-90deg);
      }
      .level-2-header {
        color: rgba(255, 255, 255, 0.5);
        text-align: right;
        width: 100%;
        font-size: 12px;
        margin-right: 5px;
      }
      .it-sect-info-date-level-2 {
        text-align: center;
        width: 100%;
        color: $var-color--dar-blue;

        font-size: 11px;
        margin-right: 5px;
      }
      .it-sect-info-date-level-2-middle {
        text-align: center;
        width: 100%;
        color: rgba(255, 255, 255, 0.2);
        font-size: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
        display: flex;
        justify-content: flex-end;
        margin-right: 5px;

        & > div {
          border-left: 2px dotted #6ec6ed;
          height: 18px;
          margin-right: 21px;
        }
      }
    }

    .itinerary-info-section-left-level2-version {
      width: calc(10% + 15px);
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-width: 15px;
      background-color: transparent;
      border-top-left-radius: 10px;

      border-bottom-left-radius: 10px;

      .itinerary-info-section-left-centered {
        border-top-left-radius: inherit;
        border-bottom-left-radius: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        margin-left: 3px;
        margin-top: 2px;
      }

      .itinerary-create-svg-outer {
        cursor: pointer;
        &:hover {
          fill: #f7b844;
          .itinerary-create-svg {
            fill: #f7b844;
          }
        }
        width: 15px;
        height: 25px;
        fill: #8c97a4;
        display: none;
      }
    }
  }

  .itinerary-section-right-wrapper,
  .itinerary-day {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top-right-radius: 10px;
    .blocks {
      display: flex;
      width: 100%;
      .itinerary-create-svg-outer {
        width: 15px;
        height: 15px;
        fill: #ffffff66;
        display: none;
      }
      .itinerary-item-up-button {
        .initerary-item-button {
          .hub-custom-btn {
            & > path {
              fill: white !important;
            }
          }
        }
      }
      &::-webkit-scrollbar {
        display: none;
      }

      .itinerary-section-rt-left {
        margin-top: 10px;
        height: 100%;
        width: 31.5px;
        margin-right: -31.5px;
        height: 78%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #254562;
        margin-left: 6px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 5;

        .itinerary-create-svg-outer {
          cursor: pointer;
          &:hover {
            fill: #f7b844;
            .itinerary-create-svg {
              fill: #f7b844;
            }
          }
          width: 15px;
          height: 25px;
          fill: #8c97a4;
        }
        &:hover {
          background-color: rgba(255, 255, 255, 0.3) !important;
          cursor: pointer;
          .itinerary-create-svg {
            fill: #f7b844;
          }
        }
      }
    }
    .itinerary-section-rt-bottom {
      min-height: calc(10% - 20px);
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-bottom: 9px;
      padding-left: 0px;
      .it-sect-desc-btn {
        background-color: transparent;
        border: none;
        color: $var-color--dar-blue;
        font-weight: 600;
        font-size: 15px;
        text-align: start;
      }
      .default-notes-color {
        color: rgba(255, 255, 255, 0.4);
      }
      .it-sect-desc-inpts {
        width: auto;
        min-width: 95%;
        background-color: transparent;
        border: none;
        caret-color: ffffff;
        font-size: 14px;
        color: #ffffff;
        padding: 5px;
        border: none;
        border-radius: 7px;
        outline: none;
        &:focus {
          border: none;
          border-radius: 7px;
          outline: none;
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
    }
  }
}

.it-blocks-botom {
  height: 100%;
  width: 100%;
}

.itinerary-sect-header {
  width: 100%;
  // width:
  border-bottom: 1px solid #254562;
  display: flex;
  justify-content: center;
  height: 60px;
  margin-top: 10px;

  .itinerary-sct-hdr-rt-rt {
    color: white;
    text-align: center;
    font-size: 30px;
    // width: 100vw;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
  }

  .it-title-cont {
    position: relative;
    color: white;
    text-align: center;
    font-size: 22px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 70%;
    align-items: center;
    .it-dur-sp {
      font-weight: 700;
      font-size: 22px;
      color: white;
    }
    .it-nts-inpt {
      width: 90%;
      text-align: start;
      // word-wrap: max($numbers: 75);
      min-height: 20px;
      background-color: transparent;
      border: none;
      caret-color: #ffffff;
      font-size: 14px;
      color: #ffffff;
      padding: 5px;
      font-weight: 700;
      color: white;
      font-size: 18px;
      color: white;
      cursor: text;
      margin-left: 43px;
      &:focus {
        // border: 1px solid #ffffff75;
        border-radius: 7px;
        outline: none;
        // word-wrap: max($numbers: 75);
        border: none;
        text-transform: uppercase;
      }
    }
    .it-days-sp {
      font-size: 18px;
      color: #6ec6ed;
      font-weight: 600;
    }
  }
}

.itinerary-scroller {
  // max-height: 359px;
  //min-height: calc(100vh - 480px);
  //max-height: calc(100vh - 480px);
  overflow-y: auto;
  height: fit-content;
  width: 100%;
  z-index: 1;
  &::-webkit-scrollbar {
    display: none;
  }
  &--large {
    max-height: calc(100vh - 355px) !important;
  }
}

.adv-blks-selectors {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  .adv-blks-selt-dsp {
    border: 1px solid #254562;
    // background-color: #254562;
    // color: #ffffff;
    background-color: $var-color--dar-blue;
    color: white;
    border-bottom: none;
  }
  .adv-blks-uselt-dsp {
    border: 1px solid #254562;
    background-color: #254562;
    color: #ffffff;
    border-bottom: 0px solid #254562;
  }
  .blk-selt {
    width: 200px;
    height: 40px;
    display: flex;
    justify-content: center;
    margin: 0px 2px;
    text-transform: uppercase;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    cursor: pointer;
  }

  .itineraries-section--btn-container {
    width: 7%;
    margin-right: -7%;
    justify-content: flex-start;
    display: flex;
    // margin-left: 10px;
    border-bottom: 1px solid #254562;
    z-index: 20;
    span {
      margin-left: 43px;
      svg {
        fill: #254562 !important;
      }
      .custom-btn-svg-outer {
        fill: #254562 !important;
      }
    }
    .custom-btn-svg-outer {
      fill: #09132b !important;
    }
    &:hover {
      svg,
      path {
        fill: $var-color--light-orange;
      }
    }
  }
  .adv-blk-tab-container {
    width: 100%;
    justify-content: center;
    display: flex;
    //position: absolute;
    font-size: 18px;
    font-weight: 700;
    border-bottom: 1px solid #254562;
  }
}
.hub-itinerary-hero {
  height: 250px;
  background-position: center;
  // width: 100%;
  width: 100vw;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  .hub-itinerary-title {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: rgba(9, 19, 43, 0.8);
    height: 50px;
    font-size: 24px;
    color: white;
    text-align: center;
    text-transform: uppercase;
    align-items: center;
  }
}

.itinerary-notes-wrapper {
  width: 100%;
  min-height: 31px;
  height: auto;
  display: flex;
  padding: 0px 0px;
  justify-content: center;
  flex-direction: row;
  margin: 0px 20px;
  margin: 0 auto;
  padding: 0px 5px;
  align-items: center;
  .info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    gap: 10px;
  }
  .it-notes-rt-spn {
    font-size: 14px;
    color: white;
    cursor: text;
  }
  .default-notes-color {
    color: rgba(255, 255, 255, 0.4);
  }

  .it-nts-inpt {
    // width: auto;
    // min-width: 95%;
    width: 95%;
    text-align: start;
    // word-wrap: max($numbers: 75);
    min-height: 20px;
    background-color: transparent;
    border: none;
    caret-color: #ffffff;
    color: #ffffff;
    padding: 5px;
    font-size: 18px;
    color: white;
    cursor: text;
    margin-left: 40px;
    &:focus {
      // border: 1px solid #ffffff75;
      border-radius: 7px;
      outline: none;
      // word-wrap: max($numbers: 75);
      border: none;
      background-color: rgba(255, 255, 255, 0.2);
      // text-transform: capitalize;
    }
    // }
  }
}

.it-title-toggle-cont {
  font-size: 14px;
  color: #ffffff;
}

.current-day-itinerary-item {
  // color: #f7b844 !important;
  text-decoration: underline 5px $var-color--light-orange;
}

.current-time-itinerary-item {
  color: #f7b844 !important;
}

.disable-reorder {
  transform: none !important;
}

.traverse-placeholder {
  display: flex;
  width: 40%;
  justify-content: center;
  border-bottom: none;
  height: 35px;
  margin-top: 5px;
}

.itinerary--tabs--wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  border-bottom: 1px solid #254562;
  .itinerary--tab {
    width: 200px;
    height: 40px;
    display: flex;
    justify-content: center;
    margin: 0px 2px;
    text-transform: uppercase;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    border: 1px solid #254562;
    background-color: $var-color--dar-blue;
    color: #ffffff;
    border-bottom: 0px solid #254562;
    font-weight: 700;
  }
  .itinerary--tab__selected {
    border: 1px solid #254562;
    background-color: #f7b844;
    color: #254562;
    border-bottom: none;
  }
}

.itinerary-closed-buttons {
  &:hover {
    .hub-advSel-blocks-titles {
      // color: #f7b844;
      color: white;
    }
  }
  &:hover {
    // box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.35);
    background-color: #6d7381;
    .hub-advSel-blocks-titles {
      color: #f7b844;
    }
  }
  width: 95%;
  color: white;
  font-size: 18px;
  font-weight: 700;
  height: 50px;
  text-transform: uppercase;
  // border: 2px solid #f7b844;
  box-shadow: 0 2px 1px -10px rgba(255, 255, 255, 0.093),
    0 4px 2px -7px rgba(255, 255, 255, 0.133),
    0 6px 5px -10px rgba(255, 255, 255, 0.167),
    0 12px 15px -12px rgba(255, 255, 255, 0.207);
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #3a4256;
  //   animation-name: shrink;
  // animation-duration: 0.5s;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  .adv-blk-title-container {
    display: flex;
    height: auto;
    align-items: center;
    text-align: center;
    /* margin-right: 8.25%; */
    justify-content: center;
    width: 100px;
    &--no-bottom-border {
      border-bottom: none;
    }
    & > div {
      display: flex;
      height: auto;
      align-items: flex-end;
      text-align: center;
      & > span {
        color: white;
        font-size: 18px;
        font-weight: 700;
      }
    }
  }
}

.ambassador_flag {
  width: 13px;
  margin-top: 16px;
  margin-left: -5px;
}

.itinerary-item-drilldown-placeholder,
.itinerary-item-drilldown-button {
  width: 31.5px;
  margin-left: 5px;
}

.itinerary-item-drilldown-button {
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  height: 78%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  margin-top: 10px;
  svg,
  path {
    fill: white;
    width: 15px;
    // margin-top:21px;
  }
  &:hover {
    svg,
    path {
      fill: $var-color--light-orange;
    }
  }
}

.itinerary-info-section-left-middle {
  height: 30px;
  margin-top: -15px;
  margin-bottom: -15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-left: 3px;
  z-index: 20;
  // &:hover {
  //   background-color: rgba(255, 255, 255, 0.4);
  //   .itinerary-draggable-svg-outer {
  //     fill: $var-color--light-orange;
  //   }
  // }
  .itinerary-draggable-svg-outer {
    width: 21px;
    height: 24px;
    fill: rgba(255, 255, 255, 0.4);
    path {
      fill: $var-dark-green;
    }
  }
}

.itinerary-sub-nav--left {
  display: flex;
  flex-direction: row;
  .itinerary-sub-nav-left {
    &--button {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      &:focus {
        outline: none;
      }
      svg {
        fill: rgba(255, 255, 255, 0.4);
      }
      span {
        margin-left: 5px;
        color: rgba(255, 255, 255, 0.4);
      }
      &:hover {
        svg,
        span {
          fill: $var-color--light-orange;
          color: $var-color--light-orange;
        }
      }
    }
  }
}

.itinerary-voted {
  svg,
  path {
    fill: $var-color--light-orange;
  }
}

.itinerary-section-info-wrapper-level2 {
  // width: calc(10% - 50px);
  // margin-left: 50px;
  // width: calc(10% - 23px) !important;
}
.itinerary-section-wrapper-level2 {
  // width: calc(100% - 26px);
  // margin-left: 26px;
}

.itinerary-dragging-button {
  background-color: rgba(255, 255, 255, 0.4) !important;
  .itinerary-draggable-svg-outer {
    fill: $var-color--light-orange !important;
    path {
      fill: $var-dark-green;
    }
  }
}

.itinerary-tripboard--filter--container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 2;
  .itinerary-tripboard--filter {
    width: 100px;
    height: 35px;
    background-color: #264562;
    border: none;
    outline: none;
    cursor: pointer;
    color: white;
    font-size: 0.8em;
    // border: 1px solid $var-color--light-orange;
    border: 1px solid $var-color--dar-blue;
    margin: 0px 4px;
    margin-top: 10px;

    border-radius: 10px;
    &--selected {
      // background-color: $var-color--light-orange;
      // color: $var-color--dar-blue;
      border: 1px solid #264562;
      background-color: $var-color--light-blue;
      color: $var-color--dar-blue;
    }
  }
}
.adv-blk-title-container {
  width: 100%;
  justify-content: center;
  display: flex;
  border-bottom: 1px solid #254562;
  &--no-bottom-border {
    border-bottom: none !important;
  }
  & > div {
    & > span {
      color: white;
      font-size: 18px;
      font-weight: 700;
    }
  }
}

.itinerary-tripboard--filter--container--with-bottom-border {
  border-bottom: 1px solid $var-dark-green;
  padding-bottom: 5px;
  justify-content: flex-start;
  flex-direction: row-reverse;
  width: 100%;
  padding: 5px;
  margin: 0 auto;
  .itineraries-section--btn-container {
    width: 7%;
    margin-right: -4%;
    margin-left: -3%;
    z-index: 10;
  }
  & > div:not(.itineraries-section--btn-container) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.google-maps-module--wrapper {
  width: calc(100% - 95px);
  height: 700px;
  max-height: 900px;
  padding: 20px;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  overflow: hidden;
  padding-top: 5px;
}

.itinerary-itinerary-item-blocks-wrapper {
  // overflow-x: auto;
}

.itinerary-item-scroll-indicator-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 2px;
  z-index: 0;
  margin-bottom: -10px;
  &--placeholder {
    border-top: 2px solid $var-dark-green;
    margin-top: 14px;
  }
  .itinerary-item-scroll-indicator-content-container {
    height: 10px;
    margin-top: 10px;
    width: 100% !important;
    & > div {
      width: 100% !important;
    }
  }
}

.level-two-title {
  // color: rgba(255, 255, 255, 0.5) !important;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .button-container-rows {
    display: flex;
    &--small {
      .button {
      }
    }
    .button {
      padding: 5px 20px;
      cursor: pointer;
      color: #827700;
      font-weight: 700;
      font-size: 15px;
      margin: 0px 4px;
      &--selected {
        color: $var-dark-green;
        text-decoration: underline;
      }
    }
  }
}
.itinerary-info-section-left-top {
  transition: 0.5s;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  &::before {
    content: attr(data-text);
    position: relative;
    margin-bottom: -10px;
    margin-top: -61px;
    margin-right: -27px;
    margin-left: -12px;
    font-size: 11px;
    padding: 1px 5px;
    display: none;
    color: $var-color--dar-blue;
    background: #f7b844;
    border-radius: 4px;
    transition: opacity 0.1s ease-out;
    z-index: 99;
    text-align: left;
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.3) !important;
    cursor: pointer;
    .itinerary-create-svg {
      fill: #f7b844;
    }
    &::before {
      display: inline-block;
    }
  }
  &:hover::before {
    display: inline-block;
  }
}

.itinerary-info-section-left-bottom {
  transition: 0.5s;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  position: relative;
  &::before {
    content: attr(data-text);
    position: absolute;
    margin-bottom: -10px;
    margin-top: -61px;
    margin-right: -29px;
    margin-left: -12px;
    font-size: 11px;
    padding: 1px 5px;
    display: none;
    color: $var-color--dar-blue;
    background: #f7b844;
    border-radius: 4px;
    transition: opacity 0.1s ease-out;
    z-index: 99;
    text-align: left;
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.3) !important;
    cursor: pointer;
    .itinerary-create-svg {
      fill: #f7b844;
    }
    &::before {
      display: inline-block;
    }
  }
  &:hover::before {
    display: inline-block;
  }
}

.itinerary-info-section-level-1-edits {
  transition: 0.5s;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  &::before {
    content: attr(data-text);
    position: relative;
    margin-bottom: -10px;
    margin-top: -61px;
    margin-right: -79px;
    margin-left: -4px;
    font-size: 11px;
    padding: 1px 5px;
    display: none;
    color: $var-color--dar-blue;
    background: #f7b844;
    border-radius: 4px;
    transition: opacity 0.1s ease-out;
    z-index: 99;
    text-align: left;
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.3) !important;
    cursor: pointer;
    .itinerary-create-svg {
      fill: #f7b844;
    }
    &::before {
      display: inline-block;
    }
  }
  &:hover::before {
    display: inline-block;
  }
}

.edit-item-svg-wrapper {
  &::before {
    content: attr(data-text);
    position: relative;
    margin-bottom: -10px;
    margin-top: -61px;
    margin-right: -22px;
    margin-left: -4px;
    font-size: 11px;
    padding: 1px 5px;
    display: none;
    color: $var-color--dar-blue;
    background: #f7b844;
    border-radius: 4px;
    transition: opacity 0.1s ease-out;
    z-index: 99;
    text-align: left;
  }
  &:hover {
    &::before {
      display: inline-block;
    }
  }
}

.upspaced-close-button {
  // margin-top: 20px;
}

.itineraries-section--btn-container {
  span {
    margin-left: 43px;
    svg {
    }
    .custom-btn-svg-outer {
    }
  }
  .custom-btn-svg-outer {
    path {
      fill: #09132b;
    }
  }
}

.button-wrapper-placeholder {
  width: 25px;
  height: 25px;
}

.itinerary-dates-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  text-align: center;
  &--center {
    padding-top: 10px;
    font-size: 1em !important;
    font-weight: 700;
  }
  span {
    // color: white;
    color: $var-dark-green;

    text-transform: uppercase;
    text-align: center;
  }
}
.trip-board-list-collapsible-icon-plus-container {
  width: 30px;
  height: 30px;
  // border: 6px solid #273a07;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: border-color linear 0.3s;
  & > img {
    width: 25px;
  }
  & > svg {
    margin-bottom: 0px;
  }
}
.trip-board-list-collapsible-icon {
  font-size: 50px;
  color: #273a07;
  transition: color linear 0.3s;
  cursor: pointer;
}
.trip-board-box-menu-single-closed {
  position: absolute;
  z-index: 120;
  transform: translateX(-50%);
  left: 100px;
  margin-left: 0px;
  margin-top: 0px;
}

.trip-board-box-menu-closed {
  position: absolute;
  z-index: 120;
  // transform: translateX(-50%);
  margin-top: 5px;
}
.trip-board-box-menu-closed--bottom_center {
  position: absolute;
  left: 43px;
  z-index: 120;
  transform: translateX(calc(-50% + 15px));
  margin-top: 5px;
  &.edit-adventure-wrapper {
    left: -130px;
    @media screen and (max-width: 450px) {
      left: 0px;
    }
  }
}

.trip-board-box-menu-opened {
  display: flex;
  // width: 330px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 5px 15px;
  letter-spacing: 0.5px;
  border: solid 3px #273a07;
  height: auto;
  background-color: white;
  .buttonify {
    width: 80%;
  }
}

.board {
  width: 400px !important;
}
@media screen and (max-width: 720px) {
  .board {
    width: 280px !important;
  }
}

.adventure-menu {
  width: 250px;
}

.edit-trip-board-menu {
  width: 300px;
}

.empty-block-text-wrapper {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  .action-svg-wrapper {
    display: flex;
    flex-direction: column;
    & > div {
      display: flex;
      flex-direction: column;
      width: auto;
      &:hover {
        a,
        span,
        path {
          color: #d18000;
          fill: #d18000;
        }
      }
      & > span {
        // color: rgba(255, 255, 255, 0.3);
        color: $var-dark-green;
        font-size: 0.8em !important;
        font-weight: 700;
        width: auto;
        cursor: pointer;
        &:hover {
          color: #6ec6ed;
        }
        a {
          // color: rgba(255, 255, 255, 0.3);
          color: #09132b;
          &:hover {
            color: #6ec6ed;
          }
        }
        span {
          cursor: pointer;
          &:hover {
            color: #6ec6ed;
          }
        }
      }
      &:nth-child(1) {
        // margin-right: 50px;
      }
      &:nth-child(3) {
        // margin-left: 60px;
      }
    }
  }
  div.create-modal-svg-wrapper {
    cursor: pointer;
    width: 175px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    &:hover {
      svg,
      path {
        fill: #d18000;
      }
    }
  }
  svg,
  path {
    // fill: rgba(255, 255, 255, 0.3);
    fill: $var-dark-green;
  }
  svg {
    width: 45px;
    height: 45px;
    margin-bottom: 10px;
    &:hover {
      fill: #6ec6ed;
    }
  }
}

.itinerary-page-close-button-encloser {
  button.hub-custom-btn {
    margin-top: 2px;
  }
}
.itineraries-section--btn-container {
  cursor: pointer;
  width: 7%;
  margin-right: -7%;
  // margin-left: 10px;
  border-bottom: 1px solid #254562;
  &:hover {
    svg,
    path {
      fill: $var-color--light-orange;
    }
  }
}

.placeholder-wrapper {
  width: 100%;
  padding: 10px 0px;
  box-sizing: border-box;
}

.itinerary-item-action-guidance {
  width: calc(100% - 14px);
  height: 106px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 7px;
  // background: rgba(255, 255, 255, 0.4);
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.3em;
  // border: 2px dashed rgba(38, 69, 98, 0.6);
  border-radius: 10px;
  // box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.1),
  //   2px 1px 6px 4px rgba(10, 10, 0, 0.5);
  font-weight: normal;
  div.create-modal-svg-wrapper {
    cursor: pointer;
  }
  svg,
  path {
    fill: $var-dark-green;
  }
  svg {
    display: block;
    width: 30px;
    height: 30px;
    margin-bottom: 5px;
  }
  & > span {
    // color: rgba(255, 255, 255, 0.3);
    // color: #264562;
    color: $var-dark-green;
    font-size: 1em !important;
    font-weight: 700;
    text-align: center;
    span.empty-block-text-high-lighter {
      cursor: pointer;
    }
  }
}

.itinerary-tripboard-no-bottom-border {
  // border-bottom: none !important;
}
.itinerary-notes-collapse-text {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.mapbox-toggle {
  & > label {
    & > div {
      & > div {
        &:nth-child(1) {
          // background-color: $var-color--light-blue !important;
        }
      }
    }
  }
  & > label > span {
    color: #264562 !important;
    font-size: 1em !important;
    margin-left: 7px !important;
  }
}
