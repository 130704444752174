$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;

.hub-page-adventure-container {
  max-width: 1440px;
  margin-top: 71px;
  min-height: calc(100vh - 85px);
  width: 100%;
}

.m_adventures_all {
  max-width: 1440px;
  height: calc(100% - 88px);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow-y: auto;
  flex-direction: column;
  z-index: 6;
  position: relative;
  &::-webkit-scrollbar {
    display: none;
  }
  & > div.m_adventures_inner {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: auto;
  }

  .scroll-container {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 600px) {
      justify-content: center;
    }
    flex-wrap: wrap;
    max-width: 1440px;
  }
}

.empty-trip-place-holder {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    h2,
    path {
      color: $var-color--light-blue;
      fill: $var-color--light-blue;
    }
  }
  h2 {
    color: $var-color--dar-blue;
  }
  svg {
    path {
      fill: $var-color--dar-blue;
    }
  }
}

.ghost-create-adventure-button {
  // height: calc(150vh / 4);
  width: 100%;
  max-width: 240px;
  // aspect-ratio: 24 / 21;
  height: 100%;
  border: 6px solid $defaultGray;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  cursor: pointer;
  // max-height: 266px;
  background-color: transparent;
  position: relative;
  overflow: hidden;
  box-shadow: none;
  box-sizing: border-box;
  transition: border-color linear 0.3s;
  @media (max-width: 500px) {
    width: 240px;
    height: calc(150vh / 8);
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    box-sizing: border-box;
    transition: opacity 0.3s, border 0.3s;
  }
  &::after {
    content: '';
    position: absolute;
    top: 37px;
    left: -141px;
    width: 200%;
    height: 200%;
    // background-color: rgba(255, 255, 255, 0.61);
    background-color: $warm1;
    border-color: transparent;
    border-radius: 50%;
    transform: translate(-10px, -70px) scale(0.1);
    opacity: 0;
    z-index: -1;
    transition: transform 0.3s, opacity 0.3s, background-color 0.2s;
  }
  .ghost-create-plus-container {
    width: 75px;
    height: 75px;
    border: 6px solid $defaultGray;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: border-color linear 0.3s;

    // .ghost-create-plus {
    //   color: $defaultGray;
    //   font-size: 5em;
    //   font-weight: 900;
    //   transition: color linear 0.4s;
    // }
  }
  .ghost-create-plus {
    color: $defaultGray;
    font-size: 5em;
    font-weight: 900;
    transition: color linear 0.4s;
  }
  &:hover {
    &::after {
      opacity: 1;
      transform: scale(1) translate(-10px, -70px);
    }
    &::before {
      opacity: 0;
    }
    border-color: $green7;
    box-shadow: 8px 8px 2px rgba(0, 0, 0, 0.4);

    .ghost-create-plus-container {
      border-color: $green7;

      .ghost-create-plus {
        color: $green7;
      }
    }
  }
}
