.reset-password-undertext{
  width:auto;
  color:white;
  text-align: center;
  // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.email-reset-under{
  &:hover{
    color: #6EC6ED;
  }
}