//screensize bigger than normal laptops

@media screen and (max-width: 720px) {
  .trip-board-box-menu-opened {
    width: 240px;
    &.board {
      width: 270px;
    }
    & > div {
      flex-wrap: wrap;
    }
    &.edit-adventure {
      width: 260px;
    }
  }
}

@media screen and (min-width: 1441px) {
}
@media screen and (min-width: 1103px) and (max-width: 1230px) {
  #itinerary-page-contents--wrapper--mobile {
    .itinerary-section-info-wrapper {
      width: 9%;
      .itinerary-info-section-right {
        width: 85%;
      }
      .itinerary-draggable-svg-outer {
        width: 21px;
        height: 24px;
        fill: $var-dark-green;
      }
      .it-sect-info-container {
        margin-right: -148px;
        width: 179%;
        height: 42%;
        /* padding-right: 0px; */
        padding-bottom: 24px;
        margin-left: 18px;
      }
    }
  }
}
@media screen and (min-width: 1025px) and (max-width: 1102px) {
  #itinerary-page-contents--wrapper--mobile {
    .itinerary-section-info-wrapper {
      width: 9%;
      .itinerary-info-section-right {
        width: 85%;
      }
      .itinerary-draggable-svg-outer {
        width: 21px;
        height: 24px;
        fill: $var-dark-green;
      }
      .it-sect-info-container {
        margin-right: -138px;
        width: 179%;
        height: 42%;
        /* padding-right: 0px; */
        padding-bottom: 24px;
        margin-left: 18px;
      }
    }
  }
}
// screen#1 iPad pro vertical
@media screen and (max-width: 1024px) {
  #itinerary-page-contents--wrapper--mobile {
    .itinerary-section-info-wrapper {
      width: 9%;
      .itinerary-info-section-right {
        width: 85%;
      }
      .itinerary-draggable-svg-outer {
        width: 21px;
        height: 24px;
        fill: $var-dark-green;
      }
      .it-sect-info-container {
        margin-right: -138px;
        width: 179%;
        height: 42%;
        /* padding-right: 0px; */
        padding-bottom: 24px;
        margin-left: 18px;
      }
    }
    .itinerary-section-info-wrapper-level2 {
      width: calc(12% - 23px) !important;
    }
  }
}
// screen#2 ipad vertical

@media screen and (min-width: 768px) and (max-width: 860px) {
  #itinerary-page-contents--wrapper--mobile {
    .itinerary-section-info-wrapper {
      width: 12%;
      .itinerary-info-section-right {
        width: 85%;
      }
      .itinerary-draggable-svg-outer {
        width: 21px;
        height: 24px;
        fill: $var-dark-green;
      }
      .it-sect-info-container {
        margin-right: -138px;
        width: 179%;
        height: 42%;
        /* padding-right: 0px; */
        padding-bottom: 24px;
        margin-left: 18px;
      }
    }
    .itinerary-section-info-wrapper-level2 {
      width: calc(16% - 24px) !important;
    }
    .itinerary-tripboard--filter--container {
      & > div {
        & > button {
          width: 140px !important;
          font-size: 0.8em !important;

          span {
            font-size: 0.8em !important;
          }
        }
      }
    }
    .action-svg-wrapper {
      & > div {
        // width: 100px;
        & > span {
          font-size: 0.7em !important;
        }
      }
    }
  }
}
// screen#3  surface duo

@media screen and (min-width: 481px) and (max-width: 767px) {
  #itinerary-page-contents--wrapper--mobile {
    .action-svg-wrapper {
      & > div {
        // width: 80px;
        & > span {
          font-size: 0.7em !important;
        }
      }
    }
    .itinerary-section-info-wrapper {
      width: 19%;
      .itinerary-info-section-right {
        width: 85%;
      }
      .itinerary-draggable-svg-outer {
        width: 21px;
        height: 24px;
        fill: $var-dark-green;
      }
      .it-sect-info-container {
        margin-right: -148px;
        width: 179%;
        height: 42%;
        /* padding-right: 0px; */
        padding-bottom: 24px;
        margin-left: 18px;
      }
    }
    .itinerary-section-info-wrapper-level2 {
      width: calc(25% - 28px) !important;
    }
    .itinerary-tripboard--filter--container {
      & > div {
        & > button {
          width: 80px !important;
          font-size: 0.6em !important;

          span {
            font-size: 0.8em !important;
          }
        }
      }
    }
    .stand-alone-search-box {
      box-sizing: border-box;
      border: 1px solid transparent;
      width: 190px;
      height: 32px;
      padding: 0 12px;
      border-radius: 3px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
      font-size: 14px;
      outline: none;
      text-overflow: ellipses;
      position: absolute;
      left: 34%;
      margin-left: -120px;
      margin-top: 60px;
      z-index: 20;
    }
  }
}
// screen#4 iphone 12 pro max,
@media screen and (min-width: 415px) and (max-width: 480px) {
  .trip-board-box-menu-closed{
    // transform: translateX(0%);
  }
  #itinerary-page-contents--wrapper--mobile {
    .action-svg-wrapper {
      & > div {
        // width: 66px;
        & > span {
          font-size: 0.6em !important;
        }
        .empty-block-text-high-lighter {
          font-size: 0.6em !important;
          & > a,
          span {
            // font-size: 0.7em !important;
          }
        }
      }
    }
    .itinerary-info-section-right-bottom {
      margin-left: 1px;
    }
    .itinerary-info-section-right-bottom > div {
      margin: 0px 2px;
    }
    .itinerary-section-info-wrapper {
      width: 19%;
      .itinerary-info-section-right {
        width: 85%;
      }
      .it-sect-info-container {
        margin-right: -127px;
        width: 179%;
        height: 42%;
        /* padding-right: 0px; */
        padding-bottom: 24px;
        margin-left: 18px;
      }
      .itinerary-draggable-svg-outer {
        width: 21px;
        height: 24px;
        fill: $var-dark-green;
      }
    }
    .itinerary-section-info-wrapper-level2 {
      width: calc(34% - 32px) !important;
      .itinerary-draggable-svg-outer {
        width: 21px;
        height: 24px;
        fill: $var-dark-green;
      }
    }
    #lodestarhub-map {
      & > div {
        & > div.gm-style {
          & > div {
            &:nth-child(5) {
              & > div {
                &:nth-child(2) {
                  left: 227px !important;
                  top: 282px !important;
                }
              }
            }
          }
        }
      }
    }
    .itinerary-tripboard--filter--container {
      & > div {
        .filter-spacer {
          width: 10px !important;
        }
        & > button {
          width: 70px !important;
          font-size: 0.6em !important;

          span {
            font-size: 0.8em !important;
          }
        }
      }
    }
    .it-sect-info-container {
      transform: rotate(-90deg);

      /* Legacy vendor prefixes that you probably don't need... */

      /* Safari */
      -webkit-transform: rotate(-90deg);

      /* Firefox */
      -moz-transform: rotate(-90deg);

      /* IE */
      -ms-transform: rotate(-90deg);

      /* Opera */
      -o-transform: rotate(-90deg);
      .it-sect-info-title,
      .it-sect-info-date {
      }
    }
    .stand-alone-search-box {
      box-sizing: border-box;
      border: 1px solid transparent;
      width: 190px;
      height: 32px;
      padding: 0 12px;
      border-radius: 3px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
      font-size: 14px;
      outline: none;
      text-overflow: ellipses;
      position: absolute;
      left: 43%;
      margin-left: -120px;
      margin-top: 60px;
      z-index: 20;
    }
    .itinerary-info-section-left-headers {
      span {
        color: $var-dark-green;
      }
    }
  }
}
// screen#5 iphone 11 && pixel 2 && pixel2 xl  iphone x
@media screen and (min-width: 391px) and (max-width: 414px) {
  .trip-board-box-menu-closed{
    transform: translateX(0%);
  }
  #itinerary-page-contents--wrapper--mobile {
    .action-svg-wrapper {
      & > div {
        // width: 61px;
        & > span {
          font-size: 0.7em !important;
        }
        .empty-block-text-high-lighter {
          font-size: 0.6em !important;
          & > a,
          span {
            // font-size: 0.7em !important;
          }
        }
      }
    }
    .itinerary-info-section-right-bottom {
      margin-left: 1px;
    }
    .itinerary-info-section-right-bottom > div {
      margin: 0px 2px;
    }
    .itinerary-section-info-wrapper {
      width: 19%;
      .itinerary-info-section-right {
        width: 85%;
      }
      .itinerary-draggable-svg-outer {
        width: 21px;
        height: 24px;
        fill: $var-dark-green;
      }
      .it-sect-info-container {
        margin-right: -127px;
        width: 179%;
        height: 42%;
        /* padding-right: 0px; */
        padding-bottom: 24px;
        margin-left: 18px;
      }
    }
    #lodestarhub-map {
      & > div {
        & > div.gm-style {
          & > div {
            &:nth-child(5) {
              & > div {
                &:nth-child(2) {
                  left: 267px !important;
                  top: 242px !important;
                }
              }
            }
          }
        }
      }
    }
    .itinerary-section-info-wrapper-level2 {
      width: calc(35% - 30px) !important;
      .itinerary-draggable-svg-outer {
        width: 21px;
        height: 24px;
        fill: $var-dark-green;
      }
    }
    .itinerary-tripboard--filter--container {
      & > div {
        .filter-spacer {
          width: 8px !important;
        }
        & > button {
          width: 65px !important;
          font-size: 0.6em !important;

          span {
            font-size: 0.8em !important;
          }
        }
      }
    }
    .it-sect-info-container {
      transform: rotate(-90deg);

      /* Legacy vendor prefixes that you probably don't need... */

      /* Safari */
      -webkit-transform: rotate(-90deg);

      /* Firefox */
      -moz-transform: rotate(-90deg);

      /* IE */
      -ms-transform: rotate(-90deg);

      /* Opera */
      -o-transform: rotate(-90deg);
      .it-sect-info-title,
      .it-sect-info-date {
      }
    }
    .stand-alone-search-box {
      box-sizing: border-box;
      border: 1px solid transparent;
      width: 190px;
      height: 32px;
      padding: 0 12px;
      border-radius: 3px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
      font-size: 14px;
      outline: none;
      text-overflow: ellipses;
      position: absolute;
      left: 44%;
      margin-left: -120px;
      margin-top: 60px;
      z-index: 20;
    }
    .itinerary-info-section-left-headers {
    }
  }
}
// screen#6 iphone 12, iphone 12 pro, iphone 6/7/8
@media screen and (min-width: 361px) and (max-width: 390px) {
  .trip-board-box-menu-closed{
    // transform: translateX(0%);
  }
  #itinerary-page-contents--wrapper--mobile {
    .action-svg-wrapper {
      .items > div {
        width: 60px;
      }
      & > div {
        & > span {
          font-size: 0.7em !important;
        }
        .empty-block-text-high-lighter {
          font-size: 0.6em !important;
          & > a,
          span {
            // font-size: 0.7em !important;
          }
        }
      }
    }

    .google-map-toggle-switch {
      width: 72% !important;
      margin-left: 10px;
    }
    .itinerary-info-section-right-bottom {
      margin-left: 1px;
    }
    .itinerary-info-section-right-bottom > div {
      margin: 0px 2px;
    }
    #lodestarhub-map {
      & > div {
        & > div.gm-style {
          & > div {
            &:nth-child(5) {
              & > div {
                &:nth-child(2) {
                  left: 217px !important;
                  top: 242px !important;
                }
              }
            }
          }
        }
      }
    }
    .itinerary-section-info-wrapper {
      width: 19%;
      .itinerary-info-section-right {
        width: 85%;
      }
      .itinerary-draggable-svg-outer {
        width: 21px;
        height: 24px;
        fill: $var-dark-green;
      }
      .it-sect-info-container {
        margin-right: -127px;
        width: 179%;
        height: 42%;
        /* padding-right: 0px; */
        padding-bottom: 24px;
        margin-left: 18px;
      }
    }
    .itinerary-section-info-wrapper-level2 {
      width: calc(38% - 32px) !important;
      .itinerary-draggable-svg-outer {
        width: 21px;
        height: 24px;
        fill: $var-dark-green;
      }
    }
    .itinerary-tripboard--filter--container {
      & > div {
        .filter-spacer {
          width: 2px !important;
        }
        & > button {
          width: 63px !important;
          font-size: 0.6em !important;

          span {
            font-size: 0.8em !important;
          }
        }
      }
    }
    .it-sect-info-container {
      transform: rotate(-90deg);

      /* Legacy vendor prefixes that you probably don't need... */

      /* Safari */
      -webkit-transform: rotate(-90deg);

      /* Firefox */
      -moz-transform: rotate(-90deg);

      /* IE */
      -ms-transform: rotate(-90deg);

      /* Opera */
      -o-transform: rotate(-90deg);
      .it-sect-info-title,
      .it-sect-info-date {
      }
    }
    .stand-alone-search-box {
      box-sizing: border-box;
      border: 1px solid transparent;
      width: 190px;
      height: 32px;
      padding: 0 12px;
      border-radius: 3px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
      font-size: 14px;
      outline: none;
      text-overflow: ellipses;
      position: absolute;
      left: 48%;
      margin-left: -120px;
      margin-top: 60px;
      z-index: 20;
    }
    .itinerary-info-section-left-headers {
      margin-left: 49px;

      margin-top: 89px;
    }
  }
}
// screen#7 iphone 12 mini && Moto G4 && Galaxy S9 && galaxy s5 galaxy s9
@media screen and (min-width: 321px) and (max-width: 360px) {
  .trip-board-box-menu-closed{
    transform: translateX(0%);
  }
  #itinerary-page-contents--wrapper--mobile {
    .button-container-rows {
      .button {
        width: 96px !important;
      }
    }
    .action-svg-wrapper {
      & > div {
        // width: 45px;
        & > span {
          font-size: 0.7em !important;
        }
        .empty-block-text-high-lighter {
          font-size: 0.5em !important;
          & > a,
          span {
            // font-size: 0.7em !important;
          }
        }
      }
    }
    .google-map-toggle-switch {
      width: 72% !important;
      margin-left: 10px;
    }
    .itinerary-info-section-right-bottom {
      margin-left: -1px;
    }
    .itinerary-info-section-right-bottom > div {
      margin: 0px 2px;
    }
    .itinerary-section-info-wrapper {
      width: 22%;
      .itinerary-info-section-right {
        width: 85%;
      }
      .itinerary-draggable-svg-outer {
        width: 21px;
        height: 24px;
        fill: $var-dark-green;
      }
      .it-sect-info-container {
        margin-right: -127px;
        width: 179%;
        height: 42%;
        /* padding-right: 0px; */
        padding-bottom: 24px;
        margin-left: 18px;
      }
    }
    #lodestarhub-map {
      & > div {
        & > div.gm-style {
          & > div {
            &:nth-child(5) {
              & > div {
                &:nth-child(2) {
                  left: 217px !important;
                  top: 242px !important;
                }
              }
            }
          }
        }
      }
    }
    .itinerary-section-info-wrapper-level2 {
      width: calc(42% - 32px) !important;
      .itinerary-draggable-svg-outer {
        width: 21px;
        height: 24px;
        fill: $var-dark-green;
      }
    }
    .itinerary-tripboard--filter--container {
      & > div {
        .filter-spacer {
          width: 0px !important;
        }
        & > button {
          width: 62px !important;
          font-size: 0.6em !important;
          margin: 0px 2px !important;

          span {
            font-size: 0.8em !important;
          }
        }
      }
    }
    .it-sect-info-container {
      transform: rotate(-90deg);

      /* Legacy vendor prefixes that you probably don't need... */

      /* Safari */
      -webkit-transform: rotate(-90deg);

      /* Firefox */
      -moz-transform: rotate(-90deg);

      /* IE */
      -ms-transform: rotate(-90deg);

      /* Opera */
      -o-transform: rotate(-90deg);
      .it-sect-info-title,
      .it-sect-info-date {
      }
    }
    .stand-alone-search-box {
      box-sizing: border-box;
      border: 1px solid transparent;
      width: 190px;
      height: 32px;
      padding: 0 12px;
      border-radius: 3px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
      font-size: 14px;
      outline: none;
      text-overflow: ellipses;
      position: absolute;
      left: 50%;
      margin-left: -120px;
      margin-top: 60px;
      z-index: 20;
    }
    .itinerary-info-section-left-headers {
    }
  }
}
// screen#8 iphone 5/se, galaxy fold
@media screen and (min-width: 280px) and (max-width: 320px) {
  .itinerary_adventure_block_bottom_icon_dropdown {
    position:relative;
    padding: 5px;
    margin: 5px;
    // .itinerary_adventure_block_bottom_icon_dropdown > svg {
    //   width: 20px;
    //   height: 20px;
    // }
  }
  .trip-board-box-menu-closed{
    // transform: translateX(0%);
    left: 43px;
  }
  #itinerary-page-contents--wrapper--mobile {
    .action-svg-wrapper {
      & > div {
        // width: 45px;
        & > span {
          font-size: 0.7em !important;
        }
        .empty-block-text-high-lighter {
          font-size: 0.5em !important;
          & > a,
          span {
            // font-size: 0.7em !important;
          }
        }
      }
      .google-map-toggle-switch {
        width: 72% !important;
        margin-left: 10px;
      }
      .itinerary-section-info-wrapper {
        width: 22%;
        .itinerary-info-section-right {
          width: 85%;
        }
        .itinerary-draggable-svg-outer {
          width: 21px;
          height: 24px;
          fill: $var-dark-green;
        }
        .it-sect-info-container {
          margin-right: -127px;
          width: 179%;
          height: 42%;
          /* padding-right: 0px; */
          padding-bottom: 24px;
          margin-left: 18px;
        }
      }
      .itinerary-section-info-wrapper-level2 {
        .itinerary-draggable-svg-outer {
          width: 21px;
          height: 24px;
          fill: $var-dark-green;
        }
        width: calc(42% - 32px) !important;
      }
      #lodestarhub-map {
        & > div {
          & > div.gm-style {
            & > div {
              &:nth-child(5) {
                & > div {
                  &:nth-child(2) {
                    left: 217px !important;
                    top: 242px !important;
                  }
                }
              }
            }
          }
        }
      }
      .itinerary-tripboard--filter--container {
        & > div {
          .filter-spacer {
            width: 0px !important;
          }
          & > button {
            width: 62px !important;
            font-size: 0.6em !important;
            margin: 0px 2px !important;

            span {
              font-size: 0.8em !important;
            }
          }
        }
      }
      .it-sect-info-container {
        transform: rotate(-90deg);

        /* Legacy vendor prefixes that you probably don't need... */

        /* Safari */
        -webkit-transform: rotate(-90deg);

        /* Firefox */
        -moz-transform: rotate(-90deg);

        /* IE */
        -ms-transform: rotate(-90deg);

        /* Opera */
        -o-transform: rotate(-90deg);
        .it-sect-info-title,
        .it-sect-info-date {
        }
      }
      .stand-alone-search-box {
        box-sizing: border-box;
        border: 1px solid transparent;
        width: 160px;
        height: 32px;
        padding: 0 12px;
        border-radius: 3px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
        font-size: 14px;
        outline: none;
        text-overflow: ellipses;
        position: absolute;
        left: 64%;
        margin-left: -120px;
        margin-top: 60px;
        z-index: 20;
      }
    }
  }
}
