.itinerary-item-modal {
  &--partialday {
    height: 534px !important;
  }
  &--fullday {
    height: 453px !important;
    &-edit {
      height: 311px !important;
    }
  }
}
.modal-itinerary-item-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  .modal-itinerary-item-info-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    // & > div {
    //   width: 30%;
    // }
  }
  .modal-itinerary-item-info-bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    // & > div {
    //   width: 30%;
    // }
  }
}

.modal-itinerary-item-info-section-left {
  width: 18%;
  text-align: start;
  color: #827700;
}
.modal-itinerary-item-info-section-mid {
  width: 55%;
  text-align: start;
  color: #827700;
}
.modal-itinerary-item-info-section-right {
  width: 27%;
  text-align: end;
  color: #827700;
}

.mod-cnt-def-itinerary-item {
  width: 100%;
  background-color: #364301 ;
  height: 480px;
  max-height: 478px;
}

.itinerary-item-padder {
  height: 540px !important;
  padding-bottom: 0px !important;
  background-color: #364301 ;
}

.error-message-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 30px;
  span {
    color: #da4431;
  }
}
.itinerary-item-notes-textarea-parent {
  // width: 91% !important;
  .itinerary-item-notes-textarea {
    height: 160px !important;
  }
}
