$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;

.pdf-file-svg-outer {
  width: 70px;
  height: 70px;
  path {
    fill: rgba(0, 0, 0, 0.7);
  }
}
.file-accordion-detail-wrapper {
  width: 93% !important;
  min-height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .file-accordion-file-wrapper-buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-left: -3%;
    margin-bottom: -5%;
    button.file-accordion-button {
      background-color: transparent;
      border: none;
      padding: 0px;
      padding: 2px;
      .file-accordion-link {
        margin: 0px;
      }
      &:focus {
        border: none;
      }
      &:hover {
        .file-accordion-edit-svg-outerlayer,
        .file-accordion-edit-svg {
          fill: #f7b844;
        }
      }
    }
  }
}
.file-accordion-wrapper {
  max-width: 100% !important;
  margin: 0px 0px 5px;
  p.MuiTypography-body1 {
    color: white;
  }
  .file-accordion-image {
    width: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    height: 150px;
  }
  .file-accordion-container {
    width: calc(100% - 16px) !important;
    padding: 0px 8px !important;
    & > div {
      &:nth-child(1) {
        width: 95%;
        .file-accordion-custom-button {
          display: flex;
          flex-direction: row;
          width: 0%;
          button {
            cursor: pointer;
            background-color: transparent !important;
            &:focus {
              outline: none;
            }
          }
        }
        & > label > span {
          padding-left: 10px;
          width: calc(100% - 10px);
        }
      }
      &:nth-child(2) {
        width: 5%;
      }
    }
  }
  .file-accordion-custom-form-label {
    flex-direction: row-reverse !important;
    margin-right: 0px;
    width: 100%;

    & > div {
      button.file-accordion-button {
        background-color: none;
        border: none;
        padding: 0px;
        padding: 2px;
        .file-accordion-link {
          margin: 0px;
        }
        &:focus {
          border: none;
        }
        &:hover {
          .file-accordion-edit-svg-outerlayer,
          .file-accordion-edit-svg {
            fill: #f7b844;
          }
        }
      }
    }
  }
  div.MuiIconButton-root {
    padding: 0px;
    margin-right: 0px;
    color: white;
    &:hover {
      color: $var-color--light-orange;
      .file-accordion-view-svg-outerlayer,
      .file-accordion-view-svg {
        fill: #f7b844;
      }
    }
  }
}

.file-accordion-view-svg-outerlayer {
  width: 15px;
  height: 15px;
  fill: #827700;
}
.file-accordion-edit-svg-outerlayer {
  width: 15px;
  height: 15px;
  fill: #827700;
}

.file-accordion-link {
  align-items: center;
  display: flex;
}

.MuiAccordion-rounded {
  border-radius: 10px !important;
}

.file-actions {
  justify-content: space-between;
}
