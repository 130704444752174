.logged_in_profile_container {
  //width: 200px;
  height: 35px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  //border: 1px solid #F7B844;
  margin-right: 5px;
  border-radius: 18px;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  img {
    height: 100%;
    border-radius: 50%;
  }
  .logged_in_name {
    width: calc(100% - 60px);
    display: flex;
    justify-content: center;
    color: white;
  }

  .logged_in_up_arrow {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;

    border-bottom: 8px solid white;
  }

  .logged_in_down_arrow {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;

    border-top: 8px solid white;
  }
}

.hub-top-nav-middle {
  min-width: 30%;
  width: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  span {
    color: white;
    font-size: 0px;
    font-weight: 700;
    text-transform: uppercase;
  }
  img {
    width: 50px;
    height: 30px;
    cursor: pointer;
  }
}
.hub-top-nav-padder {
  width: calc(100% - 48px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 10px 24px;
  background-color: #273a07;
  height: calc(100% - 19px);
}

#top-nav-left-icon {
  width: 25px;
  height: 25px;
  color: white;
  cursor: pointer;
}
.hub-top-nav-left {
  width: 230px;
  height: 100%;
  min-width: 230px;
  margin-left: 8px;
  display: flex;
  align-items: center;
}
// justify-content: row;

.hub-opened-nav-menu-outer {
  position: absolute;
  top: 62px;
  left: 0px;
}

.hub-opened-nav-menu-outer {
  background-color: #09132b;
  width: 269px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .sectors {
    color: white;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    div {
      cursor: pointer;
      width: 269px;
      height: 45px;
      padding-left: 24px;
    }
    div:hover {
      background-color: #12234d;
    }
  }

  .hamburger-sect2 {
    border-bottom: 1px solid white;
  }

  .hamburger-sect-3 {
    font-size: 14px;
  }
}

.hub-top-nav-left-trigger {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  .hamurger-sect1 {
    border: none;
  }
  .triggerhamburger {
    width: 50px;
    height: 50px;
  }
}

.hub-top-navbar-container {
  width: 100vw;
  height: 70px;
  z-index: 30;
  background-color: #273a07;
}

.opened-box {
  position: absolute;
}

.opened-box-hamburger {
  position: absolute;
  top: 71px;
  // right: 36px;
  z-index: 10;
}

.opened-box-hamburger-right {
  position: absolute;
  top: 71px;
  right: 37px;
  z-index: 20;
}

.main-nav-box-menu-outer {
  // width: 35px;
  margin-right: 2px;
  height: 35px;
  fill: white;
  margin-left: 5px;
  &:hover {
    fill: #f7b844;
  }
}

.main-nav-box-menu-closed {
  position: absolute;
  top: 50px;
  right: 35px;
  z-index: 20;
}

.contents-container {
  background-color: #09132b;
  width: 158px;
  /* top: 20px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 70px;

  .inner-contents {
    padding: 5px 0px;
    color: white;
    height: 35px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
  }

  .inner-contents:hover {
    background-color: #12234d;
  }
}

.triggerhamburger {
  cursor: pointer;
}

.hamburger-contents-container {
  // position: absolute;
  // top: 71px;
  // left: 31px;
  z-index: 1000;
  background-color: rgba(46, 54, 75, 0.9);
  width: 269px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 5px 5px;

  .logged-in-top-nav-contents-hub {
    display: flex;
    justify-content: flex-start;
    color: white;
    p {
      margin-left: 10px;
    }
    &:hover {
      background-color: #12234d;
    }
  }
  .sectors {
    color: white;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 90%;
    margin: 0 auto;
    div {
      cursor: pointer;
      width: 100%;
      height: 45px;
      // padding-left:2px;
      display: flex;
      align-items: center;
      .nav-section-text-with-tm {
        display: flex;
        align-items: flex-start;
      }
    }
    div:hover {
      background-color: #12234dc0;
      border-radius: 10px;
    }
  }
}

.logged-in-hamburger-sect1 {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 12px;
  letter-spacing: 0.5px;
}

.logged-in-hamburger-sect2 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 12px;
  letter-spacing: 0.5px;
}

.logged-in-hamburger-sect3 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 12px;
  letter-spacing: 0.5px;
}

.logged-in-hamburger-sect4 {
  margin-top: 5px;
  font-size: 10px;
  letter-spacing: 0.5px;
}

.hamburger-contents-container-right {
  background-color: rgba(46, 54, 75, 0.9);
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 5px 5px;
  letter-spacing: 0.5px;
  .logged-in-top-nav-contents-hub {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: white;
    height: 27px;
    z-index: 30;
    cursor: pointer;
    p {
      margin-right: 10px;
    }
    &:hover {
      background-color: #12234d;
      border-radius: 10px;
    }
  }
  .logged-in-top-nav-contents-hub-small {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: white;
    height: 20px;
    z-index: 30;
    cursor: pointer;
    p {
      margin-right: 10px;
    }
    &:hover {
      background-color: #12234d;
      border-radius: 10px;
    }
  }

  .logged-in-top-nav-contents-hub-user-name {
    display: flex;
    justify-content: center;
    align-items: center;
    //border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    color: white;
    height: 15px;
    z-index: 30;
    letter-spacing: 0.5px;
    cursor: default;
    .user-name-menu {
      text-transform: uppercase;
      justify-content: left;
      font-weight: 700;
      font-size: 10px;
    }

    p {
      margin-right: 10px;
    }
  }

  .logged-in-top-nav-contents-hub-user-email {
    display: flex;
    justify-content: center;
    align-items: center;
    //border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    color: white;
    height: 10px;
    z-index: 30;
    padding-bottom: 5px;
    letter-spacing: 0.5px;
    cursor: default;
    .user-email-menu {
      text-transform: lowercase;
      justify-content: center;
      font-size: 10px;
    }
  }

  .logged-in-top-nav-contents-hub-user-location {
    display: flex;
    justify-content: center;
    align-items: center;
    //border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    color: white;
    height: 10px;
    z-index: 30;
    padding-bottom: 5px;
    letter-spacing: 0.5px;
    cursor: default;
    .user-location-menu {
      //text-transform: lowercase;
      justify-content: center;
      font-size: 10px;
    }
  }

  .sectors {
    color: white;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 90%;
    margin: 0 auto;
    div {
      cursor: pointer;
      width: 100%;
      height: 30px;
      // padding-left:2px;
      display: flex;
      align-items: center;
      .nav-section-text-with-tm {
        display: flex;
        align-items: flex-start;
      }
    }
    div:hover {
      background-color: #12234dc0;
      border-radius: 10px;
    }
  }
}

.hamburger-sect3 {
  font-size: 14px;
  div {
    span {
      font-size: 12px;
    }
  }
}

.nav-section-text {
  display: flex;
  align-items: flex-start;
}

.hub-nav-profile-pic {
  border-radius: 50%;
  height: 100%;
  //width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
}

.hub-nav-profile-pic-img {
  margin: 0 5px 0 0;
  border-radius: 50%;
  height: 100%;
  //width: 30px;
  border: 1px solid #fcedd6;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  height: 40px;
}

.top-nav-user-name {
  color: white;
  padding: 17px 5px 0 0;
  letter-spacing: 2px;
  font-size: 14px;
  font-weight: 500;
}

.logged_in_right_menu {
  //width: 200px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  //border: 1px solid #F7B844;
  margin-right: 5px;
  border-radius: 18px;
  align-items: center;
  padding: 5px;
  cursor: pointer;
}

.hub-nav-right-menu {
  border-radius: 50%;
  height: 100%;
  //width: 30px;
  //border: 1px solid rgba(247, 184, 68, 0.6);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.hub-nav-right-menu-svg {
  height: 65%;
}

.hub-nav-add-button {
  cursor: pointer;
}

.main-nav-noti-unfilled-svg-outer {
  width: 23px;
  height: 23px;
  fill: white;
  margin-top: 3px;
  cursor: pointer;
  &:hover {
    fill: #f7b844;
  }
}

.main-nav-noti-filled-svg-outer {
  width: 25px;
  height: 25px;
  fill: white;
  cursor: pointer;
  &:hover {
    fill: #f7b844;
  }
}

.main-nav-contents-wrapper {
  // background-color: rgba(46, 54, 75, 0.9);
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 5px 5px;
  letter-spacing: 0.5px;
}

.main-nav-active {
  color: $warm7 !important;
  & > div {
    // background-color: #12234dc0;
    border-radius: 10px;
    & > p {
      color: $warm7 !important;
    }
  }
  .menu-navigation-text {
    color: $warm7 !important;
  }
}

.logoimage {
  height: 85%;
  object-fit: contain;
  margin-left: 20px;
  width: 89%;
}

.hub-top-nav-bottom-border {
  justify-content: center;
  display: flex;
  width: 100%;
  background-color: #273a07;
  div {
    border-bottom: 3px solid #fcedd6;
    width: 96%;
  }
}

.top-nav-login-button {
  width: 150px;
  background-color: transparent;
  border: 1px solid #f7b844;
  box-sizing: border-box;
  text-align: center;
  font-size: 18px;
  border-radius: 10px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #6ec6ed !important;
  }
}

.hamburger-contents-container {
  width: 269px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 5px 5px;
  // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  .sectors {
    color: white;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 90%;
    margin: 0 auto;
    div {
      cursor: pointer;
      width: 100%;
      height: 45px;
      // padding-left:2px;
      display: flex;
      align-items: center;
      .nav-section-text-with-tm {
        display: flex;
        align-items: flex-start;
      }
    }
    div:hover {
      background-color: #12234dc0;
      border-radius: 10px;
    }
  }

  .hamburger-sect1 {
    border-bottom: 1px solid white;
  }

  .hamburger-sect2 {
    border-bottom: 1px solid white;
  }
}

.top-nav-right {
  display: flex;
  // padding: 0 40px 0 0;
}
.tm-text {
  font-size: 6px;
}
.hub-navigation-button {
  border-radius: 20%;
  color: rgb(255, 255, 255);
  padding: 0 15px 0 0;

  &:hover {
    color: #f7b844;
  }
}
