.select-partner {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;
  //height: 100%;
}
.select-partner-header {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  color: $var-dark-green;
  margin-bottom: 20px;
  margin-top: 20px;
}

.hub-partner-select-continue-button {
  position: fixed;
  bottom: 100px;
  height: 50px;
  width: 120px;
  background-color: #da4431;
  border-radius: 5px;
  border: none;
  outline: none;
  right: 10px;
  z-index: 100;
  box-shadow: 0 0 0 0 rgba(#5a99d4, 0.5);
  -webkit-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  box-shadow: 0 0 0 0 rgba(232, 76, 61, 0.7);
  color: white;
}

@-webkit-keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}

@-moz-keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}

@-ms-keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}

@keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}
