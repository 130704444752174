.coming-soon-page-container {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 220px);
  h1 {
    color: white;
  }
}
