.cropper-view-box {
  border-radius: 50%;
  outline: none;
}
.cropper-bg {
  background-image: none;
}
.cropper-face {
  background-color: rgba(255, 255, 255, 0);
}
.cropper-move {
  cursor: grab;
}
.modal-image-edit-container {
  .image-edit-body-container {
    width: 100%;
    height: 330px;
  }
  .image-edit-footer-container {
    margin: 10px 0 10px 0;
    width: 100%;
    .footer-button-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }
  }
}
