.itinerary-block-wrapper {
  // height: calc(150vh / 4);
  height: 200px;
  // background-color: rgba(255, 255, 255, 0.5);
  background-color: $var-tab-background;
  width: 300px;
  border: none;
  border-radius: 20px;
  display: flex;
  margin: 10px 20px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  @media (max-width: $breakpoint-alpha) {
    width: 95vw !important;
  }
  // box-shadow: 0 2px 1px -10px rgba(255, 255, 255, 0.093),
  //   0 4px 2px -7px rgba(255, 255, 255, 0.133),
  //   0 6px 5px -10px rgba(255, 255, 255, 0.167),
  //   0 12px 15px -12px rgba(255, 255, 255, 0.207);

  &:hover {
    //box-shadow: 0px 0px 12px rgba(255, 255, 255, 0.35);
    box-shadow: 8px 8px 2px rgba(0, 0, 0, 0.4);
  }
  &:hover {
    .adventure-block-title-text {
      cursor: pointer;
      color: $var-dark-green;
    }
    .adventure-block-bottom-center {
      cursor: pointer;
      //color: #f7b844;
    }
    .future {
      //background-color: rgba(247, 184, 68, .6);
      background: rgba(247, 184, 68, 0.6);
    }

    .past {
      //background-color: rgba(110, 198, 237, .6);
      background: rgba(110, 198, 237, 0.6);
    }
    .now {
      //background-color: rgba(218, 68, 49, .8);
      background: rgba(218, 68, 49, 0.8);
    }
  }
}

.itinerary-block-top {
  // background-color: rgba(110, 198, 237, 0.3);
  width: 100%;
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: $var-dark-green;
  height: 60px;
  border-bottom: none;
}

.itinerary-selected-status {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 15px;
  font-size: 12px;
  background: linear-gradient(
    90deg,
    rgba(9, 19, 43, 0.6),
    rgba(247, 184, 68, 0.6),
    rgba(9, 19, 43, 0.6)
  );
}

.future {
  //background-color: rgba(247, 184, 68, .6);
  background: linear-gradient(
    90deg,
    rgba(9, 19, 43, 0.6),
    rgba(247, 184, 68, 0.6),
    rgba(9, 19, 43, 0.6)
  );
}

.past {
  //background-color: rgba(110, 198, 237, .6);
  background: linear-gradient(
    90deg,
    rgba(9, 19, 43, 0.6),
    rgba(110, 198, 237, 0.6),
    rgba(9, 19, 43, 0.6)
  );
}

.now {
  //background-color: rgba(218, 68, 49, .8);
  background: linear-gradient(
    90deg,
    rgba(9, 19, 43, 0.6),
    rgba(218, 68, 49, 0.8),
    rgba(9, 19, 43, 0.6)
  );
}

.adventure_block_svgs {
  color: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;

  .adv-svg-itinerary {
    width: 20px;
    fill: rgba(255, 255, 255, 0.35);
  }

  .adv-svg-itinerary-outer {
    width: 20px;
    &:hover {
      .adv-svg-itinerary {
        cursor: pointer;
        fill: #f7b844;
      }
    }
  }

  .adv-ct-itinerary {
    font-size: 11px;
    margin-left: 3px;
  }

  .adv-svg-friend {
    width: 15px;
    fill: rgba(255, 255, 255, 0.35);
  }

  .adv-svg-friend-outer {
    width: 15px;
  }

  .adv-ct-friend {
    font-size: 11px;
    margin-right: 3px;
  }
}

.svg-friend-section {
  &:hover {
    .adv-svg-friend {
      fill: rgba(247, 184, 68, 0.8);
    }
    .adv-ct-friend {
      color: rgba(247, 184, 68, 0.8);
    }
    &:hover:before {
      display: block;
    }
  }

  &:before {
    content: attr(data-text); /* here's the magic */
    position: absolute;

    /* vertically center */
    //top:50%;
    transform: translateY(-135%);

    /* move to right */
    //left:100%;
    margin-left: -75px; /* and add a small left margin */

    /* basic styles */
    width: 100px;
    padding: 3px;
    border-radius: 5px;
    //background:rgba(9, 19, 43, 0.6);
    background: rgba(247, 184, 68, 1);
    //color: rgba(255, 255, 255, 1.0);
    //color:rgba(247, 184, 68, 1.0);
    color: rgba(9, 19, 43, 0.8);
    text-align: center;

    display: none; /* hide by default */
  }
}

.itinerary-block-bottom {
  // background-color: #6EC6ED;
  background-color: white;
  width: 100%;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;

  .adventure-block-bottom-left {
  }

  .adventure-block-bottom-center {
    display: inline-block;
    color: $var-dark-green;
    text-align: center;
    font-size: 11px;
    width: 70%;

    .adv-blk-btm-season {
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 2px;
    }

    .adv-blk-btm-dur {
      font-weight: 700;
    }

    .adv-blk-btm-det {
      font-weight: 500;
    }
  }

  .adventure-block-bottom-right {
  }
}

.pie-chart-svg-outer {
  width: 15px;
  height: 15px;
  fill: black;
}

.pie-chart-svg {
  width: 15px;
  height: 15px;
}

.itinerary-piechart-wrapper {
  position: relative;
  cursor: default;
  width: 100% !important;
  height: 101px !important;
  cursor: pointer !important;
  margin-left: -25px;
  margin-right: -24px;
}

.itinerary-piechart-pie-outer-0 {
  // fill: rgba(145, 145, 150, 0.75);
  fill: rgba(9, 19, 43, 0.7);
  // fill: rgba(9, 19, 43, 0.6);;

  border: none;
  stroke: none !important;
}

.itinerary-piechart-pie-outer-1 {
  // fill: rgba(200, 200, 200, 0.5);
  fill: rgba(110, 198, 237, 0.7);
  // fill: #6ec6ed;

  border: none;
  stroke: none !important;
}

.itinerary-piechart-pie-inner-0 {
  // fill: rgba(9, 19, 43, 0.5);
  fill: rgba(218, 68, 49, 0.7);
  // fill: #da4431;
  border: none;
  stroke: none !important;
}

.itinerary-piechart-pie-inner-1 {
  // fill: #f7b844;
  // fill: rgba(15, 42, 54, 0.3);
  fill: rgba(247, 184, 64, 0.7);
  border: none;
  stroke: none !important;
}

.outer-label {
  fill: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.3);
}

.outer-label-minus {
  fill: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.3);
  transform: rotateX(20deg);
}

.itinerary-block-calendar-svg-outerlayer {
  fill: $var-dark-green;
  g {
    fill: $var-dark-green;
    svg {
      fill: $var-dark-green;
    }
  }
}

.itinerary--block--thumbs--wrapper {
  margin-bottom: 9px;
  display: flex;
  flex-direction: column-reverse;
  width: 33px;
  & > span {
    font-size: 12px;
    // color: rgba(255, 255, 255, 0.7);
    color: rgba(9, 19, 43, 0.6);
  }
  .itinerary--block--svg--outer {
    // fill: rgba(255, 255, 255, 0.7);
    fill: rgba(9, 19, 43, 0.6);
    width: 15px;
    height: 15px;
  }
}

.itinerary-block-pie-chart-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  .itinerary--block--charts--content--container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: -38px;
  }
  .itinerary--block--chart--label--container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column-reverse;
    margin-bottom: 5px;
    z-index: 5;
    padding-right: 2px;
    .itinerary--block--svg--outer {
      // fill: rgba(255, 255, 255, 0.7);
      fill: rgba(9, 19, 43, 0.6);
      width: 15px;
      height: 15px;
    }
    span {
      font-size: 12px;
      // color: rgba(255, 255, 255, 0.7);
      color: rgba(9, 19, 43, 0.6);
    }
  }
}

.left-thumb {
  margin-left: 30px;
}

.right-thumb {
  margin-right: 20px;
}

.default-notes-color {
  color: rgba(255, 255, 255, 0.4) !important;
}

.itinerary-block-calendar-svg-outerlayer {
  width: 70px;
  height: 70px;
  margin-bottom: 20px;
}
