// *{
//   border: solid 1px black
// }
@mixin trans($t: 0.5s, $tdelay: 0s) {
  transition: $t $tdelay;
}
@mixin flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin size($w, $h: $w) {
  width: $w;
  height: $h;
}

.carouselContainer {
  @include size(100%);
  display: flex;
  justify-content: center;
  position: relative;
}
.carouselArea {
  @include size(100%);
  white-space: nowrap;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  font-size: 0;
  margin: 0px 25px;
  > .carouselPosts {
    position: relative;
    transition: 0.5s;
    display: flex;
  }
}
.carouselPostBox {
  @include size(fit-content, 100%);
  min-width: 250px;
  max-width: 250px;
  vertical-align: middle;
  display: inline-block;
  box-sizing: border-box;
  align-self: center;
  margin: 0px 25px;
  @media (max-width: 420px) {
    width: 200px !important;
    max-width: 200px;
    min-width: 200px;
    margin: 0px 10px;
  }
  > .cover {
    @include size(100%);
    @include trans;
    background-position: center, center;
    background-size: cover;
    &:hover {
      transform: scale(1.1);
    }
  }
}
.controlLeft,
.controlRight {
  @include size(15px, 25px);
  @include flex_center;
  @include trans;
  border: none;
  border-radius: 50%;
  color: #273a07;
  font-size: 30px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  z-index: 9999;
  transform: translateY(-50%);
  &:hover {
    color: #1c1c1c;
    background-color: rgba(255, 255, 255, 0.8);
  }
}
.controlLeft {
  left: 0;
}
.controlRight {
  right: 0;
}

////// version 2
.contentBox {
  width: 100%;
  max-width: 400px;
  height: fit-content;
  display: block;
  align-items: center;
  background-color: #d5e3e7;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 15px;
  margin-bottom: 30px;
}
.cardBox {
  display: flex;
  .fontBox {
    font-family: 'Albiona';
    width: 100%;
    /* identical to box height */

    text-align: center;

    color: #273a07;
    .animate-container {
      position: relative;
      display: block;
      padding: 0px;
      overflow: hidden;
      .animate-body {
        position: relative;
        display: inline-block;
        animation: leftrightday 3s infinite alternate ease-in-out;
      }
    }
  }
  .titleStyle {
    font-family: 'Soleil';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 32px;
    /* identical to box height */

    text-align: center;

    color: #273a07;
  }
}

@keyframes leftrightday {
  0%,
  20% {
    transform: translateX(-100%);
    left: 100%;
  }
  80%,
  100% {
    transform: translateX(0%);
    left: 0%;
  }
}

.fader-day {
  position: absolute;
  top: 0;
  height: 100%;
  width: 0px;

  &.fader-left {
    left: 0;
  }

  &.fader-right {
    right: 0;
  }
}
