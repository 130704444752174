$var-color--light-orange: rgba(0, 0, 0, 0.4) !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;

.existing-trip-location-popup-wrapper {
  cursor: pointer;
  overflow: auto;
  transition: all 0.2s ease-out; // note that we're transitioning transform, not height!
  transform-origin: top;
  &--regular {
    // transform: scale(1);
  }
  &--full {
    width: 164px;
    height: 125px;
    // transform: scale(1);
  }
  .existing-trip-location-popup {
    width: 100%;

    &--top {
      height: 25%;
      .edit-location-block-button {
        width: 30px;
        height: 25px;
        background: transparent;
        border: none;
        outline: none;
        cursor: pointer;

        &:focus {
          outline: none;
          border: none;
        }
        .existing-location-popup-outer {
          min-width: 17px;
          width: 100%;
          height: 100%;
          cursor: pointer;

          g,
          path {
            fill: $var-color--light-orange;
          }
        }
      }
    }
    &--middle {
      height: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex-direction: column;
      .trip-location-block-pop-up {
        &--itinerary-item-header {
          font-weight: 700;
        }
        &--block-name {
        }
      }
    }
    &--down {
      height: 25%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .copy-to-library-button {
        width: 30px;
        height: 25px;
        background: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        &:focus {
          outline: none;
          border: none;
        }
        .existing-location-popup-outer {
          min-width: 17px;
          width: 100%;
          height: 100%;
          cursor: pointer;

          g,
          path {
            fill: $var-color--light-orange;
          }
        }
      }
      .existing-profile-location-profile-iamge {
        width: auto;
        height: 95%;
        border-radius: 50%;
        margin-right: 5px;
      }
    }
  }
}
