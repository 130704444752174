$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;

.coming_soon_components_page_title_wrapper {
  width: calc(100% - 20px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  height: auto;
  .coming_soon_components_page_title_padder {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    text-align: center;

    .page_title {
      &--coming-soon {
        color: $var-color--dark-orange;
        font-size: 18px;
      }
      &--title-text {
        font-size: 16px;
      }
    }
  }
}
