$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;

.custom-component-contents {
  p,
  h3,
  h2,
  li,
  strong,
  a {
    // color: $var-color--dar-blue;
    color: black;
  }
  a {
    &:hover {
      color: $var-color--light-blue;
    }
  }
  p,
  li,
  strong,
  a {
  }
}
