.qr-code-page-container {
  display: flex;
  justify-content: flex-start !important;
  align-items: center !important;
  flex-direction: column;
  text-align: center;
  height: max-content;
  padding-top: 40px;
  padding-bottom: 40px;
  overflow-y: auto;
  height: 100%;
  max-height: 100% !important;
  h4 {
    text-align: center;

    padding: auto 20px;
  }
}

.share-via-qr-copy-button {
  width: auto;
  height: auto;
  background-color: transparent;
  border: none;
  outline: none;

  &:focus {
    outline: none;
    border: none;
  }
  .copy-link-svg-outer {
    width: 25px;
    height: 25px;
    path {
      fill: #09132b;
    }
  }
  &:hover {
    .copy-link-svg-outer {
      path {
        fill: #f7b844;
      }
    }
  }
}

.sharvia-qr-copy-link-text {
  cursor: pointer;
  &:hover {
    color: #f7b844;
  }
}
