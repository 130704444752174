$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;
.account-profile-third-section-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 230px;
  // background-color: rgba(255, 255, 255, 0.4);
  border-radius: 10px;
  margin-top: 20px;
  .account-profile-third-section {
    &--left {
      width: 49.5%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.4);
      border-radius: 10px;
      .subscription-title-wrapper {
        justify-content: space-between;
        display: flex;
        width: calc(100% - 100px);
        padding: 10px 10px;
        margin: 0px 40px;
        padding-bottom: 2px;
        border-bottom: 2px solid rgba(255, 255, 255, 0.7);
        h4 {
          // width: 100%;
          color: white;
          margin: 10px 0px;
          margin-top: 5px;
          z-index: 1;
        }
        .subscription-edit-button-wrapper {
          width: 5%;
          margin-left: -5%;
          z-index: 2;
          .subscription-edit-svg-outer {
            fill: rgba(255, 255, 255, 0.4);
            width: 15px;
            height: 15px;
            cursor: pointer;
            &:hover {
              fill: $var-color--light-orange;
            }
          }
        }
      }
    }
    &--right {
      width: 49.5%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.4);
      border-radius: 10px;
      .subscription-title-wrapper {
        justify-content: space-between;
        display: flex;
        width: calc(100% - 100px);
        padding: 10px 10px;
        margin: 0px 40px;
        padding-bottom: 2px;
        border-bottom: 2px solid rgba(255, 255, 255, 0.7);
        h4 {
          // width: 100%;
          color: white;
          margin: 10px 0px;
          margin-top: 5px;
          z-index: 1;
        }
        .subscription-edit-button-wrapper {
          width: 5%;
          margin-left: -5%;
          z-index: 2;
          .subscription-edit-svg-outer {
            fill: rgba(255, 255, 255, 0.4);
            width: 15px;
            height: 15px;
            cursor: pointer;
            &:hover {
              fill: $var-color--light-orange;
            }
          }
        }
      }
    }
  }
}
