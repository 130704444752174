.accept-trip-invite-modal{
  .accept-trip-invite--form{
    height:414px;
    width: 100%;

    .accept-trip-invite-sections-container {
      height: 344px;
      width:100%;
      &::-webkit-scrollbar{
        display:none;
      }
      .accept-trip-invite-sections{
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 100%;
        overflow: hidden;
        scroll-snap-align: start;
        .accept-trip-invite-section-contents{
          height: calc(100% - 37px);
          width:100%;
          display:flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          // page - 1
          .accept-trip-invite-welcome-message-background{
            height: 100%;
            width: 95%;
            border-radius: 10px;
            display:flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .adventure-trip-invite-welcome-contents-wrapper{
              width: 90%;
              height: 90%;
              border: 3px solid white;
              border-radius:10px;
              background-color: rgba(255,255,255,0.7);
              display:flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;

              .adventure-trip-invite-invitor-profile-image-wrapper{
                width: 50px;
                height: 50px;
                .adventure-trip-invite-invitor-profile-image{
                  width:100%;
                  height:100%;
                  border-radius:50%;
                }
                
              }
              .adventure-trip-invite-message-wrapper{
                text-align: center;
                p{
                  text-align: center;
                }
              }
            }
          }
          // page - 2 selecting start dates
          .accept-trip-invite-select-starting-date-contents-wrapper{
            width:95%;
            height: 100%;
            display:flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            overflow-y: auto;
            &::-webkit-scrollbar{
              display:none;
            }

            .no-itineraries-message-wrapper{
              width:95%;
              height:100%;
              display:flex;
              justify-content: center;
              align-items: center;
              border-radius: 10px;
              text-align: center;
              &>p{
                color:white;
                text-align: center;
              }
            }
          }
          .accept-trip-invite-starting-point-contents-wrapper{
            width: 95%;
            height:100%;
            display:flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            .accept-trip-invite-add-locatin-block-button, .address-block-wrapper{
              width: 90%;
              height: 50px;
              margin-bottom: 5px;
              background-color: $var-color--button-unselected-blue;
              border-radius: 5px;
              border:none;
              outline:none;
              color:white;
              &:focus{
                border:none;
                outline:none;
              }
              &:hover{
                background-color: $var-color--light-blue;
              }
              &.selected{
                background-color: $var-color--light-blue;

              }
              
            }
          }
        }
      }
    }
  }
}