.assign-users--top {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  .assign-users--contents--wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .current-list-info--container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    color: white;

    .current-list-info {
      &--name {
        font-weight: 900;
        font-size: 18px;
      }
      &--description{
        font-size: 14px;
      }
    }
  }
}
