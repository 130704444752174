$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;

.subscription-plan-box-wrapper {
  width: calc(100% - 100px);
  margin: 10px 50px;
  .remove-top-border {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }
  .subscription-current-plan-indicator-box {
    width: 100%;
    height: 35px;
    background-color: #f7b844;
    display: flex;
    align-items: center;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
    span {
      font-weight: 700;
      margin-left: 10px;
      color: #273a07;
    }
  }
  .subscription-plan-box {
    // border: 2px solid $var-color--dar-blue;
    border-radius: 5px;
    height: 30vh;
    overflow: hidden;
    // background-color: $var-color--dar-blue;

    .subscription-plan-name-and-image-container {
      width: 100%;
      height: 25%;
      background-color: #273a07;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // @media screen and (max-width: $breakpoint-alpha) {
      //   height: 35%;
      // }
      .subscription-plan-name-wrapper {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        span {
          font-size: calc(1.5em + 1vmin);
          font-weight: 700;
          color: white;
          @media screen and (max-width: $breakpoint-alpha) {
            font-size: 1.5em;
          }
        }
      }
      .subscription-plan-image-wrapper {
        width: 60%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
        img.subscription-plan-image {
          height: auto;
          width: 80%;
        }
      }
    }
    .subscription-plan-detail-wrapper {
      width: 100%;
      margin: 0 auto;
      height: 55%;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      @media screen and (max-width: $breakpoint-alpha) {
        height: 35%;
      }
      // border-top: 1px solid rgba(0, 0, 0, 0.8);
      h2 {
        color: #273a07;
        @media screen and (max-width: $breakpoint-alpha) {
          font-size: small;
        }
      }
    }
    .subscription-plan-prices-wrapper {
      width: calc(100% - 4px);
      margin: 0 auto;
      background-color: white;
      height: calc(20% - 5px);
      margin-bottom: 2px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .subscription-plan-prices {
        margin-left: 20px;
        .bold-plan-price {
          font-weight: 700;
          font-size: 2em;
        }
      }
      .subscription-plan-buttons-wrapper {
        width: 40%;
        display: flex;
        justify-content: flex-end;
        .subscription-box-select-button,
        .subscription-box-select-button-placeholder {
          width: 100px;
          height: 40px;
          margin-right: 20px;
        }
        .subscription-box-select-button {
          background-color: #273a07;
          border-radius: 10px;
          border: none;
          outline: none;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            color: white;
          }
          &.cancel {
            text-align: center;
            span {
              text-align: center;
              color: white;
            }
          }
          &:hover {
            background-color: $var-color--light-blue;
          }
        }
      }
      // border-top: 1px solid rgba(0, 0, 0, 0.8);
      @media screen and (max-width: $breakpoint-alpha) {
        flex-direction: column;
        height: max-content;
        padding-bottom: 20px;
        .subscription-plan-buttons-wrapper {
          width: 100%;
          justify-content: center;
          & > a {
            margin-right: 0px;
            margin: 10px;
          }
        }
        // padding-bottom: 40px;
      }
    }
    .subscription-box-borders-wrapper {
      width: calc(100% - 4px);
      margin: 0 auto;
      background-color: white;
      .subscription-box-borders {
        width: 95%;
        margin: 0 auto;
      }
    }
  }
}
