.my-impact-summary-body {
  height: $fullBodyHeight;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
}

.circle-container {
  transition: all 0.2s east-in-out;
  &:hover {
    transform: scale(1.4);
    z-index: 100;
  }
}
///-status
$col-text: #686e74;
$shadow-col: #aac1c7;

$section-width: 80vw;
$section-max-width: 64rem;

$card-width: 19rem;
$card-height: 22.5rem;

$to-smaller: 'screen and (max-width : 620px)';
$to-vertical: 'screen and (max-width : 480px)';

@mixin flex-center() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-bottom-center() {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.impact-status-wrapper {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 6rem;
  html,
  body {
    font-size: 62.5%;
    font-family: 'Raleway', sans-serif;

    @media #{$to-smaller} {
      font-size: 45%;
    }

    @media #{$to-vertical} {
      font-size: 70%;
    }
  }

  .forhtml {
    background: radial-gradient(circle at bottom, #aac1c7, #f7fbfb 80%)
      no-repeat center center fixed;
    background-size: cover;
  }

  section {
    width: $section-width;
    max-width: $section-max-width;
    min-width: $card-width * 3.1;

    @media #{$to-vertical} {
      min-width: $card-width;
    }
  }

  .title {
    font-size: 4rem;
    font-weight: 900;
    padding-top: 10vh;

    @media #{$to-vertical} {
      font-size: 3rem;
      text-align: center;
      position: relative;
      padding-top: 6vh;
    }

    &:after {
      content: '';
      display: block;
      height: 0.3rem;
      width: 5.4rem;

      background-color: #fbcd8a;
      transform: translate(0.3rem, 2rem);

      @media #{$to-vertical} {
        left: 50%;
        position: absolute;
        transform: translate(-50%, 2rem);
      }
    }
  }

  .cards {
    padding-top: 6rem;

    display: flex;
    justify-content: space-between;

    @media #{$to-vertical} {
      flex-direction: column;
      align-items: center;
    }
  }

  .impactCard {
    width: $card-width;
    height: $card-height;

    background-color: #f9fbfb;
    border-radius: 6px;

    position: relative;
    display: flex;
    flex-direction: column;

    color: #5a6c7a;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 0.05rem;

    box-shadow: 0 1rem 3rem $shadow-col;

    @media #{$to-vertical} {
      margin-bottom: 2rem;
    }

    &__svg-container {
      height: 50%;
      @include flex-bottom-center();
    }

    &__svg-wrapper {
      width: 8rem;
    }

    &__count-container {
      flex-grow: 1;
      @include flex-center();
    }

    &__count-text {
      transform: translate(0, -0.5rem);

      &--big {
        text-transform: uppercase;
        font-size: 5rem;
        font-weight: 300;
      }
    }

    &__stuff-container {
      margin: 0 auto;
      width: 90%;
      height: 15%;
      border-top: 2px solid #e7edef;
      @include flex-center();
    }

    &__stuff-icon,
    &__stuff-text {
      display: inline-block;
    }

    &__stuff-icon {
      width: 1.3rem;
      height: 1.3rem;
      transform: translate(0, -0.3rem);
    }

    &__stuff-text {
      text-transform: uppercase;
      margin-left: 0.4rem;
      transform: translate(0, -0.2rem);
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: $card-width - 3rem;
      height: $card-height / 4;
      transform: translate(1.5rem, $card-height);
      pointer-events: none;
    }

    &--oil {
      &:after {
        background-image: radial-gradient(
          ellipse at top,
          rgba(106, 122, 135, 0.5),
          transparent 70%
        );
      }
    }

    &--tree {
      &:after {
        background-image: radial-gradient(
          ellipse at top,
          rgba(106, 191, 96, 0.5),
          transparent 70%
        );
      }
    }

    &--water {
      &:after {
        background-image: radial-gradient(
          ellipse at top,
          rgba(96, 203, 231, 0.5),
          transparent 70%
        );
      }
    }
  }
}

.impact-status-wrapper2 {
  .container {
    padding: 50px 0;
    text-align: center;
  }

  .chart {
    position: relative;
    display: inline-block;
    color: #999;
    font-size: 20px;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 400px;
    height: 400px;
  }

  #info1 {
    width: 500px;
    height: 500px;
    position: relative;
    margin: auto;
    margin-top: 100px;
  }
  #info1.pessoa {
    width: 10%;
  }
  #info1.pessoa img {
    width: 100%;
  }

  /*START - GRAFICO COLUNA*/
  .column-graph {
    width: 100%;
    max-width: 940px;
    margin: auto;
    position: relative;
    padding-top: 30px;
  }
  .column-graph .esq {
    border: none;
    position: absolute;
    left: 40px;
    border-left: 2px solid #fff;
    height: calc(100% + 50px);
    width: 2px;
    top: 0;
  }

  .column-graph .bot {
    border: none;
    position: absolute;
    left: 0px;
    bottom: -30px;
    border-bottom: 2px solid #fff;
    height: 2px;
    width: calc(100%);
  }

  .column {
    height: 50px;
    margin-left: 42px;
    margin-top: 10px;
    position: relative;
    margin-bottom: 10px;
    font-family: arial;
  }

  .gringo {
    background-color: #256f8d;
  }

  .nac {
    background-color: #f08418;
  }

  /*CHILE*/

  #chile {
    width: 1%;
    -webkit-animation: chile 2s;
    animation: chile 2s;
    animation-fill-mode: forwards;
  }

  #chile:after {
    content: ' 10 ';
    position: absolute;
    right: -24px;
    color: #fff;
    top: 10px;
    opacity: 0;
    -webkit-animation: see 2s;
    animation: see 2s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
  }

  #chile:before {
    content: 'Chile';
    position: absolute;
    left: -45px;
    color: #fff;
    top: 12px;
    opacity: 0;
    -webkit-animation: see 2s;
    animation: see 2s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
  }

  /*URUGUAI*/
  #uruguai {
    width: 5%;
    -webkit-animation: uruguai 2s;
    animation: uruguai 2s;
    animation-fill-mode: forwards;
  }

  #uruguai:after {
    content: ' 44 ';
    position: absolute;
    right: -24px;
    color: #fff;
    top: 10px;
    opacity: 0;
    -webkit-animation: see 2s;
    animation: see 2s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
  }

  #uruguai:before {
    content: 'Uruguai';
    position: absolute;
    left: -65px;
    color: #fff;
    top: 12px;
    opacity: 0;
    -webkit-animation: see 2s;
    animation: see 2s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
  }

  /*ARGENTINA*/
  #argentina {
    width: 8%;
    -webkit-animation: argentina 2s;
    animation: argentina 2s;
    animation-fill-mode: forwards;
  }

  #argentina:after {
    content: ' 110 ';
    position: absolute;
    right: -30px;
    color: #fff;
    top: 10px;
    opacity: 0;
    -webkit-animation: see 2s;
    animation: see 2s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
  }

  #argentina:before {
    content: 'Argentina';
    position: absolute;
    left: -80px;
    float: left;
    color: #fff;
    top: 12px;
    opacity: 0;
    -webkit-animation: see 2s;
    animation: see 2s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
  }

  /*EUA*/
  #eua {
    width: 50%;
    -webkit-animation: eua 2s;
    animation: eua 2s;
    animation-fill-mode: forwards;
  }

  #eua:after {
    content: ' 1243 ';
    position: absolute;
    right: 10px;
    color: #fff;
    top: 10px;
    opacity: 0;
    -webkit-animation: see 2s;
    animation: see 2s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
  }

  #eua:before {
    content: 'EUA';
    position: absolute;
    left: -46px;
    float: left;
    color: #fff;
    top: 12px;
    opacity: 0;
    -webkit-animation: see 2s;
    animation: see 2s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
  }

  /*BRASIL*/
  .nac {
    width: 85%;
    -webkit-animation: br 2s;
    animation: br 2s;
    animation-fill-mode: forwards;
  }

  .nac:after {
    content: ' 2077 ';
    position: absolute;
    right: 10px;
    color: #000;
    top: 10px;
    opacity: 0;
    -webkit-animation: see 2s;
    animation: see 2s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
  }

  .nac:before {
    content: 'Brasil';
    position: absolute;
    left: -51px;
    float: left;
    color: #fff;
    top: 12px;
    opacity: 0;
    -webkit-animation: see 2s;
    animation: see 2s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
  }

  /*CANADA*/
  #canada {
    width: 95%;
    -webkit-animation: canada 2s;
    animation: canada 2s;
    animation-fill-mode: forwards;
  }

  #canada:after {
    content: ' 2374 ';
    position: absolute;
    right: 10px;
    color: #fff;
    top: 10px;
    opacity: 0;
    -webkit-animation: see 2s;
    animation: see 2s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
  }

  #canada:before {
    content: 'Canada';
    position: absolute;
    left: -67px;
    float: left;
    color: #fff;
    top: 12px;
    opacity: 0;
    -webkit-animation: see 2s;
    animation: see 2s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
  }

  /*END - GRAFICO COLUNA*/

  .chart img {
    position: absolute;
    max-width: 100px;
    max-height: 100px;
    background: white;
  }
  /* END Container styles */

  /* Colors for the circles and positions for the graphics */
  .html {
    top: 50px;
    left: 45px;
  }

  svg .inner {
    stroke: #256f8d;
    fill: none;
    stroke-width: 10;
  }

  .html + svg .outer {
    stroke: #e34f26;
  }

  .css + svg .outer {
    stroke: #0d84ce;
  }

  .javascript {
    max-width: 90px;
    max-height: 90px;
    top: 45px;
    left: 45px;
  }

  .javascript + svg .outer {
    stroke: #f0e040;
  }

  .node {
    top: 45px;
    left: 45px;
  }

  .node + svg .outer {
    stroke: #83cd29;
  }

  .chart svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  #circle:after {
    content: 'Brasil';
    position: absolute;

    opacity: 0;

    border-right: 3px solid #f08418;
    bottom: 25px;
    left: -40px;
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 50px;
    font-size: 20px;
    color: #fff;
    border-bottom: 3px solid #f08418;

    -webkit-animation: see 2s;
    animation: see 2s;
    animation-delay: 1.5s;
    animation-fill-mode: forwards;
  }
  #pizza:after {
    content: '5%';
    position: absolute;
    opacity: 0;
    /* border-right: 3px solid #F08418; */
    /* bottom: 25px; */
    top: -113px;
    /* left: -32px; */
    right: 52px;
    padding: 10px;
    padding-bottom: 0;
    padding-left: 30px;
    padding-right: 10px;
    padding-top: 50px;
    font-size: 49px;
    color: #fff;
    border-bottom: 3px solid #f08418;
    -webkit-animation: see 2s;
    animation: see 2s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
  }
  #pizza:before {
    content: ' ';
    position: absolute;
    opacity: 0;
    top: -5px;
    right: 157px;
    /* width: 1px; */
    height: 26px;
    border-right: 3px solid #f08418;
    -webkit-animation: see 2s;
    animation: see 2s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
  }

  .chart figcaption {
    width: calc(80% - 10px);
    height: calc(80% - 10px);
    border-radius: 100%;
    position: absolute;
    z-index: 1;
    left: calc(50% - (80% / 2) - -7px);
    top: calc(50% - (80% / 2) - 13px);
    -webkit-animation: see 1s;
    animation: see 1s;
  }
  .chart figcaption h2 {
    font-family: arial;
    font-weight: lighter;
    font-size: 80px;
    color: #fff;
    margin-bottom: 10px;
  }
  #pizza {
    top: 50px;
  }

  #pizza figcaption h2 {
    font-family: arial;
    font-weight: lighter;
    font-size: 30px;
    color: #fff;
    margin-bottom: 10px;
    font-size: 50px;
    text-align: left;
    margin-top: 100px;
    padding-left: 50px;
  }

  .chart figcaption p {
    color: #fff;
    max-width: 40%;
    margin: auto;
    font-size: 20px;
  }

  .outer {
    fill: transparent;
    stroke: #f08418;
    stroke-width: 25;
    stroke-dasharray: 251%;
    -webkit-animation-play-state: running;
    /* firefox bug fix - won't rotate at 90deg angles */
    -moz-transform: rotate(-89deg) translateX(-190px);
  }

  .pizza {
    stroke-dashoffset: -270%;
    stroke-width: 80%;
  }

  /* END Circle colors and graphic positions */

  /* Set the initial values for the animation */

  .chart[data-percent='75'] .outer {
    stroke-dashoffset: -100%;
    -webkit-animation: show75 2s;
    animation: show75 2s;
  }

  .chart[data-percent='pizza'] .outer {
    stroke-dashoffset: -262%;
    -webkit-animation: pizza 1s;
    animation: pizza 1s;
  }

  /* END set initial animation values */

  /* Keyframes for the initial animation */

  @-webkit-keyframes show75 {
    from {
      stroke-dashoffset: -251%;
    }

    to {
      stroke-dashoffset: -100%;
    }
  }

  @keyframes show75 {
    from {
      stroke-dashoffset: -251%;
    }

    to {
      stroke-dashoffset: -100%;
    }
  }

  @-webkit-keyframes canada {
    from {
      width: 0;
    }

    to {
      width: 95%;
    }
  }

  @keyframes canada {
    from {
      width: 0;
    }

    to {
      width: 95%;
    }
  }

  @-webkit-keyframes eua {
    from {
      width: 0;
    }

    to {
      width: 50%;
    }
  }

  @keyframes eua {
    from {
      width: 0;
    }

    to {
      width: 50%;
    }
  }

  @-webkit-keyframes br {
    from {
      width: 0;
    }

    to {
      width: 85%;
    }
  }

  @keyframes br {
    from {
      width: 0;
    }

    to {
      width: 85%;
    }
  }

  @-webkit-keyframes argentina {
    from {
      width: 0;
    }

    to {
      width: 8%;
    }
  }

  @keyframes argentina {
    from {
      width: 0;
    }

    to {
      width: 8%;
    }
  }

  @-webkit-keyframes chile {
    from {
      width: 0;
    }

    to {
      width: 1%;
    }
  }

  @keyframes chile {
    from {
      width: 0;
    }

    to {
      width: 1%;
    }
  }
  @-webkit-keyframes uruguai {
    from {
      width: 0;
    }

    to {
      width: 5%;
    }
  }

  @keyframes uruguai {
    from {
      width: 0;
    }

    to {
      width: 5%;
    }
  }

  @-webkit-keyframes pizza {
    from {
      stroke-dashoffset: -251%;
    }

    to {
      stroke-dashoffset: -262%;
    }
  }

  @keyframes pizza {
    from {
      stroke-dashoffset: -251%;
    }

    to {
      stroke-dashoffset: -262%;
    }
  }

  @keyframes see {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}
