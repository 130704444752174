.comingsoon-modules-banner-wrapper {
  width: calc(100% - 20px);
  padding: 0 10px;
  height: auto;
  display: flex;
  justify-content: center;
  .comingsoon-module-banner-padder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-position: center;
  }
}
