.hub-checkout-page {
  max-width: 770px !important;
  .page-white-background {
    border: 2px solid white;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.4);
    width: calc(100% - 14px);
    min-height: calc(100vh - 71px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    &.paypal-checkout {
      min-height: calc(100vh - 236px);
      height: max-content;
    }
  }
  .checkout-paypal-buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    & > div {
      width: 100%;
    }
  }
}

.impact-transaction-complete-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 20vh;
  .impact-transaction-complete {
    &.top {
      & > h1 {
        font-size: 2em;
        text-align: center;
      }
    }
    &.bottom {
      text-align: center;

      & > p {
        font-size: 1.4em;
        text-align: center;
      }
    }
  }
}