$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;

.error-boundary-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .error-boundary-navbar {
    width: 100vw;
    display: flex;
    height: 71px;
    justify-content: flex-start;
    align-items: center;
    .error-boundary-logo-image {
      width: 100px;
      height: 90%;
      object-fit: contain;
    }
  }
  .error-boundary-contents {
    height: calc(100vh - 71px);
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-image: url('./errorBoundaryBackground.png');
    .error-boundary-contents-top {
      width: 100%;
      max-width: 1440px;
      height: 81%;
      margin-top: 3%;
      margin-bottom: 3%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.7);
      border-radius: 10px;
      text-align: center;
      .error-boundary-contents-buttons-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        text-align: center;
        button {
          width: 200px;
          margin: 10px;
          background: $var-color--dar-blue;
          border: none;
          border-radius: 10px;
          height: 35px;
          color: white;
        }
      }
    }
    .error-boundary-contents-bottom {
      width: 100%;
      max-width: 1440px;
      height: 10%;
      margin-bottom: 3%;
      background-color: rgba(255, 255, 255, 0.7);
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
    }
  }
}
