$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;
.impact-module--wrapper {
  display: flex;
  flex-direction: column;
  // height: 380px;
  width: 470px;
  margin-top: 10px;
  border-radius: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  .impact-module-box {
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    @media screen and (max-width: 410px) {
      .impact-button-svg-outer {
        width: 25px !important;
        height: 25px !important;
      }
      .button-content {
        & > span {
          font-size: 0.7em !important;
        }
      }

      .fs-20 {
        font-size: 0.9em !important;
      }
    }
    &--top {
      height: 40px;
      // background-color: $var-color--light-blue;
      background-color: $var-dark-green;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      color: white;
      font-weight: 700;
    }
    &--bottom {
      // background-color: $var-color--default-buttons;
      // background-color: #484d5c;
      background-color: #ffff;
      height: calc(100% - 30px);
      display: flex;
      flex-direction: column;
      .impact-module-bottom-content {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        &--info {
          height: calc(78% - 40px);
          padding: 20px 0px;
          .impact-module-bottom-content-info {
            width: 100%;
            height: 100%;
            .impact-module-headers {
              &--left {
                float: right;
              }
              height: calc(100% / 8);
              &--text--white {
                color: white;
                font-size: 14px;
                font-weight: 700;
                &.--activities {
                  width: 100%;
                  // white-space: nowrap;
                  // overflow: hidden;
                  height: max-content;
                  // text-overflow: ellipsis;
                }
              }
              &--dark-green {
                color: $var-dark-green;
                font-size: 14px;
                font-weight: 700;
              }
              &--text--blue {
                color: $var-color--light-blue;
                font-size: 14px;
                font-weight: 700;
              }
            }
            &--info-left {
              // width: 50%;
              display: flex;
              flex-direction: row;
              .impact-module-info-left {
                .impact-module-info-item {
                  width: 100%;
                  display: flex;
                  .impact-module-info-item-right {
                    width: 30%;
                  }
                  .impact-module-info-item-left {
                    width: 70%;
                  }
                }
                &--info-left-symbol {
                  width: 30%;
                  height: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  .impact-svg-outer {
                    width: 100px;
                    height: 100px;
                    path {
                      fill: $var-dark-green;
                    }
                  }
                }
                &--info-left-headers {
                  width: calc(70% - 2px);
                  height: calc(100% - 8px);
                  margin: 4px 0px;
                  padding-right: 4px;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  align-items: flex-end;
                  // border-right: 2px solid rgba(255, 255, 255, 0.4);
                }
              }
            }
            &--info-right {
              width: 50%;
              display: flex;
              height: calc(100% - 8px);

              margin: 4px 0px;
              flex-direction: column;
              justify-content: space-between;
              align-items: flex-start;
              .impact-module-headers {
                &-text--white {
                }
                &-text--blue {
                }
              }
            }
          }
        }
        &--button-group {
          display: flex;
          height: 80px;
          padding: 0px 2px;
          padding-bottom: 10px;
          // background-color: yellow;
          justify-content: space-evenly;
          button {
            background-color: $var-dark-green;
            border: none;
            border-radius: 5px;
            width: 30%;
            height: 40px;
            display: flex;
            align-items: center;
            cursor: pointer;

            &:hover {
              background-color: #80aea8;
            }
            &:disabled {
              background-color: lightgray;
              cursor: not-allowed;
              &:hover {
                background-color: lightgray;
              }
            }
            .button-svg-wrapper {
              width: 40%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              .impact-button-svg-outer {
                width: 30px;
                height: 30px;
                path {
                  fill: white;
                }
              }
            }
            .button-content {
              display: flex;
              width: 60%;
              height: 100%;
              align-items: center;
              justify-content: center;
              color: white;
            }
          }
        }
      }
    }
  }
}
.impact-donations {
  display: flex;
  flex-direction: column;
  background-color: $var-dark-green;
  .impact-donations-item-svg {
    display: flex;
    margin: auto 10px;
    .impact-button-svg-outer {
      margin: auto 0px;
      width: 30px;
      height: 30px;
      path {
        fill: #cea502;
      }
    }
  }
  .impact-donation-domain-link {
    &:hover {
      color: #cea502;
      text-decoration: underline;
    }
  }
  .impact-donation-price {
    margin: auto 10px;
  }
  .impact-donations--item {
    display: flex;
    justify-content: space-evenly;
    margin: 10px 0px;
  }
  .impact-donations--separation {
    border-bottom: 1px solid #827700;
  }
}
