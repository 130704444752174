$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;

.loader {
  border: 8px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 8px solid #273a07;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  margin: auto;
  &:after {
    background-color: rgba(0, 0, 0, 0) !important;
  }
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.mapboxgl-ctrl-top-left {
  top: 25px !important;
}

.mapboxgl-ctrl-geocoder--icon-search {
  width: 20px;
  height: 20px;
  top: 8px;
  left: 7px;
}

.mapboxgl-ctrl-geocoder--input {
  height: 36px;
}

.library-page-contents--wrapper {
  height: 100%;
  touch-action: none;
}
.library-second-section-wrapper {
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
  button {
    width: 70px;
    height: 25px;
    border: none;
    outline: none;
    border-radius: 5px;
    background: $var-color--light-orange;
    margin-left: 10px;
    cursor: pointer;
    &:hover {
      background: $var-color--light-blue;
    }
  }
}
.library-page-template-button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  .button {
    width: 150px;
    height: 25px;
    background-color: $var-dark-green;
    border: none;
    outline: none;
    cursor: pointer;
    color: white;
    font-size: 0.8em;
    // border: 1px solid $var-color--light-orange;
    // border: 1px solid $var-color--dar-blue;
    margin: 0px 4px;

    border-radius: 5px;
    &--selected {
      // background-color: $var-color--light-orange;
      // color: $var-color--dar-blue;
      border: none;
      background-color: #d18000;
      color: $var-dark-green;
    }
  }
}
.library-third-section-wrapper {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  button {
    width: 150px;
    height: 30px;
    border: none;
    outline: none;
    border-radius: 10px;
    background: $var-color--light-orange;
  }
}

.library-section-title {
  width: 0px;
  height: 92%;
  display: flex;
  align-items: center;
  border-right: 2px solid $var-dark-green;
  padding-right: 2px;

  &--activities {
    & > h3 {
      margin-top: 118px;
      color: $var-dark-green;
    }
  }
  &--lists {
    & > h3 {
      margin-top: 69px;
      color: $var-dark-green;
    }
  }
  .library-section-title-h3 {
    background: none;
    // transform-origin: 0 0;
    transform: rotate(-90deg);
    color: $var-dark-green;

    width: 100%;
    margin-left: -8px;
  }
}

.library-adventure-select-dropdown-parent {
  max-width: 604px;
  margin: 0 !important;
  .library-adventure-select-dropdown-child {
    background-color: #ffff !important;
    color: $var-dark-green !important;
    & > div > span {
      color: $var-dark-green !important;
    }
    .react-dropdown-select-dropdown {
      // background-color: $var-color--dar-blue !important;
      background-color: #ffff;
      border-radius: 10px;
      z-index: 2000;
      &::-webkit-scrollbar {
        display: none;
      }
      span {
        border-bottom: none;
      }
    }
    input::placeholder,
    input {
      color: $var-dark-green;
    }
  }
}

.library-buttons-container {
  width: 100%;
  display: flex;
  justify-content: center;
  .button-container-rows {
    display: flex;
    align-items: center;
    &--small {
      .button {
        height: 25px !important;
      }
    }
    .button {
      width: 150px;
      height: 25px;
      background-color: $var-dark-green;
      border: none;
      outline: none;
      cursor: pointer;
      color: white;
      font-size: 0.8em;
      // border: 1px solid $var-color--light-orange;
      // border: 1px solid $var-color--dar-blue;
      border: none;
      margin: 0px 4px;
      margin-top: 10px;

      border-radius: 5px;
      &--selected {
        // background-color: $var-color--light-orange;
        // color: $var-color--dar-blue;
        border: none;
        background-color: #d18000;
        color: $var-dark-green;
      }
      &--activities-lists {
        // font-size: 0.3em;
      }
    }
  }
}

.library-map-parent {
  width: calc(100% - 20px);
  height: calc(100% - 230px);
  margin: auto;
}

.extra-margin-for-button {
  margin-left: 33px;
}
.itinerary_adventure_block_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  .header {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px;
    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
    }
    .library-section-title-h3 {
      color: #273a07;
      font-size: 1em !important;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
}
.backgound-bg-mild {
  background-color: #dfd5c1;
}
.itinerary_adventure_block_bottom {
  width: 100%;
  height: 142px;
  padding: 0px 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  text-align: start;
  align-items: center;

  &::-webkit-scrollbar {
    display: none;
  }
}

.scroll-indicator-wrapper {
  width: 97%;
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  // margin-top: -16px;
  z-index: 0;
  height: 30px;
  align-items: flex-end;
  &--library-v2 {
    width: 93% !important;
    & > div {
      width: 100% !important;

      & > div {
        width: 100% !important;
      }
    }
  }
  &--library-v2-wrapper {
    border-bottom: 2px solid #264562;
    margin-top: -18px;
    width: 93% !important;
  }
  &--itinerary-trip-board,
  &--itinerary-trip-board-placeholder {
    margin-top: -27px !important;
  }
  .scroll-indicator-parent {
    width: 150px;
    &--itinerary-trip-board {
      width: 100% !important;
      & > div {
        width: 100% !important;
      }
    }
  }
}
