// 1/10/22 Paul Choi - updated file structure

@import '_accept_trip_invite.scss';
@import '_account_group.scss';
@import '_account.scss';
@import '_adventure_group.scss';
@import '_alert_message.scss';
@import '_assign_user.scss';
@import '_chat_input_styles.scss';
@import '_chat_message_styles.scss';
@import '_chat.scss';
@import '_checklist_comments_styles.scss';
@import '_checklist_dnd_tree_styles.scss';
@import '_checklist_entries_styles.scss';
@import '_checklist_list_form_styles.scss';
@import '_checklist.scss';
@import '_contacts.scss';
@import '_copy_adventure.scss';
@import '_copy_item.scss';
@import '_create_adventure_v2.scss';
@import '_create_adventure.scss';
@import '_create_edit_block_comments_styles.scss';
@import '_create_edit_block_itinerary_item_styles.scss';
@import '_create_edit_block_module_styles.scss';
@import '_create_edit_block_responsive_styles.scss';
@import '_create_edit_block_styles.scss';
@import '_create_edit_folder_responsive_styles.scss';
@import '_create_edit_folder.scss';
@import '_create_edit_inspiration.scss';
@import '_create_edit_view_block_file.scss';
@import '_create_itinerary_item.scss';
@import '_create_itinerary.scss';
@import '_delete_modal.scss';
@import '_edit_account_explorer.scss';
@import '_edit_profile_image.scss';
@import '_fetch_image_unsplash.scss';
@import '_image_op.scss';
@import '_invite_adventure_friends.scss';
@import '_invite_friends_to_adventure.scss';
@import '_invite_member_to_account.scss';
@import '_invite_new_members.scss';
@import '_layout.scss';
@import '_map_modal.scss';
@import '_modal_responsive_styles.scss';
@import '_modal_snap_scroller_styles.scss';
@import '_modal_styles.scss';
@import '_pick_date.scss';
@import '_pick_time.scss';
@import '_share_modal.scss';
@import 'loading';
@import 'no_trip_data_animation';

// 01/16/22 Add frame for Jacob's practice --
@import '_itinerary_daily_log';
.snap-scroll-container {
  overflow-y: auto;
  max-height: 100%;
  scroll-snap-points-y: repeat(350px);
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}
// 02/21/22 adding styles for flights
@import '_create_edit_block_flights_styles';
