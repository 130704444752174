$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;

.getting-started-sections {
  overflow: hidden;
  transition: all 0.3s ease-out;
  width: 90%;
  border-radius: 10px;
  margin: 10px 0px;
  background-color: rgba(255, 255, 255, 0.8);
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
  .arrow-wrapper {
    width: 10%;
    margin-right: -10%;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  .arrow {
    display: inline-block;
    color: $var-color--light-blue;
    font-size: 30px;
  }
  &--closed {
    .arrow {
      transform: rotate(90deg);
      transition: all 0.3s ease-out;
    }
  }
  &--opened {
    .arrow {
      color: $var-color--light-orange;
      transform: rotate(270deg);
      transition: all 0.3s ease-out;
    }
  }
  .getting-started-title-wrapper {
    display: flex;

    height: 70px;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    .title-wrapper {
      width: 100%;
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
      .title {
        font-size: 1.5em;
        font-weight: 700;
        letter-spacing: 1.2px;
      }
    }
  }
  .getting-started-list-content-wrapper {
    transition: all 0.4s linear;
    display: flex;
    flex: 1;
    overflow: hidden;
    width: calc(100% - 20px);
    padding-left: 20px;
    flex-direction: column;
    height: auto;

    ol {
      li,
      span {
        margin: 5px 0px;
        line-height: 1.5em;
        letter-spacing: 1.2px;
        font-size: 1.4em;
      }
    }
    ul {
      li,
      span {
        margin: 5px 0px;
        line-height: 1.5em;
        letter-spacing: 1.2px;
        font-size: 1.4em;
      }
    }
    &--closed {
      // height: 0px;
      flex: 0;
      height: 0px;
    }
    &--opened {
      // height: fit-content;
    }
    .view-video-button-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 10px;

      .view-video-button {
        width: 120px;
        height: 30px;
        border: none;
        border-radius: 5px;
        background-color: $var-color--light-orange;
        display: flex;
        justify-content: center;
        cursor: pointer;
        align-items: center;
        &:hover {
          background: $var-color--light-blue;
        }
        span {
          color: $var-color--dar-blue;
        }
      }
    }
  }
}

.getting-started-external-link {
  color: red;
  margin-left: 15px;
  .app-install-link {
    cursor: pointer;
    &:hover {
      color: #f7b844;
    }
  }
}
