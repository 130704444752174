// $cardColor: linear-gradient(-125deg, #878d98, #535a6b);;
$cardColor: #264662;
$cardColorUnread: #507a9d;
$optionsColor: #999;
$time: #9ea3ab;
$cardHover: #ebedf0;

.hub-notifications-card-link {
  text-decoration: none;
  color: $optionsColor;
}

.unread-notification-card {
  background: $cardColor;
}

.hub-notifications-card {
  display: flex;
  border-bottom: 1px solid rgba(153, 153, 153, 0.3);
  justify-content: space-between;
  background: $cardColor;
  font-size: 12px;
  cursor: pointer;
  padding: 6px;
  margin: 4px 2px;
  border-radius: 10px;
  height: 60px;
  &:hover {
    // background-color: $cardHover;
  }

  .hub-notifications-content {
    display: flex;
    color: black;
    align-items: center;

    .hub-notifications-message {
      margin: 0 8px;
      color: white;
    }

    .hub-notifications-time {
      color: $optionsColor;
    }
  }

  .hub-notifications-image {
    img {
      height: 40px;
      border-radius: 50%;
    }

    margin: 5px;
  }

  .hub-notifications-options {
    visibility: hidden;
    text-align: center;
    font-size: 20px;

    .hub-notifications-option {
      cursor: pointer;
      opacity: 0.5;
      color: $optionsColor;

      &:hover {
        opacity: 1;
      }
    }
  }

  &:hover {
    .hub-notifications-options {
      visibility: visible;
    }
  }
}
.uread-notification-card {
  background: $cardColorUnread;
}
