@media screen and (max-width: 480px) {
  #mobile-adjusted-plan {
    text-align: center;
  }
  .top-section-second > h1 {
    text-align: center;
  }
  .footer-sticker-svg-outer {
    width: 40px;
    height: 40px;
  }

  .footer-sticker-svg {
    width: 40px;
    height: 40px;
  }
  .footer-icons-wrapper {
    display: flex;
    width: auto;
    justify-content: center;
    margin-right: 26px;
    // margin-top: -23px;
    margin-top: 0px;
    margin-left: 20px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 640px) {
}
// @media screen and (min-width: 641px) and (max-width: 840px){
//  #mobile-adjusted-banner{
//     .banner-lading-header{
//       font-size: 30px;
//       text-align: center;
//        top: 42%;
//     left: 15%;
//     }
//   }
// }

@media screen and (min-width: 840px) and (max-width: 1080px) {
  #mobile-adjusted-banner {
    .banner-lading-header {
      font-size: 36px;
      text-align: center;
      top: 37%;
      left: 9%;
    }
  }
}

@media screen and (min-width: 1081px) and (max-width: 1240px) {
  #mobile-adjusted-banner {
    .banner-lading-header {
      font-size: 40px;
      text-align: center;
      top: 35%;
      left: 17%;
    }
  }
}
@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 1440px) {
}
@media screen and (min-width: 1441px) {
  .banner-container {
    width: 100vw;
    img {
      width: 100%;
    }
  }
  .banner-regular-container {
    width: 100vw;
    img {
      width: 100%;
    }
  }
}
