.mod-cnt-def-pickdate {
  height: 420px;
  max-height: 465px;
  // background-color: #757a86;
  background-color: #364301 ;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.disable-max-width {
  max-width: 726px !important;
}
.modal-date-range-picker-wrapper {
  width: 98%;
}
