.ft {
  &-SoleilBold {
    font-family: $soleilBold;
  }
  &-SoleilSemiBold {
    font-family: $soleilSemibold;
  }
  &-AlbionaLight {
    font-family: $albionaLight;
  }
  &-AlbionaExtraLight {
    font-family: $albionaExtralight;
  }
}

.fd {
  &-buttonify {
    cursor: pointer;
  }
}

.fs {
  &-bannerBig {
    font-size: 62px;
  }
  &-bannerMed {
    font-size: 50px;
  }
  &-contentHeader {
    font-size: 32px;
  }
  &-contentDescription {
    font-size: 16px;
  }
  &-10 {
    font-size: 10px;
  }
  &-11 {
    font-size: 11px;
  }
  &-12 {
    font-size: 12px;
  }
  &-13 {
    font-size: 13px;
  }
  &-14 {
    font-size: 14px;
  }
  &-15 {
    font-size: 15px;
  }
  &-16 {
    font-size: 16px;
  }
  &-17 {
    font-size: 17px;
  }
  &-18 {
    font-size: 18px;
  }
  &-19 {
    font-size: 19px;
  }
  &-20 {
    font-size: 20px;
  }
  &-21 {
    font-size: 21px;
  }
  &-22 {
    font-size: 22px;
  }
  &-23 {
    font-size: 23px;
  }
  &-24 {
    font-size: 24px;
  }
  &-25 {
    font-size: 25px;
  }
  &-26 {
    font-size: 26px;
  }
  &-27 {
    font-size: 27px;
  }
  &-28 {
    font-size: 28px;
  }
  &-29 {
    font-size: 29px;
  }
  &-30 {
    font-size: 30px;
  }
  &-31 {
    font-size: 31px;
  }
  &-32 {
    font-size: 32px;
  }
  &-33 {
    font-size: 33px;
  }
  &-34 {
    font-size: 34px;
  }
  &-35 {
    font-size: 35px;
  }
  &-36 {
    font-size: 36px;
  }
  &-37 {
    font-size: 37px;
  }
  &-38 {
    font-size: 38px;
  }
  &-39 {
    font-size: 39px;
  }
  &-40 {
    font-size: 40px;
  }
  &-41 {
    font-size: 41px;
  }
  &-42 {
    font-size: 42px;
  }
  &-43 {
    font-size: 43px;
  }
  &-44 {
    font-size: 44px;
  }
  &-45 {
    font-size: 45px;
  }
  &-46 {
    font-size: 46px;
  }
  &-47 {
    font-size: 47px;
  }
  &-48 {
    font-size: 48px;
  }
  &-49 {
    font-size: 49px;
  }
  &-50 {
    font-size: 50px;
  }
  &-51 {
    font-size: 51px;
  }
}

.fw {
  &-normal {
    font-weight: 500;
  }
  &-semiBold {
    font-weight: 600;
  }
  &-bold {
    font-weight: 700;
  }
}

.fc {
  &-warm9 {
    color: $warm9;
  }
  &-warm1 {
    color: $warm1;
  }

  &-green1 {
    color: $green1;
  }
  &-green2 {
    color: $green2;
  }
  &-green3 {
    color: $green3;
  }
  &-green4 {
    color: $green4;
  }
  &-green5 {
    color: $green5;
  }
  &-green6 {
    color: $green6;
  }
  &-green7 {
    color: $green7;
  }
  &-green8 {
    color: $green8;
  }
  &-neutral6 {
    color: $neutral6;
  }
  &-nav-warm {
    color: #f8eddb;
  }
  &-black {
    color: black;
  }
}
