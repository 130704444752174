.hub-profile-page {
  display: flex;
  align-items: center;
  height: calc(100vh - 143px);
  padding-top: 122px;
  overflow-y: scroll;
  
  .account-info {
    max-width: 1440px;
    width: 100%;

    h2 {
      padding-bottom: 20px;
    }

    .account-info-group {
      display: flex;
      padding-bottom: 20px;
      .account-ui-field {
        margin: 0 20px;
        display: flex;
        flex-direction: column;
        justify-content: left;
        .account-ui-header {
          padding-bottom: 5px;
          width: 20em;
        }
        .account-text-field {
          padding-left: 10px;
          border-radius: 10px;
          width: 30em;
          height: 30px;
          color: rgb(31, 31, 31);
          &:focus {
            outline: none;
          }
        }
      }
    }

    .account-header {
      margin-bottom: 100px;
    }
    #account-settings-display {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 10px 100px;
    }
  }
}
