.map-box-module-toolbar-wrapper {
  color: #fff;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  /* margin-bottom: -83px; */
  border-radius: 4px;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  margin-top: 5px;
  margin-left: 10px;
  right: 9px;
  top: 178px;
}

.svg-back-button {
  cursor: pointer;
  fill: rgb(39, 57, 6);
}

.mapboxgl-ctrl-geocoder--icon-close {
  width: 16px;
  height: 16px;
  margin-top: 3px;
  margin-right: 0;
}

.mapboxgl-ctrl-geocoder {
  min-width: 200px;
  width: unset;
}

.mapboxgl-ctrl-geocoder--input {
  padding: 6px 30px;
}
