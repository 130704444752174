$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;

.account-page-container {
  .account-profile-header-container {
    margin: 10px auto 10px auto;
    display: flex;
    flex-direction: row;
    padding: 0px 20px;
    .account-profile-header {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 50%;
      .account-header-username {
        color: $var-color--dar-blue;
        margin: auto 20px;
        font-size: 25px;
        padding: 0 0 20px 0;
        font-weight: 600;
      }
      .account-profile-image-container {
        display: flex;
        flex-direction: column;
        .account-profile-image {
          width: 100px;
          height: 100px;
          border-radius: 50%;
        }
        .account-profile-image-change {
          width: 100%;
          text-align: center;
          font-size: 12px;
          border-radius: 7px;
          border: none;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $var-color--dar-blue;
          background-color: $var-color--light-orange;
          margin: 3px 0 0 0;

          &:hover {
            background-color: $var-color--light-blue;
          }
        }
      }
    }
    .account-profile-header-description-container {
      width: 50%;
      height: auto;
      padding-top: 10px;
      .account-management-description {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.hub-page-container {
  &--profile {
  }
}
