$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.7);
$var-color--button-unselected-blue: #264562;

.calculate-impact-page-wrapper {
  .hub-page-container-sub-header {
    margin: 0 15%;
    display: flex;
  }
  .hub-page-container-header {
    margin: 10px auto;
    color: #273a07;
    text-align: center;
  }
  h3.hub-page-container-header {
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .calculate-impact-amount-select-amount-wrapper {
    width: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px 20px;
    .amount-title {
      width: 100%;
      h2 {
        color: $var-dark-green;
        margin-bottom: 0px;
      }
    }
    .calculate-impact-separater {
      width: 99%;
      background-color: $var-dark-green;
      height: 2px;
      border: none;
      margin: 10px 0px;
    }
    .calculate-amount-buttons-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      .calculate-impact-amount-buttons {
        width: 110px;
        background-color: transparent;
        border: 2px solid transparent;
        border-radius: 5px;
        padding: 0px;
        overflow: hidden;
        height: 80px;
        margin: 5px;
        &.selected-amount-button {
          border: 2px solid $var-color--light-orange;
        }
        .calculate-impact-button-content-top {
          width: 100%;
          height: 50%;
          background-color: $var-color--light-orange;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .calculate-impact-button-content-bottom {
          width: 100%;
          height: 50%;
          background-color: $var-color--default-buttons;
          display: flex;
          justify-content: center;
          align-items: center;
          .custom-donation-amount-wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .custom-donation-inputs-wrapper {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              & > input {
                text-align: center;
              }
              .custom-donation-amount {
                width: 80%;
                border: none;
                outline: none;
                &.focus {
                  outline: none;
                }
              }
            }
          }
        }
      }
    }
  }
  .calculate-impact-distribute-amount-wrapper {
    width: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px 20px;
    .mobile-learn-more-button {
      display: none;
    }
    .sliders-container {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    .distribute-amount-title {
      width: 100%;
      position: relative;
      h2 {
        color: $var-dark-green;
        margin-bottom: 0px;
      }
      .learn-more-partners-container {
        position: absolute;
        right: 10px;
        bottom: 0px;
      }
    }
    .calculate-impact-separater {
      width: 99%;
      background-color: $var-dark-green;
      height: 2px;
      border: none;
      margin: 10px 0px;
    }
    .impact-calculated-slider-wrapper {
      max-width: 300px;
      width: 300px;
      display: flex;
      height: 398px;
      border: 1px solid white;
      border-radius: 10px;
      flex-direction: column;
      background-color: cover;
      background-position: center;
      margin: 10px;
      transition: height 0.5s linear;

      .impact-calculate-slider-company-info-wrapper {
        width: 100%;
        height: 344px;
        .impact-calculate-company-info-logo {
          width: 90%;
          margin: 5px auto;
          height: 100px;
          .slider-logo-image {
            width: 100%;
            max-height: 70px;
            object-fit: contain;
          }
        }
        .impact-calculate-company-info-name {
          width: 100%;
          display: flex;
          justify-content: center;
          margin-bottom: 10px;
          .impact-company-name-text {
            color: $var-dark-green;
            font-size: 1em;
          }
        }
        .impact-calculate-company-info-description {
          width: 100%;
          display: flex;
          justify-content: center;
          transition: height 0.5s linear;
          position: relative;
          height: 123px;
          overflow: hidden;
          .impact-card-view-more-button-container {
            position: absolute;
            bottom: 2px;
            width: 100%;
            margin: 0 auto;
            background-color: rgba(255, 255, 255, 0.9);
            border-radius: 10px;
            padding: 10px 20px;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: background-color 0.5s linear;
          }
          .impact-company-name-text {
            color: $var-dark-green;
            font-size: 1em;
            text-align: center;
          }
        }
      }
      &.selected-impact-card {
        height: 508px;
        .impact-calculate-company-info-description {
          height: 214px;
        }
        .impact-card-view-more-button-container {
          background-color: rgba(255, 255, 255, 0.4) !important;
          bottom: -31px !important;
        }
      }
      .impact-slider-wrapper {
        width: calc(90% - 10px);
        margin: 0 auto;
        background-color: rgba(255, 255, 255, 0.4);
        display: flex;
        align-items: center;
        flex-direction: column;
        border-radius: 5px;
        padding: 5px;
        height: 140px;
        max-height: 140px;
        & > span {
          width: 95%;
        }
        & > h3 {
          margin: 2px 0px;
        }
        & > h2 {
          margin: auto;
        }
      }
    }
  }
}
.impact-donate-now-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px auto;
  // position: fixed;
  // bottom: 150px;
  z-index: 100;
}
.calculate-next-button {
  // height: 50px;
  width: 120px;
  background-color: #da4431;
  border-radius: 5px;
  border: none;
  outline: none;
  right: 10px;
  z-index: 100;
  &.pulse-button {
    -webkit-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    -moz-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    -ms-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    box-shadow: 0 0 0 0 rgba(232, 76, 61, 0.7);
  }

  color: white;
}

@-webkit-keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}

@-moz-keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}

@-ms-keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}

@keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}

@media screen and (max-width: 650px) {
  .calculate-impact-page-wrapper {
    .mobile-learn-more-button {
      display: flex !important;
      justify-content: center;
      width: 100%;
      padding-bottom: 30px;
    }
    .calculate-impact-amount-select-amount-wrapper {
      .amount-title {
        h2 {
        }
      }
      .calculate-impact-separater {
      }
      .calculate-amount-buttons-wrapper {
        .calculate-impact-amount-buttons {
          &.selected-amount-button {
          }
          .calculate-impact-button-content-top {
          }
          .calculate-impact-button-content-bottom {
            .custom-donation-amount-wrapper {
              .custom-donation-inputs-wrapper {
                .custom-donation-amount {
                  &.focus {
                  }
                }
              }
            }
          }
        }
      }
    }
    .calculate-impact-distribute-amount-wrapper {
      .sliders-container {
      }
      .distribute-amount-title {
        h2 {
        }
        .learn-more-partners-container {
          display: none;
        }
      }

      .calculate-impact-separater {
      }
      .impact-calculated-slider-wrapper {
        .impact-calculate-slider-company-info-wrapper {
          .impact-calculate-company-info-logo {
            .slider-logo-image {
            }
          }
          .impact-calculate-company-info-name {
            .impact-company-name-text {
            }
          }
          .impact-calculate-company-info-description {
            .impact-company-name-text {
            }
          }
        }
        .impact-slider-wrapper {
          & > span {
          }
          & > h3 {
          }
          & > h2 {
          }
        }
      }
    }
  }
  .impact-donate-now-button-container {
    // bottom: 71px;
    padding: 30px 0px;
    // padding-bottom: 30px;
    // padding-top: 60px;
    // background-color: rgba(255, 255, 255, 0.7);
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  }
}
