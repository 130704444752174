.account-pages-browser-settings {
  width: calc(100% - 279px);
}
.account-page-container {
  .general-header {
  margin: 10px 20px 10px 20px;
  font-size: 23px;
  font-weight: 700;
  color: $var-color--dar-blue;
}

  width: 80%;
  margin: 10px auto 0 auto;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 10px;
  height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar{
    display:none;
  }
}
.account-info-contents-wrapper{
  background: rgba(255, 255, 255, 0.7);
  border-radius: 5px;
  margin:0px 10px;
  width: calc(100% - 20px);
  padding: 10px 0px;
}