$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;
.account-info-top-section-other-user {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  height: 230px;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 10px;
  .account-info-top-section-left {
    width: 15%;
    // width: fit-content;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .account-info-group--profile-pic {
      display: flex;
      height: auto;
      justify-content: flex-end;
      width: 200px;
      margin-left: 20px;
      align-items: center;
      // padding-right: 40px;
      border-radius: 50%;
      border: 3px solid transparent;
      cursor: pointer;
      .account-profile-image {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
      .profile-pic-edit-button-wrapper {
        // margin-left: -58px;
        // margin-top: 25%;
        // padding: 6px;
        margin-left: -100%;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.4);
        display: none;
        .account-profile-pic-edit-outer {
          width: 50px;
          height: 50px;
          fill: rgba(255, 255, 255, 0.7);
          // display: none;
        }
      }
      &:hover {
        .profile-pic-edit-button-wrapper {
          display: flex;
        }
      }
    }
  }
  .account-info-top-section-right {
    width: 65%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 25px;

    .account-info-context-container {
      span {
        .hidden-edit-outer {
          width: 15px;
          height: 15px;
          fill: rgba(255, 255, 255, 0.4);
        }
      }
    }
    .account-info-group {
      &--user-name {
        span.info-span {
          color: white;
          font-size: 30px;
        }
      }
    }
    .section-divider {
      border: 1px solid rgba(255, 255, 255, 0.7);
      width: 100%;
      margin-bottom: 10px;
    }
  }
  .account-info-top-section-button-group {
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .account-profile-buttons {
      margin: 4px;
      background-color: $var-color--light-orange;
      width: 80px;
      height: 25px;
      border-radius: 5px;
      border: none;
      cursor: pointer;
      &:hover {
        background-color: $var-color--light-blue;
      }
    }
  }
}
