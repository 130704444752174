// @mixin unselectedButtonDarkBlue {
//   width: 100px;
//   height: 25px;
//   background-color: #264562;
//   border: none;
//   outline: none;
//   cursor: pointer;
//   color: white;
//   font-size: 0.9em;
//   border: 1px solid #09132b;
//   margin: 0px 4px;
//   margin-top: 10px;
//   border-radius: 5px;
// }
// @mixin selectedButtonLightBlue {
//   border: 1px solid #264562;
//   background-color: #6ec6ed;
//   color: #09132b;
// }

.moved-location-block-wrapper {
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  .moved-location-block-button {
    @include unselectedButtonDarkBlue;
    &--update {
    }
    &--edit {
    }
    &--cancel {
    }
  }
}
