$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;

html {
  height: calc(var(--vh, 1vh) * 100);
}

body {
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
  height: 100%;
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #8c867c55;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: #8c867c99;
}

.adder-buttons {
  height: 25px;
  width: 80px;
  border-radius: 10px;
  border: none;
  background-color: #f7b844;
}

.hub-page-wrapper {
  width: 100vw;
  height: calc(100% - 60px);

  @media (max-width: 600px) {
    height: calc(100% - 50px);
  }

  background-color: #f0e9d5;

  &::-webkit-scrollbar {
    display: none !important;
  }
}

.hub-page-container {
  max-width: 1510px;
  height: calc(100% - 86px);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 0px;
  }

  z-index: 2;

  &--no-background-color {
    max-width: none;
  }
}

.selected-adventure {
  max-width: none;
}

@media only screen and (max-device-width: 860px) {
  html {
  }
}

.modal-container {
  width: 500px;
  height: 500px;
  // background-color: white;
  // background-color: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .modal-loader-icon {
    // -moz-animation: spinHorizontal 0.8s infinite linear;
    // -o-animation: spinHorizontal 0.8s infinite linear;
    // -webkit-animation: spinHorizontal 0.8s infinite linear;
    // animation: spinHorizontal 0.8s infinite linear;
    animation: rotation 2s infinite linear;
  }

  h1 {
    color: #da4431;
  }
}

.suspense-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #f0e9d5;

  .modal-loader-icon {
    // -moz-animation: spinHorizontal 0.8s infinite linear;
    // -o-animation: spinHorizontal 0.8s infinite linear;
    // -webkit-animation: spinHorizontal 0.8s infinite linear;
    // animation: spinHorizontal 0.8s infinite linear;
    animation: rotation 2s infinite linear;
  }
}

@keyframes spinHorizontal {
  0% {
    transform: rotateY(0deg);
  }

  100% {
    transform: rotateY(360deg);
  }
}

.ReactModal__Overlay {
  background-color: transparent !important;

  .ReactModal__Content {
    border: none !important;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

* {
  font-family: 'Soleil', Lucida Sans, Helvetica, Arial, sans-serif !important;
}

body,
input,
input::placeholder,
textarea {
  font-family: 'Soleil', Lucida Sans, Helvetica, Arial, sans-serif !important;
}

body {
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

button {
  cursor: pointer;
}

.hub-page-container {
}

body {
  background-color: #ffff;

  #root {
    background-color: #ffff;
    height: 100%;
  }
}

.grecaptcha-badge {
  z-index: -100;
}

.background-image-behind-content {
  position: absolute;
  z-index: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  background-position: center;
  background-size: cover;

  .background-image-opacity-layer {
    width: 100%;
    height: 100%;
  }
}

.signup-background-image-behind-content {
  position: absolute;
  z-index: 0;
  top: 0px;
  width: 100vw;
  height: calc(100vh - 253px);
  min-height: 900px;
  background-position: center;
  background-size: cover;

  .background-image-opacity-layer {
    width: 100%;
    height: 100%;
  }
}

.coming-soon-wrapper {
  background-color: #f9fbff;
}

.email-error-container {
  height: auto;
  width: auto;
  justify-content: center;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 0 auto;
  min-width: 402px;

  .email-error-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 15px;
      height: 15px;
    }

    span {
      color: #827700;
      margin-left: 10px;
    }
  }
}

.buttonify {
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: #09132b;

  span.empty-block-text-high-lighter {
    color: $var-dark-green;
    font-size: 0.8em !important;
    font-weight: 700;
    width: auto;
    cursor: pointer;

    a {
      color: $var-dark-green;
    }
  }

  span.empty-block-text-high-lighter-for-itinerary-day {
    color: $var-dark-green;
    font-size: 0.8em !important;
    font-weight: 700;
    width: auto;
    cursor: pointer;
    min-height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    // background-color: #162a43b6;
    background-color: $color-block-hover-background;
    border-width: 1px;
    border-color: #a7a7a700;
    box-shadow: 8px 8px 2px rgba(0, 0, 0, 0.4);
    color: #f7b844;
    border-radius: 10px;

    .dashboard-block-svg {
      fill: #f7b844;
    }

    .no-friends-text {
      color: $var-color--light-blue;
    }

    path {
      fill: $var-color--light-blue;
    }
  }
}

// @media (hover: none) {
//   *:hover {
//     background-color: inherit;
//     color: inherit;
//   }
// }
//here and on for orientation issues
.portrait {
  -webkit-transform: rotate(90deg);
  -webkit-transform-origin: 200px 190px;
}

.portrait-onready {
  -webkit-transform: rotate(90deg);
  -webkit-transform-origin: 165px 150px;
}

.landscape-right {
  -webkit-transform: rotate(90deg);
  -webkit-transform-origin: 200px 190px;
  width: 100vh;
  height: 100vw;
}

.landscape-left {
  -webkit-transform: rotate(-90deg);
  -webkit-transform-origin: 200px 190px;
  width: 100vh;
  height: 100vw;

  #root {
    height: 100vw;
    width: 100vh;
    min-height: 0px;

    .hub-page-wrapper {
      width: 100vh;
      min-height: 84vw;

      .hub-top-navbar-container {
        width: 100vh;
        height: 70px;
      }
    }
  }
}

.landscape-onready {
  -webkit-transform: rotate(90deg);
  -webkit-transform-origin: 165px 150px;
}

.hub-signup-size-adjustment {
  max-width: 333px;
}

.sign-up-password-errors-wrapper {
  padding-left: 20px !important;
  width: calc(100% - 20px) !important;
  min-width: 0px !important;

  .password-error-inner-wrapper {
    min-width: 0px !important;
    width: 100%;
  }

  .email-error-wrapper {
    height: 33px !important;
  }
}

.sign-up-username-error-container {
  height: 42px !important;
  overflow: hidden;
  padding-left: 10px !important;
  width: calc(100% - 20px) !important;
  min-width: 0px !important;

  .email-error-wrapper {
    height: 30px;
  }
}

.sign-up-email-errors-wrapper {
  height: 30px !important;
  overflow: hidden;
  padding-left: 20px !important;
  width: calc(100% - 20px) !important;
  min-width: 0px !important;

  .sign-up-email-error-container {
    padding-left: 0px !important;
  }
}

#account-page-onboard-responsive {
  &::-webkit-scrollbar {
    display: none;
  }
}

.pwa-sign-up-wrapper {
  justify-content: center !important;
  min-height: 900px !important;
  padding: 0px !important;

  & > form {
    z-index: 5;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.coming-soon-pages-mobile-padding {
  padding-bottom: 70px;
}

.sign-up-page-container {
  // position: absolute;
  min-height: 0px;
  height: max-content !important;
  background-color: #09132b;
}

.app-div {
  overflow-x: hidden;
  width: 100vw;
  height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
}

input[type='color'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week'],
select:focus,
textarea {
  font-size: 16px;
}
