$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;
$var-color--button-highlight: rgba(255, 255, 255, 0.29);
$var-color--button-highlight-transparent: rgba(255, 255, 255, 0);
$var-time-transition-fade: 0.5s;
$var-pixels-button-border-radius: 10px;
$var-pixels-button-margin: 2px 7px 0 7px;

.block {
  .wrapper,
  .preview-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  .preview-wrapper {
    justify-content: center;
  }
  svg,
  path {
    fill: rgba(255, 255, 255, 0.6);
  }

  .top-bar {
    margin: 0 3px 0 3px;
    display: flex;
    justify-content: flex-start;
    position: relative;
    div {
      cursor: pointer;
    }
  }
  .center-content {
    height: 50px;
    .title-wrapper {
      // height: 100%;
      height: 50px;
      width: 100%;
      display: flex;
      align-items: center;
    }
    .title {
      width: 108px;
      max-height: 100%;
      // display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      font-size: 12px;
      font-weight: 600;
      text-align: center;
      text-overflow: ellipsis;
      z-index: 10;
      margin-left: 3px;
      margin-right: 3px;
      overflow: hidden;
      // white-space: nowrap;
      color: rgba(255, 255, 255, 0.8);
      hyphens: auto;
      text-transform: uppercase;
    }
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      transform: translateY(-100%);
    }
    .watermark-svg-outer {
      z-index: 9;
      color: white;
      width: 100%;
      height: 100%;
      fill: rgba(255, 255, 255, 0.1) !important;
    }
  }
  .bottom-bar {
    display: flex;
    // flex-wrap: wrap;
    justify-content: flex-start;
  }
}

.mini-block-container {
  width: 185px;
  height: 110px;
  border-radius: 10px;
  // margin-left: 12px;
  margin-right: 12px;
  margin-left: 0px;
  cursor: grab;
  box-shadow: 0 1.7px 1.1px -10px rgba(19, 10, 43, 0.093),
    0 4.3px 3px -10px rgba(19, 10, 43, 0.133),
    0 8.9px 6.6px -10px rgba(19, 10, 43, 0.167),
    0 12.3px 14.4px -10px rgba(19, 10, 43, 0.207),
    0 11px 17px -10px rgba(19, 10, 43, 0.3);
  &--no-top-left-radius {
    border-top-left-radius: 0px;
    &:active {
      border-top-left-radius: 0px !important;
    }
  }
  svg,
  path {
    fill: rgba(255, 255, 255, 0.6) !important;
  }

  .mini-block-top {
    height: 30px;
    display: flex;
    width: calc(100% - 10px);
    justify-content: space-evenly;
    color: white;
    padding: 0px 5px;
    border-top-right-radius: inherit;
    border-top-left-radius: inherit;

    .mini-block-left-button-top {
      margin: $var-pixels-button-margin;
      border-radius: $var-pixels-button-border-radius;
      width: 20%;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: flex;
      color: rgba(255, 255, 255, 0.15);
      background-color: $var-color--button-highlight-transparent; //transparent fade
      transition: $var-time-transition-fade;
      &:hover {
        background-color: $var-color--button-highlight;
        cursor: pointer;
        color: #f7b844;
      }
    }

    .mini-block-center-top {
      margin: $var-pixels-button-margin;
      border-radius: $var-pixels-button-border-radius;
      width: 20%;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: flex;
      color: rgba(255, 255, 255, 0.15);
      background-color: $var-color--button-highlight-transparent;
      transition: $var-time-transition-fade;
      .it-b-sp {
        max-width: 112px;
        text-transform: uppercase;
        text-align: center;
        font-size: 15px;
        font-weight: 700;
        color: rgba(255, 255, 255, 0.4);
      }
      &:hover {
        background-color: $var-color--button-highlight;
        cursor: pointer;
        color: #f7b844;
      }
    }

    .mini-block-right-button-top {
      margin: $var-pixels-button-margin;
      border-radius: $var-pixels-button-border-radius;
      width: 20%;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: flex;
      color: rgba(255, 255, 255, 0.15);
      background-color: $var-color--button-highlight-transparent;
      transition: $var-time-transition-fade;
      &:hover {
        background-color: $var-color--button-highlight;
        cursor: pointer;
        color: #f7b844;
      }
    }
  }

  .mini-block-center {
    display: flex;
    width: 100%;
    // height: calc(100% - 75px);
    height: 50px;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    justify-content: center;
    word-wrap: break-word;
    align-items: center;
    flex-direction: column;
    .mini-block-center-top {
      width: 100%;
      height: 50px;
      margin-bottom: -50px;
      display: flex;
      align-items: center;
      justify-content: center;
      & > div {
        width: 100%;
        height: fit-content;
        word-wrap: break-word;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        --lh: 1rem;
        line-height: var(--lh);
        --max-lines: 3;
        // max-height: calc(var(--lh) * var(--max-lines));
        max-height: 50px;
        // max-height: calc(var(--lh) * 3);
        text-overflow: ellipsis;
        // white-space: nowrap;
        overflow: hidden;
      }
    }
    .it-b-sp {
      z-index: 10;
      max-width: 100%;
      text-transform: uppercase;
      text-align: center;
      font-size: 13px;
      font-weight: 600;
      color: white;
      margin-left: 3px;
      margin-right: 3px;
      max-height: 100%;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      color: rgba(255, 255, 255, 0.8);
    }
    .it-t-tp {
      z-index: 10;
      text-transform: uppercase;
      text-align: center;
      font-size: 15px;
      font-weight: 700;
      color: white;
      margin-top: 2px;
    }
    .it-t-bt {
      z-index: 10;
      text-transform: uppercase;
      text-align: center;
      font-size: 10px;
      color: white;
    }
    .m-b-bt-sp {
      z-index: 10;
      margin-left: 2px;
      margin-right: 2px;
      margin-top: 5px;
      text-transform: lowercase;
      // font-family: Courgette;
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.6);
      text-align: center;
    }
    .mini-blk-cnt-wm-wrapper {
      // height: 90px;
      // position: absolute;
      width: 109px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      .watermark-svg-outer {
        z-index: 9;
        color: white;
        width: 100%;
        height: 100%;
        fill: rgba(255, 255, 255, 0.1) !important;
        path {
          fill: rgba(255, 255, 255, 0.1) !important;
        }
      }
    }
    .itinerary-block-spectators-container {
      opacity: 0.8;
      width: 50px;
      z-index: 9;
      display: flex;
      flex-direction: column;
      position: relative;
      .itineraries-adventure-friend-profile-image {
        width: 40px;
        height: 40px;
        z-index: 9;
        border-radius: 50%;
        &:not(:first-child) {
          margin-top: -35px;
          z-index: 8;
        }
      }
      .itinerary-block-spectators-container {
        position: absolute;
      }
    }
  }

  .mini-block-bottom {
    height: 30px;
    display: flex;
    // width: calc(100% - 10px);
    width: 100%;
    // justify-content: space-evenly;
    justify-content: flex-start;
    color: white;
    // padding: 0px 5px;
    border-top-right-radius: inherit;
    border-top-left-radius: inherit;
    .mini-blk-btm-hierarchy-wrapper {
      width: 19%;
      display: flex;
      flex-direction: column;
      padding-bottom: 2px;
      // margin: 2px 5px 2px 5px;
      align-items: center !important;
      justify-content: center;
      text-align: center;
      font-size: 11px;
      // cursor: pointer;
      margin-right: -2px;
      // &:hover {
      //   .mini-blk-hierarchy-outer,
      //   .mini-blk-hierarchy {
      //     fill: #f7b844 !important;
      //   }

      //   .mini-blk-hierarchy-vote-up {
      //     fill: #f7b844 !important;
      //   }
      // }
    }
    .mini-block-bottom-button--highlighted {
      .mini-blk-hierarchy-outer,
      .mini-blk-hierarchy {
        fill: #f7b844 !important;
      }
    }
    // .svg-list-wrapper {
    //   svg {
    //     width: 20px !important;
    //     height: 20px !important;
    //   }
    // }
    .mini-block-left-button-bottom {
      width: 45%;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      display: flex;
    }

    .mini-block-center-bottom {
      width: 20%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: rgba(255, 255, 255, 0.15);
      font-weight: 900;
      .it-b-sp {
        max-width: 112px;
        text-transform: uppercase;
        text-align: center;
        font-size: 15px;
        font-weight: 700;
      }
      &:hover {
        cursor: pointer;
        color: #f7b844;
      }
    }

    .mini-block-right-button-bottom {
      width: 45%;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: right;
      display: flex;
    }
  }

  .itinerary-block-top {
    height: 61px;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    color: white;
    background-color: rgba(9, 19, 43, 0.8);
    border-top-right-radius: 13px;
    border-top-left-radius: 13px;
    .mini-block-left-button {
      width: 40%;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: flex;
      z-index: 20;
    }

    .itinerary-block-bottom {
      height: 61px;
      display: flex;
      width: 100%;
      justify-content: space-evenly;
      color: white;
      background-color: rgba(9, 19, 43, 0.8);
      border-top-right-radius: 13px;
      border-top-left-radius: 13px;
      .mini-block-left-button {
        width: 40%;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        display: flex;
        z-index: 20;
      }

      .mini-block-right-button {
        width: 40%;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        display: flex;
        z-index: 20;
      }
    }
  }

  &:hover {
    box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.5);
  }

  &:active {
    // border-radius: 30px;
    &::before,
    &::after {
      // border-radius: 30px;
    }
  }
}
.mini-block-stacked-effect {
  z-index: -3;
  // position: absolute;
  // display: inline;
  width: 170px;
  height: 150px;
  border-radius: 10px;
  margin-left: 19px;
  margin-bottom: -161px;
  box-shadow: -2px 14px 5px -12px rgba(255, 255, 255, 0.1);
}

.mini-block-stacked-effect-2 {
  z-index: -4;
  // position: absolute;
  // display: inline;
  width: 150px;
  height: 150px;
  border-radius: 10px;
  margin-left: 27px;
  margin-bottom: -156px;
  margin-top: 21px;
}

.mini-block-stacked-effect-placeholder {
  z-index: -3;
  // position: absolute;
  // display: inline;
  width: 170px;
  height: 150px;
  border-radius: 10px;
  margin-left: 19px;
  margin-bottom: -161px;
}
.mini-block-stacked-effect-2-placeholder {
  z-index: -4;
  // position: absolute;
  // display: inline;
  width: 150px;
  height: 150px;
  border-radius: 10px;
  margin-left: 27px;
  margin-bottom: -156px;
  margin-top: 21px;
}
.min-blk-btns {
  svg {
    width: 15px;
    height: 15px;
    fill: black;
    path {
      fill: rgba(255, 255, 255, 0.15);
    }
  }
  &:hover {
    svg,
    path {
      fill: #f7b844 !important;
    }
  }
}

.mini-blk-btm-hierarchy-wrapper {
  border-radius: 4px;
  // margin: $var-pixels-button-margin;
  display: flex;
  height: auto;
  padding: 0px 4px 0px 2px;
  align-items: center;
  text-align: center;
  font-size: 11px;
  // cursor: pointer;
  color: rgba(255, 255, 255, 0.35);
  background-color: $var-color--button-highlight-transparent;
  transition: $var-time-transition-fade;
  & > svg {
    margin-left: 2px;
  }
  // &:hover {
  //   background-color: $var-color--button-highlight;
  //   color: #f7b844;
  //   svg,
  //   path {
  //     fill: #f7b844;
  //   }
  // }
  .mini-blk-hierarchy-outer {
    width: 15px;
    height: 15px;
    // &:hover {
    //   .mini-blk-hierarchy {
    //     fill: #f7b844;
    //   }
    // }
    .mini-blk-hierarchy {
      width: 15px;
      height: 15px;
      fill: rgba(255, 255, 255, 0.6);
      // cursor: pointer;
      // &:hover {
      //   svg,
      //   g {
      //     fill: #f7b844;
      //   }
      // }
    }
  }
}

.stackedEffect {
  // background-color: #fff;
  // /* Need position to allow stacking of pseudo-elements */
  position: relative;
  // /* Padding for demo purposes */
  // padding: 30px;
  border-radius: 10px;
  box-shadow: 2px 1px 1px rgba(255, 255, 255, 0.15) !important;
  &:hover {
    box-shadow: 1px 0px 4px 2px rgba(255, 255, 255, 0.3) !important;
  }
}

.stackedEffect,
.stackedEffect::before,
.stackedEffect::after {
  /* Add shadow to distinguish sheets from one another */
  // box-shadow: 2px 1px 1px rgba(255, 255, 255, 0.15);
  border-radius: 10px;
  // border: 1px solid black;
}

.stackedEffect::before,
.stackedEffect::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  // background-color: #eee;
  border-radius: 10px;
  background-color: inherit;
  box-shadow: 2px 1px 1px rgba(255, 255, 255, 0.15) !important;

  &:active {
    // border-radius: 30px;
  }
}

/* Second sheet of stackedEffect */
.stackedEffect::before {
  left: 4px;
  top: 2px;
  z-index: -3;
  border-radius: 10px;

  &:active {
    // border-radius: 30px;
  }
}

/* Third sheet of stackedEffect */
.stackedEffect::after {
  left: 8px;
  top: 4px;
  z-index: -2;
  border-radius: 10px;
  box-shadow: 0 1.7px 1.1px -10px rgba(19, 10, 43, 0.093),
    0 4.3px 3px -10px rgba(19, 10, 43, 0.133),
    0 8.9px 6.6px -10px rgba(19, 10, 43, 0.167),
    0 12.3px 14.4px -10px rgba(19, 10, 43, 0.207),
    0 11px 17px -10px rgba(19, 10, 43, 0.3) !important;
  &:active {
    // border-radius: 30px;
  }
}
.tool-tip-container {
  z-index: 99;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
// @function sheets-effect($color:white, $orientation:right, $amount:3, $sheet-size:2px){
//   $alpha: 0.3
//   $bottom: 1px
//   $side: null
//   $modifier: null
//   @if $orientation == left
//     $side: -1px
//     $modifier: -1
//   @else
//     $side: 1px
//     $modifier: 1
//   $ret: #{$side} #{$bottom} 0 #{rgba(darken($color,90%), $alpha)}
//   @for $i from 1 through $amount
//     $bottom: $bottom + $sheet-size
//     $side: $side + $sheet-size * $modifier
//     $alpha: $alpha + 0.025
//     $ret: $ret, #{$side} #{$bottom} 0 #{$color}
//     $bottom: $bottom + 1
//     $side: $side + 1 * $modifier
//     $ret: $ret, #{$side} #{$bottom} 0 #{rgba(darken($color,90%), $alpha)}
//   @return $ret
// }

// .mini-block-second-row {
//   margin-top: -3px;
//   margin-bottom: -4px;
//   width: 100%;
//   display: flex;
//   height: 12px;
//   span.percentage-text {
//     font-size: 11px;
//     color: #f7b844;
//     margin-left: -21px;
//   }
// }

@function paperShadow($depth) {
  $val: 0 -1px 1px rgba(0, 0, 0, 0.15);
  @for $i from 1 through $depth {
    $v-offset: 10 * $i;
    $spread-radius-layer: $v-offset / 2;
    $spread-radius-shadow: $spread-radius-layer - 1;
    //Create new layer
    $val: #{$val}, 0 -#{$v-offset}px 0 -#{$spread-radius-layer}px #eee;
    //Create shadow for layer
    $val: #{$val},
      0 -#{$v-offset}px 1px -#{$spread-radius-shadow}px rgba(0, 0, 0, 0.15);
  }
  @return $val;
}

.adventure-included-svg-outer {
  fill: #6ec6ed !important;
  width: 20px !important;
  height: 20px !important;
  svg,
  path {
    fill: #6ec6ed !important;
  }
}

.check-list-svg-outer {
  width: 40px !important;
  height: 25px !important;
}

.clone-custom,
.clone-custom::after,
.clone-custom::before,
.clone-custom::after {
  // border-radius: 30px !important;
}

.tooltip {
  &:hover::before {
    display: inline-block;
  }
  &--top {
    &--left::before {
      content: attr(data-text);
      position: absolute;
      margin-bottom: -54px;
      // width: 50px;
      // height: 20px;
      font-size: 11px;
      padding: 1px 5px;
      display: none;
      color: $var-color--dar-blue;
      background: $var-color--light-orange;
      border-radius: 4px;
      transition: opacity 0.1s ease-out;
      z-index: 99;
      text-align: left;
    }
    &--left:hover::before {
      display: inline-block;
    }
    &--left-stacked::before {
      content: attr(data-text);
      position: absolute;
      margin-bottom: -54px;
      top: -15px;
      // width: 50px;
      // height: 20px;
      font-size: 11px;
      padding: 1px 5px;
      display: none;
      color: $var-color--dar-blue;
      background: $var-color--light-orange;
      border-radius: 4px;
      transition: opacity 0.1s ease-out;
      z-index: 99;
      text-align: left;
    }
    &--left-stacked:hover::before {
      display: inline-block;
    }
  }
  &--bottom {
    &--left::before {
      content: attr(data-text);
      position: absolute;
      margin-top: -40px;
      // width: 50px;
      // height: 20px;
      font-size: 11px;
      padding: 1px 5px;
      display: none;
      color: $var-color--dar-blue;
      background: $var-color--light-orange;
      border-radius: 4px;
      transition: opacity 0.1s ease-out;
      z-index: 99;
      text-align: left;
    }
    &--left:hover::before {
      display: inline-block;
    }
    &--left-stacked::before {
      content: attr(data-text);
      position: absolute;
      margin-top: -54px;
      // width: 50px;
      // height: 20px;
      font-size: 11px;
      padding: 1px 5px;
      display: none;
      color: $var-color--dar-blue;
      background: $var-color--light-orange;
      border-radius: 4px;
      transition: opacity 0.1s ease-out;
      z-index: 99;
      text-align: left;
      width: fit-content;
      margin-left: 4px;
      &--add-to-trip {
        margin-left: 31px;
      }
    }
    &--left-stacked:hover::before {
      display: inline-block;
    }
  }
}

.custom-bottom-button {
  .min-blk-svg-outer {
  }
}
.folder-top-wrapper {
  display: flex;
  margin-top: 0px;
  margin-bottom: 1px;
  z-index: -5;
  .ffolder {
    top: -24px;
    left: 0;
    width: 96px;
    height: 9px;
    border-radius: 14px 0 0;
    content: '';
    display: block;
    z-index: -1;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    // position: absolute;
  }
  .ffolder-right {
    width: 29px;
    height: 24px;
    // transform: skew(40deg);
    margin-left: -14px;
    border-top-right-radius: 4px;
    z-index: -2;
  }
}
#folder {
  width: 150px;
  height: 105px;
  margin: 0 auto;
  margin-top: 50px;
  position: relative;
  // background-color: #708090;
  // background-image: url("./noun_duffel bag_2673664.svg");
  background-color: transparent !important;
  border-radius: 0 6px 6px 6px;
  box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.59);
}

// #folder:before {
//   content: "";
//   width: 50%;
//   height: 12px;
//   border-radius: 0 20px 0 0;
//   // background-color: linear-gradient(-125deg, rgb(33, 150, 243), rgb(0, 27, 96));
//   background-color: rgb(0, 27, 96);
//   // background-color: attr(data-text);
//   position: absolute;
//   top: -12px;
//   left: 0px;
//   border-bottom: 0.5px solid white;
// }
.folder-body {
  height: 100px !important;
  .folder-body-bottom {
    margin-top: -10px !important;
  }
}

.count-spans {
  z-index: 5;
  text-align: start;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 2px;
  margin-top: 10px;
  &--item-count {
    margin-left: 5px;
    margin-top: 0px;
    font-size: 12px;
  }
  &--child-count {
  }
  &--vote-up {
  }
  &--vote-down {
  }
  &--file-count {
  }
  &--comment-count {
  }
  &--entries {
  }
}

.folder-child-count {
  // margin: $var-pixels-button-margin;
  display: flex;
  height: auto;
  align-items: center;
  color: rgba(255, 255, 255, 0.35);
  & > svg {
    margin-left: 2px;
  }
  .folder-count-span {
    margin-left: 5px;
    font-size: 12px;
  }
  .mini-blk-hierarchy-outer {
    width: 15px;
    height: 15px;

    .mini-blk-hierarchy {
      width: 15px;
      height: 15px;
      fill: rgba(255, 255, 255, 0.15);
    }
  }
}

.vote-up {
  fill: #d18000 !important;
  color: #d18000 !important;
}
