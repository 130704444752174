.block-module-itinerary-item-list-wrapper {
  width: 98%;
  height: 480px;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;
  // background-color: rgba(255, 255, 255, 0.4);
  padding: 10px 0px;
  border-radius: 10px;
  margin: 0 auto;
  margin-top: -5px;
  .block-module-itinerary-item-list {
    &--header {
    }
    &--contents {
      width: 100%;
      display: flex;
      height: 100%;
      flex-direction: column;
      align-items: flex-end;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      overscroll-behavior: contain;
    }
  }
}
