.plan-blocks{
   display:flex;
    flex-direction:  column;
    justify-content: flex-start;
    align-items: center;
    width: 95%;
    margin: 0 auto;
    border: 1px solid white;
    border-radius: 40px;
    margin-top: 20px;
    background-color: #2E364B;
    // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    // width:calc(100% - 100px);
}

.tagline{
  text-align: center;
  font-size: 23px;
  color: #FFFFFF;
  // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.two-signs-container{
    display:flex;
    justify-content: center;
    width: 100%;
    img{
        width: 22%;
        margin: 20px;
    }
}
.top-section-second{
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1{
      color: #FFFFFF;
    }
    span{
      color: #F7B844;
      letter-spacing: 2px;
      font-size: 18px;
      font-weight: 600;
    }

    a{
      text-decoration: none;
      div{
        margin-top:20px;
        background-color: #F7B844;
        width: 250px;
        display:flex;
        justify-content: center;
        border-radius: 10px;
        color:black;
        cursor: pointer;
        margin-bottom: 20px;
      }
    }
  }
