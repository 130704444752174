 

.hightlight-logo{
  width: 150px;
  height: 50px;
  margin-top: 30px;

}

.lodestar-guide-text{
  font-weight: 700;
  font-size: 24px;
  margin-top:10px;
}

.highlight-header{
  width: 100%;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#banner-logo-float-img{
    width: 40%;
    min-width: 300px;
}

.bold-text-bold{
  font-weight: 900;
}

.light-text{
  font-weight: lighter;
  // font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-style: italic;;
}

.trademark_case_small{
  font-size: 10px;
}

.tagline{
  text-align: center;
  font-size: 23px;
  color: #FFFFFF;
  // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}