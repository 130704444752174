.block-category-box-wrapper{
    width:100px;
    height:100px;
    background-color: rgba(255,255,255,0.4);
    border:2px solid white;
    border-radius: 5px;
    margin: 5px;
    &.-selected{
        background-color: $var-color--light-blue;
    }
    .block-category-box-padder{
        width:100%;
        height:100%;
        display:flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .block-category-box-svg-wrapper{
            width:55px;
            height:55px;
            display:flex;
            justify-content: center;
            align-items: center;
            .block-category-box-svg-outer{
                width:100%;
                height:100%;
                path{
                    fill: white;
                }
            }
        }
        
    }
}

.block-modal-spectators{
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction: column;

    .block-modal-spectators-blocks-wrapper{
        height: 85%;
        width: 100%;
        overflow:auto;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        .spectator-block-wrapper{
            width: 90%;
            margin: 0 auto;
            height: 50px;
            background-color: rgba(255,255,255,0.7);
            border-radius:10px;
            display:flex;
            position:relative;
            margin-top: 5px;
            margin-bottom:5px;
            .spectator-block{
                &--left{
                    position:absolute;
                    left:-12px;
                    width: 20%;
                    height: 100%;
                    .spectator-profile-image{
                        border-radius: 60%;
                        width: auto;
                        height:100%;
                    }
                }
                &--right{
                    width: 100%;
                    height: 100%;
                    position:relative;
                    display:flex;
                    justify-content: center;
                    align-items: center;
                    .spectator-info-wrapper{
                        
                    }
                    .spectator-buttons-wrapper{
                        position:absolute;
                        right:0;
                        height:100%;
                        display:flex;
                        align-items: center;
                        
                        .spectating-svg-outer{
                            width: 30px;
                            height:30px;
                            fill: $var-color--light-blue;
                            path{
                                fill: $var-color--light-blue;
                            }
                        }
                    }
                }
            }
        }
    }
    .block-modal-spectators-button-wrapper{
        width: 100%;
        height: 15%;
        display:flex;
        justify-content: center;
        .block-modal-spectators-button{
            width: 150px;
            height:30px;
            border-radius: 10px;
            background-color:$var-color--light-orange;
            border:none;
            outline:none;
            display:flex;
            justify-content: center;
            align-items: center;
            &:hover{
                background-color:$var-color--light-blue;

            }
        }
    }
}