$color-background: #ffff;
$color-block-highlight: #515a6e;
$color-block-shadow: rgba(0, 0, 0, 0.2);
$color-highlight: #f7b844;
$var-color--dark-orange: #da4431;

.menu-wrapper {
  transition: transform 0.2s ease;
  z-index: 100;
  position: fixed;
  // background-color: $color-background;
  height: calc(100% - 85px);
  max-width: 295px;
  min-width: 295px;
  width: 20vw;
  overflow-y: auto;
  top: 0px;
  overflow-x: hidden;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  overscroll-behavior: none;
  &::-webkit-scrollbar {
    display: none;
  }
  .menu-drawer-wrapper {
    position: relative;
    top: 0;
    background-color: $color-background;
    min-height: 100%;
    height: auto;
    max-width: 295px;
    min-width: 295px;
    width: 100%;
    box-sizing: border-box;
    bottom: 73px;
    overflow-x: hidden;
    align-items: center;
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
    padding-top: 15px;
    .header-wrapper {
      padding: 8px 0 4px 0;
      color: white;
      letter-spacing: 0.5px;
      cursor: default;
      display: flex;
      flex-direction: column;
      align-items: center;
      .user-name {
        padding: 0 0 3px 0;
        font-size: 14px;
        letter-spacing: 2px;
      }
      .user-email {
        font-size: 12px;
        letter-spacing: 2px;
      }

      .user-profile-pic {
        border-radius: 50%;
        //border: 2px solid rgba(255, 166, 0, 0.486);

        width: 40px;
        height: 40px;
      }
      .headers-tab-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

        .headers-tab {
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          &--notification {
            margin-top: 5px;
            margin-bottom: -5px;
          }
          .menu-drawer-header-outer {
            width: 40px;
            height: 40px;
            path {
              fill: $var-dark-green;
            }
          }
          .notification-count {
            background-color: red;
            width: 15px;
            height: 15px;
            border-radius: 5px;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: -15px;
            margin-top: 15px;
          }
          &--selected {
            .menu-drawer-header-outer {
              path {
                fill: $warm7;
              }
            }
          }
        }
      }
    }

    .account-section-wrapper {
      padding: 5px 0 0 50px;
      .menu-navigation-text {
        padding: 5px 0 5px 0;
        color: $green7;
        font-size: 12px;
        cursor: pointer;
        &:hover {
          color: $warm7;
        }
      }
    }
  }
  .menu-drawer-wrapper::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* Chrome/Safari/Webkit */
    display: none;
  }
  hr {
    border: none;
    color: #a7a7a7;
    background-color: #a7a7a7;
    height: 1px;
    width: 25%;
  }
}

.progressbar-drawer-open {
  transform-origin: 0px;
  // transform: translateX(294px) !important;
  left: 295px !important;
  transition: 0.3s all;
  // animation: moveLeft 0.2s;
}

.progress-container {
  position: fixed;
  height: calc(100% - 88px);
  width: 5px;
  top: 0;
  z-index: 60;
  overscroll-behavior: none;
  div,
  a,
  span,
  section,
  p {
    overscroll-behavior: none;
  }
  .no-progress-bar {
    top: 0;
    left: -5px;
    position: absolute;
    background-color: #bf9547;
    width: 3px;
    height: 100%;
    z-index: 100;
    // transform: translateX(-10px);
  }
  .progress-bar {
    top: 0;
    left: -5px;
    position: absolute;
    height: 7%;
    background: $green7;
    width: 5px;
    z-index: 100;
    max-height: 100%;
    // transform: translateX(-10px);
  }
}

.progressbar-drawer-closed {
}
@keyframes moveLeft {
  0% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(294px) !important;
  }
}
@keyframes moveright {
}
.messages-notification-count-wrapper {
  width: 25px;
  height: 20px;
  margin-top: -20px;

  .messages-body {
    width: 100%;
    height: 100%;
    background-color: $var-color--dark-orange;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .messages-tail {
    &:before {
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
      /* left: -8px; */
      right: auto;
      top: auto;
      /* bottom: -40px; */
      border: 5px solid #da4431;
      border-color: transparent transparent transparent transparent;
      margin-left: 3px;
    }
    &:after {
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
      right: auto;
      top: auto;
      border: 3px solid;
      border-color: #da4431 transparent transparent #da4431;
    }
  }
}
.messages-wrapper {
  display: flex;
  flex-direction: row;
}
