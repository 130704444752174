$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;

.contact-section-container {
  margin: 20px 0 0 0;
  .contact-header {
    margin: 10px 20px 10px 20px;
    font-size: 30px;
    font-weight: 700;
    color: $var-color--dar-blue;
  }
  .contact-section-body {
    margin: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-gap: 10px;
    // grid-template-areas:
    //   "a b b"
    //   "c d e"
    //   "f g g ";

    // .contact-address {
    // }
    .contact-apt {
      width: 172px;
      .contact-apt-child {
        width: 172px;
      }
    }
    // .contact-city {
    // }
    .react-dropdown-select-content {
      padding: 0 0 0 10px;
    }
    .react-dropdown-select {
      width: 200px;
      border-radius: 10px;
      background-color: white;
      height: 44px;
      // box-shadow: 4px 4px 3px rgba(0, 0, 0, 0.2);
      border: none;
      .react-dropdown-select-dropdown-position-bottom {
        height: 150px;
      }
    }
  }
  .mod-fl-lab-inpt-wrapper {
    .sc-hKFxyN {
      box-shadow: 4px 4px 3px rgba(0, 0, 0, 0.2);
    }
    width: 100%;
    padding: 5px 0 5px 0;
  }
  .contact-state-country-container {
    display: flex;
    flex-direction: row;
    .contact-state-select-parent {
      margin: 0 10px 0 0;
    }
  }
  .contact-section-footer-container {
    margin: 10px 20px 10px 20px;

    .contact-section-footer {
      display: flex;
      flex-direction: row;
    }
    .contact-not-saved {
      text-align: center;
      margin: auto 0 auto 10px;
      font-style: italic;
      color: red;
    }
    .contact-button {
      width: 150px;
      text-align: center;
      font-size: 18px;
      border-radius: 10px;
      border: none;
      height: 37px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $var-color--dar-blue;

      &--cancel {
        background-color: gainsboro;
      }
      &--save {
        background-color: $var-color--light-orange;
        margin: 0 0 0 10px;
      }
      &:hover {
        background-color: $var-color--light-blue;
      }
    }
  }
}
