.mod-inv-account-explores-wrapper-container {
  height: auto !important;
  max-height: none !important;
  .mod-inv-account-explorers--wrapper {
    width: 95%;
    height: 284px;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 10px;
    .invite-from-account--wrapper {
      height: 100%;
      width: 100%;
      .invite-from-account-contents--wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
      }
    }
  }
  .email-error-container {
    height: 26px;
    margin-top: -3px !important;
    justify-content: flex-start;
    & > div {
      margin-top: 0px;
    }
  }
}
