$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
.entries-contents-wrapper {
  width: 100%;
  margin: 0 auto;
  border-radius: 4px;
  margin-top: 4px;
  max-height: 300px;
  z-index: 5;
  overflow-x: hidden;
  overflow-y: auto;
  border: 1px solid #273a07;
}

.entries-contents-wrapper::-webkit-scrollbar {
  width: 4px !important;
}
.entries-contents-wrapper::-webkit-scrollbar-thumb {
  background: #827700;
}
.entries-contents-wrapper::-webkit-scrollbar-track {
  position: relative;
  border-right: 1px solid #273A07;
}
.entries-contents-wrapper::-webkit-scrollbar-track::after {
  position: absolute;
  left: 2px;
  width: 1px;
  height: 100%;
  background-color:#273A07;
}
//checklist white paper stuff

// this is the style for the top part

.list-entries-checklist-top {
  padding-bottom: 0px !important;
  width: 92% !important;
  display: flex;
  justify-content: center;
  height: 30px;
  align-items: center;
  span {
    color: white;
    font-weight: 700;
  }
  .list-entries-checklist-top-child {
    &:disabled {
      background-color: white;
    }
  }
}

.simple-list-entry-wrapper {
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 3px;
  border-bottom: 2px solid #827700;
  input.simple-list-entry-input {
    width: 86% !important;
    height: 77% !important;
    border-radius: 10px;
    outline: none;
    border: none;
    // background-color: white;
    margin-left: 14px;
    font-size: 14px;
    &:focus {
      outline: none;
    }
  }
  button.simple-list-entry-button {
    background-color: #f7b844;
    border: none;
    outline: none;
    width: 36px !important;
    border-radius: 10px;
    height: 89%;
    margin-left: 5px;
    cursor: pointer;
    color: white;
    font-size: 30px;
    margin-top: -2px;
    text-align: center;
    line-height: 0px;
    letter-spacing: -2px;
    padding: 0px;
    &:hover {
      background-color: $var-color--light-blue;
    }
    &:focus {
      outline: none;
    }
  }
  button.full-screen-button {
    background-color: transparent;
    border: none;
    outline: none;
    width: 40px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover::before {
      display: inline-block;
    }
    &::before {
      content: attr(data-text);
      position: absolute;
      margin-bottom: -32px;
      font-size: 11px;
      padding: 1px 5px;
      display: none;
      color: #09132b;
      background: #f7b844;
      border-radius: 4px;
      transition: opacity 0.1s ease-out;
      z-index: 99;
      text-align: left;
      margin-left: -81px;
      margin-top: -7px;
      width: 64px;
      text-align: center;
    }
    .list-full-screen-svg-outer {
      fill: rgba(255, 255, 255, 0.3);
      width: 35px;
      height: 35px;
      path {
        fill: rgba(255, 255, 255, 0.4);
      }
    }
    &:focus {
      outline: none;
    }
  }
}
//line stuff
.checklist-scroll-indicator-placeholder-wrapper {
  // height: 0px;
  overflow: visible;
  // width: 91%;
  width: auto;
  // margin: 0 auto;
  z-index: 12;
  left: calc(11% / 2 + 48px);
  position: absolute;
  height: calc(100% - 56px);
  // transform: translateY(18%);

  // transform: translateY(-100%);
  top: 35px;
}
.white_paper {
  width: 89%;
  margin: 0 auto;
  height: calc(100% - 56px);
  // transform: translateY(18%);
  border-radius: 8px;
  background-color: white;
  z-index: 0;
  margin-top: 0px;
  top: 35px;
  // background: repeating-linear-gradient(
  //   white,
  //   white 36px,
  //   #6ec6ed 37px,
  //   #6ec6ed 38px
  // );
  // transform: translateY(-100%);
  position: absolute;
}
.checklist-scroll-indicator-placeholder {
  // height: calc(100% - 69px);
  height: 100%;
  // margin-top: calc(100% - 200% + 103px);
  width: 2px;
  background-color: #827700;
  z-index: 2;
  justify-self: flex-start;
}
.rstcustom__lineChildren::after {
  background-color: transparent !important;
}

.scrollbar-handle {
  position: absolute;
  width: 4px;
  height: 20px;
  background-color: red;
}
