.sign-up-success-content-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  .sign-up-success-content-header {
    color: #f7b844;
    // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-align: center;
  }

  .sign-up-success-big-text {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    color: white;
    font-size: 24px;
    // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }

  .sign-up-small-texts {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    text-align: center;
    color: white;
    font-size: 14px;
    // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    a {
      font-size: 14px;
      cursor: pointer;
    }
    a:hover {
      color: #6ec6ed;
    }
  }
}
