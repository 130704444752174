.cancel-subscription-confirmed-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.cancel-subscription-confirmed-section {
  &--top {
    height: 65%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .cancel-confirmed-svg-outer {
      width: auto;
      height: 90%;
    }
  }
  &--middle {
    height: 20%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &--bottom {
    height: 15%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
