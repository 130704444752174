.copy-item-modal {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .copy-item-wrapper {
  }
  .copy-item-description {
    text-transform: lowercase;
  }
}

.copy-item-wrapper {
  color: #827700;
}

.copy-modal {
  & > div.mod-type1-title {
    height: 40px !important;
  }
}
