$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;

.modal-background {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: hsla(40, 47%, 96%, 0.722);
  z-index: 100;
  touch-action: none;
  overscroll-behavior: none;
}

.modal-background-closed {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: hsla(40, 47%, 96%, 0.722);
  z-index: -1;
  width: 100vw;
  height: 100vh;
}
.modal-child {
  // background: white;
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // overflow: hidden;
  height: fit-content;
}
.modal-background input {
  // height: 40px;
}

.mod-type1-wrapper {
  width: 97vw !important;
  max-width: 480px !important;
  overscroll-behavior: contain;
  width: 400px;
  // height: 480px;
  border-radius: 10px;
  overflow: hidden;

  .mod-type1-padder {
    overscroll-behavior: contain;

    width: 100%;
    // height: fit-content;
    height: 100%;
    display: flex;
    justify-content: flex-start;

    flex-direction: column;
    align-items: center;
    // overflow-y: scroll;
    //new-modal-design
    background-color: #364301;
    // background-color: #757a86;
    border-radius: 10px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .mod-type1-title {
    height: 30px;
    width: 100%;
    //padding-top: 15px;
    //new-modal-design
    background-color: #364301;
    // background-color: #09132b;

    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    flex-direction: row-reverse;
    .mod-type1-title-encloser {
      display: flex;
      justify-content: flex-end;
      span {
        // margin-right: 29%;
        color: white;
        font-weight: 700;
      }
    }
    .mod-type1-mod-closer {
      width: 10%;
      display: flex;
      justify-content: flex-end;
      height: 100%;
      align-items: center;
      cursor: pointer;
      padding-right: 20px;
      margin-left: -15px;
      &:hover {
        .mod-insp-svg-close {
          fill: #f7b844;
        }
      }
      .mod-insp-svg-close-outer {
        fill: #828da7;
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
    }
    .mod-type1-mod-menu {
      width: 10%;
      display: flex;
      justify-content: flex-end;
      height: 100%;
      align-items: center;
      cursor: pointer;
      &:hover {
        .mod-insp-svg-close {
          fill: #f7b844;
        }
      }
      .mod-insp-svg-close-outer {
        fill: #828da7;
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
    }
  }
  form {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }
  .mod-cnt-def {
    width: 100%;
    //new-modal-design
    background-color: #364301;
    // background-color: #757a86;
    // height: calc(100% - 30px);
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.hide-modal {
  // display: none;
}

.modal-float-mod-wrapper {
  width: fit-content;
  height: fit-content;
  .modal-float-padder {
    width: fit-content;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    .modal-float-module-title {
      height: 30px;
      width: 100%;
      background-color: #09132b;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        color: white;
        font-weight: 700;
      }
    }
  }
}

.modal-date-range-picker-wrapper {
  // background-color: gray !important;
  margin: 5px 10px;
  border-radius: 10px;
  margin-top: 20px;

  span.rdrMonthAndYearPickers {
    display: none;
  }
  div.rdrMonthName {
    text-align: center;
    font-size: 16px;
  }
  div.rdrDateDisplayWrapper {
    display: none;
  }
  div.rdrCalendarWrapper {
  }
  button.rdrNextPrevButton {
    &:hover {
      background: #f7b844 !important;
    }
  }
}

.disable-max-width {
  width: auto !important;
}

.delete-modal-message-container {
  color: #827700;
}

.mod-cnt-def {
  height: 330px;
  max-height: 330px;
}

.image-edit-body-container {
  .edit-profile-image-profile-image-wrapper {
    width: 100%;
    // height: 100%;
    position: relative;
    .edit-profile-image-profile-image-container {
      width: 100%;
      // height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      // margin-bottom: -70%;

      .edit-profile-image-profile-image {
        width: 60%;
        height: auto;
        object-fit: contain;
        border-radius: 50%;
      }
    }
    .edit-profile-image-buttons-wrapper {
      width: 100%;
      // height: calc(100% - 10px);
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      padding-bottom: 10px;
      position: absolute;
      right: 0px;
      bottom: 0px;
      .edit-profile-image-delete-button,
      .edit-profile-image-edit-button {
        width: 25px;
        height: 25px;
        margin-right: 10px;
        cursor: pointer;
        .mod-edit-profile-image-svg-outer {
          width: 100%;
          height: 100%;
          margin-bottom: 10px;
          path {
            fill: #827700;
          }
        }
        &:hover {
          .mod-edit-profile-image-svg-outer {
            path {
              fill: $var-color--light-orange;
            }
          }
        }
      }
    }
  }
  .mod-drpzn-padder {
    width: 100%;
    height: 100%;
    &:focus {
      border: none;
      outline: none;
    }
    section,
    div {
      width: 100%;
      height: 100%;
      &:focus {
        border: none;
        outline: none;
      }
    }
    .mod-drpzn {
      &:focus {
        border: none;
        outline: none;
      }
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .mod-drpzn-inner {
        height: 100% !important;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &:focus {
          border: none;
          outline: none;
        }
        .mod-profile-edit-text-wrapper {
          height: 300px;
          width: 300px;
          border-radius: 50%;
          border: 1px solid #273a09;
          cursor: pointer;
          background-color: rgba(255, 255, 255, 0.1);
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover {
            background-color: #f7b84447;
          }
          span {
            color: #827700;
          }
        }
      }
      .mod-drpzn-cam-wrapper {
        // height: 25%;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        // margin-top: -100px;
        .mod-drpzn-svg-cam-outerlayer {
          margin-right: 10px;
          width: 25px;
          height: 25px;
          fill: white;
          // &:hover{
          //   fill: #F7B844
          // }
        }
      }
    }
  }
}
