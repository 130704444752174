//screensize bigger than normal laptops
@media screen and (min-width: 1441px) {
}
@media screen and (max-width: 1440px) {
}
// screen#1 iPad pro vertical
@media screen and (max-width: 1024px) {
}
// screen#2 ipad vertical

@media screen and (max-width: 768px) and (max-width: 860px) {
}
// screen#3  surface duo

@media screen and (min-width: 481px) and (max-width: 767px) {
  #summary-wrapper {
    overflow-y: auto !important;
  }
  .hub-sv-middle {
    width: 131% !important;
    display: flex;
    justify-content: center;
    color: white;
    height: 100%;
    align-items: center;
    text-transform: uppercase;
    font-size: 0.9em;
    font-weight: 700;
    letter-spacing: 0.5px;
  }
}
// screen#4 iphone 12 pro max,
@media screen and (min-width: 415px) and (max-width: 480px) {
  #summary-wrapper {
    overflow-y: auto !important;
  }
  .hub-sv-middle {
    width: 131% !important;
    display: flex;
    justify-content: center;
    color: white;
    height: 100%;
    align-items: center;
    text-transform: uppercase;
    font-size: 0.9em;
    font-weight: 700;
    letter-spacing: 0.5px;
  }
}
// screen#5 iphone 11 && pixel 2 && pixel2 xl iphone 6/7/8, iphone x
@media screen and (min-width: 391px) and (max-width: 414px) {
  #summary-wrapper {
    overflow-y: auto !important;
  }
  .hub-sv-middle {
    width: 131% !important;
    display: flex;
    justify-content: center;
    color: white;
    height: 100%;
    align-items: center;
    text-transform: uppercase;
    font-size: 0.9em;
    font-weight: 700;
    letter-spacing: 0.5px;
  }
}
// screen#6 iphone 12, iphone 12 pro, iphone 6/7/8
@media screen and (min-width: 361px) and (max-width: 390px) {
  #summary-wrapper {
    overflow-y: auto !important;
  }
  .hub-sv-middle {
    width: 131% !important;
    display: flex;
    justify-content: center;
    color: white;
    height: 100%;
    align-items: center;
    text-transform: uppercase;
    font-size: 0.9em;
    font-weight: 700;
    letter-spacing: 0.5px;
  }
}
// screen#7 iphone 12 mini && Moto G4 && Galaxy S9 && galaxy s5 galaxy s9
@media screen and (min-width: 321px) and (max-width: 360px) {
  #edit-block-modal-mobile {
    input.floating-lode-input {
      padding: 13px;
      padding-left: 16px;
      border-radius: 10px;
      vertical-align: bottom;
      color: #09132b;
      // background-color: rgba(255, 255, 255, 0.1);
      width: calc(100% - 30px);
    }
  }
  #summary-wrapper {
    overflow-y: auto !important;
  }
  .hub-sv-middle {
    width: 131% !important;
    display: flex;
    justify-content: center;
    color: white;
    height: 100%;
    align-items: center;
    text-transform: uppercase;
    font-size: 0.9em;
    font-weight: 700;
    letter-spacing: 0.5px;
  }
}
// screen#8 iphone 5/se, galaxy fold
@media screen and (min-width: 280px) and (max-width: 320px) {
  #summary-wrapper {
    overflow-y: auto !important;
  }
  .hub-sv-middle {
    width: 131% !important;
    display: flex;
    justify-content: center;
    color: white;
    height: 100%;
    align-items: center;
    text-transform: uppercase;
    font-size: 0.9em;
    font-weight: 700;
    letter-spacing: 0.5px;
  }
}
