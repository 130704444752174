.upgrade-to-family-plan-wrapper {
  width: calc(100% - 269px);
  max-width: calc(1440x - 269px);
  height: calc(100vh - 220px);
  margin-bottom: calc((100vh - 220px) * -1);
  background-color: rgba(255, 255, 255, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin-left: -10px;
  // z-index: 10;\
  span {
    font-size: 20px;
  }
}
