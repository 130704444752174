.dash-board-block-wrapper{
  border-radius: 10px;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  box-shadow: 
    0 2px     1px   -10px    rgba(255, 255, 255, 0.093),
    0 4px     2px   -7px     rgba(255, 255, 255, 0.133),
    0 6px     5px   -10px    rgba(255, 255, 255, 0.167),
    0 12px    15px  -12px    rgba(255, 255, 255, 0.207);

    &:hover {
      box-shadow: 0px 0px 12px rgba(255, 255, 255, 0.35);
    }

  .dshb-block-top{
      //background-color: rgba(255, 255, 255, 0.4);
      width: 100%;
      border-top-right-radius: inherit;
      border-top-left-radius: inherit;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: rgb(255, 255, 255);
      height: 15px;
      border-bottom: none;
    }

  .dshb-block-middle{
    width: 100%;
    text-align: center;
  }

  .dshb_block_svgs_container {
    width: 100%;
    color: rgba(255, 255, 255, 0.35);
    display: flex;
    text-align: center;
    justify-content: center;
    // width: 20px;

    .dashboard-svg-outer{
      width:60px;
      height: 60px;
      fill: rgba(255, 255, 255, 0.35);
    }
  }

  .dshb-block-bottom {
    background-color: rgba(255, 255, 255, 0.4);
    width: 100%;
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
  
    .dshb-block-bottom-left {
      display: inline-block;
      color: white;
    }
  
    .dshb-block-bottom-center {
      display: inline-block;
      color: white;
      text-align: center;
      font-size: 11px;
      width: 70%;
    }
  
    .dshb-block-bottom-right {
      display: inline-block;
    }
  }

}

.dshb-block-small{
  width: 20%;
  height: 100px;
  margin: 10px;
}

.dshb-block-medium{
  width: 45%;
  height: 100px;
  margin: 10px;
}

.dshb-block-large{
  width: 45%;
  height: 200px;
  margin: 10px;
}


