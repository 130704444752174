.animate-text {
  &-underline {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0.1em;
      background-color: $green6;
      opacity: 1;
      transition: opacity 300ms, transform 300ms;
      transform: scale(0);
      transform-origin: center;
    }
    &:hover::after {
      transform: scale(1);
    }
  }
  &-underline-white {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0.1em;
      background-color: white;
      opacity: 1;
      transition: opacity 300ms, transform 300ms;
      transform: scale(0);
      transform-origin: center;
    }
    &:hover::after {
      transform: scale(1);
    }
  }
}

.animate-button {
  &-slideright {
    box-shadow: inset 0 0 0 0 #ffffffa0;
    -webkit-transition: ease-out 0.2s;
    -moz-transition: ease-out 0.2s;
    transition: box-shadow ease-out 0.2s;
    &:hover {
      box-shadow: inset 300px 0 0 0 #ffffffa0;
    }
  }
}

.animate-text-grow-size-whole {
  animation: grow-whole 2s infinite linear;
  // animation-timing-function: cubic-bezier();
  span {
  }
}
.animate-text-grow-left-to-right {
  span {
    animation: grow-size 0.7s infinite;
    @for $i from 1 through 6 {
      &:nth-child(#{$i + 1}) {
        animation-delay: #{$i * 0.1}s;
      }
    }
  }
}
// input error animations
@-webkit-keyframes kf_shake {
  0% {
    -webkit-transform: translate(30px);
  }
  20% {
    -webkit-transform: translate(-30px);
  }
  40% {
    -webkit-transform: translate(15px);
  }
  60% {
    -webkit-transform: translate(-15px);
  }
  80% {
    -webkit-transform: translate(8px);
  }
  100% {
    -webkit-transform: translate(0px);
  }
}
@-moz-keyframes kf_shake {
  0% {
    -moz-transform: translate(30px);
  }
  20% {
    -moz-transform: translate(-30px);
  }
  40% {
    -moz-transform: translate(15px);
  }
  60% {
    -moz-transform: translate(-15px);
  }
  80% {
    -moz-transform: translate(8px);
  }
  100% {
    -moz-transform: translate(0px);
  }
}
@-o-keyframes kf_shake {
  0% {
    -o-transform: translate(30px);
  }
  20% {
    -o-transform: translate(-30px);
  }
  40% {
    -o-transform: translate(15px);
  }
  60% {
    -o-transform: translate(-15px);
  }
  80% {
    -o-transform: translate(8px);
  }
  100% {
    -o-origin-transform: translate(0px);
  }
}
.error-wiggle {
  -webkit-animation: kf_shake 0.4s 1 linear;
  -moz-animation: kf_shake 0.4s 1 linear;
  -o-animation: kf_shake 0.4s 1 linear;
}

/// loading animations

@keyframes animateC {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animate {
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(405deg);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes bounce-up-and-down {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(15px);
  }
}

@keyframes grow-size {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}

@keyframes grow-whole {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes launch {
  0% {
    opacity: 1;
    transform: translateX(0) scale(1);
  }
  10%,
  15% {
    transform: translateX(0) scale(0.5);
  }
  30%,
  35% {
    transform: translateX(-20) scale(0.5);
  }
  40%,
  70% {
    transform: translateX(100vw) scale(0.5);
  }
  70% {
    opacity: 1;
  }
  71% {
    opacity: 0;
    transform: translateX(100vw) scale(0.5);
  }
  72% {
    opacity: 0;
    transform: translateX(0px) scale(0.5);
  }
  100% {
    opacity: 0;
    transform: translateX(0px) scale(1);
  }
}
@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 9px;
    opacity: 1;
  }
  40% {
    height: 18px;
    width: 9px;
    opacity: 1;
  }
  100% {
    height: 18px;
    width: 9px;
    opacity: 1;
  }
}
