$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;

.hub-downloads-wrapper {
  width: calc(100% - 10px);
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  .hub-downloads-content {
    &--extension {
      margin-top: 20px;
    }
    &--pwa {
      margin-top: 20px;
    }
    &--pwa,
    &--extension {
      .hub-download-content {
        &--title {
          font-weight: 700;
          color: $var-dark-green;
        }
        &--extension-content {
          width: 100%;
          display: flex;
          justify-content: flex-start;
        }
        &--pwa-content {
          width: 100%;
          display: flex;
          justify-content: flex-start;
        }
      }
    }
  }
  p,
  h3,
  h2,
  li,
  strong,
  a {
    // color: $var-color--dar-blue;
    color: $var-dark-green;
  }
  a {
    &:hover {
      color: $var-color--light-blue;
    }
  }
  p,
  li,
  strong,
  a {
    // font-size: 19px;
  }
  p,
  li {
    line-height: 1.5em;
    letter-spacing: 1.2px;
    font-size: 1.4em;
  }
}

.downloads-page-title-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  h2 {
    text-align: center;
  }
}
