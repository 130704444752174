$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;

.add-edit-account-members-section-wrapper {
  width: 100%;
  height: 356px;
  // background-color: black;
  display: flex;

  .add-edit-account-member-section {
    width: calc(50% - 40px);
    height: calc(100% - 20px);
    padding: 10px;
    margin: 10px;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 5px;
    h3 {
      margin-top: 0px;
    }
    hr {
      width: 100%;
      border: none;
      color: rgba(0, 0, 0, 0.7);
      background-color: rgba(0, 0, 0, 0.7);
      height: 1px;
      margin: 0px;
    }
    &--add {
      .add-account-member-section-contents-wrapper {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
        .addacount-member-contents {
          // width: 50%;
          height: 100%;
          &--summary {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            .add-account-member-summary-divider {
              width: 80%;
              margin: 0 auto;
            }
            .add-account-member-summary-top {
              display: flex;
              flex-direction: column;
              width: 100%;
              align-items: center;
              margin-top: 10px;
              .add-account-member-summary-lodestar-log {
                width: 100%;
              }
            }
            .add-account-member-summary-middle {
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              height: 50%;
              padding-bottom: 20px;
              .add-account-member-summary-middle-content-wrapper {
                &--plan-name {
                  font-size: 1.7em;
                  margin-top: 5px;
                  font-weight: 700;
                  text-align: center;
                }
                &--plan-description {
                  font-size: 1.1em;
                  text-align: center;
                  margin-top: 5px;
                }
                &--plan-renewal-date {
                  font-size: 0.8em;
                  text-align: center;
                  margin-top: 5px;
                }
              }
            }
            .add-edit-account-member-bottom {
              display: flex;
              width: 100%;
              height: fit-content;
              flex-direction: column;
              height: 35%;
              .add-edit-account-explorer-count-wrapper {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                height: 80%;
                .add-edit-account-explorer-count-content-wrapper {
                  display: flex;
                  flex-direction: column;
                  width: 32%;
                  height: 70px;
                  justify-content: flex-end;

                  .explorer-count-label-wrapper {
                    height: 40px;
                    width: 100%;
                    display: flex;
                    text-align: center;
                    align-items: flex-start;
                    justify-content: center;
                    .add-edit-account-explorer-count--label {
                      font-size: clamp(0.7em, 1vw, 0.9em);
                    }
                  }

                  &--limit {
                    .add-edit-account-explorer-count {
                      &--count {
                        font-size: 18px;
                        color: $var-color--dar-blue;
                        font-weight: 700;
                        text-align: center;
                      }
                    }
                  }
                  &--count {
                    .add-edit-account-explorer-count {
                      &--count {
                        font-size: 18px;
                        color: $var-color--light-orange;
                        font-weight: 700;
                        text-align: center;
                      }
                    }
                  }
                  &--remaining {
                    .add-edit-account-explorer-count {
                      &--count {
                        font-size: 18px;
                        color: $var-color--dark-orange;
                        font-weight: 700;
                        text-align: center;
                      }
                    }
                  }
                }
              }
              .add-edit-account-buttons-wrapper {
                width: 100%;
                display: flex;
                justify-content: center;
                .add-edit-subscription-button {
                  &--upgrade,
                  &--edit {
                    border: none;
                    outline: none;
                    border-radius: 5px;
                    width: 30%;
                    height: 40px;
                    background-color: #273a07;
                    margin: 0px 2%;
                    color: white;
                    cursor: pointer;
                    &:hover {
                      background-color: $var-color--light-blue;
                    }
                    &:disabled {
                      background-color: lightgray;
                      cursor: not-allowed;
                    }
                  }
                  // &--upgrade {
                  // }
                }
              }
            }
          }
          &--addmember {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 90%;
            .addaccount-member-add-button {
              width: calc(100% - 40px);
              height: calc(100% - 40px);
              padding: 10px;
              margin: 10px;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: transparent;
              border: none;
              outline: none;
              cursor: pointer;
              &:focus {
                border: none;
                outline: none;
              }
              &:hover {
                & > div {
                  border-color: $var-color--light-orange;
                  background-color: #f7b8447e;
                }
              }
              .addaccount-member-add-button-inner-wrapper {
                width: calc(100% - 30px);
                height: calc(100% - 30px);
                margin: 10px;
                border: 5px rgba(255, 255, 255, 0.8) solid;
                border-radius: 5px;
                background-color: rgba(255, 255, 255, 0.4);
                display: flex;
                justify-content: center;
                align-items: center;
                span {
                  color: black;
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
    &--edit {
      .add-edit-account-member-section-contents-wrapper {
        height: calc(100% - 40px);
        overflow-y: auto;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
}
