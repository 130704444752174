//Abstract files
@import 'abstracts/index';

//Component files
@import 'components/index';

//Core files
@import 'core/index';

//Layout files
@import 'layout/index';

//Modal Components
@import 'modal_components/index';

//Modal files
@import 'modals/index';

//Pages files
@import 'pages/index';

//Responsive files
@import 'responsive/index';

//Section files
@import 'sections/index';

//Vendor files
@import 'vendors/index';

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}
/* .app-div{
height:100%;
position:relative;
overflow-x:hidden;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
  }
  to {
  }
}

.grecaptcha-badge {
  opacity: 0;
}
