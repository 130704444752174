.itinerary_modal {
  right: -15px;
  padding: 0px 15px 0px 0px;
  width: calc(100% + 15px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.itinerary-modal-wrapper {
  height: 505px !important;

  .mod-type1-padder {
    &>div.mod-type1-title {
      height: 40px !important;
    }
  }
}

.itinerary-modal-wrapper-edit {
  height: 556px !important;

  .mod-type1-padder {
    overscroll-behavior: contain;

    &>div.mod-type1-title {
      height: 40px !important;
    }
  }
}

.mod-cnt-def-create-itinerary {
  width: 100%;
  background-color: #364301;
  overflow-y: scroll;
  max-height: 384px;

  &::-webkit-scrollbar {
    display: none;
  }

  &>div {
    overflow: display;
    height: 346px;
  }
}

.mod-cnt-def-create-itinerary-edit {
  width: 100%;
  background-color: #364301;
  // background-color: #757a86;
  overflow-y: scroll;
  max-height: 364px;

  &::-webkit-scrollbar {
    display: none;
  }

  &>div {
    overflow: display;
    height: 324px;
  }

  .comments-comment-wrapper {
    display: flex;
    flex-direction: column;
    height: calc(251px - 115px);
    overflow-y: scroll;
  }
}

.itinerary-modal {
  &>div.mod-type1-title {
    height: 45px !important;
  }
}

.itinerary-details-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;

  .itinerary-details-span {
    width: 96%;
    color: white;
    font-size: 0.9em;
    margin: 0 auto;
  }
}

.create-itinerary-wrapper {
  width: 400px;
  max-width: calc(100vw - 80px);
  max-height: calc(var(--vh, 1vh) * 100 - 300px);
  padding-left: 40px;
  padding-right: 40px;
  overflow-y: auto;
}

@media screen and (max-width: 425px) {
  .create-itinerary-wrapper {
    width: 300px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.create-itinerary-wrapper::-webkit-scrollbar {
  width: 4px !important;
}

.create-itinerary-wrapper::-webkit-scrollbar-thumb {
  background: #827700;
}

.create-itinerary-wrapper::-webkit-scrollbar-track {
  position: relative;
  border-right: 1px solid #273A07;
}

.create-itinerary-wrapper::-webkit-scrollbar-track::after {
  position: absolute;
  left: 2px;
  width: 1px;
  height: 100%;
  background-color: #273A07;
}

.itinerary-section-container {
  width: 100%;
  height: 377px;
}

.comments-wrapper-itinerary {
  height: 100%;

  .comment-view-wrapper {
    width: 100%;
    flex-grow: 1;
    max-height: calc(100% - 120px);
  }
}