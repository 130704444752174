$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;

.hub-friends-container {
  max-width: 1440px;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 2;
  height: calc(100% - 88px);
  overflow-y: auto;
  margin-left: auto;
  margin-right: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  .hub-friends-tab--wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .hub-friends-contents {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    //background-color: rgba(255, 255, 255, 0.5);
    //border-radius: 10px;
    min-height: calc(100% - 88px);
  }
}

.hub-friends-tab--wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  cursor: pointer;
  border-bottom: 1px solid $var-color--light-orange;
  .hub-friends-tab {
    border: 1px solid $var-color--light-orange;
    color: $var-color--light-orange;
    width: 120px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin: 0px 5px;
  }
  .hub-friends-tab--selected {
    background-color: $var-color--light-orange;
    color: $var-color--dar-blue;
  }
}

$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;
@keyframes grow {
  0% {
    height: 100px;
  }

  98% {
    height: 450px;
  }
}

@keyframes growItinerary {
  0% {
    height: 0px;
  }

  98% {
    height: 230px;
  }
}

@keyframes shrink {
  0% {
    height: 450px;
  }

  98% {
    height: 150px;
  }
}

@keyframes shrinktitle {
  0% {
    height: 150px;
  }
  100% {
    height: 50px;
  }
}

@keyframes growbottom {
  0% {
    height: 0px;
  }
  100% {
    height: 370px;
  }
}

@keyframes growbottomItinerary {
  0% {
    height: 0px;
  }
  100% {
    height: 230px;
  }
}

@keyframes growbottomcrisp {
  0% {
    height: 0px;
  }
  100% {
    height: 160px;
  }
}

.hub-friends-blocks-unselected {
  cursor: pointer;
  &:hover {
    .hub-friends-blocks-titles {
      // color: #f7b844;
      color: rgb(82, 34, 34);
    }
  }
  &:hover {
    // box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.35);
    background-color: #6d7381;
    .hub-friends-blocks-titles {
      color: #f7b844;
    }
  }
  width: 95%;
  height: 50px;
  // border: 2px solid #f7b844;
  box-shadow: 0 2px 1px -10px rgba(255, 255, 255, 0.093),
    0 4px 2px -7px rgba(255, 255, 255, 0.133),
    0 6px 5px -10px rgba(255, 255, 255, 0.167),
    0 12px 15px -12px rgba(255, 255, 255, 0.207);
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #3a4256;
  //   animation-name: shrink;
  // animation-duration: 0.5s;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.hub-friends-itineries-unselected {
  //   animation-name: shrink;
  // animation-duration: 0.5s;
}

.hub-friends-files-unselected {
  cursor: pointer;
  &:hover {
    .hub-friends-blocks-titles {
      color: #f7b844;
    }
  }
  &:hover {
    // box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.35);
    background-color: #6d7381;
    .hub-friends-blocks-titles {
      color: #f7b844;
    }
  }
  width: 95%;
  height: 50px;
  // border: 2px solid #f7b844;
  box-shadow: 0 2px 1px -10px rgba(255, 255, 255, 0.093),
    0 4px 2px -7px rgba(255, 255, 255, 0.133),
    0 6px 5px -10px rgba(255, 255, 255, 0.167),
    0 12px 15px -12px rgba(255, 255, 255, 0.207);
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #3a4256;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;

  //     animation-name: shrink;
  // animation-duration: 0.5s;
}
.hub-friends-blocks-titles {
  font-size: 18px;
  color: $var-dark-green;
  text-transform: uppercase;
  font-weight: 700;
  &:hover {
    color: #f7b844;
  }
}

.hub-friends-block-titles {
  font-size: 18px;
  color: white;
  text-transform: uppercase;
  font-weight: 700;
}
//// seelectoed from herer
// .hub-friends-itineries-selected {
//   animation-name: grow;
//   animation-duration: 0.5s;
// }

.hub-friends-blocks-selected {
  width: 95%;

  border-radius: 15px;

  height: 293px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .hub-friends-block-adder-button {
    // width: 100%;
    justify-content: flex-start;
    display: flex;
  }
}

.hub-friends-sections {
  transition: transform 0.6s ease-out; // note that we're transitioning transform, not height!
  background-color: white;
  transform-origin: top;
  &.--extra-margin-top {
    margin-top: 20px;
  }
  &--selected {
    width: calc(95% - 32px);
    padding: 16px;
    border-radius: 15px;
    margin: 0 auto;
    margin-top: -34px;
    margin-bottom: 20px;
    height: auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    justify-content: flex-start;
    align-items: center;
    transition: transform 0.6s ease-out; // note that we're transitioning transform, not height!
    transform: scaleY(1); // implicit, but good to specify explicitly
    transform-origin: top;
  }
  &--unselected {
    transform: scaleY(1);
    cursor: pointer;
    &:hover {
      .hub-friends-blocks-titles {
        color: white;
      }
    }
    &:hover {
      // box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.35);
      background-color: #eae094;
      .hub-friends-blocks-titles {
        color: $var-dark-green;
      }
    }
    width: 95%;
    height: 50px;
    // border: 2px solid #f7b844;
    box-shadow: 0 2px 1px -10px rgba(255, 255, 255, 0.093),
      0 4px 2px -7px rgba(255, 255, 255, 0.133),
      0 6px 5px -10px rgba(255, 255, 255, 0.167),
      0 12px 15px -12px rgba(255, 255, 255, 0.207);
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: $var-tab-background;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.hub-friends-files-selected {
  width: 95%;
  // min-height: 450px;
  // height: auto;
  height: 450px;
  // border: 2px solid #f7b844;
  border-radius: 15px;

  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  // background-color: #3A4256;
}
.hub-friends-block-titles-sel {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  color: #f7b844;
  font-size: 20px;
  height: 30px;
  border-bottom: 2px solid #f7b844;
  align-items: center;
  // animation-name: shrinktitle;
  // animation-duration: 0.5s;
  // border-top-right-radius: inherit;
  // border-top-left-radius: inherit;
  // border-bottom: 1px solid #f7b844;

  .hub-friends-block-adder-button {
    width: 10%;
    border-top-left-radius: inherit;
    padding-left: 40px;
    font-size: 30px;
  }
  .hub-friends-block-closer {
    width: 10%;
    border-top-left-radius: inherit;
    padding-right: 40px;
    display: flex;
    justify-content: flex-end;
  }
}

.hub-friends-blocks-bottom {
  display: flex;
  flex-direction: row;
  height: calc(60% - 30px);
  width: 100%;
  max-width: 100%;
  // background-color: #09132b;
  // border: 2px solid #f7b844;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  justify-content: flex-start;
  align-items: center;
  overscroll-behavior: contain;
  // margin-top:35px;
  // overflow-x: scroll;
  // justify-content: center;
  overflow-x: auto;

  &::-webkit-scollbar- {
    display: none;
  }
  &::-webkit-scrollbar {
    display: none;
  }
}
.hub-friends-list-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: fit-content;
}

.hub-friend-section-selectors {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-direction: row-reverse;
  position: relative;
  .hub-friends-adder-button-container {
    width: 7%;
    justify-content: flex-start;
    display: flex;
    margin-right: -4%;
    margin-left: -3%;
    z-index: 5;
    // margin-left: 10px;
    // border-bottom: 1px solid #254562;
  }

  .hub-friends-section-title-container {
    width: 100%;
    justify-content: left;
    display: flex;
    //border-bottom: 1px solid $var-dark-green;
    height: 35px;
    & > div {
      display: flex;
      height: auto;
      align-items: flex-end;
      & > span {
        color: $var-dark-green;
        font-weight: 700;
        font-family: Soleil !important;
        @media screen and (min-width: 990px) {
          font-size: 24px;
        }
        @media screen and (max-width: 990px) {
          font-size: 18px; 
        }
        @media screen and (max-width: 450px) {
          font-size: 14px; 
        }
      }
    }
  }
}

.hub-friends-close-button-container {
  &:hover {
    fill: #f7b844;
    path {
      fill: #f7b844;

      cursor: pointer;
    }
    svg,
    path {
      fill: #f7b844 !important;

      cursor: pointer;
      &:hover {
        fill: #f7b844;
      }
    }
  }
}

@keyframes growbottomcrispforsummary {
  0% {
    height: 0px;
  }
  100% {
    height: fit-content;
  }
}

.hub-friends-no-content-svg-outer {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;

  &:hover {
    path {
      fill: $var-color--light-blue;
    }
  }
  path {
    fill: $var-color--dar-blue;
  }
}

.hub-friends-margin-top-remover {
  margin-top: 0px !important;
  // .adv-blk-title-container {
  //   height: 35px;
  // }
}

// .hub-friends-content-section--wrapper {
//   min-height: 230px;
// }

.no-friends-text-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: fit-content !important;
  margin-top: 10px;

  &:hover {
    cursor: pointer;
  }
  .no-friends-text {
    cursor: pointer !important;

    color: $var-dark-green;
    font-size: 18px;

    //&:hover {
    //  color: $var-color--light-blue;
    // cursor: pointer;
    //}
  }
  &:hover {
    // .no-friends-text {
    //   color: $var-color--light-blue;
    // }
    path {
      fill: $var-color--light-blue;
    }
  }
}
.no-hover {
  &:hover {
    .no-friends-text {
      color: $var-color--dar-blue !important;
    }
  }
}

.hub-friend-section-selectors {
  & > div.hub-friends-close-button-container {
    width: 7%;
    display: flex;
    justify-content: flex-end !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
    z-index: 10;
    border-bottom: none;
    position: absolute;
    right: 2%;
    button.hub-custom-btn {
      margin-top: 8px;
      path {
        fill: $var-dark-green;
      }
    }
  }
}
// .adv-blk-title-container--no-bottom-border {
//   button.hub-custom-btn {
//     margin-top: 13px;
//   }
// }

.hub-friends-tooltips-container {
  &:hover {
    path,
    svg {
      fill: $var-color--light-orange;
    }
  }
  &::before {
    margin-top: 8px !important;
  }
}

.new-friends-search-bar-wrapper {
  width: 90%;
  height: 48px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  margin-top: 10px;
  border-radius: 10px;
  padding: 10px 0px;
  &.--invite-new-friends {
    flex-direction: column;
    // height: 86px;
    height: auto;
    padding: 5px 0px;

    .invite-new-friends-searchbar-wrapper {
      width: 455px;
      display: flex;
      height: 48px;
      border: 1px solid #273A07;
      border-radius: 6px;
    }
    .invite-new-friends-email-error-wrapper {
      height: 30px;
      display: flex;
      justify-content: flex-start;
      width: 455px;
      max-width: 77%;

      .email-error-container {
        padding-left: 2px !important;
        margin: 0px !important;
      }
      span {
        color: #09132b !important;
      }
    }
    .new-friends-search-bar-wrapper {
      margin-top: 0px;
      background-color: transparent !important;
    }
  }
  &.--search-friends {
    .new-friends-search-container {
      margin-top: 16px;
      width: 455px;
      border: 1px solid #273a07;
      border-radius: 5px;
    }
  }
  .new-friends-search-container {
    background: white;
    min-width: 350px;
    width: 350px;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    overflow: hidden;
    .new-friends-svg-search-wrapper {
      width: 20px;
      height: 20px;
      path {
        fill: rgba(0, 0, 0, 0.4);
      }
    }
    .new-friends-search-bar {
      width: calc(100% - 30px);
      height: 95%;
      border: none;
      padding: 0px 16px;
      &:focus {
        outline: none;
      }
    }
  }
  .invite-new-member-button {
    // width: 100px;
    // height: 100%;
    // background-color: $var-color--light-orange;
    // border-radius: 5px;
    // border: none;
    outline: none;
    // cursor: pointer;
    // margin-left: 5px;
    &:focus {
      outline: none;
    }
    // &:hover {
    //   background-color: $var-color--light-blue;
    // }
    font-size: 14px !important;
    font-family: Soleil !important;
    font-style: normal;
    font-weight: 700;
    @media screen and (max-width: 720px) {
      font-size: 12px !important;
    }
  }
}

.show-more-button-wrapper {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  .hub-friends-show-more-button {
    width: 100px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: $var-color--light-orange;
    border: none;
    outline: none;
    cursor: pointer;
    &:hover {
      background-color: $var-color--light-blue;
    }
    &:focus {
      outline: none;
    }
  }
}

.hub-friend-invites-title {
  display: flex;
  .hub-friend-invites-count {
    color: white;
  }
  .hub-friend-invites-count--wrapper {
    width: 25px;
    height: 20px;
    margin-top: -20px;
    margin-right: -25px;
    .hub-friend-invite-count-body {
      width: 100%;
      height: 100%;
      background-color: #da4431;
      border-radius: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .hub-friend-invites-count-tail {
      &:before {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        /* left: -8px; */
        right: auto;
        top: auto;
        /* bottom: -40px; */
        border: 5px solid #da4431;
        border-color: transparent transparent transparent transparent;
        margin-left: 3px;
      }
      &:after {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        right: auto;
        top: auto;
        border: 3px solid;
        border-color: #da4431 transparent transparent #da4431;
      }
    }
  }
}

.friends-prompt-text {
  margin-top: 10px;
  //text-align: center;
  width: 100%;
  font-style: normal;
  color: #273a07;
  font-family: Soleil !important;
  @media screen and (min-width: 990px) {
    font-size: 20px;
  }
  @media screen and (max-width: 990px) {
    font-size: 12px; 
  }
}

.invite-new-friends-divider {
  width: 80%;
  background-color: #09132b;
  margin-bottom: 0px;
}
