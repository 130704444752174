.banner-two-pics-container {
  background-color: #09132b;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  // height:180px;

  .pic-stack-container {
    border: 1px solid white;
    display: flex;
    flex-direction: column;
    border-radius: 40px;
    width: 95%;
    margin: 0 auto;
    margin-bottom: 30px;
    &:hover {
      .pic-stack-top {
        background-color: #f7b844;
      }
    }
    div {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 50px;
      align-items: center;
      background-color: #2e364b;
      border-top-right-radius: inherit;
      border-top-left-radius: inherit;
      img {
        width: auto;
        height: 45px;
      }
    }
  }

  .top-section-second {
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      color: #f7b844;
    }
    span {
      color: #f7b844;
      letter-spacing: 2px;
      font-size: 18px;
      font-weight: 600;
    }

    a {
      text-decoration: none;
      div {
        margin-top: 20px;
        background-color: #f7b844;
        width: 250px;
        display: flex;
        justify-content: center;
        border-radius: 5px;
        color: black;
        cursor: pointer;
        margin-bottom: 20px;
      }
    }
  }
  img {
    width: 100%;
    height: 100%;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
  }
  .left {
    position: relative;
    color: white;
    .left-text {
      position: absolute;
      top: 40%;
      font-size: 34px;
      // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
      right: 10px;
      text-align: right;
      font-weight: 600;
    }
  }

  .right {
    position: relative;
    color: black;
    .right-text {
      position: absolute;
      top: 40%;
      font-size: 34px;
      // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
      left: 10px;
      text-align: left;
      font-weight: 600;
      width: auto;
    }
  }

  .image-center-log {
    position: absolute;
    width: 100px;
    height: 50px;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .center-text-imag {
      margin-left: 10px;
    }
  }
}

#link-sign-up {
  width: 250px;
  border: none;
  background-color: #80aea8;
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 20px;
  height: 37px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  span {
    font-weight: 500;

    color: #09132b;
    // color:white;

    font-size: 18px;
  }
}
