.mod-cnt-def-unsplash-image {
  height: 460px;
  max-height: 460px;
  width: 100%;
  // background-color: #757a86;
  background-color: #364301;
  // overflow-y: scroll;
}
.delete-modal-wrapper {
  & > div.mod-type1-title {
    height: 45px !important;
  }
}
.fetch-image-from-unsplash-modal--wrapper {
  display: flex;
  height: 510px;

  .fetch-image-from-unsplash-modal--contents--title {
    background-color: rgba(255, 255, 255, 0.4);
    height: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 8px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    span {
    }
  }
  .fetch-image-from-unsplash-modal--contents--container {
    background-color: rgba(255, 255, 255, 0.4);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    width: 80%;
    border-radius: 10px;
    height: 260px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    .unsplash-image--wrapper {
      // width: 100px;
      height: 131px;
      margin: 5px;
      border: 2px solid transparent;
      img {
        width: inherit;
        height: inherit;
        object-fit: contain;
      }
    }
    .seleted-image-wrapper {
      border: 5px solid #f7b844;
    }
  }
}
.loadmore-button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  .image-modal-loadmore-button {
    width: 100px;
    height: 25px;
    border: none;
    border-radius: 5px;
    background-color: #da4431;
    color: white;
  }
}

.pexel-modal-top {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  height: 40px;
  margin-top: 20px;
  padding-top: 4px;
  margin-bottom: 20px;
  overflow-y: hidden;
  & > div {
    padding: 0px;
    margin-top: 0px !important;
  }
}
.pexel-search-button {
  border: none;
  width: auto;
  height: 108%;
  margin-left: 4px;
  background-color: #f7b844;
  z-index: 20;
  border-radius: 5px;
  /* margin-bottom: -6px; */
  /* margin-top: 3px; */
  cursor: pointer;
  margin-left: -51px;
  margin-bottom: 5px;
  &:hover {
    background-color: #6ec6ed;
  }
}
