$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
.block-list-wrapper {
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.block-list-container {
  height: auto;
  display: flex;
  flex-direction: column;
  // border: 2px solid yellow;
  margin: 2px 0px;
  border-radius: 10px;
  overflow: hidden;
  color: white;
  .block-list {
    &--top {
      height: 25px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: #838892;
      align-items: center;
      padding: 5px 0px;

      .block-modal-list {
        &--right--editing {
          width: 55%;
          display: flex;
          justify-content: flex-start;
          border-radius: 5px;
          padding: 5px 0px;
          padding-left: 2px;
          align-items: center;
          margin-left: 5px;
          margin-top: 4px;
          margin-bottom: 5px;
          box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.11);
          border: 0.2px solid #70707023;
          background-color: rgba(255, 255, 255, 0.4);
          .list-form-priority-text {
            font-size: 11px !important;
            color: rgba(0, 0, 0, 0.4);
          }
          .block-modal-list-scheduling-container {
            display: flex;
            justify-content: flex-start;
            height: 20px;
            align-items: center;
            margin-right: 10px;
            .block-list {
              width: 50px;
              margin-right: 10px;

              &--high {
                width: 10px;
                height: 10px;
                border-radius: 10px;
                background-color: red;
                margin-left: 10px;
              }
              &--medium {
                width: 10px;
                height: 10px;
                border-radius: 10px;
                background-color: orange;
              }
              &--low {
                width: 10px;
                height: 10px;
                border-radius: 10px;
                background-color: green;
              }
            }
          }
        }
      }
      .modal-list-input-form-top-buttons-container {
        display: flex;
        align-items: center;
        margin-right: 5px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-right: 10px;
        .modal-list-priority-button {
          // border: 1px solid black;
          border-radius: 50%;
          color: black !important;
          // width: 95px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 3px solid transparent;
          height: fit-content !important;
          &:hover {
            border: 3px solid rgba(0, 0, 0, 0.3);
          }

          & > div {
            // margin-left: 2px;
            margin: 0px !important;
          }
          &--selected {
            // background-color: black;
            border: 3px solid rgba(0, 0, 0, 0.4);
            color: white !important;
          }
        }
        .modal-list-input-form {
          &--buttons {
            background-color: $var-color--light-orange;
            border: none;
            border-radius: 5px;
            margin: 0px 2px;
            cursor: pointer;
            &:focus {
              outline: none;
            }
            &:hover {
              background-color: $var-color--light-blue;
            }
            &--save {
              border: none;
              border-radius: 5px;
            }
            &--cancel {
              margin-left: 50px;
              border: none;
              border-radius: 5px;
            }
          }
        }
      }
      .block-list--top {
        &--left {
          width: 63%;
          margin-left: 4px;
          display: flex;
          .block-list--block-name {
            // border-bottom:5px solid red;
          }
        }
        &--right {
          width: 35%;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          .block-list--buttons {
            width: 25px;
            height: 25px;
            background-color: transparent;
            border: none;
            cursor: pointer;

            .block-list-view-svg-outerlayer {
              fill: rgba(255, 255, 255, 0.4);
              width: 13px;
              height: 13px;
            }
            &:hover {
              .block-list-view-svg-outerlayer {
                fill: #f7b844;
              }
            }
          }
        }
      }
    }
    &--middle {
      background-color: #838892;
      width: 100%;
      height: 18px;
      animation: closeBlock 0.1s linear;
      overflow: hidden;
      @keyframes closeBlock {
        0% {
          height: 60px;
        }
        100% {
          height: 18px;
        }
      }
      .block-description-container {
        width: 98%;
        margin-left: 4px;
        span {
          font-size: 13px;
        }
      }
      &--is-editing {
      }
    }
    &--middle--open {
      transition: smooth;
      height: 60px;
      animation: openBlock 0.1s linear;
      @keyframes openBlock {
        0% {
          height: 45px;
        }
        100% {
          height: 60px;
        }
      }
    }
    &--middle--is-editing {
      // transition: smooth;
      animation: openEdit 0.2s !important;
      animation-iteration-count: 1;
      animation-timing-function: linear;
      // animation-delay: 0.2s;
      animation-duration: 0.2s;
      height: 228px !important;
      overflow: hidden;

      @keyframes openEdit {
        0% {
          min-height: 45px;
        }
        100% {
          height: 198px;
        }
      }
    }
    &--bottom {
      width: 100%;
      display: flex;
      height: 25px;
      justify-content: flex-end;
      background-color: #646a78;
      align-items: center;
      .block-list--bottom {
        &--file-container {
          width: 10%;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          span {
            font-size: 15px;
          }
        }
        &--count-container {
          width: 25%;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-right: 10px;

          span {
            font-size: 15px;
          }
        }
        &--cost-container {
          width: 25%;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-right: 10px;
          span {
            font-size: 15px;
          }
        }
        &--total-container {
          width: 33%;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          span {
            font-size: 15px;
          }
        }
        &--checkbox-container {
          width: 6%;
          height: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-right: 10px;
          & > div {
            display: flex;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;
          }
          span {
            font-size: 15px;
          }
        }
      }
    }
  }
}

.priority-box {
  width: 10px;
  height: 10px;
  // width: 35px;
  // height: 20px;
  margin: auto;
  margin: auto 0px;
  margin-right: 5px;
  display: flex;
  border-radius: 10px;
  justify-content: center;
}

.block-modal-list-scheduling {
  display: flex;
  color: rgba(0, 0, 0, 0.3);
  width: 100%;
  justify-content: flex-start;
  margin-top: 5px;
  align-items: center;
  height: 20px;
  font-size: 14px;

  .block-modal-list {
    &--left {
      width: 11%;
      height: auto;
      display: flex;
      align-items: center;
      span {
        font-size: 14px;
        margin-left: 2px;
        // color: rgba(0, 0, 0, 0.6);
        color: rgba(0, 0, 0, 0.3);
      }
    }
    &--right {
      width: 55%;
      display: flex;
      justify-content: flex-start;
      border-radius: 5px;
      padding: 5px 0px;
      padding-left: 2px;

      margin-left: 5px;
      margin-top: 4px;
      margin-bottom: 5px;
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.16);
      border: 0.2px solid #70707023;
      &--no-box-shadow {
        box-shadow: none;
      }
      .list-form-priority-text {
        font-size: 13px;
        color: rgba(0, 0, 0, 0.4);
      }
      .block-modal-list-scheduling-container {
        display: flex;
        justify-content: flex-start;
        height: 20px;
        align-items: center;
        margin-right: 10px;
        .block-list {
          width: 50px;
          margin-right: 10px;

          &--high {
            width: 10px;
            height: 10px;
            border-radius: 10px;
            background-color: red;
            margin-left: 10px;
          }
          &--medium {
            width: 10px;
            height: 10px;
            border-radius: 10px;
            background-color: orange;
          }
          &--low {
            width: 10px;
            height: 10px;
            border-radius: 10px;
            background-color: green;
          }
        }
      }
    }
  }
}

.list-module-inputs {
  // background-color: transparent;
  border: none;
  outline: none;
  &:focus {
    outline: none;
  }
  &--block_name {
  }
  &--block_description {
  }
  &--block_count {
    width: 20px;
  }
  &--block_cost {
    width: 20px;
  }
}

.list-edit-inputs-container {
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  // animation-delay: 0.2s;
  animation-duration: 0.1s;
  @keyframes fadeInOpacity {
    0% {
      // display: none;
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  opacity: 1;

  &--block_name {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.16);
  }
  &--block_description {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.16);
    margin-top: 5px;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 5px;
  }
  &--block_count {
    display: flex;
    flex-direction: row;
  }
  &--block_cost {
    display: flex;
    flex-direction: row;
  }
  .list-edit-inputs-indicator {
    color: rgba(0, 0, 0, 0.4);
    // color: black;
    z-index: 5;

    font-size: 11px !important;

    &--block_name {
      margin-left: 4px;
    }
    &--block_description {
      margin-left: 4px;
    }
    &--block_count {
    }
    &--block_cost {
    }
  }
  .list-edit-inputs {
    // background-color: transparent;
    border: none;
    border-radius: 5px;

    &:focus {
      outline: none;
    }
    &--block_name {
      margin-top: -14px;
      padding-top: 6px;
      height: 35px;
      background-color: rgba(255, 255, 255, 0.4);
      padding-bottom: 0px;
    }
    &--block_description {
      // margin-top: -17px;
      // padding-top: 8px;
      background-color: transparent;
      height: 35px;
      padding-bottom: 0px;
      resize: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    &--block_count {
      height: 70%;
      width: 55px;
      background-color: rgba(255, 255, 255, 0.4);
      margin-top: 0px;
      margin-left: 5px;
    }
    &--block_cost {
      width: 55px;
      height: 70%;
      background-color: rgba(255, 255, 255, 0.4);
      margin-top: 0px;
      margin-left: 5px;
    }
  }
}
