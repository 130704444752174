:root {
  --swiper-navigation-size: 30px;
}

.block-image-carousel {
  aspect-ratio: 24 / 20;
  position: relative;
  overflow: hidden;

  .swiper-button-prev {
    color: #EBEBEB !important;
    left: 0px;
  }

  .swiper-button-next {
    color: #EBEBEB !important;
    right: 0px;
  }

  .swiper-pagination-bullet {
    background-color: #D9D9D9;
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background-color: #B34104;
    opacity: 1;
  }

  .swiper-button-disabled {
    color: #EBEBEB;
  }
}

.trip-block-carousel {
  width: 100%;
  aspect-ratio: 24 / 15;
}

.image-carousel {
  height: 400px;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
}

.video-card {
  height: 400px;
  border-radius: 20px;
}

.card-carousel {
  border-radius: 20px;
  padding: 0px 25px;
  position: relative;
  overflow: hidden;
}

.friend-carousel {
  border-radius: 20px;
  padding: 0px 25px;
  position: relative;
  overflow: hidden;
}

.impact-carousel {
  border-radius: 20px;
  padding: 0px 25px;
  position: relative;
  overflow: hidden;
}

@media screen and (max-width: 450px) {
  .image-carousel {
    height: 150px;
    border-radius: 10px;
    position: relative;
  }

  .video-card {
    height: 150px;
    border-radius: 10px;
  }

  .card-carousel {
    border-radius: 10px;
  }

  .friend-carousel {
    border-radius: 10px;
  }

  .impact-carousel {
    border-radius: 10px;
  }
}

.swiper {
  width: 100%;
  height: 100%;
}



.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.swiper-button-prev {
  color: #273A07;
  left: 0px;
  font-weight: bolder;
}

.swiper-button-next {
  color: #273A07;
  right: 0px;
  font-weight: bolder;
}

.swiper-button-disabled {
  color: #273A07;
}

.swiper-pagination-bullet {
  background-color: #273A07;
  opacity: 1;
}

.swiper-image-prev,
.trip-block-carousel .swiper-button-prev {
  margin-left: 16px;
}

.swiper-image-next,
.trip-block-carousel .swiper-button-next {
  margin-right: 16px;
}

.swiper-pagination-bullet-active {
  background-color: #B34104;
  opacity: 1;
}

.image-carousel .swiper-button-prev,
.trip-block-carousel .swiper-button-prev {
  color: #EBEBEB !important;
  left: 0px;
}

.image-carousel .swiper-button-next,
.trip-block-carousel .swiper-button-next {
  color: #EBEBEB !important;
  right: 0px;
}

.image-carousel .swiper-button-disabled,
.trip-block-carousel .swiper-button-disabled {
  color: #EBEBEB;
}

.image-carousel .swiper-pagination-bullet,
.trip-block-carousel .swiper-pagination-bullet {
  background-color: #D9D9D9;
  opacity: 1;
}

.image-carousel .swiper-pagination-bullet-active,
.trip-block-carousel .swiper-pagination-bullet-active {
  background-color: #B34104;
  opacity: 1;
}