.checkout-box-wrapper {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  height: 60px;
  margin: 5px 0px;
  border-radius: 10px;
  .checkout-box-left {
    width: calc(49% - 10px);
    display: flex;
    align-items: center;
    padding-left: 16px;
  }
  .checkout-box-right {
    width: calc(49% - 10px);
    display: flex;
    align-items: center;
    padding-right: 56px;
    justify-content: flex-end;
  }
}
