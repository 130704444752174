.storyblock-right{
  display:flex;
  flex-direction: row-reverse;
   align-items: flex-start;
  justify-content: flex-start;
  padding:0px 70px;
  width: calc(100% - 140px);
  margin-top: 100px;
}

.storyblock-left{
  display:flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding:0px 70px;
  width: calc(100% - 140px);
  margin-top: 100px;

}

.story-block-pic{
  width: 150px;
  height: 150px;
  border: 1px solid gray;
}

.story-block-text{
  width: calc(100% - 150px);
  margin: 0px 20px;
  color:white;
  .bold-text{
    font-weight:900;
  }
}