.hub-page-inspiration-container {
  max-width: 1440px;
  margin-top: 71px;
  min-height: calc(100vh - 85px);
  width: 100%;
  display: flex;
  flex-direction: column;
  // flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    display: none !important;
  }
  .inspiration_adder {
    width: 100%;
    height: 200px;
    border: 1px solid white;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .inspiration-adder-top {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      height: 40px;
      border: transparent;
      width: 98%;
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
      // background-color: white;
      // border-top: 1px solid white;
      // padding: 0px 2px;
      background-color: #09132b;
      img {
        margin-left: 40px;
        width: 25px;
        height: 25px;
        cursor: pointer;
      }
    }
    .inspiration-adder-body {
      width: 100%;
      height: calc(100% - 40px);
      border-top: 1px solid white;
      background-color: rgba(255, 255, 255, 0.2);
      // opacity: .2;
      border-bottom-left-radius: inherit;
      border-bottom-right-radius: inherit;
      form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        .input-text-ins-body {
          border-radius: 40px;
          margin-bottom: 10px;
        }

        .input-fil-ins-body {
          border-radius: 40px;
          margin-bottom: 10px;
        }
        img {
          width: 80%;
          height: 40%;
          object-fit: contain;
        }
      }
    }
  }
}

.image-deleter {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  #image-deleter-button {
    width: 25px;
    height: 25px;
    margin-left: 10px;
    cursor: pointer;
  }
  font-weight: 700;
  color: white;
  margin-bottom: 10px;
}
.tree-container {
  width: 95%;
  height: 100%;
  min-height: calc(100vh - 85px);
  &::-webkit-scrollbar {
    display: none !important;
  }
}
.tree-wrapper {
  &::-webkit-scrollbar {
    display: none !important;
  }
  .rst__lineHalfHorizontalRight::before,
  .rst__lineFullVertical::after,
  .rst__lineHalfVerticalTop::after,
  .rst__lineHalfVerticalBottom::after {
    background-color: transparent !important;
  }

  .rst__expandButton {
    background: orange
      url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjgiIGZpbGw9IiNGRkYiLz48ZyBzdHJva2U9IiM5ODk4OTgiIHN0cm9rZS13aWR0aD0iMS45IiA+PHBhdGggZD0iTTQuNSA5aDkiLz48cGF0aCBkPSJNOSA0LjV2OSIvPjwvZz4KPC9zdmc+)
      no-repeat center !important;
  }
  div.rst__node {
    min-height: 220px;
    height: 25% !important;
    margin: 10px 0px;
    div.rst__nodeContent {
      width: 80%;
      height: 100%;
      // border: 1px solid white;
      border-radius: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
      justify-content: space-between;
      margin: 10px;
      & > div {
        width: 100%;
        border-radius: inherit;

        div.rst__rowWrapper {
          padding: 0px;
          // border-radius: 50px;
          border-radius: inherit;
          div.rst__row {
            border-radius: inherit;

            div.rst__moveHandle {
              border-radius: inherit;
              // border-radius: 50px;
            }
            div.rst__rowContents {
              background-color: transparent;
              border-top-right-radius: inherit;
              border-bottom-right-radius: inherit;
              color: white;
            }
          }
        }
      }
    }
  }
}

.custom-tree-styles {
  // display:flex;
  // flex-direction: column;
  box-shadow: 0 1px 1.3px rgba(217, 217, 217, 0.021),
    0 2.9px 3.5px rgba(217, 217, 217, 0.031),
    0 6.7px 8.3px rgba(217, 217, 217, 0.04),
    0 20px 30px rgba(217, 217, 217, 0.06);
  border: none !important;

  .rst__moveHandle {
    border-top-left-radius: inherit !important;
    border-bottom-left-radius: inherit !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    background-color: orange;
    border: 2px solid transparent;
  }
  .rst__rowContents {
    display: flex;
    flex-direction: column-reverse;
    padding: 0px !important;
    border: none !important;
    .rst__rowToolbar {
      background-color: rgba(9, 19, 43, 0.8);
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      border-top-right-radius: inherit;
      border-top-left-radius: inherit;
      border: none !important;

      .x-button {
        background-color: none;
        border: none;
        background: none;
        color: orange;
        font-size: 18px;
        font-weight: 900;
        margin-left: 10px;
        margin-right: 20px;
      }
      .inspiration-count {
        margin-right: 40px;
        width: 45px;
        height: 20px;
        // background-color: #f7b844;
        background-color: transparent;
        border: none;
        display: flex;
        justify-content: center;
        border-radius: 20px;
        align-items: center;
        img {
          width: 20px;
        }
        span {
          margin-right: 5px;
          font-size: 16px;
          color: orange;
        }
      }
    }
    .rst__rowLabel {
      background-color: rgba(9, 19, 43, 0.8);
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom-right-radius: inherit;
      border-bottom-left-radius: inherit;
      padding: 0px !important;
      border: none !important;
    }
  }
}

.all-insp-blk-wrapper {
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
