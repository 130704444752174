.page-content-wrapper {
  .sign-up-headers {
    display: flex;
    width: 100%;
    justify-content: center;
    color: white;
    text-align: center;
    // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }
  form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    input[type='text'] {
      border-radius: 10px;
    }
    .signup-block-top {
      padding-bottom: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 98%;
      margin: 0 auto;
      border-radius: 40px;
      // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
      margin-bottom: 30px;
      // box-shadow: 8px 8px 2px rgba(0, 0, 0, 0.4);

      .signup-block-top-header {
        background-color: #09132b;
        display: flex;
        justify-content: center;
        height: 40px;
        align-items: center;
        width: 100%;
        color: white;
        font-size: 1.5em;
        // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
        font-weight: bolder;
        margin-bottom: 10px;
      }
      .select-check {
        width: 50%;
        margin: 0 auto;
        margin-top: 20px;
        border-top: 1px solid white;
        display: flex;
        justify-content: center;

        .select-check-container {
          margin: 20px 0px;
          display: flex;
          flex-direction: row-reverse;
          span {
            margin-right: 10px;
            color: white;
          }
        }
      }
    }

    .signup-block-middle {
      display: flex;
      justify-content: center;
      color: white;
      // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    }

    .check_box_label {
      color: #ffffff;
      text-align: right;
      // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    }

    .signup-block-bottom {
      // padding: 20px 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid white;
      width: 98%;
      margin: 0 auto;
      border-radius: 40px;
      background-color: #2e364b;
      // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
      padding-bottom: 20px;
      .signup-bottom-block-header {
        background-color: #09132b;
        display: flex;
        justify-content: center;
        height: 40px;
        align-items: center;
        width: 100%;
        color: white;
        font-size: 1.5em;
        // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
        font-weight: bolder;
        margin-bottom: 10px;
      }
    }

    .newsletter-inputs {
      color: white;
      min-width: 25%;
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      align-items: center;
      span {
        width: 150px;
      }
    }
    .error-placeholder {
      width: 100%;
      height: 50px;
    }
    .email-error-container {
      height: auto;
      width: auto;
      justify-content: center;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      margin: 0 auto;
      min-width: 402px;
      padding-left: 51px;
      .email-error-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        img {
          width: 15px;
          height: 15px;
        }
        span {
          color: white;
          margin-left: 10px;
        }
      }
    }
    .render-container {
      width: 100%;
      justify-content: center;
      display: flex;
      flex-direction: column;
      .render-container-header {
        display: flex;
        justify-content: center;
        width: 100%;
        color: white;
        font-size: 20px;
        font-weight: bold;
      }
      .render-container-body {
        margin: 10px 0px;
        display: flex;
        justify-content: center;
        width: 100%;
        align-items: center;
        flex-direction: column;
        // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        img {
          width: 225px;
          // height: 50px;
        }
        .plan-headers {
          font-size: 20px;
          color: white;
          // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        }
        .app-chooser {
          margin-left: 20px;
        }
      }
    }
  }
}

.bold-text-type {
  font-size: 18px;
  font-weight: 700;
}

.encloser {
  width: 28%;
  display: flex;
  border: 1px solid white;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  height: 50px;
  background-color: #121c33;
  cursor: pointer;
  #logo-top-image {
    width: 260px;
  }
  &:hover {
    background-color: #da4431;
  }
}

.encloser-selected {
  background-color: #da4431;
}

#checkbox-color {
  label:after {
    border-color: transparent;

    // background-color: #6EC6ED!important;
  }
  input:checked ~ .state.p-warning-o label:after {
    border-color: #6ec6ed;

    background-color: #6ec6ed !important;
  }
  input:checked ~ .state.p-warning-o label:before {
    border-color: #6ec6ed;
  }
}

.signup-page-signup {
  width: 250px;
  border: none;
  background-color: #f7b844;
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 20px;
  height: 37px;
  cursor: pointer;
  span {
    font-weight: 900;
    font-size: 18px;
  }
  &:hover {
    background-color: #6ec6ed;
  }
  &:disabled {
    &:hover {
      background-color: #f7b844;
    }
  }
}

.floating-lode-input {
  width: 100%;
  margin-top: 5px;
  // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  label {
    padding-left: 5px;
  }
}
div.floating-lode-input {
  border-bottom: none;
}

input.floating-lode-input {
  padding: 12px;
  padding-left: 16px;
  border-radius: 10px;
  vertical-align: bottom;
  color: #09132b;
  width: calc(100% - 26px);
}

// input.floating-lode-input::placeholder{
//   font-size: 5px;
// }

label.floating-lode-input {
  bottom: 10px;
  left: 12px;
  // color: #DA4431;
  // font-weight: 800;
}

.signup-captcha-policy {
  margin: 0 auto;
  margin-top: 30px;
  span {
    color: white;
    .recaptcha-link {
      cursor: point;
      &:hover {
        color: #6ec6ed;
      }
    }
  }
}
.span-highlighter:hover {
  color: #6ec6ed;
}

.login-link-button {
  width: 150px;
  background-color: #da4431;
  text-align: center;
  font-size: 18px;
  border-radius: 10px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  &:hover {
    background-color: #6ec6ed;
  }
}

.login-button-text {
  color: white;
  margin: 0 auto;
  font-size: 20px;
  // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
