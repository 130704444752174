$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;

.member-summary-wrapper {
  width: calc(100% - 40px);
  margin: 10px;
  padding: 10px;
  height: 220px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 5px;

  .member-summary-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 41px;
    h3 {
      margin-top: 0px;
    }
    hr {
      width: 100%;
      border: none;
      color: white;
      background-color: white;
      height: 1px;
      margin: 0px;
    }
  }
  .member-summary-contents-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: calc(100% - 41px);
    .member-summary-contents-columns {
      height: 100%;
      &--1 {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 300px;
        .account-detail-profile-pic-wrapper {
          width: 150px;
          height: 150px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: black;
          & > a {
            text-align: center;
            &:hover {
              span {
                color: $var-color--light-blue;
              }
            }
            .no-account-image-text {
              text-align: center;
              color: black;
            }
          }
          .account-detail-profile-pic {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
      }
      &--2 {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .contents-column-2 {
          display: flex;
          flex-direction: column;
          .content-description-wrapper {
            font-weight: 600;
            font-size: 14px;
          }
          .content-description-content {
            font-size: 16px;
          }
          &--accountname {
            margin-top: 20px;
          }
          &--accountdescription {
            margin-top: 15px;
          }
          &--accountstatus {
            margin-top: 15px;
          }
        }
      }
      &--3 {
      }
    }
  }
}
