@media screen and (min-width: 481px) and (max-width: 860px) {
  .signup-background-image-behind-content {
    min-height: 0px !important;
    height: 100vh !important;
  }
  .sign-up-page-container {
    max-height: 112vh !important;
  }

  #mobile-sign-up-wrapper {
    justify-content: flex-start !important;
    padding-top: 20px;
    height: 100vh !important;
    min-height: 0px !important;
    padding-bottom: 50px;
    & > form {
      margin-top: 30px !important;
      height: fit-content !important;
    }
  }
  .page-content-wrapper form .signup-block-top .select-check {
    width: 89%;
    .select-check-container {
      display: flex;
      align-items: center;
      .check_box_label {
        text-align: center;
      }
    }
  }
  .page-content-wrapper {
    .signup-block-top {
      width: 76% !important;
    }
  }

  .nav-section-text {
    display: flex;
    align-items: flex-start;
    font-size: 14px;
  }

  #tm-encloer-custom-sup {
    #mobile-trademark_banner-text {
      font-size: 19px;
    }
  }

  #mobil-footer-container {
    flex-direction: column;
    height: auto;
    .footer-contents {
      flex-direction: column;
      height: auto;
      .footer-top {
        display: flex;
        flex-direction: column-reverse;
        .footer-right .footer-logo-block img {
          margin-right: 10px;
          width: 180px;
          height: auto;
          margin-top: 10px;
        }
        .footer-middle {
          .mission-text {
            text-align: left;
            margin-left: 0px;
            margin-bottom: 10px;
          }
        }
        .footer-left {
          padding-left: 10px;
          width: 100%;
          .footer-link-blocks {
            a {
              padding-bottom: 10px;
            }
          }
        }
        .footer-middle {
          padding-left: 10px;
          width: 100%;

          a {
            padding-bottom: 10px;
          }
        }
        .footer-right {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          .footer-logo-block {
            padding-left: 10px;
            margin-bottom: 10px;
            display: flex;
            img {
              width: 200px;
              margin-bottom: 10px;
            }
          }
          .footer-icons-wrapper {
            display: flex;
            width: auto;
            justify-content: center;
            margin-right: 26px;
            margin-top: -13px;
            margin-bottom: 10px;
          }
        }
      }
      .footer-bottom {
        justify-content: flex-start;
        .soc-media-block {
          margin-left: 10px;
        }
      }
      .c-text {
        margin-top: 10px;
        font-size: 12px;
      }
    }
  }
  .login-page-background-image {
    height: calc(100vh - 71px);
    position: relative;
    max-width: 100vw;
    object-fit: cover;
    // object-position: -261px;
  }

  .hub-top-nav-bottom-border {
    justify-content: center;
    display: flex;
    width: 100%;
  }
  #mobile-adjusted-render-container {
    .encloser {
      width: 81%;
    }
  }

  #unsubscribed-header-mobile {
    font-size: 22px;
  }
  #log-in-form {
    #log-in-block-mobile {
      margin-top: 30px;
      width: 95%;
    }
  }

  .c-text-hub {
    display: flex;
    width: 100vw;
    justify-content: center;
    vertical-align: bottom;
    font-size: 12px;
    span {
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 480px) {
  .signup-background-image-behind-content {
    min-height: 0px !important;
    height: 100vh !important;
  }
  .sign-up-page-container {
    max-height: 112vh !important;
  }
  .banner-two-pics-container {
    margin-top: 20px;
    .pic-stack-container {
      .pic-stack-top-center {
        width: 75%;
      }
    }
  }
  #mobile-sign-up-wrapper {
    justify-content: flex-start !important;
    padding-top: 20px;
    height: 100vh !important;
    min-height: 0px !important;
    padding-bottom: 50px;
    & > form {
      margin-top: 30px !important;
      height: fit-content !important;
    }
  }
  .two-signs-container {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    align-items: center;
    img {
      width: 69%;
      margin: 20px;
    }
  }
  #tm-encloer-custom-sup {
    #mobile-trademark_banner-text {
      font-size: 19px;
    }
  }

  .page-content-wrapper form .signup-block-top .select-check {
    width: 89%;
    .select-check-container {
      display: flex;
      align-items: center;
      .check_box_label {
        text-align: center;
      }
    }
  }

  .nav-section-text {
    display: flex;
    align-items: flex-start;
    font-size: 14px;
  }

  .page-content-wrapper {
    .signup-block-top {
      width: 76% !important;
    }
  }
  #mobil-footer-container {
    flex-direction: column;
    height: auto;
    .footer-contents {
      flex-direction: column;
      height: auto;
      .footer-top {
        display: flex;
        flex-direction: column-reverse;
        .footer-right .footer-logo-block img {
          margin-right: 10px;
          width: 180px;
          height: auto;
          margin-top: 10px;
        }
        .footer-middle {
          .mission-text {
            text-align: left;
            margin-left: 0px;
            margin-bottom: 10px;
          }
        }
        .footer-left {
          padding-left: 10px;
          width: 100%;
          .footer-link-blocks {
            a {
              padding-bottom: 10px;
            }
          }
        }
        .footer-middle {
          padding-left: 10px;
          width: 100%;

          a {
            padding-bottom: 10px;
          }
        }
        .footer-right {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          .footer-logo-block {
            padding-left: 10px;
            margin-bottom: 10px;
            display: flex;
            img {
              width: 200px;
              margin-bottom: 10px;
            }
          }
        }
      }
      .footer-bottom {
        justify-content: flex-start;
        .soc-media-block {
          margin-left: 10px;
        }
      }
      .c-text {
        margin-top: 10px;
        font-size: 12px;
      }
    }
  }
  .login-page-background-image {
    height: calc(100vh - 71px);
    position: relative;
    max-width: 100vw;
    object-fit: cover;
    object-position: -261px;
  }

  .hub-top-nav-bottom-border {
    justify-content: center;
    display: flex;
    width: 100%;
    position: absolute;
    top: 71px;
  }
  #mobile-adjusted-render-container {
    .encloser {
      width: 81%;
    }
  }

  #unsubscribed-header-mobile {
    font-size: 22px;
  }
  #log-in-form {
    #log-in-block-mobile {
      width: 95%;
    }
  }

  .c-text-hub {
    display: flex;
    width: 100vw;
    justify-content: center;
    vertical-align: bottom;
    font-size: 12px;
    span {
      font-size: 12px;
    }
  }
}
@media screen and (max-width: 768px) {
  #two-stack-blocks {
    padding: 0px 0px;
    margin-top: 0px;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .two-stack-container {
      margin: 0 auto;
      width: 300px;
      height: 200px;
      margin-top: 30px;
    }
  }

  #c-text {
  }

  #mobile-txt-footer {
    a {
      font-size: 10px;
    }
  }
}
@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 1440px) {
  .hub-top-nav-bottom-border div {
    width: 95%;
  }
}
@media screen and (min-width: 1441px) {
  .banner-container {
    width: 100vw;
    img {
      width: 100%;
    }
  }
  .banner-regular-container {
    width: 100vw;
    img {
      width: 100%;
    }
  }
}

@media screen and (max-height: 414px) {
  #log-in-form {
    margin-top: 36px;
    height: calc(100vh - 71px);
  }
  .page-content-wrapper {
    min-height: 100vh;
    margin-bottom: 0px;
    margin-top: 71px;
    padding-bottom: 0px;
  }
  #log-in-form {
    padding-bottom: 20px;
  }
  #log-in-form #log-in-block-mobile {
    margin-top: 0px;
    padding: 20px 0px;
  }
}

@media screen and (max-height: 414px) {
  .hamburger-contents-container .sectors div {
    cursor: pointer;
    width: 100%;
    height: 39px;
  }
  .opened-box-hamburger {
    position: absolute;
    top: 71px;
    left: 21px;
    z-index: 10;
  }

  .login-page-background-image {
    height: 100vh;
  }

  #tm-encloer-custom-sup #mobile-trademark_banner-text {
    font-size: 29px;
  }

  .logoimagelink {
    height: 93%;
    display: flex;
    align-items: center;
  }

  .two-signs-container {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    align-items: center;
    img {
      width: 69%;
      margin: 20px;
    }
  }
}

@media screen and (max-height: 390px) {
  .hamburger-contents-container .sectors div {
    cursor: pointer;
    width: 100%;
    height: 36px;
  }

  .opened-box-hamburger {
    position: absolute;
    top: 71px;
    left: 18px;
    z-index: 10;
  }
}

@media screen and (max-height: 320px) {
  #log-in-form {
    margin-top: 2px;
    height: calc(100vh - 27px);
  }

  .hamburger-contents-container .sectors div {
    cursor: pointer;
    width: 100%;
    height: 26px;
  }
}

@media screen and (max-height: 360px) {
  .hamburger-contents-container .sectors div {
    cursor: pointer;
    width: 100%;
    height: 30px;
  }
}
