.insp-module-wrapper {
  height: calc(100vh / 4);
  width: 300px;
  border:none;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
  cursor: pointer;
  box-shadow: 
    0 2px     1px   -10px    rgba(255, 255, 255, 0.093),
    0 4px     2px   -7px     rgba(255, 255, 255, 0.133),
    0 6px     5px   -10px    rgba(255, 255, 255, 0.167),
    0 12px    15px  -12px    rgba(255, 255, 255, 0.207);

  &:hover {
    box-shadow: 0px 0px 12px rgba(255, 255, 255, 0.35);
  }
  &:hover {
    .insp-block-title-text {
                    cursor: pointer;
                    color: #f7b844;
                    }
    .insp-block-bottom-center {
                    cursor: pointer;
                    color: #f7b844;
                    }
    }
}

.insp-block-top{
  background-color: rgba(110, 198, 237, .3);
  width: 100%;
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(255, 255, 255);
  height: 18%;
  border-bottom: none;
}

.insp-block-title {
  background-color: rgba(9, 19, 43, 0.6);
  width: 100%;
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  height: 65%;
  border-bottom: none;

  .insp-block-title-text {
    text-transform: uppercase;
    color: rgb(255, 255, 255);
    font-size: 12px;
    font-weight: 700;
  }


}

.insp-block-status {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 35%;

  .insp-block-status-text {
    text-transform: uppercase;
    //color: rgba(255, 255, 255, .6);
    color: rgba(9, 19, 43, .6);
    font-size: 8px;
    font-weight: 700;
    letter-spacing: 2px;
  }
}

.future {
  //background-color: rgba(247, 184, 68, .6);
  background: linear-gradient(90deg, rgba(9, 19, 43, 0.6), rgba(247, 184, 68, .6), rgba(9, 19, 43, 0.6));
  
}

.past {
  //background-color: rgba(110, 198, 237, .6);
  background: linear-gradient(90deg, rgba(9, 19, 43, 0.6), rgba(110, 198, 237, .6), rgba(9, 19, 43, 0.6))
}

.now {
  //background-color: rgba(218, 68, 49, .8);
  background: linear-gradient(90deg, rgba(9, 19, 43, 0.6), rgba(218, 68, 49, .8), rgba(9, 19, 43, 0.6))
}

.insp_block_svgs {
  color: rgba(255, 255, 255, 0.35);
  display: flex;
  align-items: center;


  .insp-svg-itinerary {
    width: 20px;
    fill: rgba(255, 255, 255, 0.35);
  }

  .insp-svg-itinerary-outer {
    width: 20px;
    &:hover {
      .insp-svg-itinerary {
        cursor: pointer;
        fill: #f7b844;
      }
    }
  }

  .insp-ct-itinerary {
    font-size: 11px;
    margin-left: 3px;
  }

  .insp-svg-friend {
    width: 15px;
    fill: rgba(255, 255, 255, 0.35);
    
  }

  .insp-svg-friend-outer {
    width: 15px;
  }

  .insp-ct-friend {
    font-size: 11px;
    margin-right: 3px;
  }
}




.insp-block-bottom {
  background-color: rgba(9, 19, 43, 0.6);
  width: 100%;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
  height: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;

  .insp-block-bottom-left {
    display: inline-block;
    color: white;
  }

  .insp-block-bottom-center {
    display: inline-block;
    color: white;
    text-align: center;
    font-size: 11px;
    width: 70%;

    .insp-blk-btm-dur {
      font-weight: 700;
    }

    .insp-blk-btm-det {
      font-weight: 500;
    }
  }

  .insp-block-bottom-right {
    display: inline-block;
  }
}
