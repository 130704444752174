.qr-codes-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .ambassador_qr_section {
    margin-top: 5px;
    margin-bottom: 10px;
    width: 100%;
    height: auto;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .amb-content-section {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 10px;
      span {
        margin-left: 10px;
        margin-top: 5px;
        color: black;
        font-size: 14px;
      }
    }
    .amb-qr-code {
      padding-bottom: 20px;
    }
  }
}
