@keyframes grow_bottom_section {
  from {
    height: 42px;
  }
  to {
    height: 190px;
  }
}
@keyframes shrink_bottom_section {
  from {
    height: 100px;
  }
  to {
    height: 42px;
  }
}

.shared-adventure-module-wrapper {
  height: calc(150vh / 4);
  width: 300px;
  border: none;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  margin: 10px;
  cursor: pointer;
  max-height: 266px;
  box-shadow: 0 2px 1px -10px rgba(255, 255, 255, 0.093),
    0 4px 2px -7px rgba(255, 255, 255, 0.133),
    0 6px 5px -10px rgba(255, 255, 255, 0.167),
    0 12px 15px -12px rgba(255, 255, 255, 0.207);

  &:hover {
    box-shadow: 0px 0px 12px rgba(255, 255, 255, 0.35);
    .shared-adventure-block-bottom {
      -webkit-transition: grow_bottom_section 2s;
      animation-name: grow_bottom_section;
      animation-duration: 0.5s;
      height: 190px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .shared-adventure-block-bottom--hidden {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: calc(100% - 20px);
        height: calc(100% - 42px - 20px);
        padding: 10px;
        span {
          -webkit-transition: opacity 2s ease-in;
          -moz-transition: opacity 2s ease-in;
          -o-transition: opacity 2s ease-in;
          opacity: 1;
          color: white;
        }
      }
    }
  }
  &:hover {
    .shared-adventure-block-title-text {
      cursor: pointer;
      color: #f7b844;
    }
    .shared-adventure-block-bottom-center {
      cursor: pointer;
      //color: #f7b844;
    }
    .future {
      //background-color: rgba(247, 184, 68, .6);
      background: rgba(247, 184, 68, 0.6);
    }

    .past {
      //background-color: rgba(110, 198, 237, .6);
      background: rgba(110, 198, 237, 0.6);
    }
    .now {
      //background-color: rgba(218, 68, 49, .8);
      background: rgba(218, 68, 49, 0.8);
    }
  }
}

.shared-adventure-block-top {
  // background-color: rgba(110, 198, 237, 0.3);
  // background-color: rgba(110, 198, 237, 0.3);
  width: 100%;
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(255, 255, 255);
  height: 60px;
  border-bottom: none;
}

.shared-adventure-block-title {
  background-color: rgba(9, 19, 43, 0.6);
  // background-color: rgba(110, 198, 237, 0.3);

  width: 100%;
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  height: 65%;
  border-bottom: none;

  .shared-adventure-block-title-text {
    text-transform: uppercase;
    color: rgb(255, 255, 255);
    font-size: 12px;
    font-weight: 700;
    text-align: center;
  }
}

.shared-adventure-block-status {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30px;
  .star-ratings {
    width: 40%;
    height: 30px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    & > div > svg {
      width: 20px !important;
      height: 20px !important;
    }
  }
  & > span {
    margin-left: 10px;
    font-size: 12px;
  }

  .shared-adventure-block-season-text {
    text-transform: uppercase;
    //color: rgba(255, 255, 255, .6);
    color: rgba(9, 19, 43, 0.6);
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 2px;
  }

  .shared-adventure-block-status-text {
    text-transform: uppercase;
    //color: rgba(255, 255, 255, .6);
    color: rgba(9, 19, 43, 0.6);
    font-size: 8px;
    font-weight: 700;
    letter-spacing: 1px;
  }
}

.future {
  //background-color: rgba(247, 184, 68, .6);
  background: linear-gradient(
    90deg,
    rgba(9, 19, 43, 0.6),
    rgba(247, 184, 68, 0.6),
    rgba(9, 19, 43, 0.6)
  );
}

.past {
  //background-color: rgba(110, 198, 237, .6);
  background: linear-gradient(
    90deg,
    rgba(9, 19, 43, 0.6),
    rgba(110, 198, 237, 0.6),
    rgba(9, 19, 43, 0.6)
  );
}

.now {
  //background-color: rgba(218, 68, 49, .8);
  background: linear-gradient(
    90deg,
    rgba(9, 19, 43, 0.6),
    rgba(218, 68, 49, 0.8),
    rgba(9, 19, 43, 0.6)
  );
}

.shared-adventure_block_svgs {
  color: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;

  .adv-svg-itinerary {
    width: 20px;
    fill: rgba(255, 255, 255, 0.35);
  }

  .adv-svg-itinerary-outer {
    width: 20px;
    &:hover {
      .adv-svg-itinerary {
        cursor: pointer;
        fill: #f7b844;
      }
    }
  }

  .adv-ct-itinerary {
    font-size: 11px;
    margin-left: 3px;
  }

  .adv-svg-friend {
    width: 15px;
    fill: rgba(255, 255, 255, 0.35);
  }

  .adv-svg-friend-outer {
    width: 15px;
  }

  .adv-ct-friend {
    font-size: 11px;
    margin-right: 3px;
  }
}

.svg-friend-section {
  &:hover {
    .adv-svg-friend {
      fill: rgba(247, 184, 68, 0.8);
    }
    .adv-ct-friend {
      color: rgba(247, 184, 68, 0.8);
    }
    &:hover:before {
      display: block;
    }
  }
}

.shared-adventure-block-bottom {
  background-color: rgba(9, 19, 43, 0.6);
  width: 100%;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  -webkit-transition: shrink_bottom_section 2s;
  .shared-adventure-block-bottom--displayed {
    width: 100%;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    .shared-adventure-block-bottom-left {
      display: flex;
      color: white;
      width: 15%;
      align-items: center;

      .shared-adventure-block--user-profile-image {
        width: 30px;
        height: 30px;
        object-fit: contain;
        margin-left: 20px;
        // border: 1px solid #09132b;
        border-radius: 50%;
      }
    }

    .shared-adventure-block-bottom-center {
      display: inline-block;
      color: white;
      text-align: center;
      font-size: 11px;
      width: 70%;

      .adv-blk-btm-season {
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 2px;
      }

      .adv-blk-btm-dur {
        font-weight: 700;
      }

      .adv-blk-btm-det {
        font-weight: 500;
      }
    }

    .shared-adventure-block-bottom-right {
      width: calc(15% - 10px);
      display: flex;
      justify-content: flex-end;
      padding-right: 10px;
    }
  }
  .shared-adventure-block-bottom--hidden {
    display: none;
    width: 100%;
    height: calc(100% - 42px);
    span {
      opacity: 0;
    }
  }
}
