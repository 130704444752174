.hub-it-addr-tp {
  width: 50px;
  height: 40px;
  .hub-it-addr-btn {
    width: 50px;
    height: 30px;
    border: none;
    background: none;
    margin-bottom: 5px;
    .hub-it-addr-btn-img {
      height: 30px;
      width: 50px;
    }
  }
}

.hub-addr-btn-abs {
  // position: absolute;
  // left: 0px;
  // left: 200px;
  display: flex;
  justify-self: start;
}
