.slider-wrapper {
  padding: 26px 0;
  overflow: hidden;
  position: relative;
  padding-top: 14px;
  padding-bottom: 12px;
}
.slider-wrapper {
  width: 87%;
}
