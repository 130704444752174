.fslightbox-fade-out-strong {
  animation: none;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.25s, opacity 0.25s ease;
}

.fslightbox-fade-in-strong {
  animation: none;
  visibility: visible;
  opacity: 1;
  transition: visibility 0s 0.25s, opacity 0.25s ease;
}

.fslightboxl {
  display: none !important;
}
