.creat-block-file {
  height: 431px !important;
  max-height: 462px !important;
}
.mod-blk-file-link-wrapper {
  // background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  height: 120px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mod-blk-file-link-top {
  width: 100%;
}
