.pdf-svg-wrapper {
  // margin-left: 157px;
  .drop-zone-pdf-outer {
    width: 50px;
    height: 50px;
    path {
      fill: #827700;
    }
  }
}
