$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;
.hub-sv-wrapper {
  width: 100vw;
  height: 50px;
  margin-top: 3px;
  display: flex;
  justify-content: center;
  z-index: 31;
  background-color: #273a07;
  .triggerhamburger {
    right: 12px;
  }
  .sub-nav-bg {
    background-color: #273a07;
  }
  .hub-sv-padder {
    background-color: rgba(255, 255, 255, 0);

    // background-color:white;
    // opacity: .25;

    // width: calc(100vw * 0.96);
    width: 96%;
    // width: 100vw;
    height: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: -10px;
    padding-top: 10px;
    &.sub-nav-white-background {
      width: 100%;
    }
    .hub-sv-left {
      width: 30%;
    }
    .hub-sv-middle {
      width: 40%;
      display: flex;
      justify-content: center;
      color: #fcedd6;
      height: 100%;
      align-items: center;
      text-transform: uppercase;
      font-size: 0.9em;
      font-weight: 700;
      letter-spacing: 0.5px;
      //font-weight: 700;
      .hub-sv-cent-inf-container {
        display: flex;
        flex-direction: column;
        text-align: center;
        .cent-inf-top {
          font-size: 0.9em;
          font-weight: 500;
        }
        .cent-inf-btm {
          font-size: 0.9em;
          font-size: 18px;
        }
        .cent-inf-btm-sm {
          font-size: 0.8em;
        }
        .cent-inf-btm-btm {
          font-size: 0.8em;
          font-weight: 500;
        }
      }
    }
    .hub-sv-right {
      width: 30%;
      display: flex;
      height: 100%;
      justify-content: flex-end;
      align-items: center;
      svg {
        path {
          fill: #fcedd6;
        }
      }

      .hub-custom-btn-img {
      }
    }
  }
}
.sub-nav-box-menu-outer {
  width: 40px;
  height: 40px;
  fill: white;
  cursor: pointer;
  &.box-open {
    fill: #f7b844;
  }
  &:hover {
    // fill: #f7b844;
  }
}

.sub-nav-box-menu-closed {
  position: absolute;
  top: 50px;
  right: 0px;
  z-index: 20;
}

.sub-nav-menu-items {
  padding: 2px 0 0 0;
  display: flex;
  flex-direction: column;
  height: 70px;
  align-items: center;
  cursor: pointer;
}

.sub-nav-box-menu-open {
  // background-color: #3c4352;
  background-color: white;
  width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // flex-wrap: wrap;
  border-radius: 10px;
  padding: 5px 5px;
  letter-spacing: 0.5px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  height: auto;
  border: solid 3px #273a07;
  border-top: none;
  & > div {
    height: auto;
    display: flex;
    width: 100%;
    transition: height 400ms linear 0s;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 5px 5px;
    letter-spacing: 0.5px;
    height: auto;
    & > .sub-nav-menu-items {
      margin: 10px 5px 0 5px;
      // width: calc(50% - 30px);
      width: 90%;

      display: flex;
      justify-content: center;
      height: 90px;
      align-items: center;
      & > div {
        display: flex;
        justify-content: center;
        cursor: pointer;
        & > button {
          display: flex;
          justify-content: center;
          padding-right: 5px;
        }
      }
      span {
        margin-top: 5px;
        // color: white;
        color: $var-dark-green;
        font-size: 11px;
        letter-spacing: 0.5px;
        text-align: center;
        height: 28px;
      }
      flex-direction: column;
      .custom-btn-svg-outer {
        width: 50px;
        height: 50px;
        path {
          fill: $var-dark-green;
        }
      }
      &:hover {
        background-color: $color-block-hover-background;
        border-radius: 10px;
        cursor: pointer;

        box-shadow: 4px 4px 3px rgba(0, 0, 0, 0.2);
        svg,
        span,
        path {
          fill: $color-dashboard-highlight;
          cursor: pointer;
          color: $color-dashboard-highlight;
        }
      }
    }
  }
}

.tool-tip-container {
  // background-color:#f7b844!important;
  // p,span{color: #09132b;}
  span {
    color: #09132b !important;
  }
}

.hyper-link-hover-effect {
  cursor: pointer !important;
  &:hover {
    color: $var-color--light-blue;
  }
}
.sub-nav-svg-back-button {
  fill: #fcedd6;
}

.back-button-wrapper {
  margin: 2px 10px 0 0;
  cursor: pointer;

  &:hover {
    .sub-nav-svg-back-button {
      fill: #fcce13;
    }
  }
}

.banner-message-component-wrapper {
  position: fixed;
  width: 100vw;
  height: 20px;
  background-color: #b43f00;
  display: flex;
  justify-content: center;
  z-index: 50000;
  top: 66px;
  .banner-message-action-text {
    color: #f5eddf;
    margin-right: 5px;
    font-size: 1em;
  }
  .banner-message-banner-text {
    font-size: 1em;
  }
}
