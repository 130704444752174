.flights-inputs-wrapper{
  display:flex;
  width:100%;
  justify-content: center;
  align-items: center;
  .flight-input-from-wrapper{
    position:relative;

    div.mod-fl-lab-inpt-wrapper{
      width: 100%;
      div.floating-lode-input{
        min-width: 0px;
      }
    }
      // .floating-lode-input{
      //   &:focus{
      //     .flight-input-from-search-results-wrapper{
        
         

      //     }
      //   }
      // }
   
  }
  .to-arrow{
    color: #827700;
    font-size: 18px;
    margin:0px 5px;
  }
  .flight-input-to-wrapper{
    position:relative;
    div.mod-fl-lab-inpt-wrapper {
      width: 100%;

      div.floating-lode-input {
          min-width: 0px;
        }
    }

    // .floating-lode-input{
    //   &:focus{
    //     .flight-input-to-search-results-wrapper{
          
         
    //     }
    //   }
    // }
  
  }
  .flight-input-search-results-wrapper {
  position: absolute;
  z-index: 3;
  top: 40px;
  width: 101%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

  .airport-search-result-wrapper {
    width: calc(100% - 6px);
    height: 35px;
    padding: 0px 3px;
    font-size:12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: white;

    cursor:pointer;
    &:hover{
      background-color: rgb(242, 242, 242);
    }
    .airport-location-id{
      font-weight: 800;
    }
  }

}