.hub-page-inspiration-container-adder-not-triggered {
  max-width: 1440px;
  margin-top: 100px;
  min-height: calc(100vh - 325px);
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  //align-items: center;
}

.hub-page-inspiration-container-adder-triggered {
  max-width: 1440px;
  margin-top: 71px;
  min-height: calc(100vh - 85px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .triggered-selected-inspiration-top {
    width: 50%;
    height: calc(100vh / 4);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .triggered-selected-inspiration-bottom {
    width: 100%;
    height: calc(100vh - 70px);
    max-height: calc(100vh - 70px);
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.subs-explorer-insp-sel {
  text-align: start;
  display: flex;
  justify-content: center !important;
  height: calc(100% - 30px) !important;
  span {
    // color: #f7b844;
    color: white;
    // text-transform: capitalize;
    font-size: 22px;
    padding: 0px 20px;
    font-weight: 500;
    // margin-top: 20px;
  }
}

.insp-summary-bottom {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start !important;
  flex-wrap: nowrap !important;
  .insp-summary-btm-lft {
    width: 30%;
    height: calc(100% - 100px);
    margin: 50px 0px;
    border-right: 4px solid #f7b844;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .insp-smr-title {
      width: 100%;
      height: 15%;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      span {
        border-bottom: 2px solid #f7b844;
        color: #f7b844;
        font-size: 20px;
        padding: 0px 20px;
        font-weight: 700;
      }
    }
    .insp-smr-notes {
      height: 85%;
      width: 80%;
      color: #f7b844;
      text-align: center;
    }
  }
  .insp-summary-btm-rgt {
    width: 70%;
    height: calc(100% - 100px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin: 50px 0px;

    .insp-smry-lvl {
      width: 20%;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin: 0px 5px;
      .insp-smry-lvl-header {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 15%;
        border-bottom: 2px solid #f7b844;
        justify-content: center;
        flex-direction: center;
        align-items: center;
        .insp-smr-svg-outer {
          fill: #f7b844;
          width: 50px;
          height: 50px;
        }
        span {
          color: #f7b844;
          font-size: 18px;
          font-weight: 700;
        }
      }
      .insp-smrt-lvl-cntt {
        height: 85%;
        color: #f7b844;
        font-size: 18px;
        font-weight: 700;
      }
    }
    .insp-smry-cnt {
      width: 20%;
      margin: 0px 5px;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      .insp-smry-lvl-header {
        display: flex;
        flex-direction: row;
        border-bottom: 2px solid #f7b844;
        width: 100%;
        height: 15%;
        justify-content: center;
        flex-direction: center;
        align-items: center;

        .insp-smr-svg-outer {
          fill: #f7b844;
          width: 50px;
          height: 50px;
        }
        span {
          color: #f7b844;
          font-size: 18px;
          font-weight: 700;
          text-align: center;
        }
      }
      .insp-smrt-lvl-cntt {
        height: 85%;
        color: #f7b844;
        font-size: 18px;
        font-weight: 700;
      }
    }
  }
}

.insp_selected_mod {
  justify-content: flex-start !important;
  // height: 195px !important;
}
