$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;

.dashboard-comingup-wrapper {
}

.dashboard-regular-block-wrapper {
  width: 100%;
  height: 100%;
  box-shadow: 2px 2.7px 1.1px -4px rgba(255, 255, 255, 0.093),
    2px 3.3px 3px -5px rgba(255, 255, 255, 0.06),
    2px 2.9px 6.6px -4px rgba(255, 255, 255, 0.167),
    2px 18.3px 14.4px -5px rgba(255, 255, 255, 0.05),
    2px 17px 19px -10px rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column-reverse;
  .dashboard-regular-block-top {
    height: 85%;
    width: 100%;

    background-color: rgba(255, 255, 255, 0.3);
  }
  .dashboard-regular-block-bottom {
    height: 15%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6ec6ed;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
    span {
      color: #09132b;
      display: flex;
      align-items: center;
    }
    .dashboard-svg-outerlayer {
      width: 25px;
      height: 25px;
      fill: #09132b;
      // fill: rgba(255, 255, 255, 0.8);
      margin-right: 15px;
    }
  }
}

.dashboard-comingup-wrapper {
  height: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.dashboard-tripsthisyear-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  & > div {
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    &:hover {
      // background-color: #f7b844;

      span {
        color: #f7b844;
        // color: white;
      }
    }
    span {
      color: white;
      font-size: 16px;
    }
    .trip-list-wrapper {
      margin-left: 33px;
      display: flex;
      flex-direction: row;
      .trip-list-date {
        width: 70px;
        text-transform: uppercase;
      }
      .trip-name {
        margin-left: 10px;
        max-width: 226px;
      }
    }
  }
}

.dashboard-impact-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  .dashboard-impact-block {
    width: 28%;
    height: 90px;
    padding: 10px 0px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    // border: 1px solid black;
    .dashboard-impact-svg-wrapper {
      height: 75%;
      display: flex;
      justify-content: center;
      .dashboard-impact-svg-outer {
        width: 50px;
        height: 50px;
        fill: #ffffff;
      }
    }
    .dashboard-impact-text-wrapper {
      width: 100%;
      height: 25%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .dashboard-impact-text-top {
        color: white;
      }
      .dashboard-impact-text-bottom {
        color: rgba(255, 255, 255, 0.5);
        font-size: 14px;
      }
    }
  }
}

.dashboard-community-item-wrapper {
  width: 100%;
  height: 100%;
  color: white;
  font-size: 18px;
  display: flex;
  align-items: center;
  ul {
    margin: 20px 0px;
    list-style-type: none;
    // padding: none !important;
    padding-right: 40px;
  }
  ul > li {
    text-indent: -5px;
    margin: 10px 0px;
  }
  ul > li:before {
    content: "-";
    text-indent: -5px;
  }
}

.dashboard-linkedapps-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  .dashboard-linkedapps-blocks {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    img {
      width: 40px;
      height: 40px;
      object-fit: contain;
    }
    span {
      color: white;
    }
  }
}

.short-block-wrapper {
  height: 45%;
  width: 100%;
  box-shadow: 2px 2.7px 1.1px -4px rgba(255, 255, 255, 0.093),
    2px 3.3px 3px -5px rgba(255, 255, 255, 0.06),
    2px 2.9px 6.6px -4px rgba(255, 255, 255, 0.167),
    2px 18.3px 14.4px -5px rgba(255, 255, 255, 0.05),
    2px 17px 19px -10px rgba(255, 255, 255, 0.1);
  cursor: pointer;
  .dashboard-short-block-top {
    height: 70%;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: rgba(255, 255, 255, 0.3);
  }
  .dashboard-short-block-bottom {
    height: 30%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6ec6ed;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    span {
      color: #09132b;
      display: flex;
      align-items: center;
    }
    .dashboard-svg-outerlayer {
      width: 20px;
      height: 20px;
      fill: #09132b;
      margin-right: 15px;
    }
  }
}

.dashboard-library-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  // justify-content: flex-end;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .dashboard-library-block-contents-bottom {
    margin: 2px;
    span {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.3);
    }
  }
  .dashboard-library-block-contents-middle {
    margin: 2px;
    text-align: center;
    span {
      font-size: 16px;
      color: white;
      text-align: center;
    }
  }

  .dashboard-library-block-contents-top {
    margin: 2px;
    span {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.3);
    }
  }
}
.dashboard-friends-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  div {
    width: 100%;
    display: flex;
    justify-content: center;
    span {
      color: white;
      .dashboard-svg-outerlayer {
        width: 20px;
        height: 20px;
        fill: rgba(255, 255, 255, 0.4);
        margin-right: 15px;
      }
    }
  }
}

.svg-bottom-adder-button {
  width: 25px;
  background-color: transparent;
  border: none;
  height: 25px;
  cursor: pointer;
  // margin-left: 127px;
  &:focus {
    border: none;
    outline: none;
  }
  .dashboard-bottom-svg-outer {
    width: 100%;
    height: 100%;
    fill: rgba(9, 19, 43, 0.5);
    // fill: #09132B;
  }
  &:hover {
    .dashboard-bottom-svg-outer {
      fill: #f7b844;
    }
  }
}
.custom-spacing-container {
  width: 100%;
  justify-content: space-evenly;
  display: flex;
  & > div {
    width: 25%;
  }
  & > span {
    width: 60%;
  }
  & > button {
    width: 25%;
  }
}
.custom-bottom-spacing {
  justify-content: center;
  font-size: 16px;
}

.dashboard-svg-outerlayer {
  fill: #09132b;
}

.svg-darkblue {
  fill: #09132b;
}

.svg-lightblue {
  fill: #6ec6ed;
}

.dashboard-bottom-svg-outer {
  width: 25px;
  height: 25px;
  fill: #09132b;
  // fill: rgba(255, 255, 255, 0.8);
  margin-right: 5px;
  margin-top: 3px;
}

.modal--regular-block--getting-started {
  background-color: $var-color--light-blue;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-buildtrip-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .dashboard-buildtrip-svg-outer {
    fill: rgba(255, 255, 255, 0.4);
    width: 75px;
    height: 75px;
    cursor: pointer;
    &:hover {
      fill: #f7b844;
    }
  }
}

.mod-inv-svg-outer {
  width: 25px;
  height: 25px;
  fill: #6ec6ed;
}

.dashboard--getting-started-ele {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row-reverse;
  justify-content: flex-end !important;
  height: auto;
  & > span {
    display: flex;
    align-items: center;
    margin-left: 5px;
  }
  & > svg {
    margin-right: 5px;
    margin-left: 33px;
  }
}

.dashboard-block__dark-orange {
  height: 100%;
  background-color: #da4431;
  // .svg-darkwhite {
  //       fill: rgba(255, 255, 255, 0.4);

  // }
  .svg-lightwhite {
    fill: #da4431;
  }
}

.dashboard--launchpad--wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  .friend-svg {
    svg {
      width: 26px !important;
    }
  }
  .trip-svg {
    svg {
      width: 34px !important;
    }
  }
  height: 100%;
  .dashboard--launchpad--container {
    display: flex;
    width: 35%;
    height: 35%;
    margin: 10px;
    background-color: rgba(255, 255, 255, 0.4);
    flex-direction: column;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      background-color: #f7b844;
    }
    .dashboard--launchpad--svg__outer,
    & > svg {
      width: 40px;
      height: 40px;
      fill: rgba(255, 255, 255, 0.4);
      path {
        fill: rgba(255, 255, 255, 0.4);
      }
    }
    .dashboard--launchpad--svg__outer-library2 {
      width: 34px !important;
      height: 40px;
      fill: rgba(255, 255, 255, 0.4);
      path {
        fill: rgba(255, 255, 255, 0.4);
      }
    }
    & > span {
      color: white;
      font-size: 13px;
    }
  }
}

.linked-app-links {
  width: 35%;
  height: 35%;
  margin: 10px;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  &:hover {
    background-color: #f7b844;
  }
  & > div {
    width: 100%;
    height: 100%;
    margin: 0;
    background-color: transparent !important;
  }
}

.notfilled-in {
  svg {
    fill: #f7b844 !important;
  }
}

.svg-darkorange {
  fill: "#da4431";
}

.svg-lightblue {
  fill: $var-color--light-blue;
}

.dashboard-block-bottom-comming-soon {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .coming-soon-text {
    font-size: 10px;
    margin-top: -5px;
    color: rgba(9, 19, 43, 0.6) !important;
  }
}

.launchboard-coming-soon-text {
  font-size: 8px !important;
}
