.pick-time-section-headers {
  margin-top: 10px;
  span {
    font-size: 20px;
    color: white;
  }
}

.time-picker-modal {
  max-width: 380px !important;
  border-radius: 10px;
  height: 316px !important;
  .time-picker-modal-time-picker {
    width: 224px !important;
  }
  form {
    & > div {
      &:nth-child(1),
      &:nth-child(2) {
        margin: 15px;
      }
    }
  }
}

.modal-time-picker-parent {
  // color: black;
  color: white;
  // overflow: hidden;
  & > div {
    // background: white;
  }
  label,
  legend {
    color: white;
  }
  & > div > input {
    color: white;
    border: white;
  }
  input[type='time']::-webkit-calendar-picker-indicator {
    filter: invert(100%) saturate(3207%) hue-rotate(130deg) brightness(95%)
      contrast(80%);
  }
}
