.stepdescriber-wrapper{
      height: 169px;
    text-align: center;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 22px 0px;
    width: 350px;
    cursor:pointer;
    // border: 1px solid white;
    // border-radius: 40px;
    // background-color: #2E364B;
  .stepdescriber-top{
    margin: 5px 0px;
    height: 70px;
    display:flex;
    align-items: flex-end;
    width: 100%;
    justify-content: center;
    span{
      font-size: 18px;
      color:#F7B844;
      font-weight:700;
      margin-left:10px;
    }
  }
  .stepdescriber-middle{
    margin: 5px 0px;

       span{
    color:white;
    font-size:20px;
    }
  }
  .stepdescriber-bottom{
    margin: 5px 0px;

     span{
      color: rgba(255,255,255,0.6);
      font-size: 18px;
    
    }
  }
  .homepage-step-svg-outer{
    fill: #F7B844;
    width:70px;
    height:70px;
  }
}

.remove-top-margin{
  margin-top:0px!important;
}

.top-section-second > h1 {
    color: #FFFFFF;
    margin-bottom: 0px;
    margin-top: 42px;
}