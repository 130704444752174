.career-block-text-blocks{
  width: calc(100% - 150px);
  margin: 0px 20px;
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top:30px;
}
.apply-link-career{
  color: black;
  // margin-left: 30px;
  font-size: 16px;
  margin-top: 20px;
  img{
    width: 20px;
    height:20px;
    margin-left: 10px;
  }
  &:hover{
    background-color: #6EC6ED;
    color:#09132B;
  }
}