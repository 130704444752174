@mixin unselectedButtonDarkBlue {
  width: 100px;
  height: 25px;
  background-color: $green7;
  border: none;
  outline: none;
  cursor: pointer;
  color: white;
  font-size: 0.9em;
  border: 1px solid $green7;
  margin: 0px 4px;
  margin-top: 10px;
  border-radius: 5px;
}
@mixin selectedButtonLightBlue {
  border: 1px solid $warm7;
  background-color: $warm7;
  color: #09132b;
}
