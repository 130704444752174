$breakpoint-alpha: 480px; // adjust to your needs

.my-impact-summary-page {
  width: 90%;
  border-radius: 5px;
  margin: 0 auto;
  position: relative;
  @media (max-width: $breakpoint-alpha) {
    width: 100%;
  }
  &.customMade {
    height: max-content;
  }
  &.muiDataGrid {
    height: 635px;
    border: 1px solid black;
  }
}

.my-impact-summary-header {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  & > span {
    font-size: 17px;
    font-weight: 700;
  }
  .my-impact-summary-header-buttons-wrapper {
    width: 20%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    & > button {
      background-color: transparent;
      border: none;
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
    }
    .my-impact-svg-outer {
      width: 32px;
      height: 32px;
      &.-download {
        width: 20px;
        height: 20px;
      }
      &.-print {
        margin-top: 5px;
      }
      path {
        fill: rgba(0, 0, 0, 0.7);
      }
    }
  }
}

.summary-wrapper {
  * {
    box-sizing: border-box;
  }
  .brief-summary {
    width: 90%;
    height: max-content;
    display: block;
    box-sizing: border-box;
    margin: 0 auto;
    @media (max-width: $breakpoint-alpha) {
      .brief-summary-header {
        margin-top: 30px;
        align-items: center;
        & > span {
          font-size: 30px;
        }
      }
    }
    .brief-summary-header {
      box-sizing: border-box;
      width: 100%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      @media (max-width: $breakpoint-alpha) {
        margin-top: 30px;
        align-items: center;
        & > span {
          font-size: 30px;
        }
      }
      .date-text {
        margin-top: 20px;
      }
    }
    .brief-summary-container {
      box-sizing: border-box;

      display: block;
      width: 100%;
      padding-top: 0px;
      position: relative;

      .report-summary-container {
        display: flex;
        box-sizing: border-box;
        vertical-align: middle;
        z-index: 2;
        align-items: flex-start;
        flex-direction: column;
        @media (max-width: $breakpoint-alpha) {
          align-items: center;
        }
      }

      .report-logo-container {
        width: 70%;
        margin: auto 0;
        position: absolute;
        top: 200px;
        right: -20%;
        opacity: 0.1;
        z-index: 1;
        .report-log {
          margin: auto;
          width: 100%;
          height: auto;
          object-fit: contain;
        }
        @media (max-width: $breakpoint-alpha) {
          width: 130%;

          top: 363px;
          right: -55%;
        }
      }
      .report-summary-container {
        width: 100%;
        .summary-row {
          display: block;
          &.dollars {
            width: 58%;
            .dollar-cards {
              width: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              .dollar-card-spacer {
                width: 50px;
              }
            }
            .dollars-line {
              background-color: $green7;
            }
          }
          &.svgs {
            width: 100%;
            display: flex;
            justify-content: center;
            @media (max-width: $breakpoint-alpha) {
              flex-direction: column;
            }
            .summary-card {
              width: 45%;
              height: max-content;
              display: flex;
              align-items: center;
              @media (max-width: $breakpoint-alpha) {
                width: 100%;
              }
              .card-left,
              .card-right {
                display: inline-block;
                vertical-align: center;
              }
              .card-left {
                margin-right: 30px;
                border: 10px solid $warm1;
                padding: 30px;
                border-radius: 50%;
                width: 150px;
                height: 150px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .card-right {
                width: calc(100% - 180px);
                text-align: center;
                span {
                  text-align: center;
                }
                @media (max-width: $breakpoint-alpha) {
                  p.fs-45 {
                    font-size: 35px;
                  }
                  p.fs-28 {
                    font-size: 20px;
                  }
                }
              }
              .report-svg-outer {
                width: 100%;
                height: auto;
                path {
                  fill: $green7;
                }
              }
            }
          }
        }
      }
    }
  }
}
.report-wrapper {
  z-index: 3;
  .my-impact-summary-header-buttons-wrapper {
    z-index: 5;
  }
}

.adventure-impact-chart {
  z-index: 10;
  * {
    z-index: inherit;
  }
  .check-logo {
    width: 30px;
    height: 30px;
    margin: 0 41%;
    background-color: white;
    border-radius: 50%;
  }
  .check-logo-placeholder {
    width: 30px;
    height: 30px;
    margin: 0 41%;
  }
  .go-button {
  }
}
