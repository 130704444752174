.unsubscribed-block{
     display:flex;
    flex-direction:  column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 95%;
    margin: 0 auto;
    border: 1px solid white;
    border-radius: 40px;
    margin-top: 20px;
    background-color: #2E364B;
    padding: 15px 0px;
    color: white;
    div{
      margin-left: 20px;
    }
    .unsubscribed-header{
      font-size: 32px;
    }

    .unsubscribed-text{
      margin: 10px 20px;
    }
}