.user-avatar {
  width: 45px;
  height: 45px;
  // @media screen and (max-width: 450px) {
  //   width: 30px;
  //   height: 30px;
  // }
  img  {
    width: 45px;
    height: 45px;
    // @media screen and (max-width: 450px) {
    //   width: 30px;
    //   height: 30px;
    // }
  }
}