//screensize bigger than normal laptops
@media screen and (min-width: 1441px) {
}
@media screen and (max-width: 1440px) {
}
// screen#1 iPad pro vertical
@media screen and (max-width: 1024px) {
}
// screen#2 ipad vertical

@media screen and (max-width: 768px) and (max-width: 860px) {
  #log-in-page-wrapper {
    .log-in-block {
      & > div {
        &:nth-child(1) {
        }
        &:nth-child(2) {
          margin-bottom: 5px;
        }
      }
      .login-input-dist {
      }
    }
  }
}
// screen#3  surface duo

@media screen and (min-width: 481px) and (max-width: 767px) {
  #log-in-page-wrapper {
    .log-in-block {
      & > div {
        &:nth-child(1) {
        }
        &:nth-child(2) {
          margin-bottom: 5px;
        }
      }
      .login-input-dist {
      }
    }
  }
}
// screen#4 iphone 12 pro max,
@media screen and (min-width: 415px) and (max-width: 480px) {
  #log-in-page-wrapper {
    .log-in-block {
      & > div {
        &:nth-child(1) {
        }
        &:nth-child(2) {
          margin-bottom: 5px;
        }
      }
      .login-input-dist {
      }
    }
  }
}
// screen#5 iphone 11 && pixel 2 && pixel2 xl iphone 6/7/8, iphone x
@media screen and (min-width: 391px) and (max-width: 414px) {
  #log-in-page-wrapper {
    .log-in-block {
      & > div {
        &:nth-child(1) {
        }
        &:nth-child(2) {
          margin-bottom: 5px;
        }
      }
      .login-input-dist {
      }
    }
  }
}
// screen#6 iphone 12, iphone 12 pro, iphone 6/7/8
@media screen and (min-width: 361px) and (max-width: 390px) {
  #log-in-page-wrapper {
    .log-in-block {
      & > div {
        &:nth-child(1) {
        }
        &:nth-child(2) {
          margin-bottom: 5px;
        }
      }
      .login-input-dist {
      }
    }
  }
}
// screen#7 iphone 12 mini && Moto G4 && Galaxy S9 && galaxy s5 galaxy s9
@media screen and (min-width: 321px) and (max-width: 360px) {
  #log-in-page-wrapper {
    .log-in-block {
      & > div {
        &:nth-child(1) {
        }
        &:nth-child(2) {
          margin-bottom: 5px;
        }
      }
      .login-input-dist {
      }
    }
  }
}
// screen#8 iphone 5/se, galaxy fold
@media screen and (min-width: 280px) and (max-width: 320px) {
  #log-in-page-wrapper {
    .log-in-block {
      & > div {
        &:nth-child(1) {
        }
        &:nth-child(2) {
          margin-bottom: 5px;
        }
      }
      .login-input-dist {
      }
    }
  }
}
