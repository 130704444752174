.chat-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  .mod-cnt-def {
    height: calc(100% - 30px) !important;
    max-height: none;
  }
  .chat-info-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      color: white;
    }
    .chat-user-img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
}
