$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);

.block-modal-lists {
  display: flex;
  flex-direction: column;
  width: 91%;
  height: 481px;
  margin: 0 auto;

  justify-content: flex-start;
  .block-modal-add-button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.4);
    .block-modal-lists-add-svg-outer {
      fill: rgba(255, 255, 255, 0.5);
      width: 25px;
      height: 25px;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.7);
      .block-modal-lists-add-svg-outer {
        fill: #f7b844;

        svg {
          fill: #f7b844;
        }
      }
    }
  }
  .block-modal-lists-contents {
    height: 80%;
    width: 100%;
    overflow-y: scroll;
    background-color: rgba(255, 255, 255, 0.4);
    margin: 5px 0px;
    border-radius: 10px;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;

    &::-webkit-scrollbar {
      display: none;
    }
  }
  .block-modal-list-create-wrapper {
    height: 140px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
  }
}
