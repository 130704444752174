.map-box-module-wrapper {
  width: 100%;
  height: 70vh;
  position: relative;
  .map-container {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: none !important;
    & > div.mapboxgl-canvas-container {
      width: 100%;
      height: 1000px;
      canvas.mapboxgl-canvas {
        width: 100% !important;
        overscroll-behavior: contain;
        height: 100% !important;
        aspect-ratio: auto !important;
      }
    }
  }
}

.no-backbutton {
  .mapboxgl-ctrl-top-left {
    top: 0px !important;
  }
}

.mapboxgl-ctrl-attrib-inner, .mapboxgl-compact {
  display: none;
}

.itinerary-map-container {
  width: 100%;
  height: 100%;
  // border: 1px solid #273a07;
  & > div.mapboxgl-canvas-container {
    width: 100%;
    height: 100%;
    canvas.mapboxgl-canvas {
      width: 100% !important;
      overscroll-behavior: contain;
      height: 100% !important;
      // aspect-ratio: 2 / 1 !important;
    }
  }
}
