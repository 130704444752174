.tooltip-button-container {
  & svg {
    cursor: pointer;
  }
}

.sm {
  width: 23px;
  height: 23px
}

.md {
  width: 25px;
  height: 25px
}

.lg {
  width: 30px;
  height: 30px;
}

.list-button-add {
  & svg {
    color: #b34104 !important;
  }
  & svg path {
    color: #b34104 !important;
    fill: #b34104 !important;
  }
}
