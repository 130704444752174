$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;

@keyframes cssAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes cssAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

.badge-friend-request-wrapper {
  width: 100%;
  min-height: 64px;
  margin-bottom: 10px;
  background-color: #09132b;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  height: 60px;
  -moz-animation: cssAnimation 0.5s ease-in 7s forwards;
  /* Firefox */
  -webkit-animation: cssAnimation 0.5s ease-in 7s forwards;
  /* Safari and Chrome */
  -o-animation: cssAnimation 0.5s ease-in 7s forwards;
  /* Opera */
  animation: cssAnimation 0.5s ease-in 7s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  &--display {
  }
  &--undisplay {
  }
  .badge-image-wrapper {
    height: 100%;
    width: 50px;
    margin-left: 5px;
    margin-left: -21px;

    img {
      width: auto;
      height: 100%;
      border-radius: 50%;
    }
  }
  .badge-title-wrapper {
    width: calc(100% - 42px);
    margin-left: 14px;
    height: 100%;
    align-items: center;
    display: flex;
    span {
      color: white;
    }
  }
}

.adventure-invites-wrapper {
  position: relative;
}

.adventure-invite-opacity-layer {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(248, 247, 213, 0.4);
}
