.mod-inv-friends-lodestar-wrapper {
  width: 80%;
  display: flex;
  flex-direction: column;
  .account-group-modal-friends--wrapper {
    width: 100%;
    height: 250px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
