.create-signup-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;

    height: 290px;

  h1{
    font-weight: 700;
  }
  a{
    text-decoration:none;;
  }

  .c-r-s-middle{
    width: 150px;
    height: 30px;
    border-radius: 30px;
    display:flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color:#DA4431;
    margin:20px;
  }

  .cr-text{
    font-size: 24px;
  }
}