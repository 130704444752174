@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.lazy-loader-wrapper {
  height: calc(100% - 83px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #11304cba;
  .lazy-loader {
    // animation: rotation 1s infinite linear;
    width: 100%;
    height: 100%;
    background-color: transparent;
  }
  .loaded {
    // width: 100%;
    // height: 100%;
    object-position: center;
    // objec: no-repeat;
    object-fit: cover;
    animation: none;
    background-color: transparent;
  }
  .has-error {
    animation: none;
  }
  .no-image-error {
    width: 75px !important;
    height: 75px !important;
    background-color: transparent;
  }
}
