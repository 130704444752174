.mod-share-lodestar-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.mod-share-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;

  .mod-share-socmed-icon-wrapper {
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 50%;
    margin: 20px 0px;
  }
}
