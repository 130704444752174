$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;

.account-profile-container {
  min-height: 75vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: $var-color--default-buttons;
  .account-profile-body {
    width: 100%;
    height: 100%;
  }
}
