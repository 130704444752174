$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;

.hub-custom-btns-ctn {
  display: flex;
  margin-right: 5px;
  align-items: flex-start;
  text-align: center;

  & > span {
    text-align: center;
    font-size: 11px;
  }
  .hub-custom-btn {
    // width: 50px;
    // height: 30px;
    border: none;
    background: none;
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    &:focus {
      outline: none;
    }
    .hub-custom-btn-img {
      margin-top: 10px;
      height: 30px;
      width: 50px;
    }
  }
  &:hover {
    &:hover:before {
      display: block;
    }
  }
}

.custom-btn-disp-tt {
  &:before {
    content: attr(data-text); /* here's the magic */
    position: absolute;

    /* vertically center */
    //top:50%;
    transform: translateY(-135%);

    /* move to right */
    //left:100%;
    margin-left: -52px; /* and add a small left margin */

    /* basic styles */
    width: 100px;
    padding: 3px;
    border-radius: 5px;
    //background:rgba(9, 19, 43, 0.6);
    background: rgba(247, 184, 68, 0.6);
    //color: rgba(255, 255, 255, 1.0);
    //color:rgba(247, 184, 68, 1.0);
    color: rgba(9, 19, 43, 0.8);
    text-align: center;

    display: none; /* hide by default */
  }
}

.custom-btn-floater {
  margin-bottom: 20px;
}

.custom-btn-nav {
  margin-bottom: 15px;
}

.custom-btn-svg {
  fill: white;
  cursor: pointer;
  &:hover {
    fill: #f7b844;

    svg,
    path,
    g {
      width: 25px;
      height: 25px;
      cursor: pointer;
      &:hover {
        fill: #f7b844;
      }
    }
  }
}
.custom-btn-svg {
  &:hover {
    fill: #f7b844;

    svg,
    path,
    g {
      width: 25px;
      height: 25px;
      cursor: pointer;
      &:hover {
        fill: #f7b844;
      }
    }
  }
}
.custom-btn-etra-flt {
  margin-top: 30px;
  margin-right: 20px;
  svg,
  path,
  g {
    width: 25px;
    height: 25px;
    cursor: pointer;
    &:hover {
      fill: #f7b844;
    }
  }
}

.custom-btn-svg-ptn {
  svg {
    height: auto !important;
    width: 15px;
    height: 15px;
  }
}

.custom-btn-centered-vertically {
  & > button {
    align-items: flex-end !important;
    & > svg {
      margin-bottom: -6px;
    }
  }
}

.custom-btn-svg-muted-color {
  & > button {
    & > svg {
      fill: #254562;
    }
  }
}
.custom-btn-itinerary-tooltip {
  &::before {
    content: attr(data-text);
    position: absolute;
    transform: translateY(-135%);
    margin-left: -17px;
    margin-top: 21px;
    width: 52px;
    padding: 3px;
    border-radius: 5px;
    // background: rgba(247, 184, 68, 0.6);;
    background: $var-color--light-orange;
    color: rgba(9, 19, 43, 0.8);
    text-align: center;
    /* top: -12px; */
    display: none;
    margin-bottom: -64px;
  }
  &:hover::before {
    display: inline-block;
  }
}

.upspaced-close-button {
  &::before {
    margin-top: 32px !important;
  }
}
