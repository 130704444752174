.coming_soon_component_middle_title_wrapper {
  width: calc(100% - 20px);
  // width: 100vw;
  background-color: #f7b844;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  height: auto;
  margin-top: 0px;
  .coming_soon_component_middle_title_padder {
    padding: 10px 0px;
    text-align: center;
    h2 {
      // font-size: 18px;
      color: black;
    }
  }
}
