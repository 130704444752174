@import 'reset.scss';

@import 'typography.scss';
@import 'animations';
@import 'state_changes';
@import 'layout';

/* width */
::-webkit-scrollbar, ::-moz-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track, ::-moz-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb, ::-moz-scrollbar-track {
  background: #aaa;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover, ::-moz-scrollbar-thumb:hover {
  background: #999;
}

@media screen and (min-width: 980px) {
  /* Set the background color of the address bar */
  :root {
    --address-bar-color: #729F9E;
  }
}
