$var-color--light-orange: #f7b844 !global;
$var-color--dark-orange: #da4431;
$var-color--dar-blue: #09132b;
$var-color--light-blue: #6ec6ed;
$var-color--default-buttons: rgba(255, 255, 255, 0.4);
$var-color--button-unselected-blue: #264562;
$var-color--modal-accordion-color: #589ebd;
$var-color--itinerary-accordion-itinerary-item-button: #3a4256;
$var-dark-green: #273a07;
$breakpoint-alpha: 480px; // adjust to your needs

$var-tab-background: #dfd5c1;
//colors for dashbaord
$color-dashboard-item: #273a07;
$color-dashboard-highlight: #d18000;
$color-block-hover-background: #dfd5c1;

// colors  #####################

// not in pallete
$burgundy: #b43f00;
// greens
$green1: #80aea8;
$green2: #2a8e7f;
$green3: #0e5144;
$green4: #08463a;
$green5: #827700;
$green6: #364301;
$green7: #273a07;
$green8: #333333;

// warms
$warm1: #eae094;
$warm2: #fcce13;
$wamr3: #cea502;
$warm4: #de8d0e;
$warm5: #be6700;
$warm6: #b43f00;
$warm7: #d18000;
$warm8: #eece25;
$warm9: #b34104;
$warm10: #ffc700;

// cools
$cool1: #62819e;
$cool2: #51657a;
$cool3: #3f566e;
$cool4: #3c4a59;
$cool5: #002249;
$cool6: #08173d;
$cool7: #2d5559;
// neutrals
$neutral1: #f5eddf;
$neutral2: #fcedd6;
$neutral3: #e8d5b9;
$neutral4: #dfd5c1;
$neutral5: #fcedd6bb;
$neutral6: #7c7c7c;

//seasonal pops
$seasonal1: #d10366;
$seasonal2: #c5a9ff;
$seasonal3: #b9cd3e;
$seasonal4: #9ddbf0;
$seasonal5: #827700;

//others
$unselected-text: #cdcdcd;

//FONTSSSSS #####################
//fonts

$nav-font: 'Soleil, san-seriff';
$default-font: 'Soleil, san-seriff';
$soleil: 'Soleil san-seriff';
$soleilBold: 'Soleil Bold';
$soleilSemibold: 'Soleil Semibold';
$albionaExtralight: 'Albiona ExtraLight';
$albionaLight: 'Albiona Light';

/// BACKGROUND COLORS
// $bannerBackground: #e8d5b9e7;
$bannerBackground: rgba(232, 213, 185, 0.2);
$navBackground: #faf7f1;
$navBackground2: #faf7f1b8;

//indicators

$featureIndicatorInactive: #f8d296;
$featureIndicatorActive: #b34104;

//height
$fullBodyHeight: calc(100vh - 240px);

//button
$defaultGray: #a7a7a7;
