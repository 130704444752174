@import 'profile';
.account-main-wrapper {
  // width: 100vw;
  height: 100%;
  position: fixed;
  display: flex;
  z-index: 4;
  justify-content: center;
  .account-menu-wrapper {
    // width: 100vw;
    max-width: 1440px;
    left: 0;
    display: flex;
    justify-content: flex-start;
    .account-menu {
      // background: $var-color--dar-blue;
      background-color: white;
      height: calc(100% - 88px);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      &--browser {
        width: 270px;
      }
      &--mobile {
        width: 100vw !important;
        height: calc(100% - 88px);
      }
      .account-menu-divider-wrapper {
        display: flex;
        height: auto;
        align-items: center;
        width: 100%;
        justify-content: center;
        flex-direction: row;
        margin-top: 10px;
        span {
          color: $green7;
          font-size: 12px;
          letter-spacing: 3px;
        }
        hr {
          border: none;
          color: $green7;
          background-color: $green7;
          height: 1px;
          width: 25%;
        }
      }
      .account-menu-section {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: auto;
        align-items: flex-start;
        justify-content: center;
        padding-top: 20px;
        &--header {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          span {
            font-size: 12px;
            letter-spacing: 3px;
          }
          .account-menu-cog-outer {
            width: 35px;
            height: 35px;
            path {
              fill: rgba(255, 255, 255, 0.4);
            }
            margin-left: -1px;
          }
          span {
            // font-size: 18px;
            color: $green7;

            margin-right: 84px;
          }
        }
        &--membership {
          margin-top: 10px;
        }
        &--billing {
          margin-top: 10px;
        }
        &--settings {
          margin-top: 10px;
        }

        .account-menu-link {
          color: $green7;
          font-size: 12px;
          // margin: 4px 0x;
          margin: 4px;
          // padding: 5px 0 5px 0;
          width: 90px;
          height: 90px;
          // border: 1px solid $green7;
          color: $green7;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-top: 10px;
          border-radius: 10px;
          flex-direction: column;
          .account-menu-svg-outer {
            width: 60px;
            height: 50px;
            &.account {
              width: 50px;
            }
            &.membership {
              width: 50px;
            }

            path {
              // fill:rgba(255,255,255,0.7);
              fill: $green7;
            }
          }
          &:hover {
            color: $warm7;
            // fill: $warm7;
            cursor: pointer;
            path {
              fill: $warm7;
            }
            // color: $var-color--light-orange;
            // box-shadow: 4px 4px 1px rgba(0, 0, 0, 0.4);
            // background-color: $warm7
            background-color: $color-block-hover-background;
            box-shadow: 4px 4px 3px $color-block-shadow;
            fill: $color-dashboard-highlight;
            // path {
            //   fill: $var-color--light-orange;
            // }
          }
          &--active {
            // text-decoration: underline 2px $var-color--light-orange;

            svg {
              fill: $var-color--light-orange;

              path {
                fill: $var-color--light-orange !important;
              }
            }
            color: $var-color--light-orange;
          }
        }
      }
    }
  }
}
// @media screen and (min-width: 1510px) {
//   .account-main-wrapper {
//     left: calc((100vw - 1510px) / 2);
//   }
// }
.version-wrapper {
  position: fixed;
  bottom: 0;
  margin: 0 auto;
  span {
    color: rgba(255, 255, 255, 0.3);
  }
}
